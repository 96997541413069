/* global localStorage, fetch, AbortController */
import { format } from 'date-fns'
import { isPaused } from '@fielded/fs-api/lib/invoices/tools'
import { splitValueAndDecimal } from '@fielded/shared-ui/src/components/PriceDisplay/PriceDisplay'

const storeKey = 'retail.next_visit'
function getNextCount (config) {
  let cachedValue = localStorage.getItem(storeKey)
  const today = format(new Date(), 'yyyy-LL-dd')

  if (cachedValue) {
    cachedValue = JSON.parse(cachedValue)

    if (!cachedValue) {
      // ***
      // heads-up: when things go wrong, cached value in localstorage can be 'null' (string)
      // which parses into null (null value)
      // do nothing
    } else if (cachedValue.date === null) {
      cachedValue = null
    } else if (cachedValue.date < today) {
      cachedValue = null
    }
  }

  // Let's set a timeout for the API call as well:
  const controller = new AbortController()
  const timeout = setTimeout(() => controller.abort(), 10000)

  const update = fetch(`${config.agaveApiUrl}/retail/next-visit`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    method: 'GET',
    signal: controller.signal
  })
    .then(response => {
      clearTimeout(timeout)

      if (!response.ok) {
        const error = new Error(`Api call returned ${response.statusText}`)
        error.status = response.status
        return Promise.reject(error)
      }

      return response.json()
    })
    .then(resp => {
      if (resp.date === null) {
        return null
      }

      if (resp.date < today) {
        return null
      }
      localStorage.setItem(storeKey, JSON.stringify(resp))
      return resp
    })
    .catch(e => {
      console.log('Failed fetching new date', e)
      return null
    })

  return {
    local: cachedValue,
    update
  }
}

function getProcessedAmount (amount) {
  let newAmount = Number(amount)
  const { decimalValue } = splitValueAndDecimal(amount)

  if (Number(decimalValue)) {
    newAmount *= 100
  }

  return newAmount
}

async function payInvoice (api, data, onResetLoading) {
  const { paymentProvider, amount, transaction, email } = data
  await api.payment.cancelPaymentDebit()

  let processedAmount = getProcessedAmount(amount) // amount with a significant decimal value
  const paymentResponse = await api.payment.create({
    paymentProvider,
    amount: processedAmount,
    email,
    metadata: {
      qboCreatedDate: transaction.created_at,
      qboDocNumber: transaction.txnId
    }
  }, () => onResetLoading()) // this only executes if the user closes the gateway without making any payments

  return api.payment.paygCreditPayment.captureInvoice({
    paymentData: paymentResponse,
    paymentProvider,
    transactionId: transaction.invoiceId
  })
}

async function payInstalment (api, data, onResetLoading) {
  const { paymentProvider, amount, transaction, email } = data
  await api.payment.cancelPaymentDebit()

  let processedAmount = getProcessedAmount(amount) // amount with a significant decimal value
  const paymentResponse = await api.payment.create({
    paymentProvider,
    amount: processedAmount,
    email,
    metadata: {
      paymentPlanId: transaction.loanDetails.id
    }
  }, () => onResetLoading()) // this only executes if the user closes the gateway without making any payments

  return api.payment.paygCreditPayment.captureInstalment({
    paymentData: paymentResponse,
    paymentProvider,
    paymentPlanId: transaction.loanDetails.id || transaction.loanDetails,
    amount: amount / 100
  })
}

export {
  getNextCount,
  isPaused,
  payInvoice,
  payInstalment
}
