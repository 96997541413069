import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { findShipments } from '../../../../../../root/reducers/shipments'
import { hasFeature } from '../../../../../../van-shared/utils/features'
import { withApi } from '../../../../../../common/ApiProvider'
import { withUser } from '../../../../../../common/AuthenticationProvider'
import withConfig from '../../../../../../van-shared/hoc/withConfig'
import { fetchMasterData, selectHasReceivedMasterData } from '../../../../../../root/reducers/shipments/master-data'
import ConfirmPickUp from './ConfirmPickUp'

const ConfirmPickUpContainer = ({
  api,
  user,
  config,
  history
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const hasReceivedMasterData = useSelector(selectHasReceivedMasterData)

  if (!location.state) {
    return <Redirect to='/' />
  }

  const [loading, setLoading] = useState(true)
  const formRef = useRef()

  const { packedDeliveries, selectedDeliveries } = location.state
  const situation = packedDeliveries.length === selectedDeliveries.length ? 'ready' : 'caution'
  const driverName = get(user, 'fullName', '')

  const initialize = async () => {
    if (!hasReceivedMasterData) {
      await dispatch(fetchMasterData())
    }
    setLoading(false)
  }

  const onProceed = async () => {
    const formValidationErrors = await formRef.current.validate()
    const fields = formRef.current.getFields()
    const payload = {
      ...fields,
      shipments: selectedDeliveries
    }

    if (!formValidationErrors) {
      // Advance selected shipments
      try {
        const updatedSnapshots = await api.shipment.bulkAdvanceSnapshots(payload)
        if (updatedSnapshots && updatedSnapshots.length) {
          const userLocation = user.location
          const withListInGeoLocation = hasFeature(config.features, 'shipments:listInGeoLocation')
          const useRouteSpecificFilter = true
          const options = { noExternalShipments: true }

          // Update store
          await dispatch(
            findShipments(
              userLocation,
              withListInGeoLocation,
              useRouteSpecificFilter,
              options
            )
          )
        }
        history.push('/shipments/list')
      } catch (e) {
        throw new Error(`Error found: ${e}`)
      }
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <ConfirmPickUp
      formRef={formRef}
      history={history}
      situation={situation}
      deliveries={selectedDeliveries}
      driverName={driverName}
      onProceed={onProceed}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(ConfirmPickUpContainer)
