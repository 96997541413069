const keyBy = require('lodash/keyBy')
const { MEMBERSHIPS } = require('../../location/tools/constants')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')
const { TOPUP, ROUTINE } = require('../../shipment/tools/planning-types')

const VALIDATION_REMARKS = {
  failed: 'exceeds-loan-balance',
  success: 'within-loan-balance'
}

const calculateOrderTotalAmount = ({ order, productGroup, membershipType }) => {
  return Object.keys(order.products)
    .map(p => p.split(':')[1])
    .reduce((acc, sku) => {
      if (
        membershipType === MEMBERSHIPS.CLASSIC &&
        (order.products[`product:${sku}`].allocationType !== DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)
      ) {
        return acc
      }
      acc += (productGroup[sku].skuPrice * order.products[`product:${sku}`].adjusted) + productGroup[sku].vat
      return acc
    }, 0)
}

async function validatePlanning (api, { orders, productGroup }) {
  const productsWithNoPrice = Object.keys(productGroup)
    .filter(sku => !productGroup[sku].skuPrice)
    .map(sku => `product:${sku}`)

  const validatedOrders = await Promise.all(
    orders.map(async (order) => {
      // Store inital products cause we filter out some subtypes during this check
      const initalProducts = order.products
      try {
        order.creator = await api.user.get(order.createdBy)
        order.destination = {}
        const destination = await api.location.restAdapter.getByFSID(order.destinationId)
        if (destination) {
          Object.assign(order.destination, {
            location: {
              ...destination,
              name: destination.name_of_pharmacy
            }
          })
          if (destination.supply_chain_level === 'sdp') {
            const membershipType = destination.membership_type
            // exclude orders that contain products with no sellprice
            order.productsWithNoPrice = Object.keys(order.products).filter(p => productsWithNoPrice.includes(p))
            if (order.productsWithNoPrice.length) {
              order.shouldExcluded = true
            }
            // for classic customers, if loan limit = 0 or is null then we should skip validation
            // if it's basic bypass check as well
            if (
              (membershipType === MEMBERSHIPS.CLASSIC && !destination.loan_limit) ||
              order.orderType === DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE
            ) {
              order.remark = VALIDATION_REMARKS.success

              return order
            }

            // all products for classic-payg-only customer has to be pay_on_delivery
            if (membershipType === MEMBERSHIPS.CLASSIC_PAYG_ONLY) {
              const productTypes = Object.values(order.products).map(p => p.allocationType)
              if (!productTypes.every(p => p === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)) {
                order.shouldExcluded = true
                return order
              }
            }

            // consider only POD products in order for classic
            if (membershipType === MEMBERSHIPS.CLASSIC) {
              const podProducts = Object.keys(order.products)
                .filter(p => order.products[p].allocationType === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)
                .map(product => ({ ...order.products[product], sku: product }))
              order.products = keyBy(podProducts, 'sku')
            }

            try {
              let balance = await api.finances.getCreditInfo(order.destinationId, { excludeOrderId: order.orderId })
              balance = {
                ...balance,
                loanBalance: balance.availableCreditBalance,
                loanLimit: balance.creditLimit
              }
              order.totalAmount = calculateOrderTotalAmount({ order, membershipType, productGroup })
              if (order.orderType === ROUTINE) {
                order.shouldExcluded = order.totalAmount > balance.loanBalance || Math.abs(order.totalAmount - balance.loanBalance) > balance.loanBalance
              } else if (order.orderType === TOPUP) {
                // the TOPUP order amount has already been subtracted from the loanBalance from api
                order.shouldExcluded = balance.loanBalance < 0 || Math.abs(order.totalAmount + balance.loanBalance) > balance.loanLimit
              }
              order.remark = order.shouldExcluded ? VALIDATION_REMARKS.failed : VALIDATION_REMARKS.success
              order.shouldExcluded = !!order.productsWithNoPrice.length || order.shouldExcluded
            } catch (error) {
              console.warn(`cant get balance for ${order.destinationId}`, error)
            }
          }
        }
      } catch (error) {
        console.warn('Cant get creator', error, order.createdBy)
      }
      // Assign original products cause we want to display all sub types in planning module
      order.products = initalProducts
      return order
    })
  )
  const ordersValidated = validatedOrders.filter(order => order)
  return { orders: ordersValidated, productsWithNoPrice }
}

exports.validatePlanning = validatePlanning
exports.VALIDATION_REMARKS = VALIDATION_REMARKS
