/* global Event */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'
import keyBy from 'lodash/keyBy'

import { Loading } from '@fielded/shared-ui'

import { hasFeature } from '../../../van-shared/utils/features'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import withConfig from '../../../van-shared/hoc/withConfig'

import ReportView from './ReportView'
import { sortProductSL, sortProductByIndices } from '../common/utils'
import { userIsRetailer } from '../../../common/utils/user'
import { byProductLine, byProductLineSL } from '../../../root/reducers/reports/report-entry'

import {
  buildViewReportUrl,
  buildLocationsUrl,
  buildLedgerUrl,
  buildInventoryStatementUrl,
  buildSalesStatementUrl
} from '../app/routes-urls'

import {
  STATUS_OFFLINE
} from '../common/report-status'

class ReportViewContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      report: null,
      service: null,
      location: null,
      period: null,
      productIds: null,
      productsById: null,
      inventoryStatementUrl: null,
      salesStatementUrl: null
    }
  }

  componentDidMount () {
    window.dispatchEvent(new Event('van-hide-nav'))
    const { match, user } = this.props
    const reportId = match.params.reportId
    this.loadReport(reportId)
    const isRetailer = userIsRetailer(user)

    this.setState({isRetailer})
  }

  componentDidUpdate () {
    const { match } = this.props
    const { loading, report } = this.state
    const reportId = match.params.reportId
    if (loading || (report && report._id === reportId)) {
      return
    }
    this.setState({loading: true})
    this.loadReport(reportId)
  }

  componentWillUnmount () {
    window.dispatchEvent(new Event('van-show-nav'))
  }

  async loadReport (reportId) {
    const { api, config } = this.props

    const report = await api.report.get({ reportId })

    const productIds = Object.keys(report.stock)

    const [service, fields, location, products] = await Promise.all([
      api.service.get(report.serviceId),
      api.service.listReportFields(report.serviceId),
      api.location.get(report.location.id, report.createdAt),
      api.product.getByIds(productIds)
    ])

    const period = await api.report.period.get({
      program: service.program,
      date: new Date(report.date.period.effectiveStart),
      isEffectiveDate: true
    })

    const productsById = keyBy(products, '_id')

    const sortedProductIds = hasFeature(config.features, 'stockCount:singleProductLine')
      ? sortProductSL(service.id, report, productsById)
      : sortProductByIndices(service.id, report, productsById)

    const fieldsById = keyBy(fields, 'id')

    let inventoryStatementUrl
    let salesStatementUrl

    if (report.status !== STATUS_OFFLINE) {
      const statementUrlParams = {
        reportId: report._id,
        locationId: report.location.id,
        serviceId: service.id,
        date: period.effectiveEndDate.toJSON()
      }
      inventoryStatementUrl = buildInventoryStatementUrl(statementUrlParams)
      const hasSales = Object.values(report.stock).some(
        product => get(product, 'fields.field:shelflife-sold.amount') > 0
      )

      const hasRetainedProducs = report.partialCount && Object.values(report.stock).some(
        product => get(product, 'fields.field:sales-adjustments.amount') > 0
      )

      if (hasSales || hasRetainedProducs) {
        salesStatementUrl = buildSalesStatementUrl(statementUrlParams)
      }
    }

    // Add progress info to the report, where `complete` here means
    // that a count for a product was adjusted. We use the progress
    // properties to make this work with the product sidebar.
    let numAdjustedProducts = 0
    for (const reportProduct of Object.values(report.stock)) {
      const opening = get(reportProduct, 'fields.field:standard-opening-balance.amount')
      const balance = get(reportProduct, 'fields.field:standard-physical-count.amount')
      const buyout = get(reportProduct, 'fields.field:partner-buyout.amount')

      reportProduct.progress = {
        isComplete: !reportProduct.autoFilled || !!buyout,
        hasIssue: (balance || 0) > (opening || 0)
      }
    }
    report.progress = {
      total: Object.keys(report.stock).length,
      complete: numAdjustedProducts
    }

    this.setState({
      loading: false,
      report,
      service,
      location,
      period,
      productIds: sortedProductIds,
      productsById,
      fieldsById,
      inventoryStatementUrl,
      salesStatementUrl
    })
  }

  handleGoBack = () => {
    const { period, service, isRetailer } = this.state
    const { history, user } = this.props

    // If user is retailer we don't want them to go to location details...
    if (isRetailer) {
      history.goBack()
    } else {
      const url = buildLocationsUrl({locationId: user.location.id, date: period.entryStartDate, programId: service.program.id})
      history.push(url)
    }
  }

  handleGotoProductByOffset = (offset) => {
    const { report, productIds } = this.state
    const { match, history } = this.props

    const productId = match.params.productId
    const currentIndex = productIds.indexOf(productId)
    const newProductId = productIds[currentIndex + offset]

    const newUrl = buildViewReportUrl({reportId: report._id, productId: newProductId})
    history.push(newUrl)
  }

  handleGotoLedger = () => {
    const { service, location } = this.state
    const { match, history } = this.props
    const productId = match.params.productId
    const ledgerUrl = buildLedgerUrl({locationId: location._id, serviceId: service.id, productId})
    history.push(ledgerUrl)
  }

  render () {
    const {
      match,
      config,
      user
    } = this.props

    const {
      loading,
      report,
      service,
      location,
      period,
      productIds,
      productsById,
      inventoryStatementUrl,
      salesStatementUrl
    } = this.state

    if (loading) {
      return <Loading />
    }

    const productId = match.params.productId

    // If we have no productId redirect to the first adjusted product
    if (!productId) {
      const firstCompleteProductId = productIds.find(
        productId => report.stock[productId].progress.isComplete
      )
      const initialProductId = firstCompleteProductId || productIds[0]
      const redirectUrl = buildViewReportUrl({reportId: report._id, productId: initialProductId})
      return <Redirect to={redirectUrl} />
    }

    const products = productIds.map(id => productsById[id])
    const singleProductLine = hasFeature(config.features, 'stockCount:singleProductLine')
    const currentProduct = productsById[productId]
    const currentProductNumber = productIds.indexOf(productId) + 1

    let productLines = []
    if (singleProductLine) {
      productLines = byProductLineSL(report.stock, products, productId)
    } else {
      productLines = byProductLine(report.stock, products, service.id, service.name, productId)
    }

    const productHasAdjustments = report.stock[productId].progress.isComplete

    return (
      <ReportView
        service={service}
        location={location}
        period={period}
        report={report}
        config={config}
        user={user}

        productHasAdjustments={productHasAdjustments}
        products={products}
        productLines={productLines}
        currentProduct={currentProduct}
        currentProductNumber={currentProductNumber}

        hasShowLocationContact={hasFeature(config.features, 'stockCount:showLocationContact')}

        inventoryStatementUrl={inventoryStatementUrl}
        salesStatementUrl={salesStatementUrl}

        goBack={this.handleGoBack}
        gotoPreviousProduct={() => this.handleGotoProductByOffset(-1)}
        gotoNextProduct={() => this.handleGotoProductByOffset(1)}
        gotoProductByOffset={this.handleGotoProductByOffset}
        gotoLedger={this.handleGotoLedger}
      />
    )
  }
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withUser
)

export default withHOCs(ReportViewContainer)
