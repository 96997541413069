const { batchIdToProductId } = require('./product-batch-util')
const keyBy = require('lodash/keyBy')

const calculateShipmentVolumeAndPrice = async (shipment, mainApi) => {
  const productIds = [...Object.keys(shipment.counts).reduce((acc, productKey) => {
    const [productId] = productKey.split(':manufacturer')
    acc.add(productId)
    return acc
  }, new Set())]

  const allProductsDoc = await mainApi.product.getByIds(productIds)
  const productsById = keyBy(allProductsDoc, '_id')

  let totalVolume = 0
  let totalPrice = 0

  for (const batchId of Object.keys(shipment.counts)) {
    const productId = batchIdToProductId(batchId)
    const { quantity } = shipment.counts[batchId]

    // Get product details from productsById
    const product = productsById[productId]

    if (product) {
      const unitVolume = product.unitVolume || 0
      const unitPrice = product.unitPrice || 0
      totalVolume += quantity * unitVolume
      totalPrice += quantity * unitPrice
    } else {
      console.error(`Product ${productId} not found for shipment ${shipment.id}`)
    }
  }
  return { totalVolume, totalPrice }
}
exports.calculateShipmentVolumeAndPrice = calculateShipmentVolumeAndPrice
