const get = require('lodash/get')
const uniqBy = require('lodash/uniqBy')
const fromPairs = require('lodash/fromPairs')
const pluralize = require('pluralize')
const { format } = require('date-fns')
const { createNewOrders } = require('./create-new-orders')
const { calculateCutoffDateForPurchase } = require('../calculate-cutoff-date-for-purchase')
const { PAYMENT_STATUSES, PAYMENT_CHOICES, ORDER_TYPES_TO_SUPPLIER_TYPE, ORDER_TYPES } = require('../../constants')
const { smartId, byId } = require('../../../tools/other-imports')
const { nextDeliveryDateAfterCutOff } = require('../../../tools/route-planning-schedule')
const locationIdToProperties = require('../../../tools/location-id-to-properties')
const { DIRECT_ORDER_TYPES } = require('../../../allocation/config')
const PLANNING_TYPES = require('../../../shipment/tools/planning-types')
const { translateTerritoryAlias, productAliasesByMarket } = require('../../../product/tools')
const { isPaused: isUserPaused } = require('../../../invoices/tools')

const IMPORT_ORDER_TYPE = ORDER_TYPES.immediate_purchase

const IMPORT_PAYMENT_TYPES = {
  CREDIT: 'CREDIT',
  CASH: 'CASH'
}

const FREE_DELIVERY_MARKET_LIMIT = {
  // For now, there is no delivery threshhold but leaving this code as is as we might go back.
  'ke': {
    nairobi: 8000,
    instantDelivery: 10000,
    default: 10000
  },
  'ng': {
    delta: 0, // 206000
    edo: 0, // 258000
    enugu: 0, // 161000
    fct: 0, // 196000
    kaduna: 0, // 204000
    kano: 0, // 212000
    kwara: 0, // 48000
    lagos: 0, // 86000
    oyo: 0, // 117000
    rivers: 0, // 232000
    instantDelivery: 0, // 53000
    default: 0 // 53000
  }
}

const isOrderingPausedForLocation = api => async location => {
  const {_id: locationFsid} = location

  const isBasicUser = get(location, 'userLocation.membership') === 'basic'
  if (isBasicUser) {
    return false
  }

  const [paymentMethod, payables] = await Promise.all([
    api.payment.getRetailerPaymentMethod(locationFsid)
      .catch(err => {
        console.log('Error getting payment method', err)
        return undefined
      }),
    api.finances.getPayablesList(locationFsid).catch(err => {
      console.log('Error getting payables', err)
      return {}
    })
  ])

  const {installments, invoices} = Object.values(payables).reduce((acc, payment) => {
    const invoices = payment.transactions.filter(
      ({ txn_type: txnType }) => txnType === 'invoice'
    )
    const installments = payment.transactions.filter(
      ({ txn_type: txnType }) => txnType === 'installment'
    )
    return {
      installments: [...acc.installments, ...installments],
      invoices: [...acc.invoices, ...invoices]
    }
  }, {installments: [], invoices: []})

  const hasActiveMandate = paymentMethod && get(paymentMethod, 'data.isActive')

  return isUserPaused(
    invoices,
    hasActiveMandate,
    installments,
    api.location
  )
}

exports.createSLBulkImportSnapshots = createSLBulkImportSnapshots
async function createSLBulkImportSnapshots (api, data, user, locations) {
  try {
    const locationsById = byId(locations)

    const planType = ORDER_TYPES_TO_SUPPLIER_TYPE[IMPORT_ORDER_TYPE]
    const uploadedRows = parseCSV(data)

    const locationIds = uniqBy(
      uploadedRows.map((row) => row.locationId),
      '_id'
    )
    const locationSuspensionStatuses = fromPairs(
      (await Promise.all(
        locationIds
          .map(locationId => locationsById[locationId])
          .map(isOrderingPausedForLocation(api))
      ))
        .map((isPaused, idx) => [locationIds[idx], isPaused]))
    const pausedLocations = Object.keys(locationSuspensionStatuses).filter(
      (locationId) => locationSuspensionStatuses[locationId]
    )
    if (pausedLocations.length) {
      throw new Error(
        `Ordering is paused for ${pluralize(
          'location',
          pausedLocations.length
        )}: ${pausedLocations.join(', ')}`
      )
    }

    const ordersData = await groupProductsByOrder(uploadedRows, api, planType, locationsById)

    const translatedOrdersData = await translateProductIds(ordersData, api)

    await calculateOrderTotals(translatedOrdersData, api, locationsById)

    // Create snapshots
    const snapshots = []
    Object.values(translatedOrdersData).map(orderData => {
      //  Create an allocation for each order
      const allocation = {
        ...orderData,
        // check quantity
        products: orderData.products.reduce((acc, p) => {
          const quantity = parseInt(p.quantity)
          if (quantity <= 0) {
            throw new Error(`Invalid quantity for product ${p._id}`)
          }
          acc[p._id] = {
            original: quantity,
            adjusted: quantity,
            allocationType: orderData.paymentType === IMPORT_PAYMENT_TYPES.CREDIT
              ? DIRECT_ORDER_TYPES.PAY_ON_DELIVERY
              : DIRECT_ORDER_TYPES.IMMEDIATE_PURCHASE
          }
          return acc
        }, {})
      }

      const [snapshot] = createNewOrders({
        orderType: orderData.paymentType === IMPORT_PAYMENT_TYPES.CREDIT
          ? ORDER_TYPES.routine
          : IMPORT_ORDER_TYPE,
        programId: 'program:shelflife',
        allocations: [allocation],
        user,
        paymentStatus: PAYMENT_STATUSES.unpaid,
        paymentChoice: orderData.paymentType === IMPORT_PAYMENT_TYPES.CREDIT
          ? PAYMENT_CHOICES.payment_plan_payment
          : PAYMENT_CHOICES.on_delivery
      })

      snapshots.push(snapshot)
    })

    const asRows = await getSnapshotsAsRows(api, snapshots, locations)

    const counts = {
      products: asRows.length,
      orders: new Set(asRows.map(row => row.orderId)).size,
      locations: new Set(asRows.map(row => row.destinationId)).size
    }

    return { snapshots, counts, asRows }
  } catch (error) {
    console.error('Error creating bulk import snapshots:', error)
    throw error
  }
}

const getFreeDeliveryLimitPerMarket = (country, market = 'default', isFastDelivery) => {
  if (!country) {
    return 0
  }

  if (isFastDelivery) {
    return FREE_DELIVERY_MARKET_LIMIT[country].instantDelivery
  }

  return FREE_DELIVERY_MARKET_LIMIT[country][market] || FREE_DELIVERY_MARKET_LIMIT[country].default
}

const isValidDeliveryDateFormat = (date) => {
  const regexFormat = /^\d{2}-\d{2}-\d{4}$/
  return (date && regexFormat.test(date))
}

const isDeliveryFree = (orderValue, country, market = 'default', isFastDelivery) => {
  if (!country || !orderValue) {
    return false
  }

  // We are making sure that if there was a market prop,
  /// but is not in the listed in const,
  // we return the default one
  const limitPerMarket = getFreeDeliveryLimitPerMarket(country, market, isFastDelivery)

  return orderValue >= limitPerMarket
}

async function groupProductsByOrder (rows, api, planType, locationsById) {
  const ordersData = {}
  const supplierIdsCache = {}
  const supplierStockCache = {}

  for (const { locationId, productId, quantity, paymentType, deliveryDate } of rows) {
    if (!deliveryDate) {
      throw new Error(`Missing delivery date for product ${productId} at location ${locationId}`)
    }

    if (!isValidDeliveryDateFormat(deliveryDate)) {
      throw new Error(`Invalid delivery date format for product ${productId} at location ${locationId}`)
    }

    const [day, month, year] = deliveryDate.split('-')
    const internalDeliveryDate = `${year}-${month}-${day}`
    const dateObject = new Date(internalDeliveryDate)
    if (isNaN(dateObject.getTime()) || !isValidDeliveryDateFormat(deliveryDate)) {
      throw new Error(`Invalid delivery date for product ${productId} at location ${locationId}`)
    }

    const internalOrderId = smartId.idify(
      { locationId, paymentType, deliveryDate },
      'locationId:paymentType:deliveryDate'
    )
    const location = locationsById[locationId]

    if (!ordersData[internalOrderId]) {
      if (!supplierIdsCache[locationId]) {
        [supplierIdsCache[locationId]] = await api.order.getSupplierIdsForLocation(location, { planType })
      }
      const supplierId = supplierIdsCache[locationId]

      if (!supplierStockCache[supplierId]) {
        const heldStockParams = {
          locationId: supplierId,
          planType: PLANNING_TYPES.IMMEDIATE_PURCHASE
        }

        const response = await api.order.getHeldStock(heldStockParams)
        supplierStockCache[supplierId] = response.ledger
      }

      ordersData[internalOrderId] = {
        location,
        paymentStatus: PAYMENT_STATUSES.unpaid,
        paymentChoice: PAYMENT_CHOICES.on_delivery,
        paymentType,
        deliveryDate: internalDeliveryDate,
        supplier: supplierId,
        products: []
      }
    }

    const thisOrderSupplier = ordersData[internalOrderId].supplier

    if (!supplierStockCache[thisOrderSupplier][productId]) {
      throw new Error(`Product ${productId} not available from supplier ${thisOrderSupplier}`)
    }

    if (supplierStockCache[thisOrderSupplier][productId].quantity < quantity) {
      throw new Error(`Product ${productId} has not enough stock from supplier ${thisOrderSupplier}. Available stock: ${supplierStockCache[thisOrderSupplier][productId]} < requested quantity: ${quantity}`)
    }

    ordersData[internalOrderId].products.push({
      _id: productId,
      quantity
    })
  }

  return ordersData
}

async function calculateOrderTotals (ordersData, api, locationsById) {
  const orderCalculations = Object.values(ordersData).map(async (orderData) => {
    const location = locationsById[orderData.location._id]
    try {
      const totals = await calculateTotals(api, orderData.products, location, new Date().toJSON())
      const deliveryFee = await getDeliveryFee(api, location, orderData.deliveryDate, false, totals)
      const orderAmount = totals.total + deliveryFee
      const {availableCashBalance, loanBalance} = await getPaymentBalances(api, location._id)

      let cashBalanceUsed = 0

      if (orderData.paymentType === 'CREDIT') {
        if (loanBalance < orderAmount) {
          throw new Error(`Insufficient credit balance for location ${location._id}`)
        } // else order creation just makes the corresponding credit to be used
      } else { // orderData.paymentChoice === 'CASH'
        if (availableCashBalance) {
          const totalOrderValue = totals.total + deliveryFee
          // Cash balance used is the lesser of the available cash balance and the total order value
          cashBalanceUsed = availableCashBalance >= totalOrderValue ? totalOrderValue : availableCashBalance
        }
      }
      orderData.totalAmount = orderAmount - cashBalanceUsed
      orderData.orderAmount = orderAmount
      orderData.vat = totals.vat
      orderData.deliveryFee = deliveryFee
    } catch (error) {
      console.error('Error calculating order totals:', error)
      throw error
    }
  })

  await Promise.all(orderCalculations)
}

function parseCSV (data) {
  const rows = data.split('\n')
  const removeQuotes = field => field.replace(/"/g, '').trim()
  const headers = rows.splice(0, 1)[0].split(',').map(removeQuotes)

  return rows
    .map(row => {
      const rowFields = row.split(',').map(removeQuotes)
      return headers.reduce((acc, header, index) => {
        acc[header] = rowFields[index]
        return acc
      }, {})
    })
    .filter(row => Object.keys(row).some(header => row[header]))
}

async function getDeliveryFee (api, location, date, isFastDelivery, totals) {
  try {
    const { country: countryId, state: market } = locationIdToProperties(location.location.id)

    const serviceId = get(location, 'services.[0]')
    const serviceConfig = await api.service.get(serviceId)
    const { deliveryFee = 0 } = serviceConfig

    const route = get(location, 'joinedDocs.currentRoute')
    if (!route) {
      throw new Error(`Cannot find route for location ${location._id}`)
    }

    const deliveryDates = nextDeliveryDateAfterCutOff({
      route,
      market,
      items: 10,
      weeksAfter: 8
    })

    const [
      firstDeliveryDateAfterCutOff,
      secondDeliveryDateAfterCutOff,
      thirdDeliveryDateAfterCutOff
    ] = deliveryDates

    let realCutoffDate
    if (isFastDelivery) {
      realCutoffDate = {
        routine: new Date(),
        calendar: new Date()
      }
    } else {
      realCutoffDate = calculateCutoffDateForPurchase(countryId, market, firstDeliveryDateAfterCutOff, secondDeliveryDateAfterCutOff, thirdDeliveryDateAfterCutOff)
    }

    let indexOfRealCutoff = deliveryDates.indexOf(realCutoffDate.routine)
    // For Kenya the first possible delivery date might not be one of scheduled dates
    if (countryId === 'ke') {
      indexOfRealCutoff = 0
    }
    const deliveryDatesAfterRealCutoff = deliveryDates.slice(indexOfRealCutoff)

    const isIncluded = deliveryDatesAfterRealCutoff.some(freeDeliveryDate => {
      return format(freeDeliveryDate, 'YYYY-MM-DD') === date
    })
    const freeDelivery = isDeliveryFree(totals.total, countryId, market, isFastDelivery)

    return (isIncluded || freeDelivery) ? 0 : deliveryFee
  } catch (error) {
    console.error('Error getting delivery fee:', error)
    throw error
  }
}

async function getPaymentBalances (api, locationId) {
  try {
    const creditInfo = await api.finances.getCreditInfo(locationId)
    const loanBalance = get(creditInfo, 'availableCreditBalance', 0)
    const availableCashBalance = get(creditInfo, 'balances.availableCashBalance', 0)
    return { availableCashBalance, loanBalance }
  } catch (error) {
    console.error('Error getting payment balances:', error)
    throw error
  }
}

async function calculateTotals (api, productSelection, location, effectiveDate) {
  try {
    const { products } = await api.product.listForLocations([location._id], { date: effectiveDate })

    return productSelection.reduce((acc, product) => {
      const productData = products[product._id]
      if (productData) {
        const productPrice = api.product.tools.getPrice(productData.prices, effectiveDate)
        const productValue = product.quantity * productPrice
        acc.subtotal += productValue

        const vatMultiplier = productData.hasVat ? productData.vats[0].vat / 100 : 0
        const productVatValue = productValue * vatMultiplier
        acc.vat += productVatValue
        acc.total += productValue + productVatValue
      } else {
        console.error(`Product ${product._id} not found for location ${location._id}`)
      }
      return acc
    }, { vat: 0, subtotal: 0, total: 0 })
  } catch (error) {
    console.error('Error calculating totals:', error)
    throw error
  }
}

async function getSnapshotsAsRows (api, orderSnapshots, locations) {
  const ordersProductsIds = orderSnapshots.reduce((acc, orderSnapshot) => {
    acc.push(...Object.keys(orderSnapshot.products))
    return acc
  }, [])

  const ordersProducts = await api.product.getByIds(ordersProductsIds)
  const productsById = ordersProducts.reduce((acc, product) => {
    acc[product._id] = product
    return acc
  }, {})

  const locationsById = locations
    .reduce((acc, location) => {
      acc[location._id] = location
      return acc
    }, {})

  const rows = []
  orderSnapshots.forEach(orderSnapshot => {
    const {
      programId,
      destinationId,
      orderId,
      orderType,
      supplierId,
      deliveryDate,
      paymentType
    } = orderSnapshot
    const {
      fullName: locationName,
      location: {state}
    } = locationsById[destinationId]

    const {
      fullName: supplierName
    } = locationsById[supplierId]

    const shortProgramId = programId.replace('program:', '')
    Object.keys(orderSnapshot.products).forEach(
      (productId) => {
        const shortProductId = productId.replace('product:', '')
        const productName = productsById[productId] && productsById[productId].fullName

        rows.push({
          supplierName,
          deliveryDate,
          productName,
          programId: shortProgramId,
          locationName,
          state,
          productId: shortProductId,
          quantity: orderSnapshot.products[productId].adjusted,
          destinationId,
          orderId,
          orderType,
          paymentType
        })
      })
  })
  return rows
}

async function translateProductIds (ordersData, api) {
  const productIds = Object.values(ordersData).reduce((acc, orderData) => {
    acc.push(...orderData.products.map(p => p._id))
    return acc
  }, [])
  const products = await api.product.getProductsViaIds(productIds)
  const productsForTranslation = productAliasesByMarket(products)
  Object.values(ordersData).forEach(orderData => {
    const serviceId = orderData.location.services[0]
    orderData.products = orderData.products.map(p => {
      const translatedId = translateTerritoryAlias(p._id, productsForTranslation, serviceId)
      return {
        ...p,
        _id: translatedId
      }
    })
  })

  return ordersData
}
