const get = require('lodash/get')
const set = require('lodash/set')
const getLedgerBalanceAsReport = require('../../stock-get-ledger-balance-as-report')
const saveReport = require('./save')
const { getPeriod } = require('./get-periods')
const { get: getLocation } = require('../../location/api/read/get')
const applyCalculatedFields = require('../../tools/apply-calculated-field')
const { listReportFields } = require('../../service/api/read/list-report-fields')

module.exports = async (state, {
  locationId,
  service,
  productId,
  quantity,
  withoutCredit
}) => {
  // Check that this is a PB location:
  const location = await getLocation(state, locationId)
  if (!location.tracksPartnerBalances) {
    throw new Error('Transfers can only be made for partner balance locations')
  }

  // Get a ledger balance to use as baseline
  const ledger = await getLedgerBalanceAsReport(state, {
    locationId,
    service,
    excludeProducts: 'configuration'
  })

  if (!ledger.documents.reportId) {
    throw new Error('This location has no existing stock count to transfer from')
  }

  const lastReport = await state.dal.report.read(state, ledger.documents.reportId)

  const period = await getPeriod(state, {
    date: `${lastReport.date.period.effectiveStart}T00:00:00.000Z`,
    program: service.program,
    isEffectiveDate: true
  })

  // Transfer all opening balances, just like in web/common/reports/api
  Object.keys(ledger.stock).forEach(productId => {
    const product = ledger.stock[productId]
    product.fields['field:standard-opening-balance'] = { amount: product.fields['field:standard-physical-count'].amount }
    const partnerBalanceAmount = get(product, 'fields.field:partner-balance.amount', 0)
    product.fields['field:opening-partner-balance'] = { amount: partnerBalanceAmount }

    // Set the auto-filled prop (used in partial counts)
    // so we can filter this later if we'd need to
    // (this prop means that the user has not manually entered a balance)
    product.autoFilled = true
  })

  // Set the quantity to be transfered
  set(ledger, `stock.${productId}.fields.field:partner-buyout.amount`, quantity)
  let salesAdjustments = 0
  if (withoutCredit) {
    // Set sales adjustments to the negative of buyout if the transfer
    // should not add credit. `partner-buyout` and `sales-adjustments`
    // will be added to calculate sales in the stock situation export.
    salesAdjustments = quantity * -1
  }
  set(ledger, `stock.${productId}.fields.field:sales-adjustments.amount`, salesAdjustments)

  // We want to run calculated fields before saving:
  const fields = await listReportFields(state, service.id)
  const stock = applyCalculatedFields(ledger.stock, fields, { tracksPartnerBalances: true })

  return saveReport(state, {
    locationId,
    service,
    stock,
    period,
    submittedAt: new Date(),
    documents: ledger.documents,
    partialCount: true
  })
}
