const tools = require('../../tools')

exports.get = (state, { locationId, date, filter, includeProgramsHistory }) => {
  return state.locationsDB.get(locationId)
    .then(doc => tools.docToEntity({ doc, date, filter, includeProgramsHistory }))
}

exports.getRawDoc = async (state, { locationId, warehouseCode }) => {
  if (locationId) {
    try {
      return await state.locationsDB.get(locationId)
    } catch (err) {
      if (err.name === 'not_found') {
        return
      }
      throw err
    }
  }

  if (warehouseCode) {
    let result
    try {
      result = await state.locationsDB.query(
        tools.constants.BY_WAREHOUSE_CODE_VIEW_NAME, {
          key: warehouseCode,
          include_docs: true
        }
      )
    } catch (err) {
      if (err.status === 404) {
        throw new Error(`No design doc found for view "${tools.constants.BY_WAREHOUSE_CODE_VIEW_NAME}"`)
      }
      throw err
    }
    if (!result || !result.rows || !result.rows.length) {
      return
    }
    return result.rows[0].doc
  }

  throw new Error('Expected either `locationId` or `warehouseCode` to be passed')
}

exports.getRawDocByIds = async (state, ids) => {
  return state.locationsDB.allDocs({
    keys: ids,
    include_docs: true
  })
    .then(results => results.rows
      .map(r => r.doc)
      .filter(r => r)
    )
}

exports.listAll = async (state) => {
  const results = await Promise.all([
    // PSM
    state.locationsDB.allDocs({
      include_docs: true,
      startkey: 'zone:',
      endkey: 'zone:\uffff'
    }),
    // SL
    state.locationsDB.allDocs({
      include_docs: true,
      startkey: 'country:',
      endkey: 'country:\uffff'
    })
  ])
  const docs = []
  for (const result of results) {
    for (const row of result.rows) {
      // For user databases.
      if (row.doc && row.doc.type === 'location') {
        docs.push(row.doc)
      }
    }
  }
  return docs
}
