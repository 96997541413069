import React, { Component } from 'react'
import { connect } from 'react-redux'
import flowRight from 'lodash/flowRight'
import PropTypes from 'prop-types'

import getOrCreateDeviceId from '@fielded/fs-api/lib/shipment/tools/find-or-generate-device-id'

import { getLocalPouchDB } from '../../../sync'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import { deviceIdUpdate } from '../../../root/reducers/common/userSession'

import AppContainer from '../app/AppContainer'

class Root extends Component {
  state = {
    initialized: false
  }

  static propTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired
  }

  getChildContext () {
    return {
      rootHistory: this.props.rootHistory
    }
  }

  async componentDidMount () {
    const { user, deviceIdUpdate } = this.props
    const { deviceId } = await getOrCreateDeviceId({ PouchDB: getLocalPouchDB(user) })

    deviceIdUpdate(deviceId)
    this.setState({ initialized: true })
  }

  render () {
    const { initialized } = this.state

    if (!initialized) {
      return null
    }

    const {
      config,
      routerParams,
      rootHistory,
      api,
      showCountSyncBanner,
      showShipmentSyncBanner
    } = this.props

    return (
      <AppContainer
        config={config}
        api={api}
        routerParams={routerParams}
        rootHistory={rootHistory}
        showCountSyncBanner={showCountSyncBanner}
        showShipmentSyncBanner={showShipmentSyncBanner}
      />
    )
  }
}

Root.childContextTypes = {
  rootHistory: PropTypes.object
}

// TODO: Refactor this to a functional component and use hooks
const mapDispatchToProps = {
  deviceIdUpdate
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  connect(null, mapDispatchToProps)
)

export default withHOCs(Root)
