const QuickbooksTransactionsAdapter = require('../quickbooks/quickbooks-transactions-adapter')
const singleTransactionQuery = require('./queries/single-transaction-query')
const { createTransactionsListQuery } = require('./queries/transactions-list-query')
const { createUpcomingDueTransactionsQuery } = require('./queries/upcoming-due-transactions-query')
const { createPinnedTransactionsListQuery } = require('./queries/pinned-transactions-list-query')
const { createPayablesListQuery } = require('./queries/payables-list-query')
const { paymentPlanInstalmentsQuery } = require('./queries/payment-plan-instalments-query')
const { getOverduePAYGInvoicesQuery } = require('./queries/overdue-payg-invoice-query')
const { txnTypeQuery } = require('./queries/pay-in-instalment-query')
const futureInstalmentQuery = require('./queries/future-instalment-query')
const accountReceivableBalanceQuery = require('./queries/account-receivable-balance-query')
const { getPositionalArgs } = require('../tools/sql-tools')
const { TABLE_NAMES, SHIPMENT_WINDOW } = require('./constants')
const { MEMBERSHIPS } = require('../location/tools')
const { calculateBalance, getCreditInfo } = require('./tools')

class TransactionsAdapter extends QuickbooksTransactionsAdapter {
  async getList ({
    limit = 50,
    offset = 0,
    locationId,
    txnType,
    noLimit, // in case for pdf statement we need to fetch all at once
    overduePayment = false,
    startDate,
    endDate
  }) {
    const props = [locationId]
    let whereCondition = 'WHERE location_id = $1'
    const orderCondition = 'ORDER BY txn_date DESC, txn_type ASC'
    let propsIncrement = 1
    if (txnType) {
      propsIncrement++
      whereCondition = `${whereCondition} AND tl.txn_type = $${propsIncrement} `
      props.push(txnType)
    }
    if (overduePayment) {
      whereCondition = `${whereCondition} AND tl.amount_paid < tl.amount AND now() >= tl.due_date`
    }

    if (startDate && endDate) {
      propsIncrement++
      whereCondition = `${whereCondition} AND txn_date >= $${propsIncrement} `
      propsIncrement++
      whereCondition = `${whereCondition} AND txn_date < $${propsIncrement} `
      props.push(...[startDate, endDate])
    }
    if (!noLimit) {
      props.push(limit, offset)
    }
    const limitCondition = `LIMIT $${props.length - 1} OFFSET $${props.length}`
    const transactionsListQuery = createTransactionsListQuery()
    const query = `${transactionsListQuery} ${whereCondition} ${orderCondition} ${!noLimit ? limitCondition : ''}`

    const { rows } = await this.pgConnection.query(query, props)
    return {
      results: rows
    }
  }

  async getListByLocationIds ({
    txnType,
    overduePayment = false,
    locationIds = []
  }) {
    const dollarSigns = getPositionalArgs(locationIds)
    const props = [...locationIds]
    let whereCondition = `WHERE location_id IN (${dollarSigns})`
    if (txnType) {
      props.push(txnType)
      whereCondition = `${whereCondition} AND tl.txn_type = $${props.length} `
    }
    if (overduePayment) {
      whereCondition = `${whereCondition} AND tl.amount_paid < tl.amount AND now() >= tl.due_date`
    }
    const orderCondition = 'ORDER BY txn_date DESC, txn_type'
    const transactionsListQuery = createTransactionsListQuery()
    const query = `${transactionsListQuery} ${whereCondition} ${orderCondition}`
    const { rows } = await this.pgConnection.query(query, props)
    return {
      results: rows
    }
  }

  async getUpcomingDueTransactions ({
    checkNotificationSend = false
  } = {}) {
    const transactionsListQuery = createUpcomingDueTransactionsQuery({ isNotificationSend: checkNotificationSend })
    const whereCondition = `
       WHERE tl.amount_paid < tl.amount
       AND (tl.due_date::DATE - now()::DATE) = 1
       AND (tt.txn_type = 'installment' OR tt.txn_type = 'invoice')
       ${checkNotificationSend ? 'AND "n".id IS NULL' : ''}
    `
    const query = `${transactionsListQuery} ${whereCondition}`
    const { rows } = await this.pgConnection.query(query)
    return {
      results: rows
    }
  }

  async getPinnedTransactions ({
    locationId,
    startDate,
    endDate
  }) {
    const props = [locationId]
    let whereCondition = 'WHERE "location_id" = $1'
    if (startDate && endDate) {
      whereCondition = `${whereCondition} AND txn_date >= $2 and txn_date < $3`
      props.push(...[startDate, endDate])
    }
    const orderCondition = 'ORDER BY txn_date DESC, txn_type ASC'
    const pinnedTransactionsListQuery = createPinnedTransactionsListQuery()
    const query = `${pinnedTransactionsListQuery} ${whereCondition} ${orderCondition}`
    const { rows } = await this.pgConnection.query(query, props)
    return {
      results: rows
    }
  }

  async getPayablesList ({locationId, excludeOrderId}) {
    const params = excludeOrderId ? [locationId, excludeOrderId] : [locationId]
    const payablesListQuery = createPayablesListQuery(excludeOrderId)
    const { rows } = await this.pgConnection.query(payablesListQuery, params)
    return { results: rows }
  }

  async getPaymentPlanWithInstalments ({locationId, startDate, endDate, paymentPlanId}) {
    const props = [locationId]
    let whereCondition = 'WHERE "location_id" = $1'
    if (startDate && endDate) {
      whereCondition = `${whereCondition} AND start_date >= $2 and start_date < $3`
      props.push(...[startDate, endDate])
    }
    if (paymentPlanId) {
      whereCondition = `${whereCondition} AND payment_plan_id = $${props.length + 1}`
      props.push(paymentPlanId)
    }
    const paymentPlanInstalmentQuery = paymentPlanInstalmentsQuery()
    const query = `${paymentPlanInstalmentQuery} ${whereCondition}`
    const { rows } = await this.pgConnection.query(query, props)
    return {
      results: rows
    }
  }

  async getAccountReceivableBalance ({ locationId }) {
    const { rows } = await this.pgConnection.query(accountReceivableBalanceQuery, [locationId])
    return rows[0]
  }

  async getLoanReceivableBalance ({ locationId }) {
    const query = `SELECT -SUM(GREATEST(COALESCE("quickbooks_account_balance", 0), 0)) AS lr_balance
      FROM ${TABLE_NAMES.PAYMENT_PLAN_TABLE_NAME} "pp"
       INNER JOIN ${TABLE_NAMES.TRANSACTIONS_TABLE_NAME} "qt"
       ON "qt"."company_code" = "pp"."company_code" AND "qt"."txn_id" = "pp"."quickbooks_journal_entry_id"
      WHERE "pp"."location_id" = $1`
    const { rows } = await this.pgConnection.query(query, [locationId])
    return rows[0]
  }

  async getOne (data) {
    const {
      transactionId,
      locationUuid
    } = data
    const { rows } = await this.pgConnection.query(singleTransactionQuery, [transactionId, locationUuid])
    if (!rows[0]) {
      const error = new Error('Transaction not found')
      error.status = 404
      throw error
    }
    return rows[0]
  }

  async getTransactionType ({transactionId}) {
    const { rows } = await this.pgConnection.query(txnTypeQuery, [transactionId])
    if (!rows[0]) {
      const error = new Error('Transaction not found')
      error.status = 404
      throw error
    }
    return rows[0]
  }

  async getFutureInstallments ({locationId}) {
    const { rows } = await this.pgConnection.query(futureInstalmentQuery, [locationId])
    return {
      results: rows
    }
  }

  async getOverduePAYGInvoices () {
    const { rows } = await this.pgConnection.query(getOverduePAYGInvoicesQuery, [MEMBERSHIPS.CLASSIC_PAYG_ONLY, MEMBERSHIPS.CLASSIC, 'PAYG'])
    return rows
  }

  async getCreditInfo ({ locationUuid, dataShipmentAdapter, creditApplicationApi, orderIdToExclude }) {
    const { rows: [location] } = await this.pgConnection.query(`
      select
        fsid,
        uuid as location_id,
        orders_status,
        loan_limit
      from avocado.data_location where uuid = $1
    `, [locationUuid])

    if (!location) {
      const error = new Error('Location not found')
      error.status = 404
      throw error
    }

    const [
      pendingDeliveries,
      { results: payableTransactions },
      { results: allTransactions },
      arBalanceResponse = { ar_balance: 0 },
      lrBalanceResponse = { lr_balance: 0 },
      { status: creditApplicationStatus }
    ] = await Promise.all([
      dataShipmentAdapter.getPendingDeliveries({
        locationId: location.fsid,
        weeks: SHIPMENT_WINDOW
      }),
      this.getPayablesList({ locationId: locationUuid, excludeOrderId: orderIdToExclude }),
      this.getList({ locationId: locationUuid, noLimit: true, overduePayment: null, startDate: null, endDate: null, txnType: null }),
      this.getAccountReceivableBalance({locationId: locationUuid}),
      this.getLoanReceivableBalance({locationId: locationUuid}),
      creditApplicationApi.get(locationUuid)
    ])

    let overdueBalance
    const totalOutstandingAmount = payableTransactions.reduce((acc, transaction) => {
      if (transaction.week === 'overdue') {
        overdueBalance = transaction.totaldue
      }
      acc += transaction.totaldue
      return acc
    }, 0)

    const { pendingDeliveriesAmount, loanDeliveryAmountUsed } = pendingDeliveries
      .reduce((acc, pendingDelivery) => {
        if (!pendingDelivery.has_existing_transaction) {
          acc.loanDeliveryAmountUsed += pendingDelivery.amount
        }
        acc.pendingDeliveriesAmount += pendingDelivery.amount
        return acc
      }, { pendingDeliveriesAmount: 0, loanDeliveryAmountUsed: 0 })
    const { balance, pending } = calculateBalance({ transactions: allTransactions })

    return getCreditInfo({
      pendingDeliveriesAmount,
      arBalance: arBalanceResponse.ar_balance,
      lrBalance: lrBalanceResponse.lr_balance,
      totalOutstandingAmount,
      loanLimit: location.loan_limit,
      orderStatus: location.orders_status,
      creditApplicationStatus,
      balances: {
        balance,
        pending,
        overdue: overdueBalance
      },
      loanDeliveryAmountUsed
    })
  }
}

module.exports = TransactionsAdapter
