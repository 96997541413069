const {
  TABLE_NAMES: {
    TRANSACTIONS_TABLE_NAME,
    PAYMENT_PLAN_TABLE_NAME,
    INVOICE_PAYMENTPLAN_TABLE_NAME,
    QUICKBOOKS_INVOICE_TABLE_NAME,
    INVOICE_LINE_TABLE_NAME,
    DATA_PAYMENTS_TABLE_NAME
  }
} = require('../../constants')

module.exports = ({locationArgs = null, includeInvoicePayment = false}) => {
  return `
    SELECT
      txn.created_at,
      txn.updated_at,
      txn.id,
      txn.company_code,
      txn.txn_id,
      txn.quickbooks_doc_number,
      txn.txn_type,
      txn.txn_date,
      txn.due_date,
      txn.quickbooks_created_at,
      txn.quickbooks_updated_at,
      txn.amount,
      txn.amount_paid,
      txn.description,
      txn.location_id,
      txn.quickbooks_account_id,
      txn.order_type,
      pp.id as payment_plan_id,
      row_to_json(pp)::jsonb as payment_plan,
      "il"."shipment_id",
      coalesce("il"."order_id", p.order_id) as order_id
    FROM ${TRANSACTIONS_TABLE_NAME} as txn
    LEFT JOIN ${PAYMENT_PLAN_TABLE_NAME} as pp
          ON txn.quickbooks_account_id = pp.quickbooks_account_id AND "txn"."company_code" = "pp"."company_code"
          AND txn.txn_type = 'loan'
          AND txn.location_id = pp.location_id
    LEFT JOIN ${INVOICE_PAYMENTPLAN_TABLE_NAME} ip on ip.paymentplan_id = pp.id
    LEFT JOIN ${QUICKBOOKS_INVOICE_TABLE_NAME} "iq"
         ON ("iq"."invoice_txn_id" = txn.txn_id AND "iq"."company_code" = "txn"."company_code") or
            ("iq"."invoice_txn_id" = ip.qbo_txn_id AND "iq"."company_code" = "ip"."qbo_company_code")
    LEFT JOIN LATERAL (
          SELECT
            "il"."invoice_id",
            "il"."shipment_id",
            "il"."order_id"
          FROM ${INVOICE_LINE_TABLE_NAME} "il"
          WHERE "il"."invoice_id" = "iq"."invoice_id" AND
           ("il"."shipment_id" IS NOT NULL OR "il"."order_id" IS NOT NULL)
          LIMIT 1
    ) "il" ON true
    left join ${DATA_PAYMENTS_TABLE_NAME} p on p.payment_data ->> 'reference' = txn.description and p.location_id = txn.location_id
    ${locationArgs ? ` where txn.location_id in (${locationArgs})` : ' '}

    ${
  includeInvoicePayment
    ? `
          UNION ALL
          -- Get payments not stored on the avocado.data_quickbookstransactions table
          SELECT
            ip.created_at,
            ip.updated_at,
            ip.id::uuid,
            ip.company_code,
            ip.txn_id,
            null::text as quickbooks_doc_number,
            'payment' as txn_type,
            ip.txn_date,
            null as due_date,
            ip.quickbooks_created_at,
            ip.quickbooks_updated_at,
            ip.amount,
            null as amount_paid,
            null as description,
            ip.location_id,
            null as quickbooks_account_id,
            null as order_type,
            null as payment_plan_id,
            null as payment_plan,
            null as shipment_id,
            null as order_id
          FROM avocado.data_quickbooksinvoicepayment ip
          LEFT JOIN avocado.data_quickbookstransactions qt on ip.txn_id = qt.txn_id and ip.company_code = qt.company_code 
          WHERE qt.id is null and ip.amount != 0 and coalesce(ip.unapplied_amount, 0) = 0 
            ${locationArgs ? ` and ip.location_id in (${locationArgs}) ` : ' '}
        `
    : ' '
}
  `
}
