const { addProducts } = require('./../../../service/api/write/add-products')
const { removeProducts } = require('./../../../service/api/write/remove-products')
const { get } = require('./../read/get')

const warehousePrograms = {
  '-fp': {
    serviceId: 'program:family-planning:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-mal': {
    serviceId: 'program:malaria:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-mch': {
    serviceId: 'program:mnch:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-tb': {
    serviceId: 'program:tb:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-aid': {
    serviceId: 'program:hiv-aids:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-hci': {
    serviceId: 'program:hypertension:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-esm': {
    serviceId: 'program:essential-medicines:service:warehouse',
    products: ['product:ghsc-dummy']
  },
  '-pmtct': {
    serviceId: 'program:pmtct-scale-up:service:warehouse',
    products: ['product:ghsc-dummy']
  }
}

const updateConfiguration = async (
  state,
  product,
  updateConfiguration,
  existingProduct,
  skipValidation = true
) => {
  if (!updateConfiguration) return
  const productId = product._id
  const modifiedServices = product.services.flatMap(service => service.id)
  const newProduct = !existingProduct

  if (newProduct) {
    // figure out if a product ID "should" be in a warehouse config based on ID prefix

    const warehouseProgramKey = Object.keys(warehousePrograms)
      .find(key => productId.includes(key))
    if (!warehouseProgramKey) {
      throw new Error('Product code does not match program prefix, e.g "-aid, -mal"')
    }
    const warehouseConfig = warehousePrograms[warehouseProgramKey].serviceId
    warehousePrograms[warehouseProgramKey].products.push(productId)

    try {
      await addProducts(state, warehouseConfig, warehousePrograms[warehouseProgramKey].products, skipValidation)
      modifiedServices && modifiedServices.forEach(async service => {
        await addProducts(state, service, [productId], skipValidation)
      })
    } catch (err) {
      console.error(err)
      throw new Error(`Configuration update failed`)
    }
    return
  }
  const {services} = await get(state, productId)
  if (services) {
    const existingServices = services.flatMap(service => service.id)
    const removedServices = existingServices.filter(service => !modifiedServices.includes(service))
    const newServices = modifiedServices.filter(service => !existingServices.includes(service))
    try {
      removedServices && removedServices.forEach(async service => {
        await removeProducts(state, service, [productId], skipValidation)
      })
      newServices && newServices.forEach(async service => {
        await addProducts(state, service, [productId], skipValidation)
      })
    } catch (err) {
      console.error(err)
      throw new Error(`Configuration update failed`)
    }
  }
}

exports.updateConfiguration = updateConfiguration
exports.warehousePrograms = warehousePrograms
