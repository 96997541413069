const { moveShipmentStatus } = require('./move-shipment-status')
const { updateSuborder } = require('./update-suborder')
const { SUBORDER_STATUS } = require('../../constants')
const generateShipmentNumber = require('../../../shipment/tools/generate-shipment-number')
const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const { parse } = require('../../../tools/smart-id')
const tools = require('../../tools')

exports.createExternalShipmentSnapshots = async function (state, mainApi, params) {
  let {
    suborderId,
    productQuantities,
    additionalData = {},
    receiptData = {},
    shipmentNumber
  } = params

  let { closedStatus: subOrderStatus } = receiptData

  // Fetch suborder
  const subOrder = await state.dal.order.getSuborder(state, { suborderId })
  if (!subOrder) {
    throw createError(`No uncompleted suborder with id "${suborderId}" was found. Cannot continue.`, 404)
  }

  // Get subOrder shipment snapshots
  const shipmentSnapshots = await state.dal.order.getShipmentsOnOrder(state, subOrder._id)

  const status = SUBORDER_STATUS[subOrderStatus]

  // Check if the default shipment created off subOrder has already been packed
  const defaultPacked = checkDefaultPacked(shipmentSnapshots)

  // If there is no packed shipment for this SubOrder
  // We pack the default shipment
  if (!defaultPacked && status === SUBORDER_STATUS['packed']) {
    return updateSuborder(state, params)
  }

  // If the default has already been packed create a new shipment off the same subOrder
  // Check to make sure external shipment id does not already exist
  const existingShipmentNumber = findShipmentNumber(shipmentSnapshots, shipmentNumber)
  if (existingShipmentNumber) {
    throw createError(`Cannot create shipment as a shipment with the same id already exist "${shipmentNumber}"`, 400)
  }

  // Update suborder doc
  const shipmentNumbers = subOrder.additionalData && subOrder.additionalData.shipmentNumbers
    ? subOrder.additionalData.shipmentNumbers
    : []

  const updatedOrderDoc = {
    ...subOrder,
    closedStatus: status,
    isComplete: status === SUBORDER_STATUS.received,
    additionalData: {
      ...subOrder.additionalData,
      ...additionalData,
      updatedAt: receiptData.updatedAt,
      shipmentNumbers: [
        ...shipmentNumbers,
        shipmentNumber // Add new shipmentNumber to shipment number array
      ]
    }
  }

  // Update suborder
  await state.dal.order.update(state, updatedOrderDoc)

  let snapshots
  try {
    // Create and advance shipment snapshot
    snapshots = await createExternalSnapshots(state, suborderId, status, productQuantities, shipmentNumber)
  } catch (e) {
    throw createError(`Error creating shipment`, 500)
  }

  return { id: get(snapshots[snapshots.length - 1], 'id') }
}

// Helper functions

function checkDefaultPacked (shipmentSnapshots) {
  const snapshot = shipmentSnapshots.find(snapshot => parse(snapshot._id).status === SUBORDER_STATUS['packed'] && snapshot.type === 'snapshot')
  return snapshot
}

function findShipmentNumber (shipmentSnapshots, shipmentNumber) {
  const snapshot = shipmentSnapshots.find(snapshot => snapshot.externalShipmentId === shipmentNumber && snapshot.type === 'snapshot')
  return snapshot
}

async function createExternalSnapshots (state, suborderId, status, productQuantities = {}, externalShipmentId) {
  const productIds = Object.keys(productQuantities)

  // Fetch all products by product IDs if there are any
  const allProducts = productIds.length ? await state.dal.product.getByIds(state, productIds) : []
  const productsById = keyBy(allProducts, '_id')

  const snapshotCount = tools.createShipmentCounts({
    orderSnapshotProducts: productQuantities,
    productsById,
    useAmount: true
  })

  const statusToCreateMap = {
    'packed': ['new', 'packed'],
    'sent': ['new', 'packed', 'sent'],
    'received': ['new', 'packed', 'sent', 'arrived', 'received']
  }

  const statusesToCreate = statusToCreateMap[status] || []

  // Create shipment snapshot number
  const shipmentNumber = generateShipmentNumber()

  // Create all necessary snapshots
  const snapshots = await Promise.all(
    statusesToCreate.map(async status => {
      return moveShipmentStatus(state, {
        suborderId,
        status,
        shipmentCount: snapshotCount,
        priorStateSnapshot: {},
        externalShipmentId,
        shipmentNumber
      })
    })
  )
  return snapshots
}

function createError (message, status) {
  const err = new Error(message)
  err.status = status
  return err
}
