const VERSIONS = {
  'program:immunization:service:immunization': {
    current: '2.0.0'
  },
  'program:hiv-aids:service:warehouse': {
    current: '2.0.0'
  },
  // This one is a pretty obscure testing thing:
  // it does not exist in prod
  'program:hiv-aids:service:warehouse-hiv-expiry': {
    current: '2.0.0'
  },
  'program:family-planning:service:warehouse': {
    current: '2.0.0'
  },
  'program:malaria:service:warehouse': {
    current: '2.0.0'
  },
  'program:mnch:service:warehouse': {
    current: '2.0.0'
  },
  'program:tb:service:warehouse': {
    current: '2.0.0'
  },
  'program:hypertension:service:warehouse': {
    current: '2.0.0'
  },
  'program:essential-medicines:service:warehouse': {
    current: '2.0.0'
  }
}
exports.VERSIONS = VERSIONS

const DEFAULT_VERSION = '1.0.0'
exports.DEFAULT_VERSION = DEFAULT_VERSION

exports.getVersion = function (serviceId) {
  return VERSIONS[serviceId]
    ? VERSIONS[serviceId].current
    : DEFAULT_VERSION
}
