const TRANSACTIONS_TABLE_NAME = 'avocado.data_quickbookstransactions'
const PAYMENT_PLAN_TABLE_NAME = '"avocado"."data_paymentplan"'
const PAYMENT_INVOICE_TABLE_NAME = 'avocado.data_quickbooksinvoicepayment'
const PAYMENT_RELATION_TABLE_NAME = 'avocado.data_quickbookspaymentrelation'
const PAYMENT_DEBIT_TABLE_NAME = 'avocado.data_paymentdebit'
const DATA_PAYMENTS_TABLE_NAME = 'avocado.data_payment'
const INVOICE_PAYMENTPLAN_TABLE_NAME = 'avocado.data_invoicepaymentplan'
const PAYG_CREDIT_PAYMENTS_TABLE_NAME = 'avocado.data_paygcreditpayment'
const QUICKBOOKS_INVOICE_TABLE_NAME = 'avocado.data_invoice_quickbooks'
const INVOICE_LINE_TABLE_NAME = 'avocado.data_invoice_line'
const LATE_FEE_GRACE_PERIOD = 14
const LATE_FEE_DURATION_CAP = 45
const LATE_FEE_WEEKLY_PERCENTAGE = 0.5
const LATE_FEE_DAYS_IN_WEEK = 7
const LATE_FEE_AMOUNT_THRESHOLD = {
  ke: 1000,
  ng: 2500
}
const SHIPMENT_WINDOW = 6

const TABLE_NAMES = {
  TRANSACTIONS_TABLE_NAME,
  PAYMENT_PLAN_TABLE_NAME,
  PAYMENT_INVOICE_TABLE_NAME,
  PAYMENT_RELATION_TABLE_NAME,
  PAYMENT_DEBIT_TABLE_NAME,
  DATA_PAYMENTS_TABLE_NAME,
  INVOICE_PAYMENTPLAN_TABLE_NAME,
  PAYG_CREDIT_PAYMENTS_TABLE_NAME,
  QUICKBOOKS_INVOICE_TABLE_NAME,
  INVOICE_LINE_TABLE_NAME
}

const TRANSACTIONS_COLUMNS = [
  'id',
  'company_code',
  'txn_id',
  'txn_type',
  'txn_date',
  'amount',
  'amount_paid',
  'descriptions',
  'quickbooks_doc_number',
  'location_id',
  'created_at',
  'updated_at'
]
const QBO_TIMEZONE = 'America/Phoenix'

const DEBITS = {
  invoice: 'invoice',
  installment: 'installment',
  refund: 'refund'
}

const CREDITS = {
  payment: 'payment',
  credit_memo: 'credit memo',
  loan: 'loan',
  write_off: 'write-off',
  bonus: 'bonus',
  installment_payment: 'installment payment'
}

const TXN_TYPES = {
  ...DEBITS,
  ...CREDITS
}

const TXN_SUBTYPES = {
  'pplan': 'pplan'
}

module.exports = {
  DEBITS,
  CREDITS,
  TXN_TYPES,
  TXN_SUBTYPES,
  TABLE_NAMES,
  TRANSACTIONS_COLUMNS,
  QBO_TIMEZONE,
  LATE_FEE_GRACE_PERIOD,
  LATE_FEE_DURATION_CAP,
  LATE_FEE_WEEKLY_PERCENTAGE,
  LATE_FEE_DAYS_IN_WEEK,
  LATE_FEE_AMOUNT_THRESHOLD,
  SHIPMENT_WINDOW
}
