const get = require('lodash/get')
const { getInsertProperties } = require('../../lib/tools/sql-tools')
const { isDeleted } = require('./tools/utils')
const { getMapping } = require('./tools/transform-entity')

const ACCOUNT_TABLE_NAME = 'avocado.data_quickbooksaccount'
const TRANSACTIONS_TABLE_NAME = '"avocado"."data_quickbookstransactions"'
const QUICKBOOKS_LOCATION_TABLE_NAME = '"avocado"."data_quickbookslocation"'
const PAYMENT_TABLE_NAME = '"avocado"."data_quickbooksinvoicepayment"'
const PAYMENT_RELATIONS_TABLE_NAME = '"avocado"."data_quickbookspaymentrelation"'
const PAYMENT_PLAN_TABLE_NAME = '"avocado"."data_paymentplan"'

class QuickbooksTransformAdapter {
  constructor (
    pgConnection,
    username,
    logger,
    rawTransactions,
    quickbooksInterface,
    notificationsApi,
    tools
  ) {
    this.pgConnection = pgConnection
    this.username = username
    this.logger = logger
    this.rawTransactions = rawTransactions
    this.quickbooksInterface = quickbooksInterface
    this.notificationsApi = notificationsApi
    this.tools = tools
  }

  async createTransformHelpers ({ companyCode }) {
    return {
      companyCode,

      logger: this.logger,

      getAccountIds: async () => this.quickbooksInterface.accountIds[companyCode] || {},

      getLocationId: async (customerId) => {
        if (!customerId) {
          return null
        }
        const { rows } = await this.pgConnection.query(`
          select * from ${QUICKBOOKS_LOCATION_TABLE_NAME}
          where company_code = $1 and quickbooks_customer_id = $2
        `, [companyCode, customerId])
        return get(rows, '0.location_id')
      },

      getCustomerIdThruPaymentPlan: async (quickbooksAccountId) => {
        const { rows } = await this.pgConnection.query(`
          select ql.quickbooks_customer_id
          from ${PAYMENT_PLAN_TABLE_NAME} "pp"
          left join ${QUICKBOOKS_LOCATION_TABLE_NAME} "ql" on "ql"."location_id" = "pp"."location_id"
          where "pp".quickbooks_account_id = $2 and "pp".company_code = $1;
        `, [companyCode, quickbooksAccountId])
        return get(rows, '0.quickbooks_customer_id')
      },

      existsPaymentPlanWithLrAccount: async (accountId) => {
        if (!accountId) {
          return null
        }
        const { rows } = await this.pgConnection.query(`
          select pp.quickbooks_account_id from ${PAYMENT_PLAN_TABLE_NAME} pp
          where pp.company_code = $1 and pp.quickbooks_account_id = $2
        `, [companyCode, accountId])
        return get(rows, '0.quickbooks_account_id', null)
      },

      getLoanReceivablesAccountIds: async (customerId) => {
        if (!customerId) {
          return []
        }
        const { rows } = await this.pgConnection.query(`
          select pp.quickbooks_account_id
          from avocado.data_paymentplan pp
          left join avocado.data_quickbookslocation ql
                 on ql.location_id = pp.location_id
          where ql.company_code = $1 and ql.quickbooks_customer_id = $2
        `, [companyCode, customerId])
        return rows.map(r => r.quickbooks_account_id)
      }
    }
  }

  async insertRows (tableName, rows) {
    if (rows.length === 0) {
      return []
    }
    const {
      columns,
      values,
      placeholders
    } = getInsertProperties(rows)
    const { rows: insertedRows } = await this.pgConnection.query(`
      INSERT INTO ${tableName} (${columns})
      VALUES ${placeholders}
      RETURNING id
    `, values)
    return insertedRows
  }

  createPersistHelpers () {
    return {
      logger: this.logger,
      insertPayment: async (payment) => {
        const rows = await this.insertRows(PAYMENT_TABLE_NAME, [payment])
        return rows[0].id
      },

      insertPaymentRelations: async (rels) => {
        const rows = await this.insertRows(PAYMENT_RELATIONS_TABLE_NAME, rels)
        return rows.map(r => r.id)
      },

      insertTransactions: async (txns) => {
        const rows = await this.insertRows(TRANSACTIONS_TABLE_NAME, txns)
        return rows.map(r => r.id)
      },

      sendInAppNotifications: (txns) => {
        return this.notificationsApi.transactionsNotifications.newInvoiceNotification(txns)
      },

      insertAccounts: async (accs) => {
        if (accs.length === 0) {
          return []
        }
        const {
          columns, values, placeholders
        } = getInsertProperties(accs)
        const { rows } = await this.pgConnection.query(`
          INSERT INTO ${ACCOUNT_TABLE_NAME} AS acc (${columns})
          VALUES ${placeholders}
          ON CONFLICT (company_code, quickbooks_id) DO
          UPDATE SET quickbooks_updated_at = excluded.quickbooks_updated_at
                   , updated_at = excluded.updated_at
                   , name = excluded.name
                   , active = excluded.active
                   , balance = excluded.balance
                   , balance_with_subaccounts = excluded.balance_with_subaccounts
          RETURNING id
        `, values)
        return rows.map(r => r.id)
      }
    }
  }

  async deleteTransaction (companyCode, id) {
    await this.pgConnection.query(`
      DELETE FROM ${TRANSACTIONS_TABLE_NAME}
      WHERE txn_id = $1 and company_code = $2
    `, [id, companyCode])
  }

  async markAutoInvoiceDeleted (companyCode, id, type) {
    if (type === 'Invoice') {
      await this.pgConnection.query(`
        UPDATE avocado.data_invoice_quickbooks
           SET invoice_deleted = true
        WHERE invoice_txn_id = $1 AND company_code = $2
      `, [id, companyCode])
    } else if (type === 'CreditMemo') {
      await this.pgConnection.query(`
        UPDATE avocado.data_invoice_quickbooks
           SET creditmemo_deleted = true
        WHERE creditmemo_txn_id = $1 AND company_code = $2
      `, [id, companyCode])
    }
  }

  async deletePayment (companyCode, id) {
    const { rows } = await this.pgConnection.query(`
      DELETE FROM ${PAYMENT_TABLE_NAME}
      WHERE txn_id = $1 and company_code = $2
      RETURNING id
    `, [id, companyCode])
    if (rows.length > 0) {
      await this.pgConnection.query(`
          DELETE FROM ${PAYMENT_RELATIONS_TABLE_NAME}
          WHERE payment_id = any($1)
      `, [rows.map(r => r.id)])
    }
  }

  async transformRawRow (companyCode, id) {
    const rawRow = await this.rawTransactions.getRawRow(companyCode, id)
    const info = {
      id: rawRow.txn_id,
      type: rawRow.txn_type,
      companyCode: rawRow.company_code
    }
    const deleted = isDeleted(rawRow.raw_data)

    await Promise.all([
      this.deletePayment(info.companyCode, info.id),
      this.deleteTransaction(info.companyCode, info.id)
    ])

    if (deleted) {
      await this.markAutoInvoiceDeleted(info.companyCode, info.id, info.type)
      return this.logger.info({ code: 'deleted' })
    }

    const transformHelpers = await this.createTransformHelpers({
      companyCode: info.companyCode
    })

    const persistHelpers = this.createPersistHelpers()
    const mapping = getMapping(info.type)
    const mapped = mapping.map(rawRow)
    const transformed = await mapping.transform(mapped, transformHelpers)
    if (!transformed) {
      this.logger.info({ code: 'empty-transform-result' })
      return
    }
    const ids = await mapping.persist(transformed, persistHelpers)
    this.logger.info({ code: 'inserted', ids })
    return ids
  }

  /**
   *
   * @param {String} companyCode NG, KE
   * @param {Object} entities {
   *   Invoice: Array[QuickbooksData],
   *   JournalEntry: Array[QuickbooksData],
   *   CreditMemo: Array[QuickbooksData],
   *   Payment: Array[QuickbooksData]
   *   Purchase: Array[QuickbooksData]
   *   Deposit: Array[QuickbooksData]
   *   Account: Array[QuickbooksData]
   * }
   * more details https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account
   * @returns {Promise<void>}
   */
  async transformAndSave (companyCode, entities) {
    const rawTxns = this.tools.entitiesToRawRows(companyCode, entities)
    const savedIds = await this.rawTransactions.saveRawTransactions(rawTxns)
    const data = savedIds.map(id => ({ id, companyCode }))
    return Promise.all(
      data.map(val => this.transformRawRow(val.companyCode, val.id))
    )
  }
}

module.exports = QuickbooksTransformAdapter
