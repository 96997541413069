import React, { Fragment, useState } from 'react'
import { Page, Text } from '@fielded/shared-ui'

import { byCompleteAndDate } from '../../../../../root/reducers/shipments'
import { getSortedDeliveries } from '../shared/utils'
import ServiceListEmptyState from '../../../../retailer/service-visits/ServiceListEmptyState'
import ServiceListCard from '../../../../retailer/service-visits/ServiceListCard'
import ServiceHistoryList from '../../../../retailer/service-visits/serviceHistory/ServiceHistoryList'

const LIST_DISPLAY_COUNT = 10

const FacilityDeliveries = ({
  user,
  history,
  shipments,
  showCompleted = true
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [displayCount, setDisplayCount] = useState(LIST_DISPLAY_COUNT)

  const { complete, incomplete } = byCompleteAndDate(shipments, user)

  const scheduledDeliveries = getSortedDeliveries(incomplete, user)
  const completedDeliveries = getSortedDeliveries(complete, user)

  const displayList = completedDeliveries.slice(0, displayCount)
  const thereAreMoreItemsThanWeShow = (
    completedDeliveries.length > displayCount && displayList.length >= displayCount
  )

  // LOAD MORE DATA
  const onLoadMore = () => {
    setIsLoading(true)
    setTimeout(() => {
      setDisplayCount(displayCount => displayCount + LIST_DISPLAY_COUNT)
      setIsLoading(false)
    }, 1000)
  }

  return (
    <Fragment>
      <Page.Panel narrow alignLeft>
        <Page.Panel.Section>
          {scheduledDeliveries.length === 0 && (
            <ServiceListEmptyState />
          )}

          {!!scheduledDeliveries.length && (
            <section className='vs-u-margin-bottom'>
              <Text.Heading className='vs-u-margin-bottom'>Incoming deliveries</Text.Heading>
              <section className='service-visit__list'>
                {scheduledDeliveries.map(delivery => (
                  <ServiceListCard
                    key={delivery.snapshotId}
                    history={history}
                    service={delivery}
                    isTitleShortFormat
                    className={`facility-deliveries-list__card--${delivery.status}`}
                  />
                ))}
              </section>
            </section>
          )}
        </Page.Panel.Section>
      </Page.Panel>

      {showCompleted && !!completedDeliveries.length && (
        <Page.Panel narrow alignLeft>
          <Page.Panel.Section>
            <ServiceHistoryList
              title='Delivery'
              className='facility-deliveries-history-list__card'
              displayList={displayList}
              history={history}
              isLoading={isLoading}
              shouldLoadMore={thereAreMoreItemsThanWeShow}
              onLoadMore={onLoadMore}
            />
          </Page.Panel.Section>
        </Page.Panel>
      )}
    </Fragment>
  )
}

export default FacilityDeliveries
