const PGAdapter = require('./../common/pg-adapter')
const QUICKBOOKS_LOCATION_TABLE_NAME = 'avocado.data_quickbookslocation'
const QUICKBOOKS_LOCATION_COLUMNS = [
  'id',
  'created_at',
  'updated_at',
  'company_code',
  'quickbooks_customer_id',
  'location_id',
  'quickbooks_sync_token'
]

class QuickbooksLocationAdapter extends PGAdapter {
  constructor (pgConnection, username) {
    super(
      pgConnection,
      QUICKBOOKS_LOCATION_TABLE_NAME,
      username,
      QUICKBOOKS_LOCATION_COLUMNS,
      'id'
    )
  }
  async getOneWithMarketData (id, { whereCondition = 'id' }) {
    const query = `
      SELECT "ql".*, "l"."market", "l"."code", "l"."email", "m"."id" as "market_id"
      FROM "avocado"."data_quickbookslocation" ql
      LEFT JOIN "avocado"."data_location" l ON "l"."uuid" = "ql"."location_id"
      LEFT JOIN "avocado"."data_market" m ON l.market ilike any (m.aliases)
      WHERE "ql"."company_code" != 'LEGACY' AND "ql"."${whereCondition}" = $1
    ` // we need exclude legacy from query
    const { rows } = await this.pgConnection.query(query, [id])
    const row = rows[0]
    if (!row) {
      const err = new Error('Row not found')
      err.status = 404
      throw err
    }
    return row
  }

  async getInvoiceLocationTxnData (qboCreatedDate, qboDocNumber) {
    const { rows } = await this.pgConnection.query(`
      select 
        ql.*,
        l.market,
        l.code,
        l.email,
        m.id as market_id,
        qt.txn_id
      from avocado.data_quickbookstransactions qt
      left join avocado.data_location l on qt.location_id = l.uuid
      left join avocado.data_quickbookslocation ql on l.uuid = ql.location_id 
      left join avocado.data_market m on l.market ilike any (m.aliases)
      where ql.company_code  != 'LEGACY' and qt.txn_type = 'invoice'
        and qt.quickbooks_created_at = $1 and qt.quickbooks_doc_number = $2
    `, [qboCreatedDate, qboDocNumber])

    const row = rows[0]
    if (!row) {
      const err = new Error('Invoice quickbooks location not found')
      err.status = 404
      throw err
    }
    return row
  }
}

module.exports = QuickbooksLocationAdapter
