import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import flowRight from 'lodash/flowRight'

import withOffline from '@fielded/shared-ui/src/common/offline'
import { SyncProgress } from '@fielded/shared-ui'
import { Close } from '@fielded/shared-ui/src/icons'

import { hasFeature } from '../../van-shared/utils/features'
import withConfig from '../../van-shared/hoc/withConfig'
import { isShelflifeReseller } from '../../van-shared/utils/utils'
import { syncSelection } from '../reducers/common/sync'
import { isPSMFacility } from '../../subapps/shipments/common/utils'
import { withAuth } from '../../common/AuthenticationProvider'
import { withApi } from '../../common/ApiProvider'
import { getTCDocuments } from '../../common/terms-and-conditions-api'
import { cfLogSignOut } from '../../common/cf-adapter'

import MainMenuUserDetails from './MainMenuUserDetails'
import MainMenuLogoutPrompt from './MainMenuLogoutPrompt'
import MainMenuItems from './MainMenuItems'

const MainMenu = ({
  user,
  history,
  config,
  isOnline,
  isCollapsed,
  pendingContracts,
  outdatedContracts,
  hasSubscriptionBasedService,
  isBasicUser,
  isRetailer,
  isLeadUser,
  userWithDB,
  onToggleCollapsed,
  onOpenNavCollapsed,
  logout,
  canUserLogout,
  persistor
}) => {
  const sync = useSelector(syncSelection)
  const [userDetailsExpanded, setUserDetailsExpanded] = useState(false)
  const [logoutStep, setLogoutStep] = useState(null)
  const [logoutAnimation, setLogoutAnimation] = useState(false)
  const [menuContainerRef, setMenuContainerRef] = useState()
  const [menuListRef, setMenuListRef] = useState()

  const menuContainer = useRef()
  const menuList = useRef()

  const {
    app,
    shortName,
    name,
    program
  } = config

  let userName = user.name

  if (user.profile && user.profile.fullName) {
    userName = user.profile.fullName
  }
  const isPSM = app === 'psm'
  const isPSMFacilityUser = isPSMFacility(user)
  const isSLReseller = isShelflifeReseller(config)
  const termsAndConditionsDocs = getTCDocuments(config, user)

  useEffect(() => {
    updateRefs()

    return () => {
      window.removeEventListener('resize', checkMenuChange)
    }
  }, [menuContainerRef, menuListRef])

  const updateRefs = () => {
    setMenuContainerRef(menuContainer.current)
    setMenuListRef(menuList.current)

    checkMenuChange()
    window.addEventListener('resize', checkMenuChange)
  }

  const closeMobileSidebar = () => {
    window.dispatchEvent(new CustomEvent('show-mobile-sidebar', { // eslint-disable-line
      detail: { show: false }
    }))
  }

  const checkMenuChange = () => {
    // Closing the Sidebar in Mobile mode, for any resize
    // this prevents having the dimmed backdrop overlaying the app
    closeMobileSidebar()
  }

  const toggleCollapsed = () => {
    onToggleCollapsed()
    setUserDetailsExpanded(false)

    // Set timeout to allow re-render and css transitions to finish
    // before checking height again to see if scroll buttons are needed
    setTimeout(() => {
      checkMenuChange()
    }, 300)
  }

  const toggleUserDetails = () => {
    if (!userDetailsExpanded) {
      onOpenNavCollapsed()
      setUserDetailsExpanded(true)
    } else {
      setUserDetailsExpanded(false)
    }
  }

  const onRequestLogout = async () => {
    if (!hasFeature(config, 'destroyDataOnLogout')) {
      await logout(user, {history, config, clearLocalData: false})
      return
    }

    try {
      const allowLogout = await canUserLogout()
      const logoutStep = allowLogout ? 'confirm' : 'block'
      setLogoutStep(logoutStep)
    } catch (error) {
      // If we had trouble figuring out a pending upload count,
      // still let the user log out.
      setLogoutStep('confirm')
    } finally {
      setLogoutAnimation(true)
    }
  }

  const onCancelLogout = () => {
    setLogoutAnimation(false)

    setTimeout(() => {
      setLogoutStep(null)
    }, 300)
  }

  const onLogout = async () => {
    setLogoutStep('loggingOut')
    cfLogSignOut(user)
    logout(user, {history, config, clearLocalData: true})
    persistor.purge()
  }

  return (
    <div className={classnames(
      'main-menu',
      {'main-menu--is-collapsed': isCollapsed},
      {'main-menu--prompt-visible': logoutStep}
    )}>
      <div className='main-menu__close' onClick={closeMobileSidebar}><Close /></div>
      <div className='main-menu__top'>
        <button
          className='main-menu__toggle'
          onClick={() => toggleCollapsed()}
        >
          Menu
        </button>
        <div className='main-menu__app'>
          <div className='main-menu__app-name'>{shortName || name}</div>
          <div className='main-menu__app-program'>{program}</div>
        </div>
      </div>

      <MainMenuUserDetails
        hasUser={!!user}
        isExpanded={userDetailsExpanded}
        userName={userName}
        termsAndConditionsDocs={termsAndConditionsDocs}
        onToggleUserDetails={toggleUserDetails}
        onRequestLogout={onRequestLogout}
      />

      <nav className='main-menu__content'>
        <div className='main-menu__content-scroll' ref={menuContainer}>
          <ul className='navigation-list' ref={menuList}>
            <MainMenuItems
              config={config}
              user={user}
              isPSM={isPSM}
              isPSMFacilityUser={isPSMFacilityUser}
              isSLReseller={isSLReseller}
              isBasicUser={isBasicUser}
              isLeadUser={isLeadUser}
              isRetailer={isRetailer}
              isCollapsed={isCollapsed}
              isOnline={isOnline}
              pendingContracts={pendingContracts}
              outdatedContracts={outdatedContracts}
              hasSubscriptionBasedService={hasSubscriptionBasedService}
              userWithDB={userWithDB}
            />
          </ul>
        </div>
      </nav>

      <div className='main-menu__connection-status'>
        <SyncProgress
          isOnline={isOnline}
          isUpToDate={sync.upToDate}
          progress={sync.percentComplete}
          compact={isCollapsed}
        />
      </div>

      <MainMenuLogoutPrompt
        appName={config.shortName}
        logoutStep={logoutStep}
        triggerAnimation={logoutAnimation}
        onCancelLogout={onCancelLogout}
        onLogout={onLogout}
      />
    </div>
  )
}

MainMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const withHOCs = flowRight(
  withOffline,
  withApi,
  withRouter,
  withConfig
)

export default (withAuth('logout', 'canUserLogout', 'user')(withHOCs(MainMenu)))
