const get = require('lodash/get')
const { getTerritoryForProduct } = require('../../service/tools/territory-lookup')
const { translateTerritoryAlias, productAliasesByMarket } = require('../../product/tools/translate-territory-alias')
const { batchIdToProductId } = require('../../shipment/tools/product-batch-util')

exports.bulkTranslateShipmentProducts = bulkTranslateShipmentProducts
function bulkTranslateShipmentProducts (state, {
  shipment,
  location,
  products,
  specificCountsObject,
  service,
  serviceId
}) {
  let logger
  if (state) {
    logger = state.logger
  }
  let {counts} = shipment
  if (specificCountsObject) {
    counts = specificCountsObject
  }

  let destinationService = serviceId

  if (!destinationService) {
    destinationService = service ? service.id : get(location, 'services[0]')
  }

  // Sometimes we get passed the basic tier service, replace with regular service
  destinationService = destinationService.replace('-basic-tier', '')

  const productsForTranslation = productAliasesByMarket(products)

  const productsNotTranslated = []

  const translatedCounts = Object.keys(counts).reduce((acc, batch) => {
    const productId = batchIdToProductId(batch)
    const territory = getTerritoryForProduct(productId)

    if (!territory) {
      acc[batch] = counts[batch]
      return acc
    }

    const productService = get(territory, 'service')

    if (productService !== destinationService) {
      const translatedProductId = translateTerritoryAlias(productId, productsForTranslation, destinationService)
      // If we could not translate this product log it to sentry.
      if (productId === translatedProductId) {
        productsNotTranslated.push(productId)
      }
      const newBatchId = batch.replace(productId, translatedProductId)
      acc[newBatchId] = counts[batch]
      return acc
    }

    acc[batch] = counts[batch]
    return acc
  }, {})

  if (productsNotTranslated.length > 0) {
    logger.warn(`Warning: Could not translate products ${productsNotTranslated.toString()} in delivery ${shipment.id}`)
  }

  return {
    translatedCounts,
    productsNotTranslated
  }
}
