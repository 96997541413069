import masterDataSlice from './master-data-reducer'

export {
  fetchMasterData,
  selectHasError,
  selectHasReceivedMasterData,
  selectMasterData
} from './master-data-reducer'

export default masterDataSlice
