const installmentQueryBuilder = require('./installments-query-builder')

const {
  TABLE_NAMES: {
    TRANSACTIONS_TABLE_NAME
  }
} = require('../../constants')

const overdueInvoiceTransactions = (locationIdArgs) => `
  transactions AS (
    SELECT
      txn.created_at,
      txn.updated_at,
      txn.id,
      txn.company_code,
      txn.txn_id,
      txn.quickbooks_doc_number,
      txn.txn_type,
      txn.txn_date,
      txn.due_date,
      txn.quickbooks_created_at,
      txn.quickbooks_updated_at,
      txn.amount,
      txn.amount_paid,
      txn.description,
      txn.location_id,
      txn.quickbooks_account_id,
      txn.order_type,
      '{}'::jsonb as payment_plan,
      null as "shipment_id",
      null as "order_id"
    FROM ${TRANSACTIONS_TABLE_NAME} as txn
    where txn.txn_type = 'invoice' and coalesce(txn.amount, 0) - coalesce(txn.amount_paid, 0) > 0 and txn.location_id in (${locationIdArgs})
  )
`

const overdueInstalmentTransactions = (locationIdArgs) => `
installments AS (
  ${installmentQueryBuilder({instalmentTableJoin: true, isActiveClause: true, includeBalance: false, locationIdArgs, onlyUnpaid: true})}
)
`

module.exports = {
  overdueInvoiceTransactions,
  overdueInstalmentTransactions
}
