const saveChanges = require('./shipment-save-changes')
const findShipment = require('./shipment-find')
const { isBatchId, isSnapshotId } = require('./tools/product-batch-util')
const toShipmentId = require('./tools/to-shipment-id')
const getBatchesMatchingProductId = require('./tools/get-batches-matching-product-id')
const { generateUnknownBatchForProduct } = require('./tools/product-batch-util')

const hasProduct = (productId, counts) => {
  return getBatchesMatchingProductId(productId, counts).length
}

function addProduct (state, snapshotId, productId) {
  if (isBatchId(productId)) return Promise.reject(new Error(`${productId} should be a productId`))
  if (!isSnapshotId(snapshotId)) return Promise.reject(new Error(`${snapshotId} should be a snapshotId`))

  const shipmentId = toShipmentId(snapshotId)
  return findShipment(state, shipmentId)
    .then(shipments => {
      const shipment = shipments[0]
      if (hasProduct(productId, shipment.counts)) {
        return shipment
      }

      return saveChanges(state, snapshotId, {
        [generateUnknownBatchForProduct(productId)]: {
          quantity: 0
        }
      })
    })
}

module.exports = addProduct
