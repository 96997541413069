let cachedInitialSyncStatus

// This should all be synchronous
export const getInitialSyncStatus = () => {
  if (cachedInitialSyncStatus !== undefined) {
    return cachedInitialSyncStatus
  }
  const localStorageStatus = window.localStorage.getItem('initialSyncStatus')
  cachedInitialSyncStatus = !!localStorageStatus
  return cachedInitialSyncStatus
}

export const saveInitialSyncStatus = (syncStatus) => {
  cachedInitialSyncStatus = syncStatus
  window.localStorage.setItem('initialSyncStatus', JSON.stringify(syncStatus))
}
