module.exports = `
with
args as (
  select
    $1::uuid as invoice_id
),
invoice as (
  select
    a.invoice_id as id,
    i.created_at,
    now()::text as date,
    m.country_code,
    case
      when m.country_code = 'ng' then 0.075
      when m.country_code = 'ke' then 0.16
      else 0
    end as country_vat_rate,
    upper(substring(market_id from 1 for 3)) as market_code,
    coalesce(m.invoice_emails, ARRAY[]::character varying[]) as cc_emails,
    l.code as location_code,
    l.membership_type,
    (string_to_array(coalesce(l.email, ''), ','))[1] as email,
    ql.company_code as company_code,
    ql.quickbooks_customer_id as customer_id,
    s.shipment_ids
  from args a
  left join avocado.data_invoice i on i.id = a.invoice_id
  left join avocado.data_location l on l.uuid = i.location_id
  left join avocado.data_market m on l.market_id = m.id
  left join avocado.data_quickbookslocation ql on ql.location_id = i.location_id
  cross join lateral (
    select array_agg(distinct shipment_id) as shipment_ids
    from avocado.data_invoice_line il
    where il.invoice_id = a.invoice_id
      and il.shipment_id is not null
  ) s
  where ql.company_code != 'LEGACY'
),
lines as (
  select
    l.*,
    to_char(l.date, 'YYYY-MM-DD') as date_str
  from avocado.data_invoice_line l
  cross join args a
  where l.invoice_id = a.invoice_id
),
delivery_fee_lines as (
  select
    'DF' as sku,
    'Delivery Fee' as item,
    '' as description,
    1 as quantity,
    delivery_fee as price,
    0 as bonus,
    delivery_fee as gross,
    false as vatable,
    0 as vat,
    'delivery_fee' as event_type,
    order_invoicing_type,
    'debit' as booking_type
  from (
    select
      o.order_id,
      delivery_fee,
      max(order_invoicing_type) as order_invoicing_type
    from lines l
    inner join avocado.data_order o on o.order_id = l.order_id
    group by o.order_id, delivery_fee
    having delivery_fee is not null
  ) df
),
fee_lines as (
  select
    l.sku,
    item_name as item,
    description,
    1 as quantity,
    amount as price,
    0 as bonus,
    amount as gross,
    false as vatable,
    0 as vat,
    f.tableoid::regclass::text as event_type,
    order_invoicing_type,
    'debit' as booking_type
  from lines l
  left join avocado.fee f on f.event_id = l.event_id
  where event_type = 'fee'
),
product_lines as (
  select
    l.date,
    l.sku,
    display_name as item,
    sellprice as price,
    p.hs_code,
    ( case
      when i.country_code = 'ke' THEN ''
      else
        case l.event_type
          when 'ooc_buyout' then 'OOC buyout ' || date_str
          when 'buyout' then 'Buyout ' || date_str
          when 'delivery' then 'Delivery ' || date_str
          when 'service_change_buyout' then 'Service change buyout ' || date_str
          when 'cnr_sell_off' then 'Retained on ' || date_str
          when 'topup_buyout' then 'Top up request on ' || date_str
          when 'stock_count' then 'Open: ' || e.prev_opening || ' Close: ' || e.closing || ' ' || date_str
          when 'stock_count_adjustment' then 'Open: ' || e.prev_opening || ' Close: ' || e.closing || ' ' || date_str || ' adjustment'
          else date_str
        end
      end
    ) || (
      case
      when i.country_code = 'ke' and coalesce(p.hs_code, '') = '' then 'VAT: ' || trim_scale(i.country_vat_rate * 100) || '%'
      else ''
      end
    ) as description,
    abs(quantity) as quantity,
    bonus,
    gross,
    -- For kenya invoices, product with hs_code have no vat, we ignore the value in vat column
    -- For kenya invoices, product without hs_code automatically gave vat applied, we ignore the value in vat column
    -- For nigeria invoices, continue to rely on value in the vat column per sku
    case
      when i.country_code = 'ke' and coalesce(p.hs_code, '') = '' then true
      when i.country_code = 'ke' and coalesce(p.hs_code, '') != '' then false
      else p.vat
    end as vatable,
    gross * (
      coalesce(
        case
          when i.country_code = 'ke' and coalesce(p.hs_code, '') = '' then 1
          when i.country_code = 'ke' and coalesce(p.hs_code, '') != '' then 0
          else p.vat::int
        end,
        0
      ) * i.country_vat_rate) as vat,
    l.event_type,
    order_invoicing_type,
    case
      when quantity > 0 then 'debit'
      when quantity < 0 then 'credit'
    end as booking_type
  from lines l
  cross join invoice i
  left join lateral (
    -- make sure we only ever join one price
    select *
    from real.sellprice_daily
    where sku = l.sku and date = date_trunc('day', l.date)
    limit 1
  ) sp on true
  left join lateral (
    -- make sure we only ever join one product and prefer active ones
    select *
    from avocado.data_productsku ps
    left join avocado.data_productpresentation pr on ps.presentation_id = pr.id
    left join avocado.data_productbase pb on pb.id = pr.product_id
    left join real.hs_code_category c on lower(c.category) = lower(pb.category)
    where old_sku_id = l.sku
    order by ps._deleted nulls first,
             visibility = 'Discontinued'
    limit 1
  ) p on true
  left join avocado.data_stocksummaryevent e on l.event_id = e.id
  cross join lateral (
    select abs(quantity) * sellprice - bonus as gross
  ) g
  where l.event_type != 'fee'
    and quantity <> 0
  order by sku, date
),
agg_lines as (
    select
      sku,
      item,
      description,
      price,
      hs_code,
      date,
      quantity,
      gross,
      bonus,
      vatable,
      vat::double precision,
      event_type,
      order_invoicing_type,
      booking_type,
      false as is_fee
    from product_lines
  union all
    select
      sku,
      item,
      description,
      price,
      null as hs_code,
      now() as date,
      quantity,
      gross,
      bonus,
      vatable,
      vat::double precision,
      event_type,
      order_invoicing_type,
      booking_type,
      true as is_fee
    from fee_lines
  union all
    select
      sku,
      item,
      description,
      price,
      null as hs_code,
      current_date as date,
      quantity,
      gross,
      bonus,
      vatable,
      vat::double precision,
      event_type,
      order_invoicing_type,
      booking_type,
      true as is_fee
    from delivery_fee_lines
)
select
  (select to_jsonb(i) from (
    select
      id,
      created_at as "createdAt",
      date,
      market_code as "marketCode",
      location_code as "locationCode",
      country_vat_rate as "vatRate",
      membership_type as "membershipType",
      email,
      cc_emails as "ccEmails",
      company_code as "companyCode",
      customer_id as "customerId",
      coalesce(shipment_ids, array[]::text[]) as "shipmentIds"
    from invoice
  ) i) as invoice,
  coalesce(
    (select jsonb_agg(l) from (
      select
        sku,
        item,
        description,
        price::double precision,
        hs_code as "hsCode",
        quantity::double precision,
        gross::double precision,
        vatable,
        vat::double precision,
        bonus::double precision,
        event_type as "eventType",
        order_invoicing_type as "orderInvoiceType",
        booking_type as "bookingType",
        is_fee as "isFee",
        date
      from agg_lines
     ) l),
     '[]'::jsonb
  ) as "lines"
`
