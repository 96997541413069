import { C_R, TOPUP, RESUPPLY } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { isHeldLocation } from '@fielded/fs-api/lib/shipment/tools/is-held-location'
import getSnapshotId from '@fielded/fs-api/lib/shipment/tools/get-snapshot-id'

export const COMPLETED_SHIPMENT_STATUSES = ['sent', 'arrived', 'received']

export const isReturnShipment = (user, shipment) => {
  // Always flag C&R shipments as returns:
  if (shipment.planningType === C_R) {
    return true
  }

  if (shipment.planningType === RESUPPLY) {
    return false
  }

  const originIsHeld = shipment.origin && isHeldLocation(shipment.origin.id)
  const destinationIsHeld = shipment.destination && isHeldLocation(shipment.destination.id)
  return destinationIsHeld && !originIsHeld
}

export const isTopUp = (shipment) => {
  if (!shipment) {
    return false
  }

  return shipment.planningType === TOPUP
}

export const isPendingTopup = shipment => {
  if (!shipment) {
    return false
  }

  const isPendingShipment = shipment.status === 'request' || shipment.status === 'in-process'
  return isPendingShipment && isTopUp(shipment)
}

export const isCR = (shipment) => {
  if (!shipment) {
    return false
  }

  return shipment.planningType === C_R
}

export const getReturnItems = (shipment) => {
  return Object.keys(shipment.counts).reduce((acc, val) => {
    const data = shipment.counts[val]
    const quantity = data.adjustment ? (data.adjustment.original - data.quantity) : data.quantity
    acc[val] = {
      checked: true,
      quantity: quantity
    }
    return acc
  }, {})
}

export const transformToReturnShipment = (state, shipment) => {
  const returnItems = getReturnItems(shipment)
  const data = {
    origin: shipment.destination,
    destination: shipment.origin,
    counts: returnItems,
    statusType: shipment.statusType,
    orderId: shipment.orderId,
    routeId: shipment.routeId
  }
  const id = getSnapshotId(state, data)
  Object.assign(data, {
    id,
    snapshotId: id
  })
  return data
}

export const getFractionChecked = batches => batches
  .reduce((accumulator, batch) => {
    accumulator.total++
    if (batch.checked) {
      accumulator.checked++
    }
    return accumulator
  }, { checked: 0, total: 0 })

export const isShipmentStatusComplete = (status) => {
  return COMPLETED_SHIPMENT_STATUSES.includes(status)
}
