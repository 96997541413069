import React from 'react'

import { TableEmptyBox } from '@fielded/shared-ui'
import { Truck } from '@fielded/shared-ui/src/icons'

const LMDListEmptyInfoBox = ({loading}) => {
  return (
    <TableEmptyBox
      loading={loading}
      loadingText='Updating deliveries list...'
      mainCopy='No last mile deliveries'
      supportingCopy='No last mile deliveries available. Check back later for updates.'
      icon={<Truck />}
    />
  )
}
export default LMDListEmptyInfoBox
