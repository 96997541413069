/**
 *  This page is meant to be used by SL drivers
 *  doing shipment deliveries at a Pharmacy.
 *
 *  We look up shipments to be delivered on a location.
 *  If only one is found, redirect to picklist for that,
 *  otherwise, show shipment card for each w link to picklist.
 */
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'
import { withUser } from '../../../common/AuthenticationProvider'
import { findShipments } from '../../../root/reducers/shipments'
import { fetchMasterData, selectMasterData } from '../../../root/reducers/shipments/master-data'
import ErrorView from '../components/LoadingError'
import { dateSortedIncompleteArrival } from './selectors'
import Deliveries from './Deliveries'

const DeliveriesContainer = ({
  api,
  user,
  match,
  config,
  rootHistory
}) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)
  const shipmentsFromState = useSelector(state => state.shipments)

  const [mdLocation, setMdLocation] = useState({})
  const [hasReceivedShipments, setHasReceivedShipments] = useState(false)
  const [error, setError] = useState(null)

  const userLocation = get(match, 'params.location')
  const location = userLocation ? {id: userLocation} : user.location

  const shipments = dateSortedIncompleteArrival(shipmentsFromState)
  const products = get(masterData, 'products.byId', {})

  const hasReceivedMasterData = get(masterData, 'hasReceivedMasterData', false)

  const initialize = async () => {
    if (!hasReceivedMasterData) {
      await dispatch(fetchMasterData())
    }

    if (!hasReceivedShipments) {
      try {
        await dispatch(findShipments(location))
        setHasReceivedShipments(true)
        setError(null)
      } catch (error) {
        setError(error)
      }
    }

    try {
      const locationId = location.id
      const mdLocation = await api.location.get(locationId)

      if (mdLocation) {
        setMdLocation(mdLocation)
      }
    } catch (error) {
      console.warn(`Could not get location from master data`, error)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    const getShipments = async () => {
      setHasReceivedShipments(false)
      setError(null)

      try {
        await dispatch(findShipments(location))
        setHasReceivedShipments(true)
      } catch (error) {
        setError(error)
      }
    }

    getShipments()
  }, [location.id])

  if (error) {
    return (
      <ErrorView error={error} />
    )
  }

  if (!(hasReceivedShipments && hasReceivedMasterData)) {
    return <Loading loadingText='Looking for open deliveries …' />
  }

  let locationFallback = location.id.split(':').pop()
  locationFallback = locationFallback.charAt(0).toUpperCase() + locationFallback.slice(1)

  if (shipments.length === 1) {
    const targetShipment = shipments[0].snapshotId
    return <Redirect to={`/shipments/pick-list/${targetShipment}`} />
  }

  return (
    <Deliveries
      shipments={shipments}
      location={mdLocation.fullName || locationFallback}
      config={config}
      products={products}
      rootHistory={rootHistory}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(DeliveriesContainer)
