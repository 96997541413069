module.exports = getLedgerBalanceAsReport

const get = require('lodash/get')
const tools = require('./tools')
const getLedgerDate = require('./tools/get-ledger-balance2').getLedgerDate
const getLedgerBalance = require('./stock-get-ledger-balance')
const { isV2Id, isV1Id } = require('./report/tools/ids')
const { get: getLocation } = require('./location/api/read/get')

const isCount = (report) => {
  // Need to differentiate between ledger and counts
  // to get the 'documents' key right
  // unfortunately that is not available on doc.type
  if (!report) {
    return false
  }

  let { _id } = report
  _id = _id || ''
  return isV1Id(_id) || isV2Id(_id)
}

async function getLedgerBalanceAsReport (state, params) {
  const paramsWithEntities = Object.assign(
    { // default to today, overwritten if included in params
      date: new Date().toJSON()
    },
    params,
    { withEntities: true }
  )

  const calculateFields = params.calculateFields
  const asDocument = params.asDocument
  const returnShipmentStock = params.returnShipmentStock
  delete paramsWithEntities.returnShipmentStock
  delete paramsWithEntities.asDocument
  delete paramsWithEntities.calculateFields

  const {
    ledger,
    products,
    reports,
    shipments,
    preCalculatedLedger,
    baseReport,
    addedShipments,
    fields
  } = await getLedgerBalance(state, paramsWithEntities)

  let { locationId, service, date, includeScheduledOutbound } = paramsWithEntities
  let location = await getLocation(state, locationId, paramsWithEntities.date)

  if (location) {
    location.id = locationId
  } else {
    location = { id: locationId }
  }

  const { user } = state

  const report = tools.ledgerBalanceToReport({
    ledger, location, service, date, user, products, asDocument, fields, calculateFields
  })

  const reportMeta = getLedgerDate({
    location,
    date,
    // Need to add the pre-calc ledger back in the
    // reports array here so we can get the submittedAt date for
    // a ledger that is based on a pre-calculated one
    reports: reports.concat(preCalculatedLedger || []),
    shipments,
    includeScheduledOutbound
  })

  report.submittedAt = reportMeta.lastChangeDate
  // This can be used to see if a stock count was submitted for this period
  report.hasStockCount = reportMeta.stockCountId === report._id

  // This is a meta prop to be used to debug
  // Shelflife report opening balances and what data they are based on
  //
  // The `reportUpdatedAt` part is used by the Orders API to know when
  // was the last count in a particular location, which is needed to calculate
  // the QTO (quantity to order)
  let reportId
  let reportRev
  let reportUpdatedAt
  let serverLedgerShipments = []
  let serverLedgerId = 'none'
  let counts = []

  const isStockCount = isCount(baseReport)
  if (baseReport && isStockCount) {
    reportId = baseReport._id
    reportRev = baseReport._rev
    reportUpdatedAt = baseReport.updatedAt
  } else if (baseReport) {
    // Server generated ledger balance, use the 'document' items from there
    reportId = get(baseReport, 'documents.reportId')
    reportRev = get(baseReport, 'documents.reportRev')
    counts = get(baseReport, 'documents.counts', [])
    reportUpdatedAt = get(baseReport, 'documents.reportUpdatedAt')
    serverLedgerShipments = get(baseReport, 'documents.shipments') || []
    serverLedgerId = baseReport._id
  }

  report.documents = {
    reportId,
    reportRev,
    reportUpdatedAt,
    serverLedgerId,
    counts: Array.from(new Set(counts.concat(reportMeta.counts))),
    shipments: serverLedgerShipments.concat(addedShipments.map(s => s.snapshotId))
  }

  // This is only used for PSM for now
  if (returnShipmentStock) {
    report.shipmentStock = addedShipments.length > 0
      ? addedShipments.reduce((acc, shipment) => {
        acc = {...acc, ...shipment.counts}
        return acc
      }, {})
      : {}
  }

  return report
}
