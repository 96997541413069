import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { byId } from '../../../../subapps/shipments/common/utils'
import { updateCurrentMasterData } from '../../../../van-shared/utils/utils'

const maybeSubscribeToChanges = (api, dispatch) => {
  if (!maybeSubscribeToChanges.setup) {
    maybeSubscribeToChanges.setup = true
    return () => api.batch.onListUpdated(batches => dispatch(updateBatches(batches)))
  }
  return api.batch.list
}

const getProcessedMasterData = (batches, products, manufacturers) => {
  if (batches) {
    batches = byId(batches, '_id')
  }

  if (products) {
    products = {
      byId: byId(products, '_id'),
      allIds: products.map(doc => doc._id)
    }
  }

  if (manufacturers) {
    manufacturers = {
      byId: byId(manufacturers),
      allIds: manufacturers.map(manufacturer => manufacturer.id)
    }
  }

  return { batches, products, manufacturers }
}

const initialState = {
  batches: {},
  products: { byId: {}, allIds: [] },
  manufacturers: { byId: {}, allIds: [] },
  hasReceivedMasterData: false,
  error: null
}

export const fetchMasterData = createAsyncThunk('shipments/fetchMasterData', async (args, thunkAPI) => {
  const { extra: { api }, dispatch } = thunkAPI
  const getBatches = maybeSubscribeToChanges(api, dispatch)

  try {
    const response = await Promise.all([
      getBatches(),
      api.product.listAll(),
      api.batch.listManufacturers()
    ])

    const [batches, products, manufacturers] = response
    const processedMasterData = getProcessedMasterData(batches, products, manufacturers)
    return processedMasterData
  } catch (error) {
    return error
  }
})

export const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    updateBatches: (state, action) => {
      const batches = action.payload
      state.batches = byId(batches, '_id')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterData.fulfilled, (state, action) => {
        if (Object.keys(action.payload).length) {
          const { batches, products, manufacturers } = action.payload
          state.batches = batches
          state.products = products
          state.manufacturers = manufacturers
          state.hasReceivedMasterData = true
        }
      })
      .addCase(fetchMasterData.rejected, (state, action) => {
        state.error = action.payload
      })
  }
})

const { updateBatches } = masterDataSlice.actions

export const selectMasterData = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')
  return newState.masterData
}

export const selectProducts = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')
  return newState.masterData.products
}

export const selectHasError = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')
  return newState.masterData.error
}

export const selectHasReceivedMasterData = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')
  return newState.masterData.hasReceivedMasterData
}

export default masterDataSlice.reducer
