import React from 'react'
import classnames from 'classnames'

import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight'
import { FaBan } from '@react-icons/all-files/fa/FaBan'
import { FaBars } from '@react-icons/all-files/fa/FaBars'
import { FaBell } from '@react-icons/all-files/fa/FaBell'
import { FaShoppingCart } from '@react-icons/all-files/fa/FaShoppingCart'
import { FaChartBar } from '@react-icons/all-files/fa/FaChartBar'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import { FaClipboardList } from '@react-icons/all-files/fa/FaClipboardList'
import { FaCogs } from '@react-icons/all-files/fa/FaCogs'
import { FaCommentAlt } from '@react-icons/all-files/fa/FaCommentAlt'
import { FaDownload } from '@react-icons/all-files/fa/FaDownload'
import { FaEdit } from '@react-icons/all-files/fa/FaEdit'
import { FaExchangeAlt } from '@react-icons/all-files/fa/FaExchangeAlt'
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle'
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle'
import { FaEye } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash'
import { FaFile } from '@react-icons/all-files/fa/FaFile'
import { FaGem } from '@react-icons/all-files/fa/FaGem'
import { FaHome } from '@react-icons/all-files/fa/FaHome'
import { FaInbox } from '@react-icons/all-files/fa/FaInbox'
import { FaInfo } from '@react-icons/all-files/fa/FaInfo'
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle'
import { FaLandmark } from '@react-icons/all-files/fa/FaLandmark'
import { FaLightbulb } from '@react-icons/all-files/fa/FaLightbulb'
import { FaListUl } from '@react-icons/all-files/fa/FaListUl'
import { FaListOl } from '@react-icons/all-files/fa/FaListOl'
import { FaMapMarked } from '@react-icons/all-files/fa/FaMapMarked'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import { FaMinusCircle } from '@react-icons/all-files/fa/FaMinusCircle'
import { FaPercentage } from '@react-icons/all-files/fa/FaPercentage'
import { FaPhone } from '@react-icons/all-files/fa/FaPhone'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle'
import { FaQuestionCircle } from '@react-icons/all-files/fa/FaQuestionCircle'
import { FaRedoAlt } from '@react-icons/all-files/fa/FaRedoAlt'
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt'
import { FaRegClock } from '@react-icons/all-files/fa/FaRegClock'
import { FaRegCopy } from '@react-icons/all-files/fa/FaRegCopy'
import { FaRegTrashAlt } from '@react-icons/all-files/fa/FaRegTrashAlt'
import { FaRoad } from '@react-icons/all-files/fa/FaRoad'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import { FaShippingFast } from '@react-icons/all-files/fa/FaShippingFast'
import { FaSlidersH } from '@react-icons/all-files/fa/FaSlidersH'
import { FaSync } from '@react-icons/all-files/fa/FaSync'
import { FaThLarge } from '@react-icons/all-files/fa/FaThLarge'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle'
import { FaTruck } from '@react-icons/all-files/fa/FaTruck'
import { FaUnlockAlt } from '@react-icons/all-files/fa/FaUnlockAlt'
import { FaUpload } from '@react-icons/all-files/fa/FaUpload'
import { FaUserAlt } from '@react-icons/all-files/fa/FaUserAlt'
import { FaUserPlus } from '@react-icons/all-files/fa/FaUserPlus'

import { VscStarFull } from '@react-icons/all-files/vsc/VscStarFull'

import { ImSpinner6 } from '@react-icons/all-files/im/ImSpinner6'

import { BiDotsVerticalRounded } from '@react-icons/all-files/bi/BiDotsVerticalRounded'

import { Adjust } from './Adjust'
import { CardGift } from './CardGift'
import { Cashback } from './Cashback'
import { ClockRotate } from './ClockRotate'
import { Collapse } from './Collapse'
import { Collection } from './Collection'
import { Expand } from './Expand'
import { Gift } from './Gift'
import { GiftWithCash } from './GiftWithCash'
import { GiftWithError } from './GiftWithError'
import { Intercom } from './Intercom'
import { Orientation } from './Orientation'
import { PartnerBalanceIndicator } from './PartnerBalanceIndicator'
import { PayAsYouSell } from './PayAsYouSell'
import { PayOnDelivery } from './PayOnDelivery'
import { Products } from './Products'
import { TrendDown } from './TrendDown'
import { TruckCollection } from './TruckCollection'
import { TruckGift } from './TruckGift'

const Arrow = ({ direction = 'left' }) => (
  <span className={`icon--${direction}`}>
    <FaArrowRight />
  </span>
)

const Chevron = ({ direction = 'up' }) => (
  <span className={`icon--${direction}`}>
    <FaChevronRight />
  </span>
)

const Issue = () => (
  <span style={{ color: '#ffb500' }}>
    <FaQuestionCircle />
  </span>
)

const Spinner = () => (
  <span className='icon--spinner'>
    <ImSpinner6 />
  </span>
)

const Truck = ({ direction = 'left' }) => (
  <span className={classnames(
    { 'icon--flipped': direction === 'left' }
  )}
  >
    <FaTruck />
  </span>
)

const Refresh = ({ direction = 'right' }) => (
  <span className={classnames(
    { 'icon--flipped': direction === 'left' }
  )}
  >
    <FaRedoAlt />
  </span>
)

const Unlock = () => (
  <span className='icon--flipped'>
    <FaUnlockAlt />
  </span>
)

export {
  Adjust,
  Arrow,
  BiDotsVerticalRounded as EllipsisVerical,
  CardGift,
  Cashback,
  Chevron,
  ClockRotate,
  Collapse,
  Collection,
  Expand,
  FaBan as Ban,
  FaBars as Bars,
  FaBell as Bell,
  FaShoppingCart as Cart,
  FaChartBar as BarChart,
  FaCheck as Checkmark,
  FaCheckCircle as CheckmarkCircle,
  FaClipboardList as Clipboardlist,
  FaCogs as Settings,
  FaCommentAlt as Comment,
  FaDownload as Download,
  FaEdit as Edit,
  FaExchangeAlt as Exchange,
  FaExclamationCircle as ExclamationCircle,
  FaExclamationTriangle as ExclamationTriangle,
  FaEye as Eye,
  FaEyeSlash as EyeSlash,
  FaFile as Document,
  FaGem as Gem,
  Gift,
  GiftWithCash,
  GiftWithError,
  FaHome as Home,
  FaInbox as Inbox,
  FaInfo as Info,
  FaInfoCircle as InfoCircle,
  FaLandmark as Landmark,
  FaLightbulb as Lightbulb,
  FaListUl as ListUl,
  FaListOl as ListOl,
  FaMapMarked as MapMarked,
  FaMapMarkerAlt as MapMarker,
  FaMinusCircle as MinusCircle,
  FaPercentage as Percentage,
  FaPhone as Phone,
  FaPlus as Plus,
  FaPlusCircle as PlusCircle,
  FaQuestionCircle as QuestionMark,
  Refresh,
  FaRegCalendarAlt as Calendar,
  FaRegClock as Clock,
  FaRegCopy as Copy,
  FaRegTrashAlt as Bin,
  FaRoad as Road,
  FaSearch as Search,
  FaShippingFast as TruckFast,
  FaSlidersH as Filters,
  FaSync as Sync,
  FaThLarge as Grid,
  FaTimes as Close,
  FaTimesCircle as CloseCircle,
  FaUserAlt as User,
  FaUpload as Upload,
  FaUserPlus as UserPlus,
  Intercom,
  Issue,
  Orientation,
  PartnerBalanceIndicator,
  PayAsYouSell,
  PayOnDelivery,
  Products,
  Spinner,
  Unlock,
  VscStarFull as Star,
  TrendDown,
  Truck,
  TruckCollection,
  TruckGift
}
