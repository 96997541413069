export const defaultState = {
  sources: {}, // e.g. { 'stock-count': { download: 5, upload: 10 }, 'master-data': { download: 500 } }
  numerator: 0, // Our best guess at overall progress: any decreases seen in pending numbers.
  denominator: 0, // An aggregate pending count.
  percentComplete: -1, // A percent to show users which we only increment.
  upToDate: true, // `false` when any db is not up to date.
  stockBackgroundUpdate: true, // true when we are performing a stock count db sync
  shipmentBackgroundUpdate: true, // true when we are perfroming a van shipments db sync
  errors: []
}

// Action types
const reducerName = 'sync'
const SET_ACTIVE = `${reducerName}/SET_ACTIVE`
const SET_COMPLETE = `${reducerName}/SET_COMPLETE`
const UPDATE_PENDING = `${reducerName}/UPDATE_PENDING`
const SYNC_ERROR = `${reducerName}/SYNC_ERROR`
export const AUTH_ERROR = `${reducerName}/AUTH_ERROR`

// Reducers
const setActiveReducer = (state, { source, direction }) => {
  const nextState = cloneState(state)
  nextState.sources[source] = nextState.sources[source] || {}
  nextState.sources[source][direction] = nextState.sources[source][direction] || 0
  nextState.upToDate = false
  if (source === 'stock-count' && direction === 'download') {
    nextState.stockBackgroundUpdate = true
  }
  if (source === 'van-shipments' && direction === 'download') {
    nextState.shipmentBackgroundUpdate = true
  }
  return nextState
}

const setCompleteReducer = (state, { source, direction }) => {
  const nextState = cloneState(state)
  if (source === 'stock-count' && direction === 'download') {
    nextState.stockBackgroundUpdate = false
  }
  if (source === 'van-shipments' && direction === 'download') {
    nextState.shipmentBackgroundUpdate = false
  }
  if (nextState.sources[source]) {
    delete nextState.sources[source][direction]
    if (Object.keys(nextState.sources[source]).length === 0) {
      delete nextState.sources[source]
    }
  }
  if (Object.keys(nextState.sources).length === 0) {
    const mutatedDefault = { ...defaultState,
      stockBackgroundUpdate: nextState.stockBackgroundUpdate,
      shipmentBackgroundUpdate: nextState.shipmentBackgroundUpdate
    }
    return mutatedDefault // This is our global reset to zero but keep background update state.
  } else {
    return nextState
  }
}

const updatePendingReducer = (state, { source, direction, pending }) => {
  const nextState = cloneState(state)
  // If a source is not present, this call to pending was a race condition from a throttled function and we ignore it.
  if (!nextState.sources.hasOwnProperty(source) || !nextState.sources[source].hasOwnProperty(direction)) {
    return nextState
  }
  if (source === 'stock-count' && direction === 'download') {
    nextState.stockBackgroundUpdate = true
  }
  if (source === 'van-shipments' && direction === 'download') {
    nextState.shipmentBackgroundUpdate = true
  }
  // Progress is positive when our `pending` count decreases, negative on increase.
  const progress = nextState.sources[source][direction] - pending
  if (progress > 0) {
    nextState.numerator += progress
  } else {
    nextState.denominator += -1 * progress
  }
  // `percentComplete` is a proxy value to show users. It should never decrease.
  const newPercent = nextState.denominator ? (nextState.numerator / nextState.denominator) : 1
  if (newPercent > nextState.percentComplete) {
    nextState.percentComplete = newPercent
  }
  nextState.sources[source][direction] = pending
  return nextState
}

const syncErrorReducer = (state, { source, networkConnectionInfo, timestamp, direction, message, error }) => {
  const nextState = cloneState(state)
  nextState.errors = [
    ...nextState.errors,
    {
      source,
      direction,
      message,
      timestamp,
      error,
      networkConnectionInfo
    }
  ]
  // Limiting list of error objects to 100 to avoid hogging memory.
  nextState.errors = nextState.errors.slice(0, 100)
  return nextState
}

export const syncReducer = (state = defaultState, {type, ...actionProps} = {}) => {
  switch (type) {
    case SET_ACTIVE: return setActiveReducer(state, actionProps)
    case SET_COMPLETE: return setCompleteReducer(state, actionProps)
    case UPDATE_PENDING: return updatePendingReducer(state, actionProps)
    case SYNC_ERROR: return syncErrorReducer(state, actionProps)
    case AUTH_ERROR: return syncErrorReducer(state, actionProps)
    default: return state
  }
}

export const syncSelection = (state) => state.sync

// Action creators
export const setActive = (source, direction) => ({
  type: SET_ACTIVE,
  source,
  direction
})

export const setComplete = (source, direction) => ({
  type: SET_COMPLETE,
  source,
  direction
})

export const updatePending = (source, direction, pending) => ({
  type: UPDATE_PENDING,
  source,
  direction,
  pending
})

export const syncError = (source, direction, error, message) => {
  let networkConnectionInfo = 'unavailable'
  if (window.navigator.connection) {
    const {downlink, effectiveType, rtt} = window.navigator.connection
    networkConnectionInfo = { downlink, effectiveType, rtt }
  }
  const type = (error && error.status && error.status === 401) ? AUTH_ERROR : SYNC_ERROR
  const newError = {
    type,
    source,
    direction,
    message,
    error,
    networkConnectionInfo,
    timestamp: new Date().toISOString()
  }
  // Until https://github.com/fielded/van-orga/issues/1353 is in, log these to the console
  // for basic troubleshooting.
  console.error(newError)
  return newError
}

function cloneState (state) {
  const nextState = {
    ...state,
    sources: {}
  }
  for (let key in state.sources) {
    nextState.sources[key] = { ...state.sources[key] }
  }
  nextState.errors = [...state.errors]
  return nextState
}
