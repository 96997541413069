module.exports = {
  parseShipmentIdIntoLocationProps
}

function parseShipmentIdIntoLocationProps (id) {
  const result = {
    origin: {},
    destination: {}
  }

  let pattern = /origin:country-(\w+)-state-([\w-]+)-sdp-([\w-]+):destination:country-(\w+)-state-([\w-]+)-sdp-([\w-]+):/
  const matchSL = pattern.exec(id)

  if (matchSL) {
    // Extract information for 'origin'
    result.origin.country = matchSL[1].toUpperCase()
    result.origin.state = matchSL[2]
    result.origin.sdp = matchSL[3]
    result.origin.id = `country:${matchSL[1]}:state:${result.origin.state}:sdp:${result.origin.sdp}`
    result.origin.name = matchSL[3]

    // Extract information for 'destination'
    result.destination.country = matchSL[4].toUpperCase()
    result.destination.state = matchSL[5]
    result.destination.sdp = matchSL[6]
    result.destination.id = `country:${matchSL[4]}:state:${result.destination.state}:sdp:${result.destination.sdp}`
    result.destination.name = matchSL[6]
    return result
  }

  pattern = /origin:zone-([\w-]+)-name-([\w-]+):destination:zone-([\w-]+)-state-([\w-]+)-lga-([\w-]+)-sdp-([\w-]+):/
  const matchPSMLMD = pattern.exec(id)
  if (matchPSMLMD) {
  // Extract information for 'origin'
    result.origin.zone = matchPSMLMD[1]
    result.origin.name = matchPSMLMD[2]
    result.origin.id = `zone:${result.origin.zone}:name:${result.origin.name}`

    // Extract information for 'destination'
    result.destination.zone = matchPSMLMD[3]
    result.destination.state = matchPSMLMD[4]
    result.destination.lga = matchPSMLMD[5]
    result.destination.sdp = matchPSMLMD[6]
    result.destination.id = `zone:${result.destination.zone}:state:${result.destination.state}:lga:${result.destination.lga}:sdp:${result.destination.sdp}`
    return result
  }

  // Extract information for 'destination'
  const dest = id.split(':destination:')[1]
  const destCountry = dest.split(':')[0].split('-state-')[0].replace('-', ':')
  result.destination.state = dest.split(':')[0].split('-state-')[1].split('-sdp-')[0]
  result.destination.name = dest.split(':')[0].split('-state-')[1].split('-sdp-')[1]
  result.destination.sdp = dest.split(':')[0].split('-sdp-')[1]
  result.destination.id = `${destCountry}:state:${result.destination.state}:sdp:${result.destination.name}`

  return result
}
