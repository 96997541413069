const keyBy = require('lodash/keyBy')
const { list: listFunders } = require('../../funders')
const { listPrograms } = require('./../../program/api')
const { get: getLocation, getByIds: getLocationsById } = require('./../../location/api')
const { PSM_WAREHOUSE_DEFINITIONS } = require('../tools')
const { locationIdToProperties } = require('../../../lib/tools')

// this is to show a list of select controls to a user for what they can export
exports.getRerveStockTemplateOptions = async function (state) {
  const funders = await listFunders(state)
  const programDocs = await listPrograms(state)
  const allWarehouseOptions = templateOptions({funders, programDocs})
  const { id: userLocationId, level } = state.user.location
  const { level: levelFromId } = locationIdToProperties(userLocationId)
  const userLevel = level || levelFromId

  if (userLevel === 'national') {
    return allWarehouseOptions
  } else if (userLevel === 'state') {
    const allWarehouseIds = allWarehouseOptions.locations.map(({ id }) => id)
    // returns only warehouses that supplies to the state
    const allWarehousesByState = await getLocationsById(state, allWarehouseIds)
    return {
      locations: allWarehousesByState.map(({ _id, name, additionalData }) =>
        ({ id: _id, name, warehouseCode: additionalData.warehouseCode }))
    }
  }

  const userLocation = await getLocation(state, userLocationId)
  return filterOptionsForUser(allWarehouseOptions, userLocation, state.user.funders, state.user.programs)
}

function filterOptionsForUser (allWarehouseOptions, userLocation, fundersFilter = [], userPrograms = []) {
  const {warehouseCode} = userLocation.additionalData

  const locations = allWarehouseOptions.locations
    .filter(warehouse => warehouse.warehouseCode === warehouseCode)

  const programs = allWarehouseOptions.programs
    .filter(program => userLocation.programs.find(p => (p.id === program._id)))
    .filter(program => userPrograms.includes(program._id))
    .reduce((acc, program) => {
      const filteredProgram = Object.assign({}, program, {
        funders: program.funders.filter(funder =>
          !fundersFilter || fundersFilter.includes(funder._id)
        )
      })
      return acc.concat(filteredProgram)
    }, [])
    .filter(program => program.funders.length)

  return {locations, programs}
}

function templateOptions ({funders, programDocs}) {
  const programsById = keyBy(programDocs, 'id')

  const programsWithFunder = funders
    .reduce((acc, funderDoc) => {
      Object.keys(funderDoc.programs).forEach(programId => {
        // Hide sub funders
        if (!funderDoc.hasOwnProperty('ordersParentFunderId')) {
          acc[programId] = acc[programId] || {_id: programId, name: programsById[programId].name, funders: {}}
          acc[programId].funders[funderDoc._id] = funderDoc
        }
      })
      return acc
    }, {})

  const programs = Object.values(programsWithFunder)
    .map(program =>
      Object.assign({}, program, {
        funders: Object.values(program.funders)
          .map(funder => ({name: funder.name, _id: funder._id}))
      })
    )

  const locations = Object.values(PSM_WAREHOUSE_DEFINITIONS)
    .map(d => ({name: d.name, warehouseCode: d.warehouseCode, id: d._id}))

  return {locations, programs}
}
