import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withConfig from '../../../van-shared/hoc/withConfig'
import AppContainer from '../app/AppContainer'

class Root extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired
  }

  render () {
    const {
      routerParams,
      rootHistory,
      config
    } = this.props

    return (
      <AppContainer
        rootHistory={rootHistory}
        routerParams={routerParams}
        config={config}
      />
    )
  }
}

export default withConfig(Root)
