const { batchIdToProductId } = require('../../shipment/tools/product-batch-util')
const { fetchData } = require('./fetch-data.js')
const { isBasicTierService } = require('../../tools')
const { MEMBERSHIPS } = require('../../location/tools/constants')
const { getMembershipTypeAwareServiceId } = require('../../location/tools/membership-type-service-ids')

// TODO: this is duplicated - is there an API method for this?
const isAdjustmentRegexp = /:adjustment:/
const isShipmentAdjustment = id => isAdjustmentRegexp.test(id)

// string to return in case a value is not available.
// useful in tests where we don't have locations set up
const valueNotAvailable = 'n/a'

// get entry type to decide which processing function above we'll choose
const getEntryType = ({ id, type }) => {
  if (type === 'stockCount') return 'stock_count'
  if (isShipmentAdjustment(id)) return 'shipment_adjustment'
  return 'shipment'
}

// get sku from product id. Product ids are prefixed with 'product:', while skus do not have this prefix
const getSku = productId => productId
  ? productId
    .replace(/^product:/, '')
    .replace(/,/g, '')
  : valueNotAvailable

// compose a location fsid sku, eg `country:ke:state:nairobi:sdp:cadium-pharmacy-10047`
const getLocationFsidSku = ({ locationFsid, sku }) => `${locationFsid}-${sku}`

// given a year and a week, compose a yearweek string in the format `<year>.<week>`, eg `2022.01`
const getYearweek = ({ year, week }) => year && typeof week !== 'undefined'
  ? `${year}.${week.toString().padStart(2, '0')}`
  : valueNotAvailable

// derive event type for stock counts from remark.
// Determine whether it's a stock count adjustment by looking at the `partialCount` property
const getEventType = ({ partialCount, partnerBuyout, standardRemark }) => {
  if (standardRemark && standardRemark.startsWith('pod_delivery adjustment')) return 'shipment_adjustment'
  if (standardRemark && standardRemark.startsWith('pod_delivery')) return 'delivery'
  if (standardRemark && standardRemark.startsWith('purchase_delivery')) return 'delivery'
  if (standardRemark && standardRemark.startsWith('purchase_delivery adjustment')) return 'shipment_adjustment'
  if (standardRemark && standardRemark.startsWith('immediate_purchase_delivery')) return 'delivery'
  if (standardRemark && standardRemark.startsWith('immediate_purchase_delivery adjustment')) return 'shipment_adjustment'
  if (standardRemark && standardRemark.startsWith('topup_buyout')) return 'topup_buyout'
  if (standardRemark && standardRemark.startsWith('c&r_invoice')) return 'cnr_sell_off'
  if (standardRemark && standardRemark.startsWith('ooc_sellout')) return 'ooc_buyout'
  if (standardRemark && standardRemark.startsWith('service_change_buyout')) return 'service_change_buyout'
  if (partnerBuyout !== undefined) return 'buyout'
  if (partialCount) return 'stock_count_adjustment'
  return 'stock_count'
}

// derive delivery type from remark
const getDeliveryType = standardRemark => {
  if (standardRemark && standardRemark.startsWith('pod_delivery')) return 'pay_on_delivery'
  if (standardRemark && standardRemark.startsWith('pod_buyout')) return 'pay_on_delivery'
  if (standardRemark && standardRemark.startsWith('purchase_delivery')) return 'purchase'
  if (standardRemark && standardRemark.startsWith('purchase_buyout')) return 'purchase'
  if (standardRemark && standardRemark.startsWith('immediate_purchase_delivery')) return 'immediate_purchase'
  if (standardRemark && standardRemark.startsWith('immediate_purchase_buyout')) return 'immediate_purchase'
  return 'pay_as_you_sell'
}

// get all the data for given location and period and transform into stock events rows
const generateReportData = async (
  state,
  locationId,
  serviceId,
  period,
  {
    locations,
    service,
    locale,
    includeChildren,
    adjustments
  } = {
    locations: null,
    service: null,
    locale: 'en-US',
    includeChildren: false,
    adjustments: true
  }
) => {
  const params = {
    state,
    locationId,
    serviceId,
    period,
    service,
    locations,
    includeChildren,
    ignoreService: true
  }
  // We want to get both basic and classic services when we fetch a location's data for that period
  if (service && service.id) {
    const complementaryMembership = isBasicTierService(service.id)
      ? MEMBERSHIPS.CLASSIC
      : MEMBERSHIPS.BASIC

    params.service = [
      service,
      {
        ...service,
        id: getMembershipTypeAwareServiceId(service.id, complementaryMembership)
      }
    ]
  }

  const events = await fetchData(params)

  // ledger is used for for closing
  let currentOpenings = {}

  let rows = []
  for (const event of events) {
    // destructure the event
    // events hold accumulated information from stock counts, shipments and shipment adjustments
    // look at `./fetch-data.js` for how the events are built
    const {
      locationId,
      location: {
        additionalAttributes: {
          uuid
        } = {}
      } = {},
      deliveryCycle: {
        year,
        week
      } = {},
      startdate,
      entry: {
        id,
        type,
        _id,
        createdBy,
        updatedBy: {
          user: updatedBy
        } = {},
        createdAt,
        submittedAt,
        partialCount,
        counts = {},
        origin: {
          id: shipmentOriginFsid
        } = {},
        shipment: {
          origin: {
            id: originFsid
          } = {},
          counts: shipmentCounts
        } = {},
        snapshotDates: {
          sent: snapshotSentAt,
          received: snapshotReceivedAt
        } = {},
        stock = {},
        changes = {},
        isAutomaticReturnShipment
      } = {}
    } = event

    const locationUuid = uuid || valueNotAvailable
    const locationFsid = locationId || valueNotAvailable

    const isOutgoing = (shipmentOriginFsid || originFsid) === locationFsid

    // If it's an automatically created return shipment we ignore
    if (isOutgoing && isAutomaticReturnShipment) {
      continue
    }

    // prepare ledger for location
    currentOpenings[locationId] = currentOpenings[locationId] || {}

    // handle
    const entryType = getEntryType({ id, type })
    switch (entryType) {
      case 'stock_count':
        const parentEventDate = (partialCount && startdate) || undefined
        const stockCountFields = {
          event_id: _id,
          driver: createdBy || valueNotAvailable,
          date: submittedAt,
          parent_event_date: parentEventDate
        }

        for (const productId in stock) {
          const {
            autoFilled,
            fields: {
              'field:opening-partner-balance': { amount: openingPartnerBalance } = {},
              'field:opening-shelflife-balance': { amount: openingShelflifeBalance } = {},
              'field:partner-balance': { amount: partnerBalance } = {},
              'field:partner-buyout': { amount: partnerBuyout } = {},
              'field:partner-sold': { amount: partnerSold } = {},
              'field:shelflife-balance': { amount: shelflifeBalance } = {},
              'field:shelflife-sold': { amount: shelflifeSold } = {},
              'field:sales-adjustments': { amount: salesAdjustments } = {},
              'field:standard-consumed': { amount: standardConsumed } = {},
              'field:standard-opening-balance': { amount: standardOpeningBalance } = {},
              'field:standard-physical-count': { amount: standardPhysicalCount } = {},
              'field:standard-remark': { amount: standardRemark } = {}
            } = {}
          } = stock[productId]

          if (autoFilled && partnerBuyout === undefined) continue
          if (standardPhysicalCount === undefined) continue

          const sku = getSku(productId)
          const locationFsidSku = getLocationFsidSku({ locationFsid, sku })
          const yearweek = getYearweek({ year, week })
          const eventType = getEventType({ partialCount, partnerBuyout, standardRemark })
          const deliveryType = getDeliveryType(standardRemark)

          const sold = deliveryType === 'pay_on_delivery' ? salesAdjustments : standardConsumed
          const slSold = partnerBuyout && partnerBuyout > 0
            ? -partnerBuyout
            : (shelflifeSold || 0) + (salesAdjustments || 0)

          // When we have `field:sales-adjustments` as the inverse of
          // `field:partner-buyout`, the previous behavior in stock summary is
          // no remove the sale from the invoice and not give a credit.
          // If we want a credit, `field:sales-adjustments` is either 0 or
          // absent and `field:partner-buyout` is greater than 0.
          const parentEventInvoiceMerge = salesAdjustments ? salesAdjustments === -partnerBuyout : false

          const row = {
            ...stockCountFields,

            location_id: locationUuid,
            location_fsid: locationFsid,
            yearweek,

            location_fsid_sku: locationFsidSku,
            sku,
            event_type: eventType,
            delivery_type: deliveryType,

            notes: standardRemark,

            closing: standardPhysicalCount,

            prev_opening: standardOpeningBalance,
            current_opening: standardPhysicalCount,
            sold,

            prev_sl_balance: openingShelflifeBalance,
            sl_balance: shelflifeBalance,
            sl_sold: slSold,

            prev_partner_balance: openingPartnerBalance,
            partner_balance: partnerBalance,
            partner_sold: partnerSold,

            parent_event_invoice_merge: parentEventInvoiceMerge
          }

          rows.push(row)

          // store count in ledger
          currentOpenings[locationId][productId] = standardPhysicalCount
        }

        break
      case 'shipment_adjustment':
        const shipmentAdjustmentFields = {
          event_id: id,
          driver: createdBy ? createdBy.user : valueNotAvailable,
          date: createdAt,
          event_type: 'shipment_adjustment'
        }

        for (const batchId in changes) {
          const productId = batchIdToProductId(batchId)

          const {
            paymentType
          } = shipmentCounts[batchId]

          if (paymentType === 'pay_on_delivery') continue

          const sku = getSku(productId)
          const locationFsidSku = getLocationFsidSku({ locationFsid, sku })
          const yearweek = getYearweek({ year, week })
          const deliveryType = paymentType || 'pay_as_you_sell'

          const {
            quantity: deliveredValue
          } = changes[batchId]

          // set delivered based on the shipment direction
          const delivered = isOutgoing ? -deliveredValue : deliveredValue

          // update ledger with what has been delivered
          currentOpenings[locationId][productId] = currentOpenings[locationId][productId] || 0
          currentOpenings[locationId][productId] += delivered

          const currentOpening = currentOpenings[locationId][productId]

          const row = {
            ...shipmentAdjustmentFields,

            location_id: locationUuid,
            location_fsid: locationFsid,
            yearweek,

            location_fsid_sku: locationFsidSku,
            sku,

            delivery_type: deliveryType,
            delivered,

            current_opening: currentOpening
          }

          rows.push(row)
        }

        break
      case 'shipment':
        const date = isOutgoing ? (snapshotSentAt || snapshotReceivedAt) : snapshotReceivedAt
        const shipmentFields = {
          event_id: id,
          driver: updatedBy || valueNotAvailable,
          date: date,
          event_type: 'shipment'
        }

        for (const batchId in counts) {
          const productId = batchIdToProductId(batchId)

          const {
            paymentType,
            quantity: deliveredValue
          } = counts[batchId]

          // set delivered based on the shipment direction
          const delivered = isOutgoing ? -deliveredValue : deliveredValue

          const sku = getSku(productId)
          const locationFsidSku = getLocationFsidSku({ locationFsid, sku })
          const yearweek = getYearweek({ year, week })
          const deliveryType = paymentType || 'pay_as_you_sell'

          // update ledger with what has been delivered
          currentOpenings[locationId][productId] = currentOpenings[locationId][productId] || 0
          currentOpenings[locationId][productId] += delivered

          const currentOpening = currentOpenings[locationId][productId]

          const row = {
            ...shipmentFields,

            location_id: locationUuid,
            location_fsid: locationFsid,
            yearweek,

            location_fsid_sku: locationFsidSku,
            sku,

            delivery_type: deliveryType,
            delivered,

            current_opening: currentOpening
          }

          rows.push(row)
        }

        break
      default:
        throw (new Error('Unknown entry type'))
    }
  }

  return rows
}

module.exports = {
  generateReportData
}
