import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ActionsBar from '../ActionsBar'
import Button from '../Button'
import BottomDrawer from '../BottomDrawer'
import DeemphasizedLink from '../DeemphasizedLink'
import OptionsGroup from '../OptionsGroup'
import { Filters } from '../../icons'

const AdvancedFilters = ({
  availableFilters,
  selectedFilters,
  visibleFilters,
  onApplyFilters,
  onClearFilters,
  onChangeFilterValues,
  modalVisible,
  onCloseModal,
  onOpenModal,
  onToggleOption
}) => {
  if (!availableFilters) {
    return null
  }

  const filterKeys = Object.keys(availableFilters)

  return (
    <Fragment>
      <Button
        colorVariant='brand'
        fill='outline'
        onClick={() => onOpenModal()}
        icon={<Filters />}
      >
        Filters
      </Button>
      <BottomDrawer
        isOpen={modalVisible}
        onClose={() => onCloseModal()}
        customHeaderText='Filter'
        withScrollableSection
        withActionBar
      >
        <div>
          {!!filterKeys.length && filterKeys.map(filter => {
            const { label, options } = availableFilters[filter]
            const optionsNotCollapsed = Object.keys(options).length < 3
            return (
              <div
                className='vs-advanced-filters__filter'
                key={label}
              >
                <OptionsGroup.Stateless
                  label={label}
                  options={options}
                  multi
                  inRows
                  value={selectedFilters[filter].selected}
                  onValueChange={(selection) => onChangeFilterValues(filter, selection)}
                  optionsVisible={optionsNotCollapsed || visibleFilters[filter]}
                  onToggleOptionVisibility={() => onToggleOption(filter)}
                />
              </div>
            )
          })}
        </div>
        <ActionsBar
          className='vs-advanced-filters__actions'
          layout='space-between'
          nowrap
        >
          <Button
            colorVariant='brand'
            fill='full'
            size='regular'
            onClick={() => onApplyFilters()}
          >
            Apply filters
          </Button>
          <DeemphasizedLink
            onClick={onClearFilters}
            colorVariant='brand'
          >
            clear
          </DeemphasizedLink>
        </ActionsBar>
      </BottomDrawer>
    </Fragment>
  )
}

AdvancedFilters.propTypes = {
  /**
   * An object used to create the list of filters
   */
  availableFilters: PropTypes.object.isRequired,
  /**
   * An object containing information about filters that have been selected
   */
  selectedFilters: PropTypes.object.isRequired,
  /**
   * Function to determine what should happen when filters are applied
   */
  onApplyFilters: PropTypes.func.isRequired,
  /**
   * Function determining what should happen on clearning filters
   */
  onClearFilters: PropTypes.func.isRequired,
  /**
   * Function determining what should happen when filter values change
   */
  onChangeFilterValues: PropTypes.func.isRequired,
  /**
   * Controls visibility of the filters
   */
  modalVisible: PropTypes.bool
}

export default AdvancedFilters
