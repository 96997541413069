import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { QuantityDisplay } from '@fielded/shared-ui'
import { Chevron } from '@fielded/shared-ui/src/icons'
import { getShipmentBatches } from '@fielded/fs-api/lib/shipment/utils/utils'

import { shipmentTitle } from '../../../../../common/utils'
import { selectProducts } from '../../../../../../../root/reducers/shipments/master-data'
import ConfirmPickUpContentsTableItems from './ConfirmPickUpContentsTableItems'

const ConfirmPickUpContentsTable = ({ shipments }) => {
  const products = useSelector(selectProducts)

  const [selectedShipment, setSelectedShipment] = useState()
  const colCount = 4

  const onSelectShipment = (event, shipment) => {
    event.stopPropagation()
    setSelectedShipment()

    if (selectedShipment && selectedShipment.id === shipment.id) {
      return
    }

    setSelectedShipment(shipment)
  }

  return (
    <div className='confirm-pick-up-contents'>
      <table className='confirm-pick-up-contents__main-table'>
        <thead>
          <tr>
            <th className='confirm-pick-up-contents__icon'>&nbsp;</th>
            <th className='confirm-pick-up-contents__name' colSpan={colCount}>Shipments</th>
            <th className='confirm-pick-up-contents__quantity'>No. of products</th>
          </tr>
        </thead>

        <tbody>
          {shipments.length ? shipments.map(shipment => {
            const facilityName = shipmentTitle(shipment)
            const productCount = Object.keys(shipment.counts).length
            const isExpanded = selectedShipment && selectedShipment.id === shipment.id
            const batches = getShipmentBatches(shipment, products.byId)

            return (
              <tr
                key={shipment.shipmentNo}
                className='confirm-pick-up-contents__main-table-row'
                onClick={(event) => onSelectShipment(event, shipment)}
              >
                <td className='confirm-pick-up-contents__icon'>
                  <Chevron direction={isExpanded ? 'up' : 'down'} />
                </td>
                <td colSpan={colCount} className='confirm-pick-up-contents__name'>{facilityName}</td>
                <td className='confirm-pick-up-contents__quantity'><QuantityDisplay value={productCount} /></td>
                <td className='confirm-pick-up-contents__items'>
                  <ConfirmPickUpContentsTableItems
                    isExpanded={isExpanded}
                    batches={batches}
                  />
                </td>
              </tr>
            )
          }) : (
            <tr>
              <td colSpan={colCount}>
                There are no products in this delivery
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ConfirmPickUpContentsTable
