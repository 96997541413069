module.exports = checkStockShipmentUpdate
const findShipment = require('./shipment/shipment-find')
const isShipmentRelevant = require('./tools/is-shipment-relevant')

/**
* check for new shipments against
* date the stock report was created.
* @param {*} state
* @param {*} params
* @returns true if newShipments {arrival|sent} false otherwise
*/
function checkStockShipmentUpdate (state, params = {}) {
  return findShipment(state, params)
    .then(shipments => {
      return shipments.some((shipment) => {
        return isShipmentRelevant({
          shipment,
          location: params.location,
          endDate: new Date().toJSON(),
          startDate: params.createdAt || new Date().toJSON() // if report is fresh
        })
      })
    })
}
