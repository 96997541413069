const uuid = require('uuid')
const { getInsertProperties } = require('../tools/sql-tools')
const { newInvoicesForSmsQuery } = require('./sql/new-invoices-query')
const invoiceToQboQuery = require('./sql/invoice-to-qbo')
const shipmentToInvoiceQuery = require('./sql/shipment-to-invoice')
const eventsToInvoiceQuery = require('./sql/events-to-invoice')

class PgInvoiceApi {
  constructor (pgConnection) {
    this.pgConnection = pgConnection
  }

  async getNewInvoicesForSms (params) {
    if (!this.pgConnection) {
      throw new Error('Request available only from backend')
    }
    const { dateLimit } = params

    const { rows } = await this.pgConnection.query(newInvoicesForSmsQuery, [dateLimit])
    return {
      results: rows
    }
  }

  async invoiceToQbo (pgInvoiceId) {
    const { rows: [{
      invoice,
      lines
    }] } = await this.pgConnection.query(invoiceToQboQuery, [pgInvoiceId])
    return {
      invoice,
      lines
    }
  }

  async eventsToInvoice ({ locationId, eventDates }) {
    const { rows: [{ invoice, lines }] } = await this.pgConnection.query(
      eventsToInvoiceQuery,
      [locationId, eventDates]
    )
    return { invoice, lines }
  }

  async shipmentToInvoice ({ shipmentId }) {
    const { rows: [{ invoice, lines }] } = await this.pgConnection.query(
      shipmentToInvoiceQuery,
      [shipmentId]
    )
    return { invoice, lines }
  }

  async getQboInvoices ({ shipmentId, orderId, locationId }) {
    let result
    if (shipmentId) {
      result = await this.pgConnection.query(`
        select i.created_at,
               iq.invoice_id,
               iq.invoice_txn_id,
               iq.invoice_deleted,
               iq.creditmemo_txn_id,
               iq.creditmemo_deleted,
               iq.company_code
        from avocado.data_invoice_line il
        left join avocado.data_invoice_quickbooks iq on iq.invoice_id = il.invoice_id
        left join avocado.data_invoice i on i.id = il.invoice_id
        where il.shipment_id = $1
          and i.location_id = $2
          and iq.id is not null
          and iq.invoice_deleted = false
          and iq.invoice_txn_id is not null
        group by 1, 2, 3, 4, 5, 6, 7
        order by 1 desc
      `, [shipmentId, locationId])
    } else if (orderId) {
      result = await this.pgConnection.query(`
        select i.created_at,
               iq.invoice_id,
               iq.invoice_txn_id,
               iq.invoice_deleted,
               iq.creditmemo_txn_id,
               iq.creditmemo_deleted,
               iq.company_code
        from avocado.data_invoice_line il
        left join avocado.data_invoice_quickbooks iq on iq.invoice_id = il.invoice_id
        left join avocado.data_invoice i on i.id = il.invoice_id
        where il.order_id = $1
          and i.location_id = $2
          and iq.id is not null
          and iq.invoice_deleted = false
          and iq.invoice_txn_id is not null
        group by 1, 2, 3, 4, 5, 6, 7
        order by 1 desc
      `, [orderId, locationId])
    } else {
      throw new Error('Shipment-id or order-id required')
    }
    return result.rows.map(r => ({
      pgInvoiceId: r.invoice_id,
      qboInvoiceId: r.invoice_txn_id,
      qboInvoiceDeleted: r.invoice_deleted,
      qboCreditMemoId: r.creditmemo_txn_id,
      qboCreditMemoDeleted: r.creditmemo_deleted,
      qboCompanyCode: r.company_code
    }))
  }

  async insertInvoice ({ invoice, lines }) {
    if (lines.length === 0) {
      return null
    }
    const invoiceId = uuid.v4()

    const {
      columns: invoiceColumns,
      values: invoiceValues,
      placeholders: invoicePlaceholders
    } = getInsertProperties([{
      id: invoiceId,
      location_id: invoice.locationId
    }])

    const {
      columns: lineColumns,
      values: lineValues,
      placeholders: linePlaceholders
    } = getInsertProperties(lines.map(l => ({
      invoice_id: invoiceId,
      event_id: l.eventId || null,
      event_type: l.eventType,
      order_invoicing_type: l.orderInvoicingType,
      sku: l.sku,
      date: l.date,
      quantity: l.quantity,
      bonus: l.bonus || 0,
      order_id: l.orderId,
      shipment_id: l.shipmentId || null,
      reconciled: l.reconciled != null ? l.reconciled : true
    })), null, invoiceValues.length)

    await this.pgConnection.query(`
      with invoice_insert as (
        insert into avocado.data_invoice (${invoiceColumns})
        values ${invoicePlaceholders}
      )
      insert into avocado.data_invoice_line (${lineColumns})
      values ${linePlaceholders}
    `, [...invoiceValues, ...lineValues])

    return invoiceId
  }

  async previousInvoiceOrCreditFailed ({ invoiceId, createdAt, shipmentIds }) {
    const { rowCount } = await this.pgConnection.query(`
      select 1
      from avocado.data_invoice i
      left join avocado.data_invoice_line il on il.invoice_id = i.id
      left join avocado.data_invoice_quickbooks iq on iq.invoice_id = i.id
      where i.id <> $1         -- exclude the one we are processing
        and i.created_at < $2  -- only check invoices older than the current
        and il.event_type = 'delivery'
        and il.shipment_id = any($3)
        and iq.id is not null
        and iq.invoice_txn_id is null
        and iq.creditmemo_txn_id is null
      limit 1
    `, [invoiceId, createdAt, shipmentIds])
    return rowCount > 0
  }

  async getOrCreateQboInvoiceRecord (invoiceId, companyCode) {
    const { rows: [qboInvoiceRow] } = await this.pgConnection.query(`
      with invoice_insert as (
        insert into avocado.data_invoice_quickbooks (invoice_id, company_code)
        values ($1, $2)
        on conflict(invoice_id) do nothing
        returning *
      )
      select
        id,
        invoice_txn_id as "invoiceTxnId",
        invoice_deleted as "invoiceDeleted",
        creditmemo_txn_id as "creditMemoTxnId",
        creditmemo_deleted as "creditMemoDeleted"
      from (
        -- we either want to return the inserted or existing lines here
        select * from invoice_insert
        union
        select * from avocado.data_invoice_quickbooks where invoice_id = $1
      ) i
    `, [invoiceId, companyCode])
    return qboInvoiceRow
  }

  async updateQboRecordInvoiceId (recordId, qboInvoiceId) {
    await this.pgConnection.query(`
      update avocado.data_invoice_quickbooks
      set invoice_txn_id = $2,
          invoice_deleted = false
      where id = $1
  `, [recordId, qboInvoiceId])
  }

  async updateQboRecordCreditId (recordId, qboCreditMemoId) {
    await this.pgConnection.query(`
      update avocado.data_invoice_quickbooks
      set creditmemo_txn_id = $2,
          creditmemo_deleted = false
      where id = $1
    `, [recordId, qboCreditMemoId])
  }

  async getUnpaidQboInvoiceTxns (shipmentIds) {
    const { rows: qboInvoiceTxns } = await this.pgConnection.query(`
      select
        i.created_at as "createdAt",
        t.txn_id as "txnId",
        t.amount,
        t.amount_paid as "amountPaid"
      from avocado.data_invoice_line il
      left join avocado.data_invoice i on i.id = il.invoice_id
      left join avocado.data_invoice_quickbooks iq on iq.invoice_id = il.invoice_id
      inner join avocado.data_quickbookstransactions t on t.txn_id = iq.invoice_txn_id
                                                      and t.company_code = iq.company_code
      where il.shipment_id = any($1)
        and t.amount_paid < t.amount
      group by 1, 2, 3, 4
      order by 1 asc
    `, [shipmentIds])
    return qboInvoiceTxns
  }
}

module.exports = {
  PgInvoiceApi
}
