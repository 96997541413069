import { differenceInCalendarISOWeeks } from 'date-fns'

const CREDIT_MEMO = 'credit memo'
const WRITE_OFF = 'write-off'
const INSTALMENT_PAYMENT = 'installment payment'
const PENDING_INVOICE_PAYMENT = 'pending invoice payment'
const PENDING_INSTALMENT_PAYMENT = 'pending installment payment'

// Please this is the transaction types and their UI transalations
// take care when modifying this
export const TXN_TYPES_HUMANIZED = {
  // debits
  invoice: 'invoice',
  installment: 'Instalment',
  refund: 'withdrawal',

  // credits
  payment: 'payment',
  [CREDIT_MEMO]: 'credit memo',
  loan: 'payment plan',
  [WRITE_OFF]: 'write-off',
  bonus: 'cashback',
  [INSTALMENT_PAYMENT]: 'payment',
  [PENDING_INVOICE_PAYMENT]: 'payment',
  [PENDING_INSTALMENT_PAYMENT]: 'payment'
}

export const pendingTxnTypes = [PENDING_INVOICE_PAYMENT, PENDING_INSTALMENT_PAYMENT]

export const PAYMENT_WEEKS = {
  CURRENT_WEEK: '0',
  NEXT_WEEK: '1',
  OVERDUE: 'overdue'
}

// A type of instalment with just 1 term
export const isFixedTermPayment = (transaction) => {
  if (!transaction || !transaction.payment_plan || transaction.txn_type !== paymentPlanInstalment) {
    return false
  }
  return transaction.payment_plan.term === 1
}

const isFutureFixedTermPayment = (dueDate) => {
  return differenceInCalendarISOWeeks(dueDate, new Date()) > 0
}

const { CURRENT_WEEK, NEXT_WEEK, OVERDUE } = PAYMENT_WEEKS
const [invoice, paymentPlanInstalment] = Object.keys(TXN_TYPES_HUMANIZED)

export const getPayableDetails = (payables) => {
  if (!payables) {
    return
  }

  // if no payable listed for current week we add a default placeholder.
  // This is needed to display fixed term payments
  if (!payables[CURRENT_WEEK]) {
    payables[CURRENT_WEEK] = { week: '0', totaldue: 0, transactions: [] }
  }

  const paymentList = Object.values(payables)

  return paymentList.reduce((data, payment) => {
    // Payment due this week (payment could have multiple transactions ie invoice and instalment)
    if (payment.week === CURRENT_WEEK) {
      data.currentWeek.push(payment)
    }

    // Payment due next week (payment could have multiple transactions ie invoice and instalment)
    if (payment.week === NEXT_WEEK) {
      const nextWeekTransactions = []
      let nextWeekTotalDue = 0

      payment.transactions.forEach(transaction => {
        const { amount_paid: amountPaid, amount } = transaction
        const amountDue = (amount - amountPaid)
        if (!isFixedTermPayment(transaction)) {
          nextWeekTransactions.push(transaction)
          nextWeekTotalDue += amountDue
        }
      })

      data.nextWeek.push({
        ...payment,
        transactions: nextWeekTransactions,
        totaldue: nextWeekTotalDue
      })
    }

    // Future payments. This includes all payments that are NOT part of the current week's (this week) payments
    // Future payments = next week payments and all other subsequent week payments
    // We have an exception though, every fixed-term payment should be visible on the overview page (ie current week).
    if (Number(payment.week) >= Number(NEXT_WEEK)) {
      const futureWeekTransactions = []
      let futureWeekTotalDue = 0

      payment.transactions.forEach(transaction => {
        const {
          due_date: duedate,
          amount_paid: amountPaid,
          amount
        } = transaction

        const amountDue = (amount - amountPaid)

        if (isFixedTermPayment(transaction) && isFutureFixedTermPayment(duedate)) {
          const currentWeekPayments = data.currentWeek[0]
          currentWeekPayments.totaldue += amountDue
          currentWeekPayments.transactions.push(transaction)
        } else {
          futureWeekTransactions.push(transaction)
          futureWeekTotalDue += amountDue
        }
      })

      data.futureWeeks.push({
        ...payment,
        transactions: futureWeekTransactions,
        totaldue: futureWeekTotalDue
      })
    }

    // Overdue payments (it could have multiple transactions ie invoices and instalments)
    if (payment.week === OVERDUE && payment.totaldue > 0) {
      data.overduePayments.push(payment)
    }

    payment.transactions.map(transaction => {
      transaction.amount_due = transaction.amount - transaction.amount_paid

      if (transaction.txn_type === invoice) {
        data.invoices.push(transaction)
      } else {
        data.instalments.push(transaction)
      }
    })
    return data
  }, {
    currentWeek: [],
    nextWeek: [],
    futureWeeks: [],
    overduePayments: [],
    invoices: [],
    instalments: []
  })
}

export const getProcessedPaymentPlans = (paymentPlans) => {
  const processedPaymentPlans = paymentPlans
    .map(paymentPlan => (
      {...paymentPlan,
        instalments: paymentPlan.instalments.map(i => ({
          ...i,
          amount_due: i.amount - i.amount_paid,
          txn_type: paymentPlanInstalment,
          txn_date: paymentPlan.created_at
        }))
      }
    ))
    .reduce((data, plan) => {
      if (plan.is_active) {
        // Note: Using plan.is_paid for filtering is no longer reliable.
        // Some payment plans have is_paid set to true, but their quickbooks_account_balance
        // still shows a positive sum, indicating outstanding amounts.

        if (plan.quickbooks_account_balance > 0) {
          data.open.push(plan)
        }

        if (plan.quickbooks_account_balance <= 0) {
          data.closed.push(plan)
        }

        data.all.push(plan)
      }
      return data
    }, { open: [], closed: [], all: [] })

  return processedPaymentPlans
}

export const sortTransactions = (transactions, sortDueDate = false) => {
  const newTransactionsList = [...transactions]

  if (!newTransactionsList.length) {
    return []
  }

  // txn_date: all transansactions except payment plan instalments
  // txn_created_at: payment plan instalments
  const cleanTransactionDate = (transaction) => {
    let sortDate = sortDueDate ? transaction.due_date : transaction.txn_date || transaction.created_at
    return new Date(sortDate).getTime()
  }

  return newTransactionsList
    .sort((a, b) => (cleanTransactionDate(b) - cleanTransactionDate(a)))
    .map(transaction => ({
      ...transaction,
      txn_date: transaction.txn_date || transaction.created_at
    }))
}
