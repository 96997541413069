import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Loading } from '@fielded/shared-ui'

import {
  HAS_NO_DATA,
  IS_LOADING,
  LOADING_FAILED,
  LOADING_SUCCEEDED
} from '../../../root/reducers/reports/utils'

import withConfig from '../../../van-shared/hoc/withConfig'
import { fetchOverview } from '../../../root/reducers/reports/overview'

class Loader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      locationId: null,
      date: null,
      programId: null
    }
  }
  async load () {
    const {
      fetchOverview,
      locationId,
      date,
      programId,
      config
    } = this.props

    if (this.state.locationId === locationId &&
        this.state.date === date &&
        this.state.programId === programId) {
      return
    }
    this.setState({ locationId, date, programId })
    fetchOverview({
      locationId,
      date,
      programId
    }, config)
  }

  componentDidMount () {
    this.load()
  }

  componentDidUpdate () {
    this.load()
  }

  render () {
    const {
      loadingStatus,
      children
    } = this.props

    switch (loadingStatus) {
      case HAS_NO_DATA:
      case IS_LOADING:
        return <Loading />
      case LOADING_FAILED:
        return <h3>Error, loading has failed</h3>
      case LOADING_SUCCEEDED:
        return children
      default:
        console.error('Unknown loading status: ' + loadingStatus)
    }
  }
}

const mapStateToProps = ({ overview }) => ({
  loadingStatus: overview.status
})

const mapDispatchToProps = {
  fetchOverview
}

const ConnectedLoader = withConfig(connect(mapStateToProps, mapDispatchToProps)(Loader))

// Component decorator function that will ensure the locations are
// fetched before the provided component is rendered.
const withOverview = Comp => (props) => {
  const {
    match: {
      params: {
        locationId,
        date,
        programId
      }
    }
  } = props
  return (
    <ConnectedLoader
      locationId={locationId}
      date={date}
      programId={programId}
    >
      <Comp {...props} />
    </ConnectedLoader>
  )
}

export default withOverview
