import React from 'react'

import { Accordion } from '@fielded/shared-ui'
import { Chevron } from '@fielded/shared-ui/src/icons'

const ConfirmPickUpContentsTableItems = ({ isExpanded, batches }) => {
  return (
    <Accordion>
      <Accordion.Section isExpanded={isExpanded}>
        <Accordion.Section.Content>
          <table className='confirm-pick-up-contents__sub-table'>
            <thead>
              <tr>
                <th className='confirm-pick-up-contents__icon'>&nbsp;</th>
                <th className='confirm-pick-up-contents__name confirm-pick-up-contents__name--sub'>Products</th>
                <th className='confirm-pick-up-contents__quantity confirm-pick-up-contents__quantity--sub'>Quantity</th>
              </tr>
            </thead>

            <tbody>
              {batches.map(({ _id, name, picked }) => (
                <tr key={_id}>
                  <td className='confirm-pick-up-contents__icon'>
                    <Chevron direction={isExpanded ? 'up' : 'down'} />
                  </td>
                  <td className='confirm-pick-up-contents__name'>{name}</td>
                  <td className='confirm-pick-up-contents__quantity'>{picked}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Accordion.Section.Content>
      </Accordion.Section>
    </Accordion>
  )
}

export default ConfirmPickUpContentsTableItems
