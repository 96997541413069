// some of our current locations are on DD, but are using mandates of other locations (same owner),
// the data model doesn't show it but it’s indicated in the comment of the mandate.
// keys of object MANDATE_RELATIONS is main location who is sharing same mandate id with other locations id's in each array
// list is from https://docs.google.com/spreadsheets/d/1iMu9slWpteat6Nyj-hGAOCNzc1-xsQQaRrKQQrz6cs4/edit#gid=0
// todo: TBD if this will used to often maybe we should store this relations in database

const MANDATE_RELATIONS = {
  development: {
    'b7639529-ca5e-4c60-b7df-52be002f1f03': [
      '2042c7a1-3dc0-4836-9f43-7194774be61c'
    ]
  },
  production: {
    'a5623194-d3a3-4601-9557-8300f981c21b': [
      '7aa6459a-ea84-447c-98c9-3154dbc507fa'
    ],
    'c1f0c5b7-14e2-462a-84c3-0fb38a4abe22': [
      'a14d6f46-b650-4686-ac29-8cb01db259d2',
      'bd5fc466-08b9-4017-a3ac-d91daf756430'
    ],
    '15fa2231-54ec-4bdc-9760-38418691e131': [
      '4c07214d-037a-4993-ae88-7d355c208be2'
    ],
    '4743f2c2-2e87-4887-8bdf-98a7444a9cd1': [
      '41519839-fef3-4fb8-913c-d49d7d296f48'
    ],
    'fafb0b3a-de1c-4032-b19c-be8c9ccf76c4': [
      'f3383322-8d88-4a6f-9250-021a5350f0f4'
    ],
    '1420fbfe-81aa-48c2-b82f-8b77575c8a9a': [
      '3a0672e4-b5d6-43f4-9806-1fea934cfa55'
    ],
    'f6587c0b-ee2a-4bee-bbbd-d4d7f577e7ea': [
      '0e25a46f-0a37-4338-9a1a-e3c87cbf0507'
    ],
    '56884762-ba0a-4c1e-82df-80a2ad8358c8': [
      '048c5aaa-f56b-4990-b7a4-b550c7775ab1'
    ],
    'e6723c8e-d5e6-4c7d-a4ee-c6782f85ba9b': [
      '2e4153cb-3078-4bf5-87c4-5980abe783cc'
    ],
    'b0b2ecdd-6495-4e52-968c-9270b8d7d52e': [
      '43f78913-9393-4bf8-a383-2c7aaf4dc590'
    ],
    '006592d7-309e-4bb4-95bd-22785e673578': [
      'd0c9a3d6-106d-4de7-b034-cccdcb625b9d'
    ],
    'dfa6a0ad-22ad-42ac-a32f-b167cae144d7': [
      'cebfe119-3714-4b2c-92f5-411a02be6378'
    ],
    'f65fb87c-4877-4e87-ab29-4ba2d3ee21dc': [
      'f114cf3e-6fcb-477e-88f7-603623d6705a'
    ],
    'fe15cb2d-1ad1-48f6-a1c3-154348e990d1': [
      'e8ac2b64-a1fa-4657-96f5-f5b48e779542'
    ],
    '208e3b8f-b459-4af0-8f77-fb81d1b8a44a': [
      'a22cc2fd-b51a-4174-8352-d80e4c5095f5'
    ],
    '9deddc3d-fe7d-4493-9591-d90ef6e5748d': [
      '62fe014f-73a7-4ec9-aaff-a4386dd2d754'
    ],
    '314bf9d0-e82b-4a89-8601-294045cf1bbb': [
      '0fdbaba7-b878-47f9-8b71-e600b60d69cb',
      '8c37d57b-d175-4f55-b0de-98949a57677a'
    ],
    'a13b3d9c-1e3e-444f-8f22-fc45cf0a4234': [
      '78b29148-ad95-4ab2-8cc8-7da11b135677'
    ],
    'bc1f9ebd-8c5b-48aa-9880-53b635a6deb8': [
      '9249fa2b-1e64-440e-a15b-f1cc6973177e'
    ],
    'b716103f-75f5-489d-a5cf-e1bce75287e6': [
      '1f549531-51dd-4c31-9973-19a5267b7579'
    ],
    '77f1ece8-8877-4aee-a543-512f7e01d9d5': [
      '067406bf-1100-43e4-8708-5ec524f78a0c'
    ],
    'b38ebd69-8180-4bae-830d-529f82517ee9': [
      '2146e0db-7040-4a62-ba42-ea0949d2d581'
    ],
    'd01e324f-82d5-4d05-b084-0bd7d15dabd1': [
      '45badff9-a922-4dd0-940f-0c1419e90131',
      '2d2bb01f-6c95-40d4-8a7b-af21c28d0c1f'
    ],
    '7d4fe28d-e29f-4516-933c-6c2bcf2298b2': [
      '73e8edb2-4e54-4053-b4ea-9b8c584f6222',
      '137c6d79-33f4-49e1-88d8-d47a6bb41b0d',
      '8f1ad16d-e170-4e90-a122-a96fba371ef2'
    ],
    'dc799277-4bf8-419e-a95b-8bdc9c88b7e1': [
      '37e0266b-0522-4b2e-a42c-6dc3d2a4a55a'
    ],
    'fd77b7e0-ab8c-4994-b05a-e1238d8753c0': [
      '083641ef-c559-4348-a851-5037f6853b70'
    ],
    '9a550239-8b04-416c-9b07-5fa936a8358f': [
      '847c5b24-67fb-4b75-bea9-eeab2847bd22'
    ],
    'c7d1c8d8-eaca-4276-8344-d943f62d8b3e': [
      '2146057e-9da7-4248-bdec-ec998d74777e',
      '99723457-eac8-4193-bff9-da5e9ab450aa'
    ],
    'a8630951-180c-424c-8a5a-7178d726470a': [
      '68c79aee-f20e-4588-940a-1d7ae0523bc5',
      '059d967a-8d88-4e4f-8593-50400c366444',
      'a3e52c30-ab91-4111-a50b-c930fa9d3eec'
    ],
    'a6989324-c767-4e07-8d6d-935d21f3f68a': [
      'ef1c5497-d0f1-46bf-b74a-2d00a55d1de0'
    ],
    '7f4f3a59-b079-4b7f-87d5-de5468cba6d8': [
      '443c65c5-b335-4089-a8e4-443ed79ae0d2'
    ],
    'e3fbf988-a35a-4d4d-b79c-5ba687af8a37': [
      '2e08d597-61ca-4a85-84fc-8bb31d5c343d'
    ],
    'b30ec047-b5af-4c17-af95-46ac4a7ca003': [
      'e917cad2-b932-478e-a1d7-e37a12630618'
    ],
    'a19fbbd5-e82f-4f16-85d8-fd0af2b2e557': [
      '2785c4a3-1394-42fc-8023-d4f230e75cd3'
    ]
  }
}

const mandateRelations = (environment) => MANDATE_RELATIONS[environment === 'production' ? 'production' : 'development']

const findParentLocation = (id, environment) => Object.keys(mandateRelations(environment)).find(prop => mandateRelations(environment)[prop].includes(id))

module.exports = {
  findParentLocation
}
