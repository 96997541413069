import { updateShipment } from '../../../root/reducers/shipments'
import get from 'lodash/get'
import { makeAllocations, getAddedProducts } from './utils'

export const createSnapshot = (opts, shipment) => async (dispatch, getState, { api }) => {
  const shipmentId = shipment.id
  const shipmentTypeId = shipment.shipmentType
    ? shipment.shipmentType.id
    : 'routine'

  const {syncWithAllocation} = opts
  delete opts.syncWithAllocation

  const params = {
    ...shipment,
    shipmentTypeId,
    ...opts,
    parentDocId: shipment.snapshotId
  }

  if (syncWithAllocation) {
    const shipments = await api.shipment.find({location: shipmentId}, {checkSnapShotLocation: true})

    // It's ok if we don't find shipments here
    // we only need it if we need to update SL allocations
    const dbShipment = shipments[0]

    // For SL: modification of new shipment while packing to add new products
    // needs to be synced with allocation md, which means this process requires
    // the user to be online
    const status = get(dbShipment, 'status')

    if (status === 'new') {
      const newProducts = getAddedProducts(params)

      if (Object.keys(newProducts).length) {
        const facilityId = dbShipment.destination.id
        const currentAllocation = await api.allocation.getConfiguration({ facilityId })

        await api.allocation.addConfiguration({
          configurations: [{
            facilityId,
            products: Object.assign({}, currentAllocation.products, makeAllocations('directOrder', newProducts)),
            unsubscriptions: Object.assign({}, currentAllocation.unsubscriptions)
          }],
          effectiveDate: currentAllocation.date,
          noAggregate: true
        })
      }
    }
  }

  await api.shipment.createSnapshot(params)
  const updatedShipments = await api.shipment.find(shipmentId)

  // Online/offline case if you suddenly go offline in the middle of it all
  if (!updatedShipments.length) {
    throw new Error(`Shipment ${shipmentId} is not available`)
  }

  dispatch(updateShipment(updatedShipments[0]))
  // return the shipment, to be used by components using `withShipment`
  // that won't get the updated version via redux
  return updatedShipments[0]
}
