import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loading } from '@fielded/shared-ui'
import { PLANNING_TYPES } from '@fielded/fs-api/lib/shipment/tools'

import { makeDeliveryItems } from '../../common/makeDeliveryItems'
import { getLastSnapshotSignature, getLastSnapshotSignedBy } from '../../common/utils'
import { isReturnShipment } from '../../../../common/utils/shipment'

import { withApi } from '../../../../common/ApiProvider'

import SLDeliveryConfirmation from './SLDeliveryConfirmation'
import { updateCurrentMasterData } from '../../../../van-shared/utils/utils'

const LOADING_NOT_STARTED = 'not-started'
const LOADING_IN_PROGRESS = 'in-progress'
const LOADING_COMPLETE = 'complete'

class SLDeliveryConfirmationContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: LOADING_NOT_STARTED,
      items: null
    }
  }

  async componentDidMount () {
    const {loading} = this.state
    if (loading !== LOADING_NOT_STARTED) {
      return
    }
    this.setState({loading: LOADING_IN_PROGRESS})

    const {shipment, productsById, api} = this.props

    let locationId = shipment.destination.id
    if (isReturnShipment(null, shipment)) {
      locationId = shipment.origin.id
    }

    const location = await api.location.get(locationId)

    const {service: serviceId} = api.service.tools.getServiceForLocationId(locationId)
    const service = await api.service.get(serviceId)

    const report = await api.stock.getLedgerBalanceAsReport({
      locationId,
      service,
      calculateFields: true
    })

    const items = makeDeliveryItems({ shipment, report, productsById }) || []
    const totalToPay = items.reduce((acc, item, index) => {
      acc = acc + item.totalToPay
      return acc
    }, 0)

    const signature = getLastSnapshotSignature(shipment)
    const name = getLastSnapshotSignedBy(shipment)

    this.setState({
      loading: LOADING_COMPLETE,
      items,
      signature,
      name,
      totalToPay,
      location
    })
  }

  render () {
    const {
      loading,
      items,
      signature,
      name,
      location
    } = this.state

    const {
      shipment,
      formRef,
      showTotalAmountConfirmation,
      onProceedClick,
      onCloseModal,
      onOpenModal,
      onCommentChange,
      comment,
      config,
      productsById
    } = this.props

    if (loading !== LOADING_COMPLETE) {
      return <Loading loadingText='Loading delivery note …' />
    }

    const shipmentCountry = this.props.shipment.destination.country
    return (
      <SLDeliveryConfirmation
        location={location}
        shipment={shipment}
        productsById={productsById}
        items={items}
        shipmentCountry={shipmentCountry}
        signature={signature}
        config={config}
        formRef={formRef}
        onProceed={onProceedClick}
        onCloseModal={onCloseModal}
        onOpenModal={onOpenModal}
        comment={comment}
        name={name}
        onCommentChange={onCommentChange}
        showTotalAmountConfirmation={showTotalAmountConfirmation}
        isCollectRedistribute={this.props.shipment.planningType === PLANNING_TYPES.C_R}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  return {
    productsById: newState.masterData.products.byId
  }
}

export default withApi(connect(mapStateToProps)(SLDeliveryConfirmationContainer))
