const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const findLast = require('lodash/findLast')
const flatMap = require('lodash/flatMap')
const sortBy = require('lodash/sortBy')
const maxBy = require('lodash/maxBy')

const { getISOWeek } = require('date-fns')

const { dateToWeeklyReportingPeriod } = require('../../tools/date-utils')
const { periodIdToDate } = require('../../report/tools/ids')
const { batchIdToProductId } = require('../../shipment/tools/product-batch-util')

const { isString, isNumber } = require('../../utils/is-type')
const { listAll: listAllProducts } = require('../../product')
const { listChildren: listLocationChildren } = require('../../location')
const findForLocation = require('../../report/api/find-for-location')
const findShipment = require('../../shipment/shipment-find')
const findShipmentAdjustments = require('../../shipment/shipment-find-adjustments')
const {getPriceAt, mergeCounts} = require('./tools')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')
const { SHIPMENT_STATUS } = require('../../shipment/constants')
const { bulkTranslateShipmentProducts } = require('../../shipment/tools/bulk-translate-shipment-products')
const { getRelevantSnapshotDate } = require('../utils/get-relevant-snapshot-date')

const VALUE_NOT_AVAILABLE = 'n/a'

let locale = 'en-NG'

const getNumericValue = (target, zeroValue = 0) => {
  if (target && target.amount) {
    return getNumericValue(target.amount)
  }
  if (target && target.quantity) {
    return getNumericValue(target.quantity)
  }
  if (isNumber(target)) {
    return target
  }
  if (isString(target)) {
    const parsed = parseInt(target, 10)
    if (!Number.isNaN(parsed)) {
      return parsed
    }
  }
  return zeroValue
}

const isPayOnDelivery = (shipment, productId) => {
  const counts = get(shipment, 'counts', {})
  const productCount = getProductFromCount(productId, counts, {})

  return get(productCount, 'paymentType') === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY
}

const getProductFromCount = (productId, counts = {}) => {
  // see: https://github.com/fielded/van-store-api/blob/c31474dbf8d99919eaaa504fa883608248ddef05/lib/tools/virtual-batch.js#L7
  // assuming no same product with different manufacturer
  const productCountId = Object.keys(counts).find(key => key.startsWith(productId + ':'))
  return counts[productCountId]
}

const getShipmentProductCount = (productId, shipment, key = 'counts') => {
  const counts = get(shipment, key, {})
  const productCount = getProductFromCount(productId, counts)

  if (isPayOnDelivery(shipment, productId)) {
    return 0
  }

  return getNumericValue(productCount)
}

const getShipmentsProductCount = (locationId, productId, shipments = [], key = 'counts') => {
  let count = 0
  for (const s of shipments) {
    const shipmentCount = getShipmentProductCount(productId, s, key)
    if (s.origin && s.origin.id === locationId) {
      // When the shipment originates from the location subtract from the count
      count -= shipmentCount
    } else {
      count += shipmentCount
    }
  }
  return count
}

const getMostRecentShipmentProductCount = (productId, shipments = []) => {
  // most recent shipment that contains this product
  const mostRecent = findLast(shipments, shipment => !!getProductFromCount(productId, shipment.counts))
  return getShipmentProductCount(productId, mostRecent)
}

const getShipmentsByStatus = (shipments = [], status = 'new') => {
  return shipments.reduce((shipments, shipment) => {
    const newShipmentId = Object.keys(shipment.history).find(id => id.includes(`status:${status}`))
    if (newShipmentId) {
      shipments.push(shipment.history[newShipmentId])
    }
    return shipments
  }, [])
}

// HACK: Modified on first function call (might remove last 2 fields)
// so we can hide these fields in production while keeping them in staging/dev
// TODO: remove when we enable this in all environments
const allFields = [
  {
    // ${locationId}-${productId}
    name: 'Location SKU',
    fromReport ({productDoc, locationDoc}) {
      const locationId = locationDoc._id
      const productId = productDoc._id
      return locationId && productId
        ? `${locationId.replace(/^country:/, '')}-${productId.replace(/^product:/, '')}`
        : VALUE_NOT_AVAILABLE
    }
  },
  {
    // This should be the name of the delivery cycle, not calendar week
    // that's why the week on the stock count has priority.
    // See #4240 (comments)
    // year.weeknum (YYYY.WW)
    name: 'Week Nr.',
    fromReport ({periodStockCount, latestShipment, deliveryCycle}) {
      let year
      let week
      if (deliveryCycle) {
        year = deliveryCycle.year
        week = deliveryCycle.week
      } else if (periodStockCount) {
        year = get(periodStockCount, 'date.year')
        week = get(periodStockCount, 'date.week')
      } else if (latestShipment) {
        year = new Date(latestShipment.snapshotDates.received).getFullYear()
        week = getISOWeek(latestShipment.snapshotDates.received)
      }

      return year && typeof week !== 'undefined'
        ? `${year}.${week.toString().padStart(2, '0')}`
        : VALUE_NOT_AVAILABLE
    }
  },
  {
    // productId (without product: prefix)
    name: 'SKU',
    fromReport ({productDoc}) {
      return get(productDoc, '_id', VALUE_NOT_AVAILABLE).replace(/^product:/, '')
    }
  },
  {
    // product full name
    name: 'Product Name',
    fromReport ({productDoc}) {
      return productDoc.fullName || VALUE_NOT_AVAILABLE
    }
  },
  {
    // locationId (without country: prefix)
    name: 'Location',
    fromReport ({locationDoc}) {
      return get(locationDoc, '_id', VALUE_NOT_AVAILABLE).replace(/^country:/, '')
    }
  },
  {
    name: 'Location Name',
    fromReport ({locationDoc}) {
      return get(locationDoc, 'name', VALUE_NOT_AVAILABLE)
    }
  },
  {
    // The funder a location was assigned to on the delivery week
    name: 'Driver',
    fromReport ({periodStockCount, latestShipment}) {
      if (periodStockCount) {
        return get(periodStockCount, 'createdBy', VALUE_NOT_AVAILABLE)
      }
      return get(latestShipment, 'updatedBy.user', VALUE_NOT_AVAILABLE)
    }
  },
  {
    // Shipments (which can happen after stock count) can be annotated with shipment date
    name: 'Delivery Date',
    fromReport ({periodStockCount, latestShipment, locationDoc}) {
      if (periodStockCount) {
        return periodStockCount.submittedAt
          ? new Date(periodStockCount.submittedAt).toLocaleDateString(locale)
          : VALUE_NOT_AVAILABLE
      }
      const locationId = locationDoc._id
      const shipmentOrigin = latestShipment.origin.id
      const isOutgoing = shipmentOrigin === locationId
      const version = latestShipment.version

      // If the shipment is an outgoing (from pharmacy) use the sent snapshot date.
      let date = isOutgoing
        ? (latestShipment.snapshotDates.packed || latestShipment.snapshotDates.sent || latestShipment.snapshotDates.received)
        : latestShipment.snapshotDates.received

      if (version !== '1.0.0') {
        date = latestShipment.snapshotDates.received
      }

      return date
        ? new Date(date).toLocaleDateString(locale)
        : VALUE_NOT_AVAILABLE
    }
  },
  {
    // Stock on hand of the stock report (driver count)
    name: 'Closing',
    fromReport ({periodStockCount, productDoc}) {
      return getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-physical-count`, 0)
      )
    }
  },
  {
    // opening balance from stock report. This is the balance the driver last left
    // at the location on previous visit - effectively, the ledger balance immediately
    // prior to a new stock report (saved in the opening balance field)
    name: 'Opening balance',
    fromReport ({periodStockCount, productDoc}) {
      return getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-opening-balance`, 0)
      )
    }
  },
  {
    // Sold from stock report (will be calculated as opening balance - stock on
    // hand, and saved in the sold field)
    name: 'Quantity sold',
    fromReport ({periodStockCount, productDoc, locationDoc}) {
      const sold = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-consumed`, 0)
      )

      // when tracking partner balances
      // sold can not be negative
      // we consider parter sock "negative consumption", i.e. a negative sale
      // in the regular crediting model.
      // Partner balances see opening < count meaning
      // there was no sale and the difference is tracked as partner stock
      if (locationDoc.tracksPartnerBalances) {
        return Math.max(sold, 0)
      }

      // if not tracking, sold can be negative to signify crediting
      return sold
    }
  },
  {
    // Total of shipments received quantities for this product and location,
    // in the current reporting cycle of shipments after the last stock count.
    name: 'Delivery',
    fromReport ({locationDoc, shipmentsAfter, productDoc}) {
      return getShipmentsProductCount(locationDoc._id, productDoc._id, shipmentsAfter)
    }
  },
  {
    // The ledger balance as of the last delivery between this delivery date and
    // the next delivery date (current row's stock report + all stock received)
    name: 'New Opening',
    fromReport ({locationDoc, periodStockCount, shipmentsAfter, productDoc, allReportsForCycle}) {
      const current = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-physical-count`, 0)
      )

      const maxCountDate = maxBy(allReportsForCycle.map(r => r.submittedAt))
      const ledgerShipments = shipmentsAfter.filter(s => s.snapshotDates.received > maxCountDate)
      const received = getShipmentsProductCount(locationDoc._id, productDoc._id, ledgerShipments)

      return current + received
    }
  },
  {
    // Price of the product at the location as of the delivery date
    name: 'Sell Price',
    fromReport ({periodStockCount, latestShipment, productDoc}) {
      const deliveryDate = latestShipment
        ? dateToWeeklyReportingPeriod('weekly', latestShipment.date)
        : get(periodStockCount, 'date', {}).reportingPeriod
      return getPriceAt(productDoc, deliveryDate) || 0
    }
  },
  {
    // Qty Sold * Current product price
    name: 'Sales$',
    fromReport ({periodStockCount, productDoc}) {
      const soldQty = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-consumed`, 0)
      )

      // https://github.com/fielded/van-orga/issues/4015#issuecomment-609688502
      // This has been clarified in slack to mean 'the price when the sale was recorded'
      const price = getPriceAt(productDoc, get(periodStockCount, 'date', {}).reportingPeriod) || 0
      // prevent -0 being returned on a zero price and negative quantity
      return Math.max(0, soldQty * price)
    }
  },
  {
    // Remarks from stock count
    name: 'Notes',
    fromReport ({periodStockCount, productDoc}) {
      return get(periodStockCount, `stock.${productDoc._id}.fields.field:standard-remark.amount`, '')
    }
  },
  {
    // Qty Received * Product price for that week
    name: 'Delivered$',
    fromReport ({locationDoc, periodStockCount, shipmentsAfter, productDoc}) {
      const receivedQty = getShipmentsProductCount(locationDoc._id, productDoc._id, shipmentsAfter)
      const price = getPriceAt(productDoc, get(periodStockCount, 'date', {}).reportingPeriod) || 0
      return receivedQty * price
    }
  },
  {
    // show shipment comment only on the first product in a location
    name: 'Delivery comment',
    fromReport ({latestShipment, productIndex}) {
      const comments = get(latestShipment || {}, 'comments', [])
      const comment = comments.find(c => c.comment && c.id.includes('status:received')) || {}
      return productIndex === 0 ? (comment.comment || VALUE_NOT_AVAILABLE) : ''
    }
  },
  {
    // show the most recent planned value for this product,
    // in the case of top-ups don't aggregate just use the planned count
    name: 'Planned',
    fromReport ({shipmentsAfter, productDoc}) {
      const shipments = getShipmentsByStatus(shipmentsAfter)
      return getMostRecentShipmentProductCount(productDoc._id, shipments)
    }
  },
  {
    // show the most recent packed value for this product,
    // in the case of top-ups don't aggregate just use the packed count
    name: 'Packed',
    fromReport ({shipmentsAfter, productDoc}) {
      const shipments = getShipmentsByStatus(shipmentsAfter, 'sent')
      return getMostRecentShipmentProductCount(productDoc._id, shipments)
    }
  },
  {
    name: 'Previous SL Balance',
    fromReport ({periodStockCount, shipmentsAfter, productDoc}) {
      // calculated field on report
      return getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:opening-shelflife-balance`),
        0
      )
    }
  },
  {
    name: 'SL Balance',
    fromReport ({locationDoc, periodStockCount, shipmentsAfter, productDoc, allReportsForCycle}) {
      const current = getNumericValue(get(periodStockCount, `stock.${productDoc._id}.fields.field:shelflife-balance`), 0)

      // This is actually ledger balance at the end of week
      // So it's different from the value on the report
      /* here! */
      const maxCountDate = maxBy(allReportsForCycle.map(r => r.submittedAt))
      const ledgerShipments = shipmentsAfter.filter(s => s.snapshotDates.received > maxCountDate)
      const received = getShipmentsProductCount(locationDoc._id, productDoc._id, ledgerShipments)
      return current + received
    }
  },
  {
    name: 'SL Sold',
    fromReport ({periodStockCount, locationDoc, shipmentsAfter, productDoc}) {
      // calculated field on report
      let value = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:shelflife-sold`),
        0
      )
      const salesAdjustments = getNumericValue(get(periodStockCount, `stock.${productDoc._id}.fields.field:sales-adjustments`), 0)
      if (salesAdjustments !== 0) {
        value = value + Math.max(salesAdjustments, -value)
      }

      if (locationDoc.tracksPartnerBalances) {
        return Math.max(value, 0)
      }

      return value
    }
  },
  {
    name: 'Previous Partner Balance',
    fromReport ({periodStockCount, productDoc}) {
      return getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:opening-partner-balance`),
        0
      )
    }
  },
  {
    name: 'Partner Balance',
    fromReport ({periodStockCount, productDoc}) {
      return getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:partner-balance`),
        0
      )
    }
  },
  {
    name: 'Partner Sold',
    fromReport ({periodStockCount, productDoc}) {
      const value = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:partner-sold`),
        0
      )
      return Math.max(value, 0)
    }
  },
  {
    name: 'Invoice Adjustments',
    fromReport ({ locationDoc, invoiceAdjustments, startdate, enddate, productDoc, periodStockCount }) {
      // Don't include adjustments for on-demand shipments
      // (see https://www.notion.so/fielded/RFC-Stock-Summary-Table-Refactor-c2867938c2574e099b98e8a075f864c0)
      // they should not show up in sales
      invoiceAdjustments = invoiceAdjustments.filter(adjustment => !isPayOnDelivery(adjustment.shipment, productDoc._id))

      const shipmentAdjustments = getShipmentsProductCount(locationDoc._id, productDoc._id, invoiceAdjustments, 'changes')
      // Read buy-outs from stock count:
      const buyOuts = getNumericValue(get(periodStockCount, `stock.${productDoc._id}.fields.field:partner-buyout`), 0)

      /* Fix for manual edits: we cant add more sales adjustments than there are sales,
       * the rest will have to be credited if we somehow added more
       */
      const sales = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:shelflife-sold`),
        0
      )
      let salesAdjustments = getNumericValue(get(periodStockCount, `stock.${productDoc._id}.fields.field:sales-adjustments`), 0)
      if (salesAdjustments !== 0) {
        salesAdjustments = Math.max(salesAdjustments, -sales)
      }

      // Sign of buy-outs in inversed:
      // in the buy out field, positive value means transfering from partner to shelflife
      // so in that case it would be a negative adjustment
      return shipmentAdjustments - (buyOuts + salesAdjustments)
    }
  },
  {
    name: 'Forecast Adjustments',
    fromReport ({ locationDoc, shipmentsAfter, productDoc, enddate }) {
      const changes = flatMap(shipmentsAfter, s => s.changes)
        // Ignore adjustments where the adjustment was created within this cycle:
        // This is because we assume those to be applied to the opening value of next cycle already:
        // https://docs.google.com/document/d/1sFCu7P-bAiHqg2ZcdwendyQMQI01Xi77QxzT83sFm9c/edit#heading=h.aqwf6bu6ni77
        .filter(change => change.createdAt >= enddate)
      return getShipmentsProductCount(locationDoc._id, productDoc._id, changes, 'changes')
    }
  },
  {
    name: 'Invoice Adjustment Date',
    fromReport ({ startdate, enddate, allReportsForCycle, adjustmentCounts, invoiceAdjustments, adjustedCycles, productDoc }) {
      // 1. Check all reports from current period, does any of them contain a buy-out?
      const buyOutDates = allReportsForCycle.filter(report => {
        return getNumericValue(get(report, `stock.${productDoc._id}.fields.field:partner-buyout`)) !== 0
      }).map(report => {
        return report.submittedAt
      })

      // 2. Check Shipment Adjustments from previous periods that change this product
      const adjustmentDates = invoiceAdjustments.filter(change => {
        return getShipmentProductCount(productDoc._id, change, 'changes') !== 0
      })
        .map(change => change.effectiveAt)

      // 3. find the max of the date
      let adjustmentDate = maxBy(buyOutDates.concat(adjustmentDates))

      if (!adjustmentDate) {
        return ''
      }

      // 4. if max is not within this cycle, we need to figure out the invoice date
      // (this happens for delivery adjustments)
      // invoice date would be next full count after the adjustments
      if (adjustmentDate < startdate) {
        const invoiceCount = adjustmentCounts.find(report => {
          return !report.partialCount && report.submittedAt > adjustmentDate
        })

        if (invoiceCount) {
          adjustmentDate = invoiceCount.submittedAt
        } else {
          // This means the next full count was the one starting this reporting cycle
          adjustmentDate = startdate
        }
      }

      return new Date(adjustmentDate).toLocaleDateString(locale)
    }
  },
  {
    name: 'Sales Adjustments',
    fromReport ({ periodStockCount, productDoc }) {
      /* Fix for manual edits: we cant add more sales adjustments than there are sales,
       * the rest will have to be credited if we somehow added more
       */
      const sales = getNumericValue(
        get(periodStockCount, `stock.${productDoc._id}.fields.field:shelflife-sold`),
        0
      )
      let salesAdjustments = getNumericValue(get(periodStockCount, `stock.${productDoc._id}.fields.field:sales-adjustments`), 0)
      if (salesAdjustments !== 0) {
        salesAdjustments = Math.max(salesAdjustments, -sales)
      }

      return salesAdjustments
    }
  }
]
let fields = allFields

const headers = () => fields.map(f => f.name)

const fromReport = (reportRow) => {
  const {products, location, periodStockCount} = reportRow
  return Object.keys(periodStockCount.stock).map((productId, productIndex) => {
    return fields.map(f => (
      f.fromReport({
        ...reportRow,
        periodStockCount,
        productDoc: get(products, productId, {}),
        locationDoc: location,
        productIndex
      })
    ))
  })
}

const fromShipment = (shipmentRow) => {
  const {products, location, periodStockCount} = shipmentRow
  return Object.keys(products).map((productId, productIndex) => {
    return fields.map(f => (
      f.fromReport({
        ...shipmentRow,
        products: null,
        periodStockCount: null,
        location: null,
        deliveryCycle: periodStockCount && periodStockCount.date,
        productDoc: get(products, `${productId}.doc`, {}),
        locationDoc: location,
        productIndex
      })
    ))
  })
}

const reportFields = {allFields, headers, fromReport, fromShipment}
// NOTE: remove when removing 'adjustments is optional'
Object.defineProperty(reportFields, 'fields', {
  get () {
    return fields
  },

  set (value) {
    fields = value
  }
})

// this returns a similar shape as stock report
// it doesn't include the `current` stock report
// it looks like this {products: {productId: {id:.., doc:.., location:.., amount:..}}, location:..}
const createShipmentProducts = (stockCount, shipmentProducts, productsMap) => {
  const productsList = shipmentProducts
    // find products not already in the report doc
    .filter(product => !stockCount.stock[product.id])
    .map(shipmentProduct => ({
      id: shipmentProduct.id,
      doc: productsMap[shipmentProduct.id],
      amount: shipmentProduct.quantity
    }))

  const products = keyBy(productsList, 'id')
  return { products }
}

const generateReportData = async (
  state,
  locationId,
  serviceId,
  period,
  options = {}
) => {
  locale = options.locale || 'en-NG'
  const includeChildren = options.includeChildren != null ? options.includeChildren : true
  const currentPeriod = period
  const startDate = periodIdToDate(period)

  if (!options.adjustments) {
    fields = allFields.filter(field => !(/adjustment/i.test(field.name)))
  } else {
    fields = allFields
  }

  // This is the date used for product prices and location configurations
  const entityDate = new Date(startDate).toJSON()

  let locations = options.locations
  if (!locations) {
    locations = await listLocationChildren(
      state,
      locationId,
      {
        includeSelf: true,
        date: entityDate,
        filters: { services: [serviceId] }
      }
    )
  }
  const locationsMap = keyBy(locations, '_id')
  const location = locationsMap[locationId]

  const params = {
    location,
    locationId,
    service: options.service,
    serviceId,
    startDate: startDate.toJSON(),
    includeChildren,
    entityOptions: {
      addFields: true
    }
  }
  let stockCounts = await findForLocation(state, params)
  stockCounts = sortBy(stockCounts, ['submittedAt'])

  if (stockCounts) {
    let podCounts = []
    stockCounts.forEach(count => {
      Object.keys(count.stock).forEach(productId => {
        const stockCountProduct = count.stock[productId]
        const remark = get(stockCountProduct, `fields.field:standard-remark.amount`, '')
        if (
          remark.startsWith('pod_delivery') ||
          remark.startsWith('pod_buyout') ||
          remark.startsWith('purchase_delivery') ||
          remark.startsWith('immediate_purchase_delivery') ||
          remark.startsWith('purchase_buyout')) {
          podCounts.push(count._id)
        }
      })
    })
    stockCounts = stockCounts.filter(count => !podCounts.includes(count._id))
  }

  let products = options.products
  if (!products) {
    products = await listAllProducts(state, { date: entityDate })
  }
  const productsMap = keyBy(products, '_id')

  let indexedStockCounts = stockCounts.reduce((acc, stockCount) => {
    const locationId = stockCount.location.id
    acc[locationId] = acc[locationId] || {}

    // Note: use reporting period here, not submittedAt
    // since we added adjustments, submittedAt can be after end of reporting period
    // but it should still belong to a certain week.
    if (stockCount.date.reportingPeriod === currentPeriod) {
      // With V2, we might have more than one stock count
      acc[locationId].current = acc[locationId].current || []
      acc[locationId].current.push(stockCount)

      if (!acc[locationId].startdate) { // use the earliest startdate for the cycle
        acc[locationId].startdate = stockCount.createdAt
      }
    } else if (stockCount.date.reportingPeriod > currentPeriod) {
      // This finds the first report in a period after the exported one
      // this marks the end of the reporting cycle
      if (!acc[locationId].enddate || stockCount.submittedAt < acc[locationId].enddate) {
        acc[locationId].enddate = stockCount.createdAt
      }
    }

    return acc
  }, {})

  const reportRows = {}

  // Now we need to merge all stock counts from 'current week' into one per location
  Object.keys(indexedStockCounts).forEach(locationId => {
    const currentCounts = indexedStockCounts[locationId].current

    // Only process locations that have a reporting cycle starting in this week
    // (cycle = distance between 2 stock counts)
    if (!currentCounts || !currentCounts.length) {
      return
    }

    reportRows[locationId] = {
      // We need to keep all counts from current period available to determine buy-out date #1106
      allReportsForCycle: currentCounts,
      periodStockCount: mergeCounts(currentCounts),
      location: locationsMap[locationId],
      products: productsMap,
      enddate: indexedStockCounts[locationId].enddate,
      startdate: indexedStockCounts[locationId].startdate
    }
  })

  const locationIdsForExport = Object.keys(reportRows)

  const locationShipments = await Promise.all(
    locationIdsForExport
      .map(async (locationId) => {
        const {startdate, enddate = null} = reportRows[locationId]

        // We are looking for shipments made after the period stock report,
        // but the api will return all shipments that were modified within that cycle
        // we need filter the shipments by date manually afterwards.
        let shipments = await findShipment(state, {
          location: locationId,
          startdate,
          enddate
        }, {checkSnapShotLocation: true})

        shipments = shipments.filter(s => {
          if (s.version === '1.0.0') {
            const isOrigin = s.origin.id === locationId
            const isDestination = s.destination.id === locationId

            // Get the relevant snapshot date
            const relevantSnapshotDate = getRelevantSnapshotDate(isOrigin, s)

            // If it's an automatically created return shipment and it's the origin of the shipment we ignore
            if (isOrigin && s.isAutomaticReturnShipment) return false

            // If the location is the shipment origin, only take packed snapshot into consideration or the sent snapshot if the packed snapshot does not exist
            // If the location is the shipment destination, only take received snapshot into consideration
            return ((isDestination && s.status === SHIPMENT_STATUS.RECEIVED) || (isOrigin && (s.status === SHIPMENT_STATUS.SENT || s.status === SHIPMENT_STATUS.PACKED))) &&
              (relevantSnapshotDate > startdate) &&
              (!enddate || relevantSnapshotDate < enddate)
          } else if (s.version === '2.0.0') {
            const relevantSnapshotDate = s.snapshotDates.received

            return ((s.status === SHIPMENT_STATUS.RECEIVED)) &&
              (relevantSnapshotDate > startdate) &&
              (!enddate || relevantSnapshotDate < enddate)
          }
        }).map(s => {
          const { translatedCounts } = bulkTranslateShipmentProducts(state, {
            shipment: s,
            location,
            products: Object.values(products),
            serviceId
          })
          s.counts = translatedCounts
          return s
        })

        const latestShipment = shipments[shipments.length - 1]

        // this call is to find adjustments that were created in this
        // period. This is used for invoicing.
        // To read adjustments that were effective in this period,
        // which is needed for forecasting, we use the 'changes' array
        // on the shipments themselves
        let invoiceAdjustments = await findShipmentAdjustments(state, {
          location: { id: locationId },
          startdate,
          enddate
        })

        // Ignore adjustments where the adjustment was created within this cycle:
        // This is because we assume those to be applied to the opening value of next cycle already:
        // (therefore they are assumed to be included in the invoicing already)
        // https://docs.google.com/document/d/1sFCu7P-bAiHqg2ZcdwendyQMQI01Xi77QxzT83sFm9c/edit#heading=h.aqwf6bu6ni77
        invoiceAdjustments = invoiceAdjustments.filter(change => {
          return change.effectiveAt <= startdate && (!enddate || change.createdAt <= enddate)
        })

        // We need to pick up the shipments that were adjusted so we can check the delivery type
        invoiceAdjustments = await Promise.all(invoiceAdjustments.map(async adjustment => {
          const shipments = await findShipment(state, adjustment.id.split(':status:')[0])
          return {
            ...adjustment,
            // will only be one when we search by id,
            // but the api returns an array
            shipment: shipments[0]
          }
        }))

        let adjustedCycles = []
        const earliestAdjustment = sortBy(invoiceAdjustments, ['effectiveAt'])[0]

        let adjustmentCounts = []
        if (earliestAdjustment) {
          // Find all reports between the earliest adjustment
          // and the current cycle, we'll need this in invoice-adjustments later
          // fielded/fiels-supply#1106
          adjustmentCounts = await findForLocation(state, {
            location: locationsMap[locationId],
            locationId,
            service: options.service,
            serviceId,
            startDate: earliestAdjustment.effectiveAt,
            endDate: startdate, // we already have all reports after this date
            includeChildren: false,
            entityOptions: { raw: true }
          })

          adjustmentCounts = sortBy(adjustmentCounts, ['submittedAt'])
        }

        return ({latestShipment, shipmentsAfter: shipments, locationId, invoiceAdjustments, adjustedCycles, adjustmentCounts})
      })
  )

  let shipmentRows = {}
  for (const {latestShipment, shipmentsAfter, locationId, invoiceAdjustments, adjustmentCounts} of locationShipments) {
    reportRows[locationId] = Object.assign(reportRows[locationId], {
      latestShipment,
      shipmentsAfter,
      invoiceAdjustments,
      adjustmentCounts
    })

    if (!latestShipment) {
      continue
    }

    const shipmentCounts = {}
    // 1. Sum all shipments into one object
    shipmentsAfter.forEach(shipment => {
      Object.keys(shipment.counts).forEach(batchId => {
        const productId = batchIdToProductId(batchId)
        shipmentCounts[productId] = shipmentCounts[productId] || 0
        shipmentCounts[productId] += shipment.counts[batchId].quantity
      })
    })

    // 2. Move to a separate array
    const shipmentProducts = Object.keys(shipmentCounts).map(productId => {
      return {
        id: productId,
        quantity: shipmentCounts[productId]
      }
    })

    const shipmentRowsForLocation = createShipmentProducts(
      reportRows[locationId].periodStockCount,
      shipmentProducts,
      productsMap
    )

    shipmentRows[locationId] = Object.assign(
      {},
      reportRows[locationId],
      shipmentRowsForLocation
    )
  }

  const rows = flatMap(Object.values(reportRows), reportFields.fromReport)
  // these are for products that were not in the stock count,
  // but are present in the shipment
  const shipmentProductRows = flatMap(Object.values(shipmentRows), reportFields.fromShipment)

  return [reportFields.headers(), ...rows, ...shipmentProductRows]
}

module.exports = {generateReportData, reportFields}
