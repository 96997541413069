import * as batchTools from '@fielded/fs-api/lib/batch/tools'

export const isUntracked = product => product && (product.storageType === 'dry' || product.storageType === '')

const isKnown = batchId => !batchTools.isUnknownBatch({ id: batchId })

export const isNotTBD = batchId => batchId.indexOf('manufacturer:tbd:batchNo:TBD') === -1

// Batches that can be checked are:
// - not "unknown" batches with zero quantity
// - not To Be Determined ("tbd") batches
// Unknown batches are used for (A) untracked batches (product.storageType === 'dry') and
// (B) as a hack in external arrivals for adding products, since the store API only allows adding batches.
// Tbd batches are used in planning a distribution when there is no available stock.
export const batchCanBeChecked = (counts, batchId) => ((isKnown(batchId) || counts[batchId].quantity) && isNotTBD(batchId))
