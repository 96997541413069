import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { FiChevronsLeft } from '@react-icons/all-files/fi/FiChevronsLeft'
import { FiChevronsRight } from '@react-icons/all-files/fi/FiChevronsRight'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'

const Pagination = ({
  onChange,
  pageLimit,
  totalItems,
  currentOffset
}) => {
  const onPageChange = (changeType) => {
    let newOffset = 0
    if (changeType === 'next') {
      newOffset = currentOffset + pageLimit
    }

    if (changeType === 'prev') {
      const reducedOffset = currentOffset - pageLimit
      newOffset = reducedOffset > 0 ? reducedOffset : 0
    }

    if (changeType === 'fist') {
      newOffset = 0
    }

    if (changeType === 'last') {
      const leftoverOffset = totalItems % pageLimit
      newOffset = totalItems - leftoverOffset
    }

    return onChange(newOffset)
  }

  const totalPagesNumber = totalItems !== 0 ? Math.ceil(totalItems / pageLimit) : 1
  const currentPage = Math.min(Math.floor(currentOffset / pageLimit) + 1, totalPagesNumber)

  return (
    <nav className='vs-pagination'>
      <Button
        className='vs-pagination__button'
        fill='outline'
        colorVariant='brand'
        icon={<FiChevronsLeft />}
        onClick={() => onPageChange('first')}
        disabled={currentOffset === 0}
      >
        first
      </Button>
      <Button
        className='vs-pagination__button'
        fill='outline'
        colorVariant='brand'
        icon={<FiChevronLeft />}
        onClick={() => onPageChange('prev')}
        disabled={currentOffset === 0}
      >
        previous
      </Button>
      <span>{currentPage} of {totalPagesNumber}</span>
      <Button
        className='vs-pagination__button'
        fill='outline'
        colorVariant='brand'
        icon={<FiChevronRight />}
        onClick={() => onPageChange('next')}
        disabled={(totalItems - currentOffset) <= pageLimit}
      >
        next
      </Button>
      <Button
        className='vs-pagination__button'
        fill='outline'
        colorVariant='brand'
        icon={<FiChevronsRight />}
        onClick={() => onPageChange('last')}
        disabled={(totalItems - currentOffset) <= pageLimit}
      >
        last
      </Button>
    </nav>
  )
}

Pagination.propTypes = {
  onChange: PropTypes.func,
  pageLimit: PropTypes.number,
  totalItems: PropTypes.number,
  currentOffset: PropTypes.number
}

Pagination.defaultProps = {
  onChange: () => {},
  pageLimit: 10,
  totalItems: 0,
  currentOffset: 0
}

export default Pagination
