const { funderDocsToEntities } = require('./../../../tools/utils')

exports.list = async function (state, { filterForUser = false } = {}) {
  let funders = await state.dal.funders.listAll(state)
  if (filterForUser) {
    const { user } = state
    // Defaults to returning all funders if the user has no funders.
    if (user.funders && user.funders.length !== 0 && funders.length !== user.funders.length) {
      funders = funders.filter(funder => user.funders.includes(funder._id))
    }
  }
  return funderDocsToEntities(funders)
}
