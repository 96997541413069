import orderBy from 'lodash/orderBy'

import { isShipmentComplete } from '../../../subapps/shipments/common/utils'
import { hasBatchesOrQuantitiesForAllProducts } from './pick-list'

export const byComplete = (shipments, user) => Object.keys(shipments)
  .reduce((shipmentsByCompletion, snapshotId) => {
    const shipment = shipments[snapshotId]

    if (isShipmentComplete(shipment, user)) {
      shipmentsByCompletion.complete.push(shipment)
    } else {
      shipmentsByCompletion.incomplete.push(shipment)
    }

    return shipmentsByCompletion
  }, {complete: [], incomplete: []})

export const withRelevantBatchesFlag = shipments => Object.keys(shipments)
  .reduce((index, snapshotId) => {
    const shipment = shipments[snapshotId]
    index[snapshotId] = {
      ...shipment,
      hasBatchesOrQuantitiesForAllProducts: hasBatchesOrQuantitiesForAllProducts(shipment.counts)
    }
    return index
  }, {})

const byCompleteSorted = shipmentsByCompletion => ({
  complete: orderBy(shipmentsByCompletion.complete, ['date', 'createdAt'], ['desc', 'desc']),
  incomplete: orderBy(shipmentsByCompletion.incomplete, ['date', 'createdAt'], ['desc', 'desc'])
})

export const byCompleteAndDate = (shipments, user) =>
  byCompleteSorted(byComplete(shipments, user))
