import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'
import { batchCanBeChecked } from '../batches'

// The fake "unknown" batches used by external arrivals need to be removed before
// creating the next snapshot
export const preAdviceRelevantBatches = counts => {
  return Object.keys(counts).reduce((relevant, batchId) => {
    if (batchCanBeChecked(counts, batchId)) {
      relevant[batchId] = counts[batchId]
    }
    return relevant
  }, {})
}

const countsToProductIdArray = counts => {
  const nonUniqueProductIds = Object.keys(counts).map(batchIdToProductId)
  const uniqueProductIds = Array.from(new Set(nonUniqueProductIds))
  return uniqueProductIds
}

// Check if batches (for batch tracking products) or quantities (for non
// batch tracking) products are available for all products.
// Particularly useful for pre-advice shipments where an initial "fake"
// unknown batch is added to the shipment (because the API doesn't allow
// to add products, only batches)
export const hasBatchesOrQuantitiesForAllProducts = counts => {
  const allProductIds = countsToProductIdArray(counts)
  const allProductIdsWithRelevantBatches = countsToProductIdArray(preAdviceRelevantBatches(counts))
  return allProductIds.length === allProductIdsWithRelevantBatches.length
}

// Check if batches (for batch tracking products) or a quantity (for non
// batch tracking) have been provided for the product
export const hasBatchesOrQuantityForProduct = (counts, productId) => {
  const allProductIdsWithRelevantBatches = countsToProductIdArray(preAdviceRelevantBatches(counts))
  return allProductIdsWithRelevantBatches.indexOf(productId) > -1
}
