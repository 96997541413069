const { routeDocsToEntities } = require('../../../tools/utils')
const { isAfter, isBefore } = require('date-fns')

exports.list = async function (state, { filterForUser = false, filterExpired = false, dateRange } = {}) {
  let routes = await state.dal.routes.listAll(state)
  // Currently being used by PSM to only display routes within the start and end date specified
  if (filterExpired) {
    routes = routes.filter(route => {
      const currentDate = new Date().toJSON().split('T')[0]
      return route.endDate && route.endDate >= currentDate && route.startDate && route.startDate <= currentDate
    })
  }
  if (filterForUser) {
    const { user } = state
    if (user.routes && user.routes.length !== 0 && routes.length !== user.routes.length) {
      routes = routes.filter(route => user.routes.includes(route._id))
    }
  }
  if (dateRange) {
    const {from, to} = dateRange
    routes = routes.filter(
      (route) =>
        (!from || isAfter(route.startDate, from)) &&
        (!to || isBefore(route.startDate, to))
    )
  }
  return routeDocsToEntities(routes)
}
