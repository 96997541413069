import keyBy from 'lodash/keyBy'
import { addDays } from 'date-fns'

import { getLocationRank } from '@fielded/fs-api/lib/shipment/tools'
import { parse } from '@fielded/fs-api/lib/tools/smart-id'

import { isUntracked } from '../../../root/reducers/shipments/batches'
import { formatLocation } from '../common/utils'
import { formatDate, toTitleCase } from '../../../van-shared/utils'
import { translateProductsToRetailerMarket } from '../../../common/translate-products-to-retailer-market'

const sortedProducts = [
  'product:bcg',
  'product:diluent-bcg',
  'product:opv',
  'product:penta',
  'product:mv',
  'product:diluent-mv',
  'product:yf',
  'product:diluent-yf',
  'product:hep-b',
  'product:td',
  'product:pcv',
  'product:ipv',
  'product:ad-syg',
  'product:bcg-syg',
  'product:5-reconst-syg',
  'product:2-reconst-syg',
  'product:safety-boxes'
]

const getDecoratedProduct = (state, productId) => {
  const masterDataProduct = state.masterData.products.byId[productId] || {}
  return {
    ...masterDataProduct,
    availableStock: 0,
    totalSent: 0,
    totalReceived: 0,
    difference: 0,
    batches: []
  }
}

const getBatchesByProduct = (state, shipment, batches) => {
  return Object.keys(batches).reduce((byProductsMemo, batchId) => {
    const batch = batches[batchId]
    const productId = batch.productId
    byProductsMemo[productId] = byProductsMemo[productId] || getDecoratedProduct(state, productId)
    byProductsMemo[productId].batches.push(batch)
    if (batch.sent) {
      byProductsMemo[productId].totalSent += batch.sent
      byProductsMemo[productId].difference -= batch.sent
    }
    if (batch.received) {
      byProductsMemo[productId].totalReceived += batch.received
      byProductsMemo[productId].difference += batch.received
    }
    return byProductsMemo
  }, {})
}

const getProductRow = (product) => {
  const tracksBatches = !isUntracked(product)
  return {
    tracksBatches,
    product,
    isProductRow: true,
    batch: {}
  }
}

const getBatchRow = batch => {
  return {
    batch,
    isProductRow: false,
    product: {}
  }
}

const byProduct = (a, b) =>
  sortedProducts.indexOf(a) - sortedProducts.indexOf(b)

const mergeShipmentAndMasterProducts = (state, productsOnShipment) => {
  return Object.keys(productsOnShipment).sort(byProduct).reduce((rows, productId) => {
    const productRow = getProductRow(productsOnShipment[productId])
    rows.push(productRow)
    if (productRow.tracksBatches) {
      rows.push(...productsOnShipment[productId].batches.map(getBatchRow))
    }
    return rows
  }, [])
}

// Checking for undefined as a batch may have only sent or only received (orphan shipments).
const getDifferences = (batchesById) => {
  return Object.keys(batchesById).reduce((memo, id) => {
    let difference = 0
    const batch = batchesById[id]
    if (batch.sent === undefined) {
      difference = batch.received
    } else if (batch.received === undefined) {
      difference = -1 * batch.sent
    } else {
      difference = batch.received - batch.sent
    }
    memo[id] = { ...batch, difference }
    return memo
  }, {})
}

const getBatchQuantities = (shipment, snapshots) => {
  const allBatches = {}
  snapshots.forEach(snapshotId => {
    const counts = Object.keys(shipment.history[snapshotId].counts)
    counts.forEach(batchId => {
      const sentOrReceived = snapshotId.includes('sent') ? 'sent' : 'received'
      allBatches[batchId] = allBatches[batchId] || {}
      allBatches[batchId][sentOrReceived] = shipment.history[snapshotId].counts[batchId].quantity
    })
  })
  return allBatches
}

const getBatchesWithMasterData = (state, batches) => {
  return Object.keys(batches).map(batchId => {
    const parts = parse(batchId)
    const fields = {}
    if (parts.batchNo) {
      fields.batchNumber = parts.batchNo === 'unknown'
        ? ''
        : parts.batchNo
    }

    if (state.masterData.batches[batchId]) {
      const { expiry, manufacturer, productId, _id } = state.masterData.batches[batchId]
      return {
        ...fields,
        _id,
        manufacturer,
        productId,
        ...batches[batchId],
        expiry: formatDate(expiry, 'expiry')
      }
    }

    const derivedFields = {}
    if (parts.product) {
      derivedFields.productId = `product:${parts.product}`
    }

    return {
      ...batches[batchId],
      ...fields,
      ...derivedFields,
      _id: batchId
    }
  })
}

// Some or all of the translated shipment ids may be lacking from our originally
// fetched originalProductsById master data, so let's augment that original data with
// data from the newly translated product ids
const getProductDataForMissingProducts = async (productIds, originalProductsById, api) => {
  const missingTranslatedIds = productIds
    .filter(id => !Object.keys(originalProductsById).includes(id))

  const missingProducts = missingTranslatedIds.length
    ? await api.product.getProductsViaIds(missingTranslatedIds)
    : []

  return keyBy(missingProducts, '_id')
}

// Looking to expand sortedProducts [product:bcg, product:diluent-bcg, ...]  into rows for printing:
// [ 0: { product: {product:bcg info} }
//   1: { batch: {bcg batch 1 info} }
//   2: { batch: {bcg batch 2 info} }
//   3: { product: {product:diluent-bcg info (say product:diluent-bcg has no batches)}}
//   4: { product {product:diluent-bcg info}}
//   5: { batch: {bcg diluent-bcg batch 1 info} } ... ]

export const getPrintRows = (state, shipment) => {
  const sentReceivedSnapshots = Object.keys(shipment.history).filter(snapshotId => (
    snapshotId.includes('sent') || snapshotId.includes('received')
  ))
  // Returns { batchId: {sent: 10, recieved: 5} batchId: ... }
  const allBatches = getBatchQuantities(shipment, sentReceivedSnapshots)
  // Returns { batchId: {sent: 10, recieved: 5, difference: -5 } }
  const withDifferences = getDifferences(allBatches)
  const withMasterData = getBatchesWithMasterData(state, withDifferences)
  // Returns { productId: { totalSent, totalReceived, batches: [ {} ] }, ... }
  const byProducts = getBatchesByProduct(state, shipment, withMasterData)
  const rows = mergeShipmentAndMasterProducts(state, byProducts, sortedProducts)
  return rows
}

// Ensure we use latest shipment even if URL snapshot is older
export const shipmentBySnapshotId = (state, snapshotId) => {
  const shipmentId = snapshotId.split(':status:')[0]
  const latestSnapshotId = Object.keys(state.shipments).find(snapshotId =>
    state.shipments[snapshotId].id === shipmentId
  )
  if (latestSnapshotId) {
    return state.shipments[latestSnapshotId]
  }
}

export const shipmentForPrinting = async (state, shipment, originalProductsById, api) => {
  // First let's translate product ids in shipment into retailer market ones.
  // Also get effectively translated ids.
  const {
    translatedShipment,
    translatedIds
  } = await translateProductsToRetailerMarket(shipment, api)

  // Fill in our products master data with the newly translated products
  const missingProductsById = await getProductDataForMissingProducts(translatedIds, originalProductsById, api)
  const allProductsbyId = {...originalProductsById, ...missingProductsById}

  const origin = {
    ...translatedShipment.origin,
    name: formatLocation(translatedShipment.origin),
    state: translatedShipment.origin.state ? toTitleCase(translatedShipment.origin.state) : ''
  }
  const destination = {
    ...translatedShipment.destination,
    name: formatLocation(translatedShipment.destination),
    state: translatedShipment.destination.state ? toTitleCase(translatedShipment.destination.state) : ''
  }
  const reverseDistribution = getLocationRank(translatedShipment.destination) > getLocationRank(translatedShipment.origin)

  const date = formatDate(translatedShipment.date, 'long')
  const year = translatedShipment.date.split('-')[0]
  // distributions usually begin 2-3 weeks before their calendar quarter
  // TODO provide flexibility on fs-setting for specifying VAN shipment distribution quarterly date
  // see https://github.com/fielded/van-orga/issues/2102
  // and https://github.com/fielded/van-orga/issues/2109
  const quarter = formatDate(addDays(translatedShipment.date, 34), 'quarter')
  const rows = getPrintRows(state, translatedShipment)
  return {
    ...translatedShipment,
    date,
    year,
    origin,
    rows,
    quarter,
    reverseDistribution,
    destination,
    showStoreReceiveVoucher: (translatedShipment.status === 'received'),
    allProductsbyId
  }
}
