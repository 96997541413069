const ALL_FUNDERS_ID = 'all-funders'

const IDENTIFIER_TO_FILTER = {
  funders: 'funderId',
  routes: 'routeId'
}

const IDENTIFIERTOID = {
  funders: 'funder',
  routes: 'route'
}

module.exports = {
  getFullId,
  getFullProgramId,
  getShortProgramId,
  getFullServiceID,
  getShortServiceId,
  isFilterIncluded,
  getShortId,
  isIdenticalDoc,
  funderDocsToEntities,
  routeDocsToEntities,
  withResponseRev,
  ALL_FUNDERS_ID,
  getNames,
  getNormalizedAdditionalData,
  IDENTIFIER_TO_FILTER,
  IDENTIFIERTOID
}

const { idify, parse } = require('./smart-id')

const vanLongLocationNames = require('./van-long-location-names')

function getFullId (fullKey, shortId) {
  if (!fullKey.endsWith('Id')) {
    return shortId
  }

  const key = fullKey.slice(0, fullKey.length - 2)
  return idify({ [key]: shortId }, key)
}

function getFullProgramId (shortProgramId) {
  const object = { program: shortProgramId }
  return idify(object, 'program')
}

function getShortProgramId (fullProgramId) {
  return parse(fullProgramId).program
}

function getFullServiceID (shortServiceId, program) {
  const object = { program, service: shortServiceId }
  return idify(object, 'program:service')
}

function getShortServiceId (fullServiceID) {
  return parse(fullServiceID).service
}

function getFullIdentifierId (shortId, identifier) {
  if (!shortId) return
  const object = { [identifier]: shortId }
  return idify(object, identifier)
}

function getShortId (fullId) {
  if (!fullId) return
  let value
  const parsed = parse(fullId)

  if (Object.keys(parsed).length !== 1) {
    throw new Error('Parsing ID failed', fullId)
  }

  for (let key in parsed) {
    value = parsed[key]
  }
  return value
}

function getDocCompareString (doc) {
  return JSON.stringify(Object.assign({}, doc, { updatedAt: undefined, updatedBy: undefined }))
}

// Caution: does not support circular references.
function isIdenticalDoc (currentDoc, updatedDoc) {
  return getDocCompareString(currentDoc) === getDocCompareString(updatedDoc)
}

function funderDocsToEntities (docs) {
  return docs.map(doc => {
    return Object.assign({}, doc, {
      id: doc._id.replace('funder:', '')
    })
  })
}

function routeDocsToEntities (docs) {
  return docs.map(doc => {
    return Object.assign({}, doc, {
      id: doc._id.replace('route:', '')
    })
  })
}

function withResponseRev (obj, response) {
  return Object.assign({}, obj, { _rev: response.rev })
}

function isFilterIncluded (shortId, filter = [], identifier) {
  if (filter.length === 0) return true

  const id = getFullIdentifierId(shortId, identifier)
  return (filter.includes(id) || id === `${identifier}:${ALL_FUNDERS_ID}`)
}

function getNames (doc) {
  if (doc.fullName) {
    return {
      storeName: doc.name,
      storeFullName: doc.fullName
    }
  }

  if (doc._id === 'national') {
    return {
      storeName: 'NSCS',
      storeFullName: 'National Strategic Cold Store'
    }
  }

  let suffix
  switch (doc.level) {
    case 'zone':
      suffix = ' Zonal Store'
      break
    case 'state':
      suffix = ' State Store'
      break
    case 'lga':
      suffix = ' LGA Store'
      break
    default:
      suffix = ''
  }

  const longName = vanLongLocationNames[doc._id]
  return {
    storeName: `${doc.name}${suffix}`,
    storeFullName: `${longName || doc.name}${suffix}`
  }
}

function getNormalizedAdditionalData (additionalData = {}) {
  const physicalAddress = additionalData.physicalAddress || additionalData.address
  const normalizedAdditionalData = Object.assign({}, additionalData, {physicalAddress})
  delete normalizedAdditionalData.address

  return normalizedAdditionalData
}
