import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import withPageContext from '../withPageContext'
import PanelActions from './PanelActions'
import PanelHeader from './PanelHeader'
import PanelMessage from './PanelMessage'
import PanelSection from './PanelSection'

const Panel = ({
  children,
  withBackground,
  withMargin,
  withMinimalMargin,
  reduceBottomMargin,
  status,
  narrow,
  mediumWidth,
  alignLeft,
  spread,
  situation,
  relative,
  className
}) => {
  const classes = classnames(
    'vs-panel',
    { 'vs-panel--with-background': withBackground },
    { 'vs-panel--spread': spread },
    { 'vs-panel--narrow': narrow },
    { 'vs-panel--medium': mediumWidth },
    { 'vs-panel--align-left': alignLeft },
    { 'vs-panel--with-margin': withMargin },
    { 'vs-panel--with-minimal-margin': withMinimalMargin },
    { 'vs-panel--reduce-bottom-margin': reduceBottomMargin },
    { 'vs-panel--relative': relative },
    { 'vs-panel--status': status },
    { 'vs-panel--status-info': status === 'info' },
    { 'vs-panel--status-warning': status === 'warning' },
    { 'vs-panel--status-danger': status === 'danger' },
    { 'vs-panel--status-success': status === 'success' },
    { 'vs-panel--on-situation-not-neutral': situation && situation !== 'neutral' }
  )

  const processedChildren = React.Children.map(children, child => {
    if (!child) return null
    let childWithInheritedProps = child

    // Pass status prop to the Panel.Header if passed as child here
    if (child.type === PanelHeader) {
      childWithInheritedProps = React.cloneElement(child, { status })
    }

    return childWithInheritedProps
  })

  if (spread) {
    return (
      <div className={
        classnames(
          'vs-panel-wrapper',
          { 'vs-panel-wrapper--spread': spread },
          className
        )
      }>
        <div className={classes}>
          {processedChildren}
        </div>
      </div>
    )
  }

  return <div className={classnames(classes, className)}>{processedChildren}</div>
}

Panel.propTypes = {
  /**
   * The child elements of the Panel
   */
  children: PropTypes.node.isRequired,

  /**
   * Set if Panel should be have a filled background
   */
  withBackground: PropTypes.bool,

  /**
   * Set if Panel should be have margin around it
   */
  withMargin: PropTypes.bool,

  /**
   * Set if Panel should be have only small margin around
   */
  withMinimalMargin: PropTypes.bool,

  /**
   * Set if Panel should reduce or remove bottom margin
   */
  reduceBottomMargin: PropTypes.bool,

  /**
   * Set if Panel should have position:relative
   */
  relative: PropTypes.bool,

  /**
   * Apply a status to the Panel with the appropriate styling
   */
  status: PropTypes.oneOf([
    null,
    'info',
    'warning',
    'danger',
    'success'
  ]),

  /**
   * Set if this Panel should have a max-width on large screens
   */
  narrow: PropTypes.bool,

  /**
   * Set if this Panel should fill the available space (flex)
   */
  spread: PropTypes.bool,

  /**
   * Add additional classnames to Panel. Use carefully.
   */
  className: PropTypes.string
}

Panel.defaultProps = {
  withBackground: false,
  withMargin: true,
  withMinimalMargin: false,
  reduceBottomMargin: false,
  narrow: false,
  spread: false,
  status: null,
  className: undefined
}

Panel.displayName = 'Page.Panel'

Panel.Actions = PanelActions
Panel.Header = PanelHeader
Panel.Message = PanelMessage
Panel.Section = PanelSection

export default withPageContext(Panel)

// This is to be consumed by the storybook only, otherwise the props are not showing in the stories
export const PagePanelNoHOC = Panel
