const get = require('lodash/get')
const libphonenumber = require('google-libphonenumber')
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

class AfricasTalkingAdapter {
  // TODO: throw if credentials not there
  constructor (logger, africastalkingCredentials, africasTalking) {
    this.logger = logger
    const {
      apiKey,
      username,
      shortCode
    } = africastalkingCredentials

    try {
      this.smsService = africasTalking({ apiKey, username })
    } catch (err) {
      // The africastalking package seems to throw plain objects instead of
      // errors in certain cases so we make sure it's always a proper Error.
      if (err instanceof Error) {
        throw err
      }
      const wrapperErr = new Error(`Error creating SMS Service: ${JSON.stringify(err)}`)
      throw wrapperErr
    }
    this.shortCode = shortCode
  }

  async sendSms (to, message) {
    // assuming `to` will always be without prefix
    const _to = Array.isArray(to) ? to.map(val => `+${val}`) : [`+${to}`]
    const from = this.shortCode

    const validNumbers = _to
      .filter(number => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(number))
        } catch (error) {
          console.log(`Failed to parse phoneNumber ${number}`)
        }
      })

    const response = await this.smsService.SMS.send({ from, to: validNumbers, message })

    const fail = response.SMSMessageData.Recipients.length === 0 ||
      response.SMSMessageData.Recipients.some(recipient => recipient.status !== 'Success')
    const success = !fail

    return {
      providerResponse: response,
      providerSmsId: get(response, 'SMSMessageData.Recipients[0].messageId'),
      success,
      provider: 'AT'
    }
  }
}

module.exports = AfricasTalkingAdapter
