import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import flowRight from 'lodash/flowRight'

import { Loading } from '@fielded/shared-ui'

import { hasFeature } from '../../../van-shared/utils/features'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'
import { withUser } from '../../../common/AuthenticationProvider'
import get from 'lodash/get'
import { findReport } from '../common/api'
import { buildOpenReportUrl } from '../app/routes-urls'
import { discardOngoingEntry } from '../../../root/reducers/reports/report-entry'

import {
  HAS_NO_DATA,
  IS_LOADING,
  LOADING_FAILED,
  LOADING_SUCCEEDED
} from '../../../root/reducers/reports/utils'

class FindReportContainer extends Component {
  constructor (...args) {
    super(...args)
    this.state = {
      status: HAS_NO_DATA,
      report: null
    }
  }

  async load (props) {
    // doing this in the componentWillUnmount in ReportEntryContainer
    // did not work, someone in the redux dark forest puts the report back.
    // Doing it here cleared the report entry reducer's state,
    // preventing the previous report from loading there via withReport
    this.props.discardOngoingEntry()
    const {
      config,
      user,
      api,
      match: { params }
    } = props

    // Decides whether to read last stock report or ledger balance
    const realtime = hasFeature(config.features, 'stockCount:realtime')
    const excludeProducts = get(config.features, 'stockCount.excludeProducts')
    const opensExistingReport = hasFeature(config.features, 'stockCount:opensExistingReport')
    const hideUnavailableProducts = hasFeature(config.features, 'stockCount:hideUnavailableProducts')
    const autoPopulateReceived = hasFeature(config, 'features.autoPopulateReceived')

    const partialCount = params.partialCount === 'partial' || params.productId === 'partial'
    const serviceId = params.serviceId
    const locationId = params.locationId
    const date = params.date

    if (this.state.status.IS_LOADING) {
      return
    }
    this.setState({status: IS_LOADING, report: null})

    let report
    try {
      report = await findReport(api, {
        locationId,
        serviceId,
        date,
        user,
        realtime,
        opensExistingReport,
        excludeProducts,
        hideUnavailableProducts,
        autoPopulateReceived,
        partialCount
      })
    } catch (error) {
      console.error(error)
      this.setState({status: LOADING_FAILED})
      return
    }
    this.setState({
      status: LOADING_SUCCEEDED,
      report
    })
  }

  componentDidMount () {
    this.load(this.props)
  }

  render () {
    const { match: { params } } = this.props
    const {
      status,
      report
    } = this.state

    const productId = params.productId && params.productId !== 'partial'

    switch (status) {
      case HAS_NO_DATA:
      case IS_LOADING:
        return <Loading />
      case LOADING_FAILED:
        return <h3>Error, loading has failed</h3>
      case LOADING_SUCCEEDED:
        return <Redirect to={buildOpenReportUrl({reportId: report._id, productId})} />
      default:
        return <h3>Error: unkonwn loading state</h3>
    }
  }
}

const mapDispatchToProps = {discardOngoingEntry}

const withHOCs = flowRight(
  withConfig,
  withApi,
  withUser,
  connect(null, mapDispatchToProps)
)

export default withHOCs(FindReportContainer)
