import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { hasFeature } from '../../../van-shared/utils/features'
import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from '../common/WithShipmentWrapper'
import withMasterData from '../common/withMasterData'

import { batchIdToProductId, headerLocationFor } from '../common/utils'
import PlanningProducts from './PlanningProducts'
import {
  selectedProducts,
  toggleProductSelected,
  updateProductQuantity,
  setPlannerParams,
  updatePaymentType
} from '../../../root/reducers/shipments/planning'
import shipmentsBreadcrumb from '../common/shipmentsBreadcrumb'
import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
import { getCurrentPrice } from '@fielded/fs-api/lib/product/tools'

class AddProductContainer extends Component {
  constructor () {
    super()

    this.state = { loadedPlan: false }
  }

  async componentDidMount () {
    // to clear previous selection of products before navigating to product view
    const {
      prevSelectedProducts,
      toggleProductSelected,
      updateProductQuantity,
      updatePaymentType,
      setPlannerParams,
      shipment,
      api
    } = this.props

    const locationId = shipment.destination.id
    const [location] = await api.location.getLocationsViaIds([locationId])
    const membership = get(location, 'membership')
    const allocationConfig = await api.allocation.getConfiguration({ facilityId: locationId })
    const locationSubs = allocationConfig ? allocationConfig.products : {}
    const locationSubsSku = locationSubs ? Object.keys(locationSubs) : []
    this.setState({ locationSubsSku, membership })
    prevSelectedProducts.forEach(product => {
      updateProductQuantity(product._id, 0)
      updatePaymentType(product._id, DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL)
      toggleProductSelected(product._id)
    })

    // this is reloaded every time the component is mounted,
    // to make sure destination in plan is up to date with destination in shipment
    setPlannerParams(shipment.origin.id)
      .then(() => this.setState({ loadedPlan: true }))
  }

  handleProductSelected = productId => {
    return this.props.toggleProductSelected(productId)
  }

  handleClickNext = () => {
    this.props.history.replace(`/shipments/pick-list/${this.props.shipment.snapshotId}/shipment-options/add-product/quantity`)
  }

  render () {
    const {
      products,
      selectedProducts,
      history,
      shipment,
      config,
      snapshotId
    } = this.props

    if (!this.state.loadedPlan) {
      return <Loading />
    }

    const productIdsInPickList = new Set()
    for (const batchId in shipment.counts) {
      productIdsInPickList.add(batchIdToProductId(batchId))
    }

    const destination = headerLocationFor(shipment, config)

    // Condition taken from Shipment.js
    const shipmentLabel = shipment.statusType === 'distribution' ? 'Distribution to' : 'Arrival from'

    const showProductCode = hasFeature(config.features, 'shipments:addProduct:showProductCode')

    const productsWithPrices = products.filter(p => {
      return p.prices.length && getCurrentPrice(p.prices) !== undefined
    })

    return (
      <PlanningProducts
        products={productsWithPrices}
        selectedProducts={selectedProducts}
        alreadyAddedProducts={productIdsInPickList}
        history={history}
        onClickNext={this.handleClickNext}
        onToggle={this.handleProductSelected}
        backButtonText={`${shipmentLabel} ${destination}`}
        showProductCode={showProductCode}
        headerText={`Select products to add to shipment`}
        snapshotId={snapshotId}
        breadcrumbItems={
          [shipmentsBreadcrumb, {
            title: `${shipmentLabel} ${destination}`,
            path: `/shipments/pick-list/${snapshotId}`
          }]}
      />
    )
  }
}

const mapStateToProps = (state, { match: { params } }) => {
  const {
    products
  } = state.planning

  return {
    products,
    selectedProducts: selectedProducts(state.planning).length,
    prevSelectedProducts: selectedProducts(state.planning),
    snapshotId: params.snapshotId
  }
}

const mapDispatchToProps = {
  toggleProductSelected,
  updateProductQuantity,
  updatePaymentType,
  setPlannerParams
}

export default withConfig(
  withMasterData(
    withShipment(
      connect(mapStateToProps, mapDispatchToProps)(AddProductContainer)
    )
  )
)
