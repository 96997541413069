import React from 'react'

import Loading from '../Loading'
import Text from '../Text'

const TableEmptyBox = ({
  loading,
  loadingText,
  mainCopy,
  supportingCopy,
  icon,
  children
}) => {
  if (loading) {
    return (
      <div className='vs-u-table__empty-info-box'>
        <Loading
          loadingText={loadingText || 'Loading...'}
        />
      </div>
    )
  }

  return (
    <div
      className='vs-u-table__empty-info-box vs-u-column-centered vs-u-gap-half'
    >
      <div className='vs-u-table__empty-info-box-icon'>{icon}</div>
      <Text bold>{mainCopy}</Text>
      <Text size='small'>{supportingCopy}</Text>
      {children}
    </div>
  )
}
export default TableEmptyBox
