const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const getLedgerBalance = require('../../../stock-get-ledger-balance')
const { get: getLocation } = require('./../../../location')
const { list: listFunders } = require('./../../../funders')
const { getProgram } = require('../../../program/api')
const { getLocationProgramFunder, convertToSDPUnits, convertLedgerToSDPUnits } = require('../../tools')
const { listBatches } = require('../../../batch/api/batch-list')

exports.getAllocatedStock = async function (state, {
  locationId,
  programId,
  fundersFilter,
  date = new Date().toJSON(),
  includeExpired = false
}) {
  fundersFilter = fundersFilter || state.user.funders

  let [location, funders, program] = await Promise.all([
    getLocation(state, locationId),
    listFunders(state),
    getProgram(state, programId)
  ])

  if (!location) {
    throw new Error(`Error getting allocated stock, location with id ${locationId} not found.`)
  }

  // for our api's occasional desire for an id prop instead of _id...
  location = Object.assign({}, location, {id: location._id})
  if (!program) {
    throw new Error(`Error program ${programId} not found`)
  }

  const locationProgram = location.programs
    .find(program => program.id === programId)

  if (!locationProgram) {
    console.warn(`
      program is not enabled for this warehouse: ${programId} so no stock is found
    `)
    return {}
  }

  const services = locationProgram.services.map(service => {
    return program.services
      .find(programService => programService.id === service.id)
  })

  const ledgerResponses = await Promise.all(
    services.map(service =>
      getLedgerBalance(state, {
        locationId: location._id, service, localOnly: false, withEntities: true, includeScheduledOutbound: true
      })
    )
  )

  const productsById = ledgerResponses
    .reduce((acc, response) => {
      Object.values(response.products).forEach(product => {
        acc[product._id] = product
      })
      return acc
    }, {})

  const funderId = (programId === 'program:hiv-aids')
    // HIV warehouses do not have specific funders enabled
    // So the only way we determine which funder-specific commit to lookup
    // is off the user's funders array (fundersFilter).
    ? getHIVFunderId(fundersFilter, funders)
    // Non HIV warehouses are virtual and have specific funders enabled for programs
    : getLocationProgramFunder(location, programId)

  const commitsField = `commits.allocated:${funderId}.amount`

  const allocatedStock = {}
  let fullLedger = {}
  ledgerResponses.forEach(({ledger}) => {
    Object.keys(ledger).forEach(productId => {
      fullLedger[productId] = ledger[productId]
      allocatedStock[productId] = commitsField
        ? get(ledger, `${productId}.${commitsField}`, 0)
        : 0
    })
  })

  if (!includeExpired) {
    const batchIds = Object.values(fullLedger).reduce((acc, v) => acc.concat(Object.keys(v.batches || {})), [])
    const batches = await listBatches(state, {ids: batchIds})
    const expiredBatches = batches.filter(batch => filterByExpired(batch, date))
    const expiredBatchesById = keyBy(expiredBatches, '_id')
    Object.keys(fullLedger).reduce((acc, productId) => {
      Object.keys(fullLedger[productId].batches || {}).forEach(batchId => {
        if (expiredBatchesById[batchId]) {
          fullLedger[productId].total -= fullLedger[productId].batches[batchId]
          delete fullLedger[productId].batches[batchId]
        }
      })
    }, {})
  }

  const allocatedStockSDPUnits = convertToSDPUnits({stock: allocatedStock, productsById})
  const ledger = fullLedger
  const ledgerSDPUnits = convertLedgerToSDPUnits({ledger: fullLedger, productsById, allocatedStockSDPUnits})

  return {ledger, ledgerSDPUnits, allocatedStock, allocatedStockSDPUnits, productsById, funderId}
}

function filterByExpired (batch, date) {
  if (!batch.expiry) {
    return true
  }
  return batch.expiry < date
}

// Assumption is that user objects will always have one HIV funder,
// or HIV subfunders that all have the same parent
function getHIVFunderId (userFundersFilter = [], funders = []) {
  const hivFunders = funders.filter(funder => funder.programs['program:hiv-aids'])
  const userHIVFunders = userFundersFilter
    .map(userFunerId => hivFunders.find(funder => funder._id === userFunerId))
    .filter(x => x)

  const userHIVFundersOrParents = userHIVFunders
    .map(funder => getFunderOrParentId(funder, hivFunders))
    .filter(x => x)

  // because [pepfar--cdc, pepfar--dod] => [pepfar, pepfar]
  const userHIVFundersOrParentsUnique = [...new Set(userHIVFundersOrParents)]

  return (userHIVFundersOrParentsUnique.length === 1)
    ? userHIVFundersOrParentsUnique[0]
    : null
}

function getFunderOrParentId (funder, funders) {
  if (!funder.ordersParentFunderId) return funder._id

  const parentFunder = funders.find(f => f._id === funder.ordersParentFunderId)
  return parentFunder
    ? parentFunder._id
    : null
}
