import { formatDate } from '../../../van-shared/utils'
import { batchesByShipment } from '../../../root/reducers/shipments/batches'

export const uncheckedBatches = (batches, pickList) => Object.keys(pickList)
  .filter(productId => pickList[productId].unchecked.length)
  .reduce((index, productId) => {
    index[productId] = pickList[productId].unchecked
      .map(batchId => batches[batchId])
    return index
  }, {})

export const hasCountingDiscrepancies = (pickList) => {
  const isPickedDifferentFromTotal = productId => pickList[productId].picked !== pickList[productId].total
  return Object.keys(pickList).some(isPickedDifferentFromTotal)
}

export const decoratedBatchesByChecked = (state, snapshotId) =>
  batchesByShipment(state, snapshotId).reduce((index, batch) => {
    if (!batch.checked || batch.isUntracked) {
      return index
    }

    // TODO: move into master data with human-readle name, see:
    // https://github.com/fielded/van-orga/issues/71
    const productName = batch.productName || batch.productCode || batch.productId.split(':').pop()
    if (!index[productName]) {
      index[productName] = []
    }

    const decoratedBatch = {
      id: batch.id,
      count: batch.quantity,
      expiry: formatDate(batch.expiry, 'expiry'),
      number: batch.id.split(':').pop(),
      productName
    }

    index[productName].push(decoratedBatch)

    return index
  }, {})

export const pickListWithName = (state, snapshotId) => {
  const pickList = state.pickList
  const masterDataProducts = state.masterData.products.byId

  const pickListWithName = Object.keys(pickList).reduce((pickListAccumulator, productId) => {
    const product = pickList[productId]
    const masterDataProduct = masterDataProducts[productId]
    return {
      ...pickListAccumulator,
      [productId]: {
        ...product,
        code: masterDataProduct.code,
        name: masterDataProduct.name
      }
    }
  }, {})

  return pickListWithName
}
