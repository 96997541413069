import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { withUser } from '../../../common/AuthenticationProvider'
import withConfig from '../../../van-shared/hoc/withConfig'
import withOverview from './withOverview'
import Services from './Services'

import {
  buildFindReportUrl
} from '../app/routes-urls'

class ServicesContainer extends Component {
  constructor (props) {
    super(props)
    this.handleOpenReport = this.handleOpenReport.bind(this)
  }

  getReportUrl (service, locationId, date, partialCount) {
    return buildFindReportUrl({locationId, date, serviceId: service.id, partialCount: partialCount})
  }

  handleOpenReport (service) {
    const { history, reportLocationId, date } = this.props
    const url = this.getReportUrl(service, reportLocationId, date)
    history.push(url)
  }

  render () {
    const {
      history,
      date,
      overview,
      locationId,
      reportLocationId,
      config,
      user,
      partialCount
    } = this.props

    const isEditablePeriod = overview.periods.current.isEditable
    const reportLocation = overview.locations.all.find(l => l._id === reportLocationId)
    let error

    if (!reportLocation) {
      error = {
        message: `${reportLocationId} does not have an active service in the selected reporting period.`
      }
    }

    if (reportLocation && reportLocation.progress.services.length === 1) {
      // NOTE: partial count is at the moment only allowed for single-service locations
      // if we wanna use this for multi-service locations, we need to modify this redirection stuff
      // (to not do partial counts when no 'base' count has been made in the period)
      const url = this.getReportUrl(reportLocation.progress.services[0], reportLocationId, date, partialCount)
      return <Redirect to={url} />
    }

    return (
      <Services
        user={user}
        history={history}
        locationId={locationId}
        date={date}
        config={config}
        isEditablePeriod={isEditablePeriod}
        program={overview}
        reportLocation={reportLocation}
        reportLocationId={reportLocationId}
        onOpenReport={this.handleOpenReport}
        error={error}
      />
    )
  }
}

const mapStateToProps = (
  { overview: { overviews } },
  { match: { params: { programId, locationId, date, reportLocationId, partialCount } } }
) => {
  return {
    overview: overviews.find(o => o.id === programId),
    date,
    locationId,
    reportLocationId,
    partialCount
  }
}

export default withConfig(withUser(withOverview(connect(mapStateToProps)(ServicesContainer))))
