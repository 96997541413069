const { getRelevantSnapshotDate } = require('./get-relevant-snapshot-date')
const get = require('lodash/get')
const { SHIPMENT_VERSIONS } = require('../../shipment/constants')

const filterShipmentsByVersion = ({shipment, locationId, startdate, enddate, shipmentStatuses}) => {
  const version = get(shipment, 'version', '1.0.0')
  const isOrigin = shipment.origin.id === locationId

  const relevantSnapshotDate = version === SHIPMENT_VERSIONS.VERSION_1
    ? getRelevantSnapshotDate(isOrigin, shipment)
    : shipment.snapshotDates.received

  switch (version) {
    case SHIPMENT_VERSIONS.VERSION_1:
      const isDestination = shipment.destination.id === locationId
      const statusHistory = shipmentStatuses[shipment.id]

      // If the location is the shipment origin and packed snapshot exist, take packed snapshot into consideration
      // If the location is the shipment origin and no packed snapshot exist, take sent snapshot into consideration
      // If the location is the shipment destination, only take received snapshot into consideration
      return (
        (isDestination && shipment.status === 'received') ||
        (isOrigin && shipment.status === 'sent' && !statusHistory.includes('packed')) ||
        (isOrigin && shipment.status === 'packed')
      ) &&
        (relevantSnapshotDate > startdate) &&
        (!enddate || relevantSnapshotDate < enddate)

    case SHIPMENT_VERSIONS.VERSION_2:
      return (shipment.status === 'received') && (relevantSnapshotDate > startdate) && (!enddate || relevantSnapshotDate < enddate)
  }
}

module.exports = {
  filterShipmentsByVersion
}
