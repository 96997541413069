// Please see the README here before making changes

const toDocIdProperties = require('../../tools/to-doc-id-properties')
const toLocationProperties = require('../../tools/to-location-properties')
const toShipmentId = require('../../tools/to-shipment-id')
const toLocationId = require('../../tools/to-location-id')
const offline = require('../../tools/offline')

const DEFAULT_LIMIT = Number.MAX_SAFE_INTEGER

const isShipmentId = id => {
  const parsed = toDocIdProperties(id)
  // I chose to use those parts because all together they signify a unique shipment
  // if you're querying with less (origin, destination, date) you might get docs
  // for 2 different shipments
  return parsed.origin && parsed.destination && parsed.date && parsed.shipmentNo
}

const checkRange = (ranges, locationId, startdate, enddate) => {
  return !!ranges.find(range => {
    return range.checks.every(check => {
      switch (check.type) {
        case 'locationMatch':
          return check.matches.includes(locationId)
        case 'greaterThan':
          // If start date is not specified, count it as an offline query
          return !startdate || check.matches.every(date => (date <= startdate))
        case 'lowerThan':
          // If enddate date is not specified, count it as an offline query
          return !enddate || check.matches.every(date => (date >= enddate))
        default:
          throw new Error(`Range: check ${check.type} is not implemented yet`)
      }
    })
  })
}

const handle404 = e => {
  if (e.status === 404) {
    return null
  }

  throw e
}

const getIdDispenser = db => {
  return db.get('_local/id_dispenser')
    .catch(handle404)
}

/*
 * This is useful when we need to read from
 * both remote and local dbs
 * and treat the responses as one
 */
const mergeResponses = responses => {
  return responses.reduce((all, resp) => {
    return { rows: all.rows.concat(resp.rows) }
  }, { rows: [] })
}
/*
 * This is the heart of online-offline
 * for shipment, the function that is used to
 * decides whether to do a query against
 * the local DB or the remote db
 */
const localQuery = (state, params, idDispenser) => {
  const { prefix, locationId, startdate, enddate } = params
  // This user is not using online-offline,
  // make all queries against the Local DB
  if (!state.onlineOffline) {
    return Promise.resolve(true)
  }

  if (/^_local/.test(prefix)) {
    return true
  }

  return (idDispenser || getIdDispenser(state.shipmentsDb))
    .then(idsDoc => {
      if (!idsDoc) {
        // If the online offline user does not have an ID doc,
        // it means they are not syncing shipments,
        // or they have everything online
        console.log('No id dispenser doc found for shipments, if this app does not use shipments, that is ok')
        return true
      }
      // 1. When getting documents by id, find out if the id matches
      if (prefix) {
        const parts = toDocIdProperties(prefix)
        const shipmentId = toShipmentId(parts)
        // Test if any of the synced ids matches the shipment id
        // in that case any parts belonging to it would have been synced as well
        const shouldSync = idsDoc.ids.find(id => id.includes(shipmentId))
        return !!shouldSync
      }

      // 2. When getting documents by location, find out if we sync this location
      if (locationId) {
        return checkRange(idsDoc.range, locationId, startdate, enddate)
      }

      // This query is not covered by this check
      // this is a bit strange, let's throw an error for now
      throw new Error(`Query not covered by localQuery online-offline check ${JSON.stringify(params)}`)
    })
}

const filterLocalDocs = (state, keys) => {
  const idDispenser = getIdDispenser(state.shipmentsDb)
  return Promise.all(keys.map(docId => localQuery(state, { prefix: docId }, idDispenser)))
    .then(responses => {
      const result = { localIds: [], remoteIds: [] }
      responses.forEach((isLocal, index) => {
        if (isLocal) {
          result.localIds.push(keys[index])
        } else {
          result.remoteIds.push(keys[index])
        }
      })

      return result
    })
}

const getByKeys = (state, params) => {
  // AllDocs with Keys
  // This one is a bit tricky
  return filterLocalDocs(state, params.keys)
    .then(({ localIds, remoteIds }) => {
      const promises = []
      if (localIds.length) {
        promises.push(state.shipmentsDb.allDocs(Object.assign({}, params, { keys: localIds })))
      }

      if (remoteIds.length) {
        promises.push(catchOffline(state.shipmentsRemoteDb.allDocs(Object.assign({}, params, { keys: remoteIds }))))
      }

      return Promise.all(promises)
    })
    .then(mergeResponses)
}

const getAllDocsWithPrefix = (db, prefix) => {
  return db.allDocs({
    startkey: prefix,
    endkey: `${prefix}\ufff0`,
    include_docs: true
  })
}

const catchOffline = promise => {
  return promise
    .catch(err => {
      const offlineError =
        err.status === 0 ||
        err.code === 'ESOCKETTIMEDOUT'

      if (!offlineError) {
        return Promise.reject(err)
      }

      console.warn('Throwing offline error, original error was', err)
      const error = new Error('This data is not available Offline')
      error.status = offline.OFFLINE_ERROR
      return Promise.reject(error)
    })
}

const getByPrefix = async (state, prefix) => {
  /*
   * This is for getting all related docs
   * - all docs belonging to a shipment
   * - all surveys belonging to a snapshot
   *    etc
   *
   * Used in:
   * - Save changes
   * - survey-find
   * - survey-find-all-for-snapshot
   * - survey-find-all-for-shipment
   * - shipment-find-by-id
   *    alldocs between start/end key
   */

  // 1. Test that the prefix is a shipment id
  if (!isShipmentId(prefix)) {
    throw new Error(`Prefix has to be part of a shipment id: ${prefix}`)
  }

  const areIdsSynced = await localQuery(state, { prefix: prefix })

  // 1. We know that this shipment id should be synced
  //    and we assume all the parts are available in local db
  if (areIdsSynced) {
    return getAllDocsWithPrefix(state.shipmentsDb, prefix)
  }

  // 2. This is a bit tricky:
  // - if one of the locations is available offline, we need to
  //   look both locally and remotely
  //   this is to cover new shipments that was just recently created
  //   which have not been added in the id_dispenser sync machinery yet
  //
  //   for synced locations:
  //   - if a shipments exists and is open, you will have synced that data
  //   - if a shipment exists and is closed, you can not do much about it anyway
  //   - if a shipment exists and is open and not synced, it must be available locally
  //      (otherwise you would not access it anyway)
  //   - that means; non-recieved are read in local
  //   - received shipments (that are not marked as synced) are read in remote
  //
  //   for locations that are NOT synced
  //   - online-only
  //   - why is it important to NOT look in local data here?
  //     because the user might be looking at very stale stuff
  //     like say a few months before online offline was created
  //     and they should be warned about it
  //   - at the time of writing, there is no functionality to edit shipments
  //     for locations that you don't supply, but who knows :)
  const { origin, destination, status } = toDocIdProperties(prefix)
  const originId = toLocationProperties(origin).id
  const destinationId = toLocationProperties(destination).id

  const [originIsSynced, destinationIsSynced] = await Promise.all([
    localQuery(state, { locationId: originId }),
    localQuery(state, { locationId: destinationId })
  ])

  const readLocal = (originIsSynced || destinationIsSynced)

  if (!readLocal) {
    return catchOffline(getAllDocsWithPrefix(state.shipmentsRemoteDb, prefix))
  }

  const localData = await getAllDocsWithPrefix(state.shipmentsDb, prefix)
  // We had local data
  if (localData.rows.length > 0) {
    return localData
  }

  // This is when finding surveys, change docs and comments
  // if we got this far for a non-received shipment,
  // there will not be any (we would have synced them)
  if (status && status !== 'received') {
    return localData
  }

  // We're maybe trying to read an old shipment
  // that was never synced
  return catchOffline(getAllDocsWithPrefix(state.shipmentsRemoteDb, prefix))
}

// getById needs the same 'is location synced?'
// check as getByPrefix, so we re-use that one
const getById = (state, docId, opts = {}) => {
  if (opts.catch404) {
    return getById(state, docId)
      .catch(handle404)
  }

  // Shipments does not use local docs as much as reports, but
  // local ids include id dispenser docs and adjustment drafts
  if (/^_local/.test(docId)) {
    return state.shipmentsDb.get(docId)
  }

  return getByPrefix(state, docId)
    .then(res => {
      const row = res.rows.find(r => r.id === docId)

      if (row && row.doc) {
        return row.doc
      }

      const notFound = new Error('not_found')
      notFound.status = 404

      return Promise.reject(notFound)
    })
}

// eslint-disable-next-line
const getByLocationView = (state, locationId, query, { startdate, enddate }) => { // eslint-disable-line
  const localCheck = localQuery(state, { locationId, startdate, enddate })

  return localCheck
    .then(isLocal => {
      const db = isLocal ? state.shipmentsDb : state.shipmentsRemoteDb
      // In certain circumstances, we can circumvent the view query to just get all shipment docs
      // - if we're not looking for a specific date range
      // - if we're on a non-http pouch (i.e. id dispenser has done some filter)
      // - and the user is just looking for all data in it's own location
      const canCheat = state.prefilteredPouch &&
        !(/http/.test(db.adapter)) &&
        !startdate &&
        !enddate &&
        locationId === state.user.location.id

      if (canCheat) {
        // fastTrack this one, no need to go to the view
        return db.allDocs({ startkey: 'origin:', endkey: 'origin:{}', include_docs: query.include_docs })
      }

      const response = db.query('store-api/by-location-date', query)
      return isLocal ? response : catchOffline(response)
    })
}

/*
 * I wanna find all shipments for one location, optionally filtered
 */
// eslint-disable-next-line
const getByLocation = (state, locationId, { startdate, enddate, include_docs }) => {
  // Replace colon with hyphen
  // in the location id for query:ing this view
  const queryLocationId = toLocationId(locationId)
  const query = {
    startkey: [queryLocationId, null],
    endkey: [queryLocationId, {}],
    include_docs: include_docs
  }

  return getByLocationView(state, locationId, query, { startdate, enddate })
}

/*
 * find all shipments in one geolocation
 */
// eslint-disable-next-line
const getByGeoLocation = (state, locationId, { startdate, enddate, include_docs }) => {
  // Replace colon with hyphen
  // in the location id for query:ing this view
  let queryLocationId = toLocationId(locationId)

  // For PSM national users, we dont have shipments with origin "national"
  // So we get every shipment available in pouch
  if (queryLocationId === 'national') {
    queryLocationId = 'zone-'
  }

  const query = {
    startkey: [queryLocationId, null],
    endkey: [`${queryLocationId}{}`, {}],
    include_docs: include_docs
  }

  return getByLocationView(state, locationId, query, { startdate, enddate })
}

const write = async (state, doc) => {
  // See comments on this workflow in getByPrefix
  const areIdsSynced = await localQuery(state, { prefix: doc._id })

  if (areIdsSynced) {
    return state.shipmentsDb.put(doc)
  }

  const { origin, destination } = toDocIdProperties(doc._id)
  const originId = toLocationProperties(origin).id
  const destinationId = toLocationProperties(destination).id

  const [originIsSynced, destinationIsSynced] = await Promise.all([
    localQuery(state, { locationId: originId }),
    localQuery(state, { locationId: destinationId })
  ])

  const writeLocal = (originIsSynced || destinationIsSynced)

  if (writeLocal) {
    return state.shipmentsDb.put(doc)
  }

  return catchOffline(state.shipmentsRemoteDb.put(doc))
}

const getExternalShipment = async (state, shipmentNumber) => {
  const selector = {
    externalShipmentId: shipmentNumber
  }

  const { docs } = await state.shipmentsDb.find({ selector, limit: DEFAULT_LIMIT })
  return docs
}

module.exports = {
  getById,
  getByKeys,
  getByPrefix,
  getByLocation,
  getByGeoLocation,
  write,
  getAllDocsWithPrefix,
  getExternalShipment
}
