import get from 'lodash/get'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { MEMBERSHIPS } from '@fielded/shared-ui/src/utils/membership-type-constants'
import { isPaused as isUserPaused } from '@fielded/fs-api/lib/invoices/tools'

import { canCallEndpoint, getTimestampForNextApiCall } from '../../../../common/utils/redux-timestamp-call'
import {
  getPayableDetails,
  getProcessedPaymentPlans,
  pendingTxnTypes,
  sortTransactions
} from './utils'

const MINUTES_TO_ADD = 5

const initialState = {
  isPaused: false,
  loading: false,
  error: null,
  timestampForTheNextApiCall: 0, // next api call to get all financial data
  timestampForTheNextAllTransactionsApiCall: 0, // next api call to get all transactions ie invoices, instalments etc
  financialData: {
    creditInfo: {
      creditLimit: null,
      creditCustomer: false,
      orderStatus: null,
      availableCreditBalance: 0,
      pendingDeliveriesAmount: 0,
      cashBalance: 0,
      creditApplicationStatus: 'none',
      balances: {
        balance: 0,
        pending: 0,
        overdue: 0,
        availableCashBalance: 0
      }
    },

    paymentPlans: {
      open: [],
      closed: [],
      all: []
    },

    payables: {
      currentWeek: [],
      nextWeek: [],
      futureWeeks: [],
      overduePayments: [],
      invoices: [],
      instalments: []
    },

    allTransactions: {
      transactions: [],
      pendingTransactions: []
    }
  }
}

export const getFinances = createAsyncThunk('wallet/getFinances', async (args, thunkAPI) => {
  const { extra: { api, locationId }, getState } = thunkAPI

  let paymentMethod
  const location = getState().location
  const isBasicUser = MEMBERSHIPS.BASIC === get(location, 'userLocation.membership')

  try {
    paymentMethod = await api.payment.getRetailerPaymentMethod(locationId)
  } catch (error) {
    console.warn(error)
  }

  // this call will only be triggered if the condition (prop) is true
  try {
    const returnAll = true // return all payment plans

    const response = await Promise.all([
      api.finances.getCreditInfo(locationId),
      api.paymentPlans.getUserPaymentPlans(locationId, returnAll),
      api.finances.getPayablesList(locationId)
    ])

    const [
      creditInfoData,
      paymentPlans,
      payables
    ] = response

    const payableDetails = getPayableDetails(payables)
    const { invoices, instalments } = payableDetails
    const hasActiveMandate = paymentMethod && get(paymentMethod, 'data.isActive')

    const creditInfo = {
      ...creditInfoData,
      balances: {
        ...creditInfoData.balances,
        availableCashBalance: Math.max(creditInfoData.cashBalance || 0, 0)
      }
    }

    let isPaused = false
    if (!isBasicUser) {
      isPaused = await isUserPaused(invoices, hasActiveMandate, instalments, api.location)
    }

    return [
      creditInfo,
      paymentPlans,
      payableDetails,
      isPaused
    ]
  } catch (error) {
    return error
  }
}, {
  condition: (args, thunkAPI) => {
    if (args && args.forceRefresh) return true // This ensures that the API is called

    const { getState } = thunkAPI
    const { timestampForTheNextApiCall } = getState().finances
    const callEndpoint = canCallEndpoint(timestampForTheNextApiCall)
    return callEndpoint
  }
})

export const getAllTransactions = createAsyncThunk('wallet/getAllTransactions', async (args, thunkAPI) => {
  const { extra: { api } } = thunkAPI

  // this call will only be triggered if the condition (prop) is true
  try {
    const { results } = await api.finances.getTransactions({
      returnAll: true
    })
    const transactions = sortTransactions(results)
    const pendingTransactions = results.filter(({txn_type: txnType}) => pendingTxnTypes.includes(txnType))
    return {
      transactions,
      pendingTransactions
    }
  } catch (error) {
    return error
  }
}, {
  condition: (args, thunkAPI) => {
    if (args && args.forceRefresh) return true // This ensures that the API is called

    const { getState } = thunkAPI
    const { timestampForTheNextAllTransactionsApiCall = 0 } = getState().finances
    const callEndpoint = canCallEndpoint(timestampForTheNextAllTransactionsApiCall)
    return callEndpoint
  }
})

export const getCreditInfo = createAsyncThunk('wallet/creditInfo', async (args, thunkAPI) => {
  const { extra: { api, locationId } } = thunkAPI

  try {
    const creditInfoData = await api.finances.getCreditInfo(locationId)
    const creditInfo = {
      ...creditInfoData,
      balances: {
        ...creditInfoData.balances,
        availableCashBalance: Math.max(creditInfoData.cashBalance || 0, 0)
      }
    }
    return { creditInfo }
  } catch (error) {
    return error
  }
})

export const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    updateCreditApplicationStatus: (state, action) => {
      const creditApplicationStatus = action.payload
      state.financialData = {
        ...state.financialData,
        creditInfo: {
          ...state.financialData.creditInfo,
          creditApplicationStatus
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder

      // GETS ALL FINANCIAL DATA
      // This api call gets the user's credit info, payable list and payment plans
      // It is first called when a user logs in (PrivateRoutes).
      // It can only be triggered based on a condition after the first call.
      // CONDITION: timestampForTheNextApiCall < new Date().getTime()
      .addCase(getFinances.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getFinances.fulfilled, (state, action) => {
        const { payload } = action

        if (payload.length) {
          const [
            creditInfo,
            paymentPlans,
            payableDetails,
            isPaused
          ] = payload

          const processedPaymentPlans = getProcessedPaymentPlans(paymentPlans)
          const nextApiCallTimestamp = getTimestampForNextApiCall(MINUTES_TO_ADD)

          state.loading = false
          state.isPaused = isPaused
          state.timestampForTheNextApiCall = nextApiCallTimestamp
          state.timestampForTheNextBalancesApiCall = nextApiCallTimestamp

          state.financialData = {
            ...state.financialData,
            paymentPlans: processedPaymentPlans,
            creditInfo,
            payables: payableDetails
          }
        } else {
          state.loading = false
          state.timestampForTheNextApiCall = 0
          state.timestampForTheNextBalancesApiCall = 0
        }
      })
      .addCase(getFinances.rejected, (state, action) => {
        state.loading = false
        state.timestampForTheNextApiCall = 0
        state.timestampForTheNextBalancesApiCall = 0
        state.error = action.payload
      })

      // GET ALL TRANSACTIONS
      // This api call gets the user's transactions
      // which includes: invoices, installments, credit memo etc
      .addCase(getAllTransactions.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        if (action.payload) {
          const nextApiCallTimestamp = getTimestampForNextApiCall(MINUTES_TO_ADD)

          state.loading = false
          state.timestampForTheNextAllTransactionsApiCall = nextApiCallTimestamp

          state.financialData = {
            ...state.financialData,
            allTransactions: action.payload
          }
        } else {
          state.loading = false
          state.timestampForTheNextAllTransactionsApiCall = 0
        }
      })
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.loading = false
        state.timestampForTheNextAllTransactionsApiCall = 0
        state.error = action.payload
      })

      // GET ALL CREDIT INFO DATA
      // This includes balances, available cash, credit limit etc
      // We dont want to block the UI for this ie we won't set loading to true
      .addCase(getCreditInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialData = {
            ...state.financialData,
            creditInfo: action.payload.creditInfo
          }
        }
      })
      .addCase(getCreditInfo.rejected, (state, action) => {
        state.error = action.payload
      })
  }
})

export const { updateCreditApplicationStatus } = financesSlice.actions

export const selectFinances = (state) => state.finances.financialData
export const selectAllTransactions = (state) => state.finances.financialData.allTransactions

export const selectIsPaused = (state) => get(state, 'finances.isPaused', false)
export const selectLoadingState = (state) => get(state, 'finances.loading', false)
export const selectErrorState = (state) => get(state, 'finances.error', null)

export default financesSlice.reducer
