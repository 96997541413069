import React, { useEffect, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { format, parse, isValid } from 'date-fns'

import { Card, TextInput } from '@fielded/shared-ui'

import 'react-day-picker/dist/style.css'

const DayPickerInput = ({
  inputId,
  dateFormat,
  disabled,
  weekStartsOn,
  callbackFunction,
  initialValue,
  inputName,
  readonly,
  disabledInput,
  required,
  placeholder
}) => {
  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(new Date())

  // Hold the selected date in state
  const [selectedDate, setSelectedDate] = useState()

  // Hold the input value in state
  const [inputValue, setInputValue] = useState(initialValue || '')
  const [pickerVisible, setPickerVisible] = useState(false)

  const inputContainerRef = useRef()

  const handleDayPickerSelect = (date) => {
    if (!date) {
      setInputValue('')
      setSelectedDate()
    } else {
      setSelectedDate(date)
      setMonth(date)
      setInputValue(format(date, dateFormat))
    }

    callbackFunction(date)
    setPickerVisible(false)
  }

  const handleInputChange = (e) => {
    if (readonly) {
      return
    }

    const parsedDate = parse(e.target.value, dateFormat, new Date())
    setInputValue(parsedDate) // keep the input value in sync

    if (isValid(parsedDate)) {
      setSelectedDate(parsedDate)
      setMonth(parsedDate)
    } else {
      setSelectedDate(undefined)
    }

    callbackFunction(parsedDate)
  }

  const handleCloseDayPicker = (event) => {
    if (inputContainerRef.current && inputContainerRef.current.contains(event.target)) {
      return
    }
    setPickerVisible(false)
  }

  const registerBoundClose = () => {
    document.body.addEventListener('click', handleCloseDayPicker)
  }

  const unRegisterBoundClose = () => {
    document.body.removeEventListener('click', handleCloseDayPicker)
  }

  useEffect(() => {
    registerBoundClose()
    return () => {
      unRegisterBoundClose()
    }
  }, [pickerVisible])

  return (
    <div className='vs-day-picker-input__wrapper' ref={inputContainerRef}>
      <label htmlFor={inputId} className='visually-hidden'>
        <strong>Date: </strong>
      </label>
      <TextInput
        className='vs-day-picker-input__input'
        type='text'
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setPickerVisible(true)}
        name={inputName}
        readonly={readonly}
        disabled={disabledInput}
        required={required}
        placeholder={placeholder || ''}
      />
      {pickerVisible && (
        <Card className='vs-day-picker-input__picker'>
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            mode='single'
            selected={selectedDate}
            onSelect={handleDayPickerSelect}
            disabled={disabled}
            weekStartsOn={weekStartsOn}
          />
        </Card>
      )}
    </div>
  )
}

export default DayPickerInput
