import React from 'react'
import set from 'lodash/set'

import getFirebaseConfig from './config-firebase'
import roles from '../roles'
import { BarChart, ExclamationCircle, Home, InfoCircle, Landmark, ListOl, Settings, Truck } from '@fielded/shared-ui/src/icons'

import SLTermsOfService from './terms/SLTermsOfService'
import SLCustomerAgreement from './terms/SLCustomerAgreement'
import SLLegalAndPrivacy from './terms/SLLegalAndPrivacy'

export default function getApplicationConfig (location) {
  // this function is likely to be called with the same location parameter many
  // times, so we memoize the result instead of recomputing every time
  getApplicationConfig[location] = getApplicationConfig[location] || (() => {
    const config = {
      ...commonConfig(),
      ...perTenantConfig(location),
      ...perTenantAndEnvConfig(location),
      firebase: getFirebaseConfig(location)
    }

    // This is just used to activate some things in SL staging/dev and not prod:
    Object.keys(config.environmentExtras || {}).forEach(key => {
      set(config, key, config.environmentExtras[key])
    })

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_CONFIG_OVERRIDES) {
      let overrides
      try {
        overrides = JSON.parse(process.env.REACT_APP_CONFIG_OVERRIDES)
      } catch (err) {
        console.warn('Error parsing config overrides in enviroment, skipping.')
      }
      Object.assign(config, overrides)

      config.performanceLogging = !!process.env.POUCHDB_PERF
    }

    if (Array.isArray(config.databases)) {
      config.databases = config.databases.map((db) => {
        const withUpdates = {
          ...db,
          idEndpoint: (config.useDocDispenser ? config.cacheEndpoint : config.indicatorsEndpoint) + '/ids',
          docEndpoint: config.cacheEndpoint + '/docs',
          useDocDispenser: config.useDocDispenser,
          syncOnId: db.hasIdDispenser && !config.disableSyncOnId
        }
        delete withUpdates.hasIdDispenser
        return withUpdates
      })
    }

    return config
  })()
  return getApplicationConfig[location]
}

export const defaultNavigationItems = {
  home: {
    path: '/',
    title: 'Home',
    icon: <Home />
  },
  alerts: {
    path: '/alerts/',
    title: 'Alerts',
    icon: <ExclamationCircle />
  },
  analytics: {
    path: '/analytics/',
    title: 'Analytics',
    icon: <BarChart />
  },
  information: {
    path: '/information/',
    title: 'Information',
    icon: <InfoCircle />
  },
  reports: {
    path: '/reporting/',
    title: 'Stock reports',
    icon: <ListOl />
  },
  shipments: {
    path: '/shipments/',
    title: 'Shipments',
    icon: <Truck />,
    // entityName is used in sentences that reference the main entity of this subapp,
    // for instance: 'Go to shipment'.
    // TODO: this might not be strictly tied to navigation, consider another place for this
    entityName: 'shipment'
  },
  settings: {
    path: '/settings/',
    title: 'Administration',
    icon: <Settings />
  },
  orders: {
    path: '/orders/',
    title: 'Orders',
    icon: <ListOl />
  }
}

const TENANT_PSM = 'psm'
const TENANT_SHELFLIFE = 'shelflife'
const TENANT_MEDBURY = 'medbury'

const ENV_LOCAL = 'local'
const ENV_DEV = 'dev'
const ENV_DEV_LOCAL_DJANGO = 'dev-local-django'
const ENV_STAGING = 'staging'
const ENV_PRODUCTION = 'production'
const ENV_PREVIEW = 'preview'

function commonConfig () {
  const origin = window.location.origin
  return {
    dateFormats: {
      long: 'd LLL, yyyy',
      week: 'RRRR-[I]II'
    },
    enableLocalPersistence: false,
    disableSyncOnId: false,
    useDocDispenser: false,
    navigationItems: defaultNavigationItems,
    destroyDataOnLogout: true,
    remoteDbUrl: `${origin}/db/`,
    indicatorsEndpoint: `${origin}/api`,
    cacheEndpoint: `${origin}/cache`,
    agaveApiUrl: `${origin}/agave/v1`,
    restApiUrl: `${origin}/avocado/`
  }
}

const HOST_MAP = new Map([
  // To deploy to a demo S3 bucket, add something like:
  // 'somefeature.demo.field.supply': [TENANT_VAN, ENV_DEV],
  //
  // To test the application on a device in your local network, start the
  // application setting `HOST=0.0.0.0 npm start` and and add your local network
  // IP here like:
  // ['192.168.178.25:3000', [TENANT_PSM, ENV_DEV]],
  //
  // PSM
  ['local.psm.localhost:3000', [TENANT_PSM, ENV_LOCAL]],
  ['dev.psm.localhost:3000', [TENANT_PSM, ENV_DEV]],
  ['dev.nscip.field.supply', [TENANT_PSM, ENV_DEV]],
  ['staging.psm.localhost:3000', [TENANT_PSM, ENV_STAGING]],
  ['staging.nscip.field.supply', [TENANT_PSM, ENV_STAGING]],
  ['nscip.field.supply', [TENANT_PSM, ENV_PRODUCTION]],
  ['healthlmis.ng', [TENANT_PSM, ENV_PRODUCTION]],
  // ['prod.psm.localhost:3000', [TENANT_PSM, ENV_PRODUCTION]],
  // SHELFFLIFE
  ['local.shelflife.localhost:3000', [TENANT_SHELFLIFE, ENV_LOCAL]],
  ['dev.shelflife.localhost:3000', [TENANT_SHELFLIFE, ENV_DEV]],
  ['dev-local-django.shelflife.localhost:3000', [TENANT_SHELFLIFE, ENV_DEV_LOCAL_DJANGO]],
  ['staging.shelflife.localhost:3000', [TENANT_SHELFLIFE, ENV_STAGING]],
  ['dev.shelflife.field.supply', [TENANT_SHELFLIFE, ENV_DEV]],
  ['staging.shelflife.field.supply', [TENANT_SHELFLIFE, ENV_STAGING]],
  ['shelflife.field.supply', [TENANT_SHELFLIFE, ENV_PRODUCTION]],
  // ['prod.shelflife.localhost:3000', [TENANT_SHELFLIFE, ENV_PRODUCTION]],
  // MEDBURRY
  ['local.medbury.localhost:3000', [TENANT_MEDBURY, ENV_LOCAL]],
  ['dev.medbury.localhost:3000', [TENANT_MEDBURY, ENV_DEV]],
  ['dev-local-django.medbury.localhost:3000', [TENANT_MEDBURY, ENV_DEV_LOCAL_DJANGO]],
  ['staging.medbury.localhost:3000', [TENANT_MEDBURY, ENV_STAGING]],
  ['dev.medbury.field.supply', [TENANT_MEDBURY, ENV_DEV]],
  ['staging.medbury.field.supply', [TENANT_MEDBURY, ENV_STAGING]],
  ['medbury.field.supply', [TENANT_MEDBURY, ENV_PRODUCTION]],
  // Pull request previews
  [/^pr-\d+\.psm.preview.field.supply/, [TENANT_PSM, ENV_PREVIEW]],
  [/^pr-\d+\.shelflife.preview.field.supply/, [TENANT_SHELFLIFE, ENV_PREVIEW]],
  [/^pr-\d+\.medbury.preview.field.supply/, [TENANT_MEDBURY, ENV_PREVIEW]]
])

function parseHref (href) {
  const url = new window.URL(href)
  for (const [key, value] of HOST_MAP) {
    if (key instanceof RegExp) {
      if (key.test(url.host)) {
        return value
      }
    }
    if (key === url.host) {
      return value
    }
  }
  throw new Error(`Could not resolve config values for "${url.host}".`)
}

const localConfig = {
  'remoteDbUrl': 'http://localhost:9984/',
  'agaveApiUrl': 'http://localhost:3022/v1',
  disableSyncOnId: true,
  'environmentExtras': {
    'features.allowResetPass': true,
    'features.showEnvBanner': true
  }
}

const psmEnvConfig = {
  [ENV_LOCAL]: {
    ...localConfig,
    'environmentExtras': {
      ...localConfig.environmentExtras,
      'features.showCountSyncBanner': true,
      'features.longhaul': true,
      'features.lastMileDeliveries': true,
      'features.showDriverDeliveryView': true,
      'features.showFacilityView': true,
      'features.showShipmentSyncBanner': true,
      'features.usePGShipments': true,
      'features.autoPopulateReceived': true,
      'features.routePlanning': true
    }
  },
  [ENV_DEV]: {
    useDocDispenser: true,
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.showEnvBanner': true,
      'features.showCountSyncBanner': true,
      'features.topUpOrdering': true,
      'features.routesAdmin': true,
      'features.longhaul': true,
      'features.lastMileDeliveries': true,
      'features.showDriverDeliveryView': true,
      'features.showFacilityView': true,
      'features.showShipmentSyncBanner': true,
      'features.usePGShipments': true,
      'features.autoPopulateReceived': true,
      'features.routePlanning': true
    },
    mapbox: {
      mapboxAccessToken: 'pk.eyJ1IjoiZmllbGRtYWNoaW5lIiwiYSI6ImNseGFhNW54eDJ1b2kyanM5cnY4NDJyOHMifQ.nKNSPMzNblbM8gCF39Z38g'
    }
  },
  [ENV_PREVIEW]: {
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.showEnvBanner': true,
      'features.analyticsOFR': true,
      'features.showCountSyncBanner': true,
      'features.topUpOrdering': true,
      'features.routesAdmin': true,
      'features.longhaul': true,
      'features.lastMileDeliveries': true,
      'features.showDriverDeliveryView': true,
      'features.showFacilityView': true,
      'features.showShipmentSyncBanner': true,
      'features.usePGShipments': true,
      'features.autoPopulateReceived': true,
      'features.routePlanning': true
    },
    mapbox: {
      mapboxAccessToken: 'pk.eyJ1IjoiZmllbGRtYWNoaW5lIiwiYSI6ImNseGFhNW54eDJ1b2kyanM5cnY4NDJyOHMifQ.nKNSPMzNblbM8gCF39Z38g'
    }
  },
  [ENV_STAGING]: {
    useDocDispenser: true,
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.showEnvBanner': true,
      'features.showCountSyncBanner': true,
      'features.topUpOrdering': true,
      'features.usePGShipments': true,
      // TODO: Remove when finished, don't merge!
      'features.routePlanning': true
    },
    mapbox: {
      mapboxAccessToken: 'pk.eyJ1IjoiZmllbGRtYWNoaW5lIiwiYSI6ImNseGFhNW54eDJ1b2kyanM5cnY4NDJyOHMifQ.nKNSPMzNblbM8gCF39Z38g'
    }
  },
  [ENV_PRODUCTION]: {
    useDocDispenser: true,
    'sentryPublicKey': '5299231cb42f4b7895ed836e60a23d2d',
    'sentryProjectId': '1189860',
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.showCountSyncBanner': true,
      'features.topUpOrdering': true,
      'features.routesAdmin': false,
      'features.usePGShipments': true
    }
  }
}

const shelflifeEnvConfig = {
  [ENV_LOCAL]: {
    ...localConfig,
    // set this only for SL, PSM does not use Django
    'restApiUrl': 'http://localhost:8000/api/',
    'restTokenApiUrl': 'http://localhost:8000/api/',
    'agaveApiUrl': 'http://localhost:3022/v1',
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.shipments.allowFilterShipments': true,
      'feature.shipments.returnShipmentConfig': true,
      'features.shipments.showPackPointLedger': true,
      'features.payment.getPaymentMethod': true,
      'features.notifications.sendPlannerNotification': false,
      'features.planning.centralResupply': true,
      'features.orders.pickPaymentPlanUpfront': true,
      'features.orders.bulkOrders': true
    },
    paystack: {
      ke: {
        publicKey: 'pk_test_2980620a1c79aaab383931518a1e1292e008a9dd',
        minAuthAmount: 300
      },
      ng: {
        publicKey: 'pk_test_3564fafcd9c5a8b49e8c93ba8e220a2583d461d9',
        minAuthAmount: 100
      }
    }
  },
  [ENV_DEV]: {
    'restTokenApiUrl': 'https://dev.avocado.shelflife.field.supply/api/',
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.shipments.allowFilterShipments': true,
      'features.shipments.showPackPointLedger': true,
      'features.stockCount.adjustments': true,
      'features.stockCount.opensExistingReport': false,
      'features.showEnvBanner': true,
      'features.sendNewTopupEmailNotification': true,
      'feature.shipments.returnShipmentConfig': true,
      'features.notifications.testing': true,
      'features.payment.getPaymentMethod': true,
      'features.notifications.sendPlannerNotification': false,
      'features.planning.centralResupply': true,
      'features.orders.pickPaymentPlanUpfront': true,
      'features.orders.bulkOrders': true,
      'navigationItems.finances': {
        path: '/retailer/finances/',
        title: 'Finances',
        icon: <Landmark />,
        entityName: 'finance'
      }
    },
    googleAnalytics: {
      g4Id: 'G-GKXEFY5CHX'
    },
    paystack: {
      ke: {
        publicKey: 'pk_test_2980620a1c79aaab383931518a1e1292e008a9dd',
        minAuthAmount: 300
      },
      ng: {
        publicKey: 'pk_test_3564fafcd9c5a8b49e8c93ba8e220a2583d461d9',
        minAuthAmount: 100
      }
    },
    causalFoundry: {
      apiKey: 'cfkeyOMn292x8eNn1oZQg2od4Ksv3R29aHqxMaggq0Xv4Tyzz2Nlhjnw0SY6pcNG'
    }
  },
  [ENV_PREVIEW]: {
    'restTokenApiUrl': 'https://dev.avocado.shelflife.field.supply/api/',
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.shipments.allowFilterShipments': true,
      'features.shipments.showPackPointLedger': true,
      'features.stockCount.adjustments': true,
      'features.stockCount.opensExistingReport': false,
      'features.showEnvBanner': true,
      'feature.shipments.returnShipmentConfig': true,
      'features.notifications.testing': true,
      'features.payment.getPaymentMethod': true,
      'features.notifications.sendPlannerNotification': false,
      'features.planning.centralResupply': true,
      'features.orders.pickPaymentPlanUpfront': true,
      'features.orders.bulkOrders': true,
      'navigationItems.finances': {
        path: '/retailer/finances/',
        title: 'Finances',
        icon: <Landmark />,
        entityName: 'finance'
      }
    },
    googleAnalytics: {
      g4Id: 'G-GKXEFY5CHX'
    },
    paystack: {
      ke: {
        publicKey: 'pk_test_2980620a1c79aaab383931518a1e1292e008a9dd',
        minAuthAmount: 300
      },
      ng: {
        publicKey: 'pk_test_3564fafcd9c5a8b49e8c93ba8e220a2583d461d9',
        minAuthAmount: 100
      }
    },
    causalFoundry: {
      apiKey: 'cfkeyOMn292x8eNn1oZQg2od4Ksv3R29aHqxMaggq0Xv4Tyzz2Nlhjnw0SY6pcNG'
    }
  },
  [ENV_DEV_LOCAL_DJANGO]: {
    'remoteDbUrl': 'https://dev.shelflife.field.supply/db/',
    // see docs/Django-Web-Authentication, you'll have to make sure
    // your local_settings.py considers all requests authenticated.
    'restApiUrl': 'http://localhost:8000/api/',
    'restTokenApiUrl': 'https://dev.avocado.shelflife.field.supply/api/',
    'environmentExtras': {
      'navigationItems.finances': {
        path: '/retailer/finances/',
        title: 'Finances',
        icon: <Landmark />,
        entityName: 'finance'
      },
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.stockCount.adjustments': true,
      'features.stockCount.opensExistingReport': false
    }
  },
  [ENV_STAGING]: {
    'restTokenApiUrl': 'https://staging.avocado.shelflife.field.supply/api/',
    'environmentExtras': {
      'features.allowResetPass': true,
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.shipments.allowFilterShipments': true,
      'features.shipments.showPackPointLedger': true,
      'features.stockCount.adjustments': true,
      'features.stockCount.opensExistingReport': false,
      'feature.shipments.returnShipmentConfig': true,
      'features.showEnvBanner': true,
      'features.notifications.sendPlannerNotification': false,
      'features.planning.centralResupply': true,
      'features.orders.pickPaymentPlanUpfront': true,
      'navigationItems.finances': {
        path: '/retailer/finances/',
        title: 'Finances',
        icon: <Landmark />, // TODO
        entityName: 'finance'
      }
    },
    googleAnalytics: {
      g4Id: 'G-4NCK33D5PF'
    },
    paystack: {
      ke: {
        publicKey: 'pk_test_2980620a1c79aaab383931518a1e1292e008a9dd',
        minAuthAmount: 300
      },
      ng: {
        publicKey: 'pk_test_3564fafcd9c5a8b49e8c93ba8e220a2583d461d9',
        minAuthAmount: 100
      }
    },
    causalFoundry: {
      apiKey: 'cfkeyOMn292x8eNn1oZQg2od4Ksv3R29aHqxMaggq0Xv4Tyzz2Nlhjnw0SY6pcNG'
    }
  },
  [ENV_PRODUCTION]: {
    'restTokenApiUrl': 'https://avocado.shelflife.field.supply/api/',
    'sentryPublicKey': '5299231cb42f4b7895ed836e60a23d2d',
    'sentryProjectId': '1189860',
    'environmentExtras': {
      'features.shipments.adjustments': true,
      'features.shipments.createNewShipment': true,
      'features.stockCount.adjustments': true,
      'features.shipments.allowFilterShipments': true,
      'features.stockCount.opensExistingReport': false,
      'features.allowResetPass': true,
      'features.shipments.showPackPointLedger': true,
      'features.notifications.sendPlannerNotification': true,
      'features.planning.centralResupply': true,
      'features.orders.pickPaymentPlanUpfront': true
    },
    googleAnalytics: {
      g4Id: 'G-GCKJ090L0C'
    },
    paystack: {
      ke: {
        publicKey: 'pk_live_19475a56a6731e12790bcdd77e355d25688f28b5',
        minAuthAmount: 300
      },
      ng: {
        publicKey: 'pk_live_8870fa046b38241f32766d9ff198a8f460120c07',
        minAuthAmount: 100
      }
    },
    causalFoundry: {
      apiKey: 'cfkeyYyzWMOVmmsEgOctzTUsskd7eTa3aaBDFiRATCiBlHjEOpTmDUO7XICrivLU'
    }
  }
}

// this map contains configuration values that are unique for each tenant
// and environment combo
const PER_TENANT_AND_ENV = {
  [TENANT_PSM]: psmEnvConfig,
  [TENANT_SHELFLIFE]: shelflifeEnvConfig,
  [TENANT_MEDBURY]: shelflifeEnvConfig
}

function perTenantAndEnvConfig (location) {
  const [tenant, env] = parseHref(location)
  return {
    ...PER_TENANT_AND_ENV[tenant][env],
    env
  }
}

const getRoles = (roles = {}, selectedRoles = []) => {
  if (selectedRoles.length) {
    return selectedRoles.map(role => roles[role])
  }
  return Object.keys(roles).reduce((allRoles, role) => ([
    ...allRoles,
    roles[role]
  ]), [])
}

const longInterval = 1000 * 60 * 4 // four minutes
const extraLongInterval = 1000 * 60 * 10 // ten minutes

/**
 * Configure roles for tenants.
 *
 * Roles are defined at the 'sub-app' level.
 *
 * Each 'sub-app' should define an array of available
 * roles for the given app as the default export from a `roles.js` file located
 * in the root of the subapp (and respective `build-lib/` directory).
 *
 * We then take care of importing roles from the various subapps in the
 * `role.js` file found in this repo to construct different role-sets for each
 * vertical.
 */

const allowedPSMSettingsRoles = ['admin', 'facilities', 'facilityApprovals', 'facilityChanges', 'facilityReadonly', 'products', 'productsReadonly',
  'users', 'funders', 'routes', 'createPrograms', 'onlineOffline', 'ordersRequester', 'ordersWarehouse', 'ordersBulk', 'ordersReset', 'historicDataEntry', 'forecasting', 'planning', 'approveLonghaul', 'routePlanning']
const psmRoles = [
  ...getRoles(roles.settings, allowedPSMSettingsRoles),
  ...getRoles(roles.qcAlerts),
  {
    ...roles.stockReporting.stockReporting
  },
  /**
   * the reason we're not using any stock-count prefix is
   * to avoid disabling reporting for anyone with that base role
   */
  {
    role: 'feature:read-only-reports',
    displayName: 'Reports: ReadOnly',
    description: 'User can only see the confirmation summary page and not the products and reports page after clicking on location'
  },
  {
    ...roles.shipments.shipments,
    description: 'User can create and edit shipments'
  },
  {
    ...roles.shipments.globalShipments
  },
  {
    ...roles.shipments.lastMileDeliveries
  }
]

const shelflifeRoles = [
  {
    ...roles.stockReporting.stockReporting,
    displayName: 'Counts'
  },
  {
    ...roles.stockReporting.stockReportingAddProduct
  },
  {
    ...roles.shipments.shipments,
    displayName: 'Deliveries'
  },
  {
    ...roles.settings.admin,
    displayName: 'Admin'
  },
  {
    ...roles.settings.productsReadonly
  },
  {
    role: 'feature:read-only-reports',
    displayName: 'Counts: ReadOnly',
    description: 'User can only see the confirmation summary page and not the products and reports page after clicking on location'
  },
  {
    role: 'feature:settings:facilities:offline-edits',
    displayName: 'Offline Location Editing',
    description: 'User can propose and edit locations while offline'
  },
  {
    role: 'feature:settings:planning-calculator',
    displayName: 'Planning Calculator',
    description: 'User can trigger and also see status of planning calculator'
  },
  {
    ...roles.settings.superProductAdmin
  }
]

const psmTenantConfig = {
  name: 'Nigeria Health Logistics Management Information System',
  shortName: 'NHLMIS',
  program: 'Managed by Federal Ministry of Health',
  title: 'NHLMIS',
  unit: 'Federal Republic of Nigeria',
  logoUrl: `${window.location.origin}/psm/images/coat-of-arms-nigeria.png`,
  theme: 'psm',
  app: 'psm',
  appBase: 'psm',
  reportingPeriod: 'bimonthly',
  forecastSource: 'allocation',
  features: {
    analytics: true,
    analyticsOFR: true,
    forecasting: true,
    funders: true,
    routes: true,
    qcAlerts: true,
    locations: {
      levels: {
        ng: ['national', 'zone', 'state', 'lga', 'sdp']
      }
    },
    information: true,
    settings: {
      facilities: {
        approvals: true,
        bufferDays: 16,
        addOptions: {
          version: '2.0.0'
        },
        exportImport: {
          locations: true
        },
        editPhysicalAddress: true,
        editWard: true,
        selectGeoLocationLevel: 'lga',
        showGeoLocationChildren: true // Show only Geolocation location Children for state users
      },
      funders: {
        edit: true,
        noun: 'funder',
        addOptions: {
          version: '1.0.0'
        },
        showProgram: true
      },
      routes: {
        edit: true,
        noun: 'route',
        addOptions: {
          version: '1.0.0'
        },
        showProgram: true
      },
      programs: true, // This is to show the programs overview and create/edit programs option
      users: true,
      products: {
        exportImport: {
          options: {
            exclude: ['productType', 'maxPrice', 'buyPrice', 'vat'],
            additionalColumns: {
              tracer: 'Tracer',
              genericParent: 'Generic Parent',
              genericFactor: 'Generic Factor',
              unitOfIssue: 'Unit of Issue',
              unitOfReporting: 'Unit of Reporting',
              manufacturer: 'Manufacturer',
              alias: {
                title: 'ONE Item Ids',
                transform: product => product.alias && product.alias.one
                  ? product.alias.one.join(',')
                  : ''
              },
              unitWeight: 'Unit Weight',
              unitVolume: 'Unit Volume',
              unitPrice: 'Unit Price',
              commodityType: 'Commodity Type'
            }
          }
        },
        editCode: true,
        editPrice: true,
        productType: false,
        editStorageType: true,
        productTypeOptions: [],
        storageTypeOptions: [
          {
            label: 'Non Cold Chain',
            value: 'non-cold-chain'
          },
          {
            label: 'Cold Chain',
            value: 'cold-chain'
          }
        ],
        basicUnitOptions: [
          {
            label: 'Doses',
            value: 'doses'
          },
          {
            label: 'Units',
            value: 'units'
          },
          {
            label: 'Vials',
            value: 'vials'
          },
          {
            label: 'Patient',
            value: 'patient'
          }
        ],
        showTracer: true,
        validateAlias: true,
        updateConfiguration: true
      }
    },
    stockCount: {
      realtime: false,
      // This feature implies we need to check if there was a previous opening balance:
      editOpeningBalance: true,
      disableProductsNotInUse: true,
      reportDueDates: true,
      showLgaOnLocationListSearch: true,
      historicalDatePicker: true // dropdown option of reporting cycles back to 2016
    },
    intercom: false,
    stockReportExport: true,
    facilityExportLMD: true,
    shipments: {
      ...defaultNavigationItems.shipments,
      title: 'Shipments',
      entityName: 'shipment',
      import: true,
      listProductQuantities: true,
      removeShipment: true,
      rescheduleShipment: true,
      listInGeoLocation: true,
      // This makes the shipment UI only use the list view
      // readOnlyShipments: true,
      editPSMShipment: true,
      // this puts the "Delete shipment" button on the list view
      removeShipmentInOverview: true,
      showDeliveryNote: true
    },
    order: {
      appName: 'Orders',
      appEntityName: 'Order'
    }
  },
  databases: [
    {
      name: 'stock-count',
      bidirectional: true,
      hasIdDispenser: true,
      pollInterval: extraLongInterval,
      requiredRoles: [
        'feature:orders:warehouse',
        'feature:stock-reporting',
        'feature:read-only-reports'
      ]
    },
    {
      name: 'integrated-data',
      hasIdDispenser: true,
      pollInterval: extraLongInterval
    },
    {
      name: 'alerts',
      bidirectional: true,
      hasIdDispenser: true,
      pollInterval: extraLongInterval,
      requiredRole: 'feature:qc-alerts'
    },
    {
      name: 'master-data',
      bidirectional: true,
      requiredRoles: [
        'feature:stock-reporting',
        'feature:orders:warehouse',
        'feature:orders:requester',
        'feature:global-shipments'
      ]
    },
    {
      name: 'van-shipments',
      bidirectional: true,
      hasIdDispenser: true,
      pollInterval: extraLongInterval,
      // if the user has any of these roles, we sync shipments
      requiredRoles: [
        'feature:orders:warehouse',
        'feature:shipments',
        'feature:stock-reporting'
      ]
    }
  ],
  roles: [
    ...psmRoles
  ]
}

const shelflifeTenantConfig = {
  shortName: 'Shelf Life',
  name: 'Shelf Life',
  program: 'Powered by Field Supply',
  title: 'Field Supply',
  theme: 'shelflife',
  app: 'shelflife',
  appBase: 'shelflife',
  reportingPeriod: 'weekly',
  navigationItems: {
    ...defaultNavigationItems,
    reports: {
      ...defaultNavigationItems.reports,
      title: 'Counts'
    },
    shipments: {
      ...defaultNavigationItems.shipments,
      title: 'Deliveries',
      entityName: 'delivery'
    }
  },
  forecastSource: 'smartForecast',
  features: {
    sync: {
      alwaysUseOnlineOffline: true
    },
    termsAndConditions: {
      version: '1.0.0',
      documents: [
        {
          title: 'User Terms of Service',
          shortTitle: 'User Terms',
          description: 'the User Terms',
          path: '/user-terms-and-conditions',
          component: SLTermsOfService
        },
        {
          title: 'Customer Agreement',
          shortTitle: 'Customer Agreement',
          description: 'Customer Agreement',
          path: '/customer-agreement',
          component: SLCustomerAgreement,
          constraints: ['isRetailer']
        },
        {
          title: 'Legal and Privacy',
          shortTitle: 'Privacy and Policy',
          description: 'the Privacy and Policy',
          path: '/legal-and-privacy',
          component: SLLegalAndPrivacy
        }
      ]
    },
    shipments: {
      addProduct: {
        showProductCode: true,
        syncWithAllocation: true // this only works for sent shipments and for directOrder allocation method for now
      },
      useCRSalesStats: true,
      removeShipment: true,
      showPlanningType: true,
      listInGeoLocation: true,
      routeSpecificUsersHideNewShipments: true,
      showDeliveryNote: true,
      showFunder: true,
      adjustments: false, // set to true for dev, staging via environmentExtras,
      createNewShipment: true,
      planning: true, // used for SL shipments planning
      useProductTranslation: true // used for SL products translation between markets
    },
    routes: true,
    locations: {
      levels: {
        ng: ['country', 'state', 'sdp', 'supplier'],
        ke: ['country', 'state', 'sdp', 'supplier']
      },
      includeRouteInLocationDoc: true
    },
    showVAT: {
      ng: true,
      ke: true
    },
    settings: {
      shelflife: true,
      facilities: {
        approvals: false,
        // SL needs to enable facilities for the beginning of their reporting period.
        useLastDayOfPreviousPeriod: true,
        // creating new/update facility options
        addOptions: {
          version: '2.0.0',
          supportCountry: true
        },
        selectGeoLocationLevel: 'state',
        products: {
          subscription: true,
          manageSubscriptions: true,
          hideMaxPrice: true
        },
        exportImport: {
          allocation: {
            createShipment: true
          }
        },
        // This is to limit selecting services for SL locations
        // to be one per location, as SL services are currently 1:1 to a geolocation
        // (FCT, Lagos, Nasarawa, Kenya)
        // Having more than one service caused a bug in the stock report export
        oneServicePerLocation: true,

        // Migrating off google sheets for locations editing meant introducting 20+
        // fields to locations. Fields in this list are rendered with basic
        // UI elements (text input, number input), so anything with more custom
        // UI would come out of this list.
        additionalFields: true
      },
      routes: {
        edit: true,
        noun: 'route',
        addOptions: {
          version: '1.0.0'
        },
        showOnUsersTable: true, // Show routes column on Users Table
        filterByServiceLocation: true
      },
      users: {
        // this is to show the "create pharmacy users" button,
        // which leads to a different add/edit container for a pharamcy user
        // that has a different backend setup. SL only to start.
        createPharmacyUsers: true
      },
      planningCalculator: true,
      products: {
        editCode: true,
        editPrice: true,
        editBuyPrice: true,
        editMaxPrice: true,
        showPrices: true,
        editVAT: true,
        // These regexes are re-used, do not use global flags
        // https://geekingfrog.com/blog/post/reuse-javascript-regexp-global-flag-gotcha
        validations: [
          {
            field: 'code',
            regex: /^[0-9]+$/,
            sample: '"12345" (numbers only)'
          },
          {
            field: '_id',
            regex: /^product:[0-9]+$/,
            sample: 'product:12345'
          }
        ],
        filterByName: true,
        productType: true,
        productTypeOptions: [
          {
            label: 'Diluent',
            value: 'diluent'
          },
          {
            label: 'Dry',
            value: 'dry'
          },
          {
            label: 'Vaccine',
            value: 'vaccine'
          }
        ],
        basicUnitOptions: [
          {
            label: 'Doses',
            value: 'doses'
          },
          {
            label: 'Units',
            value: 'units'
          },
          {
            label: 'Vials',
            value: 'vials'
          }
        ]
      }
    },
    shelflife: true,
    stockCount: {
      downloadShipments: true,
      realtime: true,
      singleProductLine: true,
      showLocationContact: true,
      opensExistingReport: true,
      opensFutureReport: true,
      excludeProducts: 'configuration',
      adminOnlyHistory: true,
      reportDueDates: false,
      // show warning text when the opening balance is lower than the
      // closing balance
      dislayOpeningQuantityIssue: true,
      showStatementsDownload: true,
      adjustments: false,
      hideUnavailableProducts: true
    },
    order: {
      sl: true,
      appName: 'Planning',
      appEntityName: 'Plan',
      disableBulk: true
    },
    intercom: {
      appId: 'xqmandoq'
    },
    notifications: {
      enabled: true,
      retryInterval: 1
    }
  },
  programs: [
    'program:shelflife'
  ],
  databases: [
    {
      name: 'documents',
      requiredRole: 'feature:settings:facilities:offline-edits',
      bidirectionalRequiredRole: 'feature:settings:facilities:offline-edits',
      hasIdDispenser: true,
      pollInterval: extraLongInterval
    },
    {
      name: 'integrated-data',
      bidirectionalRequiredRole: 'feature:settings:facilities:offline-edits',
      hasIdDispenser: true,
      pollInterval: extraLongInterval
    },
    {
      name: 'van-shipments',
      bidirectional: true,
      requiredRole: 'feature:shipments',
      hasIdDispenser: true,
      pollInterval: longInterval
    },
    {
      name: 'stock-count',
      bidirectional: true,
      hasIdDispenser: true,
      pollInterval: extraLongInterval
    },
    {
      name: 'ledger-balances',
      hasIdDispenser: true,
      pollInterval: extraLongInterval
    }
    /*
     * Disabling because we were getting van doc on SL/Forth DB for the below DBs.
     * For this to work we need to deploy separate lambda functions for SL to avoid
     * cross domain weird syncing which is not safe (i can easily update our config.js
     * with another db link and they will start to get our data 🙃)
    {
      name: 'master-data',
      bidirectional: true
    },
    {
      name: 'alerts',
      bidirectional: false,
      pollInterval: alertsPollInterval
    }
    */
  ],
  roles: [
    ...shelflifeRoles
  ]
}

// this map contains configuration values that differ for each tenant, but
// stay the same for each deployment environment
// check the values in corresponding consts above above
const PER_TENANT = {
  [TENANT_PSM]: psmTenantConfig,
  [TENANT_SHELFLIFE]: shelflifeTenantConfig,
  [TENANT_MEDBURY]: {
    ...shelflifeTenantConfig,
    shortName: 'Medbury',
    name: 'Medbury',
    abbr: 'MDBY',
    program: 'Powered by Shelf Life',
    title: 'Medbury powered by Shelf Life',
    theme: 'medbury',
    app: 'medbury',
    logoUrl: `${window.location.origin}/medbury/images/Medbury-Pharmacy.png`,
    logoUrlWhite: `${window.location.origin}/medbury/images/Medbury-Pharmacy-White.png`
  }
}

function perTenantConfig (location) {
  const [tenant] = parseHref(location)
  return PER_TENANT[tenant] || {}
}

export const DEFAULT_VAN_SERVICE = {
  id: 'program:immunization:service:immunization',
  program: {
    reportingPeriod: 'weekly',
    report: {
      periods: [{
        periodType: {
          unit: 'week',
          quantity: 1
        },
        entryPeriodRule: 'current-period'
      }]
    }
  }
}
