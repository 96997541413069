import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CSSTransition } from 'react-transition-group'

import Backdrop from '../Backdrop'
import Button from '../Button'
import Text from '../Text'
import { Arrow, Close } from '../../icons'

class BottomDrawer extends React.Component {
  componentDidMount () {
    document.addEventListener('keydown', this.handleEscape)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleEscape)
  }

  handleEscape = (event) => {
    if (event.key === 'Escape') {
      this.props.onClose()
    }
  }

  render () {
    const {
      children,
      isOpen,
      onClose,
      onGoBack,
      withGoBack,
      customGoBackText,
      customHeaderText,
      className,
      withMultiscreen,
      withScrollableSection,
      withActionBar,
      wide
    } = this.props

    return (
      <div>
        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames='fade'
          mountOnEnter
          unmountOnExit
        >
          <Backdrop onClose={onClose} />
        </CSSTransition>

        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames='slide-up'
          mountOnEnter
          unmountOnExit
        >
          <div className={classnames(
            'vs-bottom-drawer',
            {'vs-bottom-drawer--wide': wide},
            className
          )}>
            <div
              className={classnames(
                'vs-bottom-drawer__top-bar',
                {'vs-bottom-drawer__top-bar--spaced': withGoBack || customHeaderText}
              )}
            >
              {withGoBack &&
                <Button
                  icon={<Arrow />}
                  onClick={onGoBack}
                  colorVariant='dark'
                >
                  {customGoBackText || `Back`}
                </Button>
              }
              {customHeaderText && (
                <Text bold size='large'>{customHeaderText}</Text>
              )}
              <Button
                icon={<Close />}
                onClick={onClose}
                colorVariant='dark'
              >
                Close
              </Button>
            </div>

            <div className={classnames(
              'vs-bottom-drawer__content',
              {'vs-bottom-drawer__content--hidden-overflow': withMultiscreen},
              {'vs-bottom-drawer__content--no-padding': withScrollableSection}
            )}>
              {withScrollableSection ? (
                <div className={classnames(
                  'vs-bottom-drawer__scrollable-section',
                  {'vs-bottom-drawer__with-action-bar': withActionBar}
                )}>
                  {children}
                </div>
              ) : (
                <Fragment>{children}</Fragment>
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

BottomDrawer.propTypes = {
  /**
   * Current visibility
   */
  isOpen: PropTypes.bool,
  /**
   * Close callback
   */
  onClose: PropTypes.func,
  /**
   * Set this to true if the BottomDrawer has multiple screens. Adds overflow: hidden
   */
  withMultiscreen: PropTypes.bool,
  /**
   * Allows you to add custom text on single screens where "Go back" option would be
   */
  customHeaderText: PropTypes.string,
  /**
   * You can customize the text to go to previous screen
   */
  customGoBackText: PropTypes.string,
  /*
   * In case you want to extend the default class names without overriding the default look.
   */
  className: PropTypes.string
}

export default BottomDrawer
