import { createSlice } from '@reduxjs/toolkit'

import { getTimestampForNextApiCall } from '../../../../common/utils/redux-timestamp-call'

// 24h
const MINUTES_TO_ADD = 1440

const initialState = {
  timestampForTheNextApiCall: getTimestampForNextApiCall(MINUTES_TO_ADD),
  deviceId: ''
}

export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    timestampUpdate: (state) => {
      state.timestampForTheNextApiCall = getTimestampForNextApiCall(MINUTES_TO_ADD)
    },
    deviceIdUpdate: (state, action) => {
      state.deviceId = action.payload
    }
  }
})

export const { timestampUpdate, deviceIdUpdate } = userSessionSlice.actions
export const selectTimestampToNextSessionCall = (state) => state.userSession.timestampForTheNextApiCall
export const selectDeviceId = (state) => state.userSession.deviceId

export default userSessionSlice.reducer
