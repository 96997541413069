import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatDate, updateCurrentMasterData } from '../../../van-shared/utils'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'

import PlanningQuantities from './PlanningQuantities'
import {
  selectedProducts as getSelectedProducts,
  updateShipmentWithProducts,
  updateProductQuantity,
  updatePaymentType
} from '../../../root/reducers/shipments/planning'
import { headerLocationFor } from '../common/utils'
import { isReturnShipment } from '../../../common/utils/shipment'
import { Loading } from '@fielded/shared-ui'

// TODO redirect to the beginning of the planner if there is no destination
export class AddProductQuantityContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { loaded: false }
  }

  async componentDidMount () {
    const { api, shipment } = this.props
    let locationId = shipment.destination.id

    if (isReturnShipment(null, shipment)) {
      locationId = shipment.origin.id
    }
    const [location] = await api.location.getLocationsViaIds([locationId])
    const shipmentType = shipment.planningType
    const allocationConfig = await api.allocation.getConfiguration({ facilityId: locationId })
    this.setState({ allocationConfig, location, shipmentType, loaded: true })
  }

  onClickSave = () => {
    const {
      shipment,
      masterData,
      config
    } = this.props
    return this.props.updateShipmentWithProducts(shipment, masterData, config).then(shipment => {
      if (shipment.status === 'received') {
        return this.props.history.replace(`/shipments/adjustment/${shipment.snapshotId}`, { newChanges: true })
      }

      this.props.history.replace(`/shipments/pick-list/${shipment.snapshotId}`, { newChanges: true })
    })
  }

  handleQuantityChange = (productId, quantity) => {
    const {
      updateProductQuantity
    } = this.props
    updateProductQuantity(productId, quantity)
  }

  handlePaymentTypeChange = (productId, paymentType) => {
    const {
      updatePaymentType
    } = this.props
    updatePaymentType(productId, paymentType)
  }

  render () {
    const { loaded, allocationConfig, location, shipmentType } = this.state

    if (!loaded) {
      return <Loading loadingText='Loading ...' />
    }

    const {
      formattedDate,
      destination,
      selectedProducts,
      history,
      showPlanningNumbers,
      config
    } = this.props

    return (
      <PlanningQuantities
        destination={destination}
        products={selectedProducts}
        onQuantityChange={this.handleQuantityChange}
        setPaymentType={this.handlePaymentTypeChange}
        onGoBack={() => history.goBack()}
        onClickSave={this.onClickSave}
        title={`Enter quantities for shipment`}
        subTitle={`${destination}, from ${formattedDate} until now`}
        showPlanningNumbers={showPlanningNumbers}
        config={config}
        allocationConfig={allocationConfig}
        location={location}
        shipmentType={shipmentType}
      />
    )
  }
}

const mapStateToProps = (state, { match: { params } }) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  /*
   * NOTE: getSelectedProducts is using state from
   * the planning reducer
   * The view is expecting `setPlannerParams` to have been called
   * in the previous view, AddProductContainer
   */
  const selectedProducts = getSelectedProducts(newState.planning)
  const shipment = newState.shipments[params.snapshotId]

  return {
    formattedDate: formatDate(shipment.date),
    selectedProducts,
    shipment,
    masterData: newState.masterData,
    destination: headerLocationFor(shipment),
    showPlanningNumbers: (!shipment.shipmentType || shipment.shipmentType.id === 'routine')
  }
}

const mapDispatchToProps = {
  updateShipmentWithProducts,
  updateProductQuantity,
  updatePaymentType
}

export default withConfig(withApi(
  connect(mapStateToProps, mapDispatchToProps)(AddProductQuantityContainer)
))
