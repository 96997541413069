const SHIPMENT_STATUS = {
  NEW: 'new',
  PACKED: 'packed',
  SENT: 'sent',
  ARRIVED: 'arrived',
  RECEIVED: 'received'
  // DELAYED: 'delayed' // TODO: new label to be added
  // CANCELED: 'canceled' this status is not needed yet
}

const SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS = {
  [SHIPMENT_STATUS.NEW]: 'Scheduled',
  [SHIPMENT_STATUS.PACKED]: 'Packed',
  [SHIPMENT_STATUS.SENT]: 'In transit',
  [SHIPMENT_STATUS.ARRIVED]: 'Arrived',
  [SHIPMENT_STATUS.RECEIVED]: 'Received'
  // [SHIPMENT_STATUS.DELAYED]: 'Delayed' // TODO: new label to be added
}

const SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER = {
  ...SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS,
  [SHIPMENT_STATUS.RECEIVED]: 'Completed'
}

const SHIPMENT_ADVANCE_STATUSES = {
  new: SHIPMENT_STATUS.PACKED,
  packed: SHIPMENT_STATUS.SENT,
  sent: SHIPMENT_STATUS.ARRIVED,
  arrived: SHIPMENT_STATUS.RECEIVED
}

const SHIPMENT_GPS_FAIL_REASONS = {
  technical_dificulty: 'GPS or app technical dificulties',
  facility_request: 'Request from facility rep',
  access_restriction: 'Physical barriers, access restriction or outside business hours',
  location_closed: 'Delivery location was closed',
  safety_risk: 'Safety risk',
  traffic_conditions: 'Traffic or road conditions',
  permission_denied: 'Location permission was denied'
}

const SHIPMENT_OTP_FAIL_REASONS = {
  noPassword: 'I was not given any otp',
  noMatch: 'I was given the otp but it’s not working'
}

const SHIPMENT_PRODUCT_MARKETS = {
  origin_sku: 'originSKU',
  destination_sku: 'destinationSKU'
}

const SHIPMENT_VERSIONS = {
  VERSION_1: '1.0.0',
  VERSION_2: '2.0.0'
}

module.exports = {
  SHIPMENT_ADVANCE_STATUSES,
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS,
  SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS_FOR_DRIVER,
  SHIPMENT_GPS_FAIL_REASONS,
  SHIPMENT_OTP_FAIL_REASONS,
  SHIPMENT_PRODUCT_MARKETS,
  SHIPMENT_VERSIONS
}
