const api = require('./api')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const { decorateRouteWithDetails } = require('./tools/decorate-route-with-details')

const rawMethods = {
  save: api.save,
  get: api.get,
  list: api.list,
  bulkImport: api.bulkImport
}

const RoutePouchAdapter = require('./data-access/route-pouch-adapter')
const isRouteEditable = require('./tools/is-route-editable')
const RoutePGDataAdapter = require('./data-access/route-pg-data-adapter')
const toRelationalModel = require('./tools/to-relational-model')

class RoutesApi extends EntityApi {
  constructor (state, agaveAdapter, logger, pgConnection, mainApi) {
    const { user, routesDB } = state
    const adapter = new RoutePouchAdapter(user, routesDB)
    super(adapter)

    this.agaveAdapter = agaveAdapter

    if (pgConnection) {
      this.pgDataAdapter = new RoutePGDataAdapter(
        pgConnection,
        user.name,
        logger
      )
    }

    this.mainApi = mainApi

    // TODO: remove this when all raw methods have been removed
    const apiMethods = wrapEntityApi(rawMethods, state)
    Object.assign(this, apiMethods)
  }

  listDeliveryDates (service, refDate) {
    return this.adapter.listDeliveryDates(service, refDate)
  }

  listCountDates (service, refDate) {
    return this.adapter.listCountDates(service, refDate)
  }

  /**
   * Getting the details of the route with all the related entities.
   * @param {Object} routeId - The id of the route.
   * @returns {Object} Route object decorated with shipments, locations and funders.
   */
  async getRouteDetails (routeId) {
    if (!routeId) {
      console.error('Cannot get route details: no routeId provided')
      return
    }
    try {
      const route = await this.get(routeId)

      const shipments = await this.mainApi.shipment.findByRoutes({
        routeIds: [routeId]
      })

      const locationAndFunderIds = shipments.reduce((acc, curr) => {
        acc.locations.add(curr.destination.id)
        acc.funders.add(curr.funderId)
        return acc
      }, {locations: new Set(), funders: new Set()})

      const locationsAndFunders = await Promise.all([
        ...Array.from(locationAndFunderIds.locations).map(
          (locationId) => this.mainApi.location.get(locationId)
        ),
        ...Array.from(locationAndFunderIds.funders).map((funderId) =>
          this.mainApi.funders.get(funderId))
      ])

      const locations = locationsAndFunders.slice(0, locationAndFunderIds.locations.size)
      const funders = locationsAndFunders.slice(-1 * locationAndFunderIds.locations.size)

      const [routeWithDetails] = await decorateRouteWithDetails(
        {
          routes: [route],
          shipments,
          locations,
          funders
        },
        this.mainApi
      )
      return routeWithDetails
    } catch (e) {
      if (e.status === 404) {
        console.error('Cannot get route details: route not found', routeId)
        return
      }
      console.error('Error fetching route', routeId, e)
      throw e
    }
  }

  async listByDates ({ from, to } = {}) {
    try {
      const routes = await this.mainApi.routes.list({
        dateRange: { from, to }
      })

      const shipments = await this.mainApi.shipment.findByRoutes({
        routeIds: routes.map((route) => route._id)
      })

      return decorateRouteWithDetails({ routes, shipments }, this.mainApi)
    } catch (e) {
      console.error('Error fetching routes by dates', e)
      return []
    }
  }

  bulkImportLocationRoutes (routes) {
    return this.agaveAdapter.post('location_routes/bulk-save', routes)
  }

  async syncToRDS (routeId) {
    let couchDBRoute
    try {
      couchDBRoute = await this.get(routeId)
    } catch (e) {
      console.error('Error fetching route from couchDB', e)
      throw e
    }
    // The only possible operation is to create new routes,
    // no to update or delete them.
    // The reason not to update is to keep the a clean
    // history of changes, route assignments change but
    // routes do not.
    // Keeping routes that have been deleted in couchDB
    // can also be useful to make sense of route assignment
    // history
    const rdsRoute = toRelationalModel(couchDBRoute)
    return this.pgDataAdapter.upsert(rdsRoute)
  }
}
Object.assign(RoutesApi.prototype, {isRouteEditable})

module.exports = rawMethods
module.exports.RoutesApi = RoutesApi
