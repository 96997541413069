import React from 'react'
import { startOfMonth } from 'date-fns'

import { Button, Form, Page } from '@fielded/shared-ui'
import { Arrow } from '@fielded/shared-ui/src/icons'

import { addTZ } from '@fielded/fs-api/lib/report/tools/tz'
import { formatDate, dateFormats } from '../../../../van-shared/utils/utils'
import BatchOptions from './NewShipmentBatchOptions'
import DayPickerInput from '../../../../van-shared/components/DayPickerInput'

// Note: the default manufacturer value should be lowercase
// but the API converts it to "Chemonics" before saving the batch.
// I think this is a bug, but until we fix it we're forced to use
// "Chemonics".
const INITIAL_STATE = {
  defaultManufacturers: [{ label: 'Unknown', value: 'chemonics' }],
  productsToDo: [],
  completedProducts: [],
  batches: [],
  dateErrors: [],
  selectedBatch: undefined
}

class NewShipmentBatchesForm extends React.Component {
  shipmentForm = React.createRef()

  constructor (props) {
    super(props)
    this.state = {
      ...INITIAL_STATE,
      batches: props.batches,
      selectedProduct: props.shipment.selectedProduct,
      productsToDo: props.shipment.products,
      completedBatchIds: props.shipment.batches.map(({ _id }) => _id),
      startOfThisMonth: startOfMonth(new Date().toISOString()).getTime()
    }
  }

  createBatchOptions = (relevantBatches, completedBatchIds, selectedBatch) => {
    const batchOptions = {}
    if (selectedBatch._id && selectedBatch.batchNumber) {
      batchOptions[selectedBatch._id] = { ...selectedBatch, batchNumber: selectedBatch.batchNumber }
    }
    relevantBatches.filter((b) => !completedBatchIds.includes(b._id)).forEach((batch) => {
      if (!batch) return
      const batchNumber = batch._id.split(':').pop()
      batchOptions[batch._id] = { ...batch, batchNumber }
    })
    return batchOptions
  }

  selectBatch = (batchId) => {
    const { batches, shipment } = this.props
    const batch = shipment.batches.find((b) => b._id === batchId) || batches.find((b) => b._id === batchId) || {}
    return this.setState({
      selectedBatch: batch
    })
  }

  deselectBatch = () => {
    return this.setState({ selectedBatch: undefined })
  }

  setQuantity = (number) => {
    const { selectedBatch } = this.state
    this.setState({
      selectedBatch: {
        ...selectedBatch,
        quantity: number
      }
    })
  }

  setBatch = (batch) => {
    if (!batch.manufacturer && this.state.defaultManufacturers.length === 1) {
      batch.manufacturer = this.state.defaultManufacturers[0].value
    }
    this.setState({
      selectedBatch: {
        ...batch,
        _id: !batch._id ? batch.batchNumber : batch._id,
        id: undefined
      }
    })
  }

  setManufacturer = (target) => {
    const { selectedBatch } = this.state
    this.setState({
      selectedBatch: {
        ...selectedBatch,
        manufacturer: target.value
      }
    })
  }

  setExpiry = (value) => {
    const { selectedBatch } = this.state
    const dateErrors = this.validateDate({ expiry: value })
    if (dateErrors.length) {
      return this.setState({
        dateErrors
      })
    }

    const formDate = addTZ(startOfMonth(value)).toISOString()
    return this.setState({
      dateErrors: [],
      selectedBatch: {
        ...selectedBatch,
        expiry: formDate
      }
    })
  }

  validateDate = (productInfo) => {
    const { startOfThisMonth } = this.state
    const errors = []
    if (!productInfo.expiry || new Date(productInfo.expiry).getTime() < startOfThisMonth) errors.push('Requires future expiry date')
    return errors
  }

  saveBatchInformation = async () => {
    const { selectedBatch, selectedProduct, batches, completedBatchIds } = this.state
    const { saveBatchCount, pushBatchToContainer } = this.props
    const dateErrors = this.validateDate(selectedBatch)
    if (dateErrors.length) {
      return this.setState({
        dateErrors
      })
    }
    const existingBatch = selectedBatch._id && batches.find((b) => b._id.toLowerCase() === selectedBatch._id.toLowerCase())
    // done for new batches that haven't been 'created' officially yet,
    // that step happens in parent.submit component
    const completedBatch = completedBatchIds.includes(selectedBatch._id)
    if (!existingBatch && !completedBatch) {
      selectedBatch.batchParams = {
        expiry: selectedBatch.expiry,
        productId: selectedProduct._id,
        batchNo: selectedBatch.batchNumber,
        // Although the API saves the manufacturer field as "Chemonics"
        // it fails if we pass "Chemonics" in here
        manufacturer: selectedBatch.manufacturer.toLowerCase()
      }
      pushBatchToContainer(selectedBatch)
      selectedBatch._id = selectedBatch.batchNumber
    }
    saveBatchCount(selectedBatch, selectedProduct._id)
    return this.setState({
      selectedBatch: undefined,
      dateErrors: []
    })
  }

  render () {
    const { selectedBatch, dateErrors, selectedProduct, startOfThisMonth } = this.state
    const { goBack, batches, shipment, removeBatchCount } = this.props

    if (selectedBatch) {
      const relevantBatches = batches.length ? cleanBatches(batches, selectedProduct) : []
      const completedBatchIds = shipment.batches.map(({ _id }) => _id)
      const batchOptions = this.createBatchOptions(relevantBatches, completedBatchIds, selectedBatch)
      const existingShipmentBatch = shipment.batches.find((batch) => batch._id === selectedBatch._id) || {}

      let dateErrorMessage
      if (dateErrors) {
        dateErrorMessage = (
          <div className='new-psm-shipment__error-wrapper'>
            {dateErrors.map((message, i) => <span className='new-psm-shipment__errors' key={i}>{message}</span>)}
          </div>
        )
      }

      const disableExpiry = (selectedBatch._rev || selectedBatch.batchParams) && !!selectedBatch.expiry

      console.log(selectedBatch.expiry)
      console.log(formatDate(new Date().toJSON(), 'expiry'))

      return (
        <Form ref={this.shipmentForm} component='form' className='new-psm-shipment__container new-psm-shipment__batch-info' onSubmit={this.saveBatchInformation}>
          <Page.Intro title={`Add ${selectedProduct.fullName} Batch`} />
          <Page.Panel>
            <Form.Section>
              <Form.Row>
                <Form.Field
                  fieldName='manufacturer'
                  labelText='Manufacturer'
                >
                  <Form.Field.Select
                    // only manufacturer listed for PSM ('Chemonics')
                    options={this.state.defaultManufacturers}
                    onChange={this.setManufacturer}
                    // For some reason `defaultValue={selectedBatch.manufacturer}` doesn't
                    // work, so since there is only one possible manufacturer for PSM
                    // I am setting it as the default
                    defaultValue={this.state.defaultManufacturers[0].value}
                    placeholder={'Select...'}
                    clearable={false}
                    disabled={this.state.defaultManufacturers.length === 1}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              <Form.Row>
                <Form.Field
                  fieldName='batchNo'
                  labelText='Batch number'
                >
                  <Form.Field.BatchSelect
                    defaultValue={selectedBatch.batchNumber ? { label: selectedBatch.batchNumber, value: selectedBatch._id } : undefined}
                    batches={batchOptions}
                    hiddenManufacturers={this.state.defaultManufacturers.concat({ value: 'Chemonics' })}
                    onValueChange={this.setBatch}
                    placeholder={selectedBatch.batchNumber ? selectedBatch.batchNumber : 'Select...'}
                    clearable={false}
                    disabled={!!existingShipmentBatch._id}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
              <Form.Row>
                <Form.Field
                  fieldName='expiry'
                  labelText='Expiry date'
                >
                  <DayPickerInput
                    initialValue={selectedBatch.expiry ? formatDate(selectedBatch.expiry, 'expiry') : formatDate(new Date().toJSON(), 'expiry')}
                    callbackFunction={this.setExpiry}
                    dateFormat={dateFormats.expiry}
                    disabledInput={disableExpiry}
                    disabled={{ before: new Date(startOfThisMonth) }}
                  />
                  {dateErrorMessage}
                </Form.Field>
              </Form.Row>
              <Form.Row>
                <Form.Field
                  fieldName='quantity'
                  labelText='Quantity'

                >
                  <Form.Field.Quantity
                    min={0}
                    required='This field is required.'
                    textAlign='right'
                    value={existingShipmentBatch.quantity}
                    onValueChange={value => this.setQuantity(value)}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </Form.Row>
            </Form.Section>
          </Page.Panel>
          <Page.Footer fixed>
            <Form.Actions className='new-psm-shipment__button-wrapper'>
              <Form.Button
                icon={<Arrow direction='right' />}
                iconSide='right'
                fill='full'
                colorVariant='brand'
                type='submit'
              >
                Save
              </Form.Button>
              <Form.Button
                onClick={this.deselectBatch}
                icon={<Arrow direction='left' />}
                iconSide='left'
                colorVariant='brand'
                fill='outline'
                alignOpposite
              >
                Back
              </Form.Button>
            </Form.Actions>
          </Page.Footer>
        </Form>
      )
    }

    const completedBatches = shipment.batches.filter(({ productId }) => productId === selectedProduct._id)
    return (
      <div className='new-psm-shipment__batch-options'>
        <BatchOptions selectedProduct={selectedProduct} batches={completedBatches} selectBatch={this.selectBatch} removeBatchCount={removeBatchCount} />
        <Page.Footer fixed>
          <div className='new-psm-shipment__button-wrapper'>
            <Button
              onClick={goBack}
              icon={<Arrow direction='left' />}
              iconSide='left'
              colorVariant='brand'
              fill='outline'
            >
            Back
            </Button>
          </div>
        </Page.Footer>
      </div>
    )
  }
}

export default NewShipmentBatchesForm

const cleanBatches = (batches, product) => {
  return batches.filter((batch) => batch.productId === product._id && new Date().getTime() <= new Date(batch.expiry).getTime())
}
