const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const set = require('lodash/set')
const { parse } = require('../../tools/smart-id')

// convertProductUnits is used for converting any batched product set
// (e.g. sent as an order update) from unitOfReporting to unitOfIssue or vice versa.
// It returns a product set of the same shape, but with
// amounts in the requested target unit, assuming the initial values are in
// the other. In case the product is not part of the given productsById, the
// value will be passed through as is.
// Expected values for the `target` parameter are either `reporting` or `issue`
exports.convertProductUnits = convertProductUnits
function convertProductUnits (batchedProducts, target, products = []) {
  if (target !== 'reporting' && target !== 'issue') {
    throw new Error('convertProductUnits: `target` parameter must be one of: `reporting`, `issue`')
  }
  const productsById = keyBy(products, '_id')

  return Object.keys(batchedProducts).reduce((acc, productId) => {
    let {unitOfIssue, unitOfReporting} = productsById[productId] || {}
    unitOfIssue = unitOfIssue || 1
    unitOfReporting = unitOfReporting || 1

    const convertAndCopyObjectInto = (obj, prop, acc) => {
      for (const key in obj) {
        const amount = obj[key]
        if (!Number.isFinite(amount)) {
          continue
        }
        acc[prop] = acc[prop] || {}
        acc[prop][key] = target === 'reporting'
          ? Math.ceil(amount * unitOfIssue / unitOfReporting)
          : Math.ceil(amount * unitOfReporting / unitOfIssue)
      }
      return acc
    }

    if (batchedProducts[productId].batches) {
      acc[productId] = Object.assign({}, acc[productId], {
        batches: Object.keys(batchedProducts[productId].batches || {}).reduce((acc, batchId) => {
          // all keys in a batch object with a numeric value will be transformed
          // so that this function supports different shapes like `{amount: 400}` or
          // `{original: 400, adjusted: 350}`
          return convertAndCopyObjectInto(batchedProducts[productId].batches[batchId], batchId, acc)
        }, {})
      })
    } else {
      acc = convertAndCopyObjectInto(batchedProducts[productId], productId, acc)
    }
    return acc
  }, {})
}

// translateAliases converts an inbound product set that is using aliased
// product and batch ids into native field supply ids. As aliases might differ
// from batch to batch, this means the result's shape can differ.
exports.translateAliases = translateAliases
function translateAliases (stock, alias, products) {
  if (alias === 'none') {
    return stock
  }
  const productsByAlias = products.reduce((acc, product) => {
    if (product.alias && product.alias[alias]) {
      product.alias[alias].forEach((aliasId) => {
        acc[aliasId] = product
      })
    }
    return acc
  }, {})

  const translated = Object.keys(stock).reduce((acc, productId) => {
    const {product: aliasId} = parse(productId)
    if (!productsByAlias[aliasId]) {
      return acc
    }
    const {product: replacement} = parse(productsByAlias[aliasId]._id)
    const translatedProductId = `product:${replacement}`

    if (!acc[translatedProductId]) {
      acc[translatedProductId] = Object.assign({}, stock[productId], {
        batches: Object.keys(stock[productId].batches).reduce((acc, batchId) => {
          const {manufacturer, batchNo} = parse(batchId)
          const transformedId = `${translatedProductId}:manufacturer:${manufacturer}:batchNo:${batchNo}`
          acc[transformedId] = stock[productId].batches[batchId]
          return acc
        }, {})
      })
    } else {
      for (const batchId in stock[productId].batches) {
        const {manufacturer, batchNo} = parse(batchId)
        const transformedId = `${translatedProductId}:manufacturer:${manufacturer}:batchNo:${batchNo}`
        acc[translatedProductId].batches[transformedId] = stock[productId].batches[batchId]
      }
    }
    return acc
  }, {})
  return translated
}

// collapseBatchesToItemIds takes a batched product set using Field Supply ids
// and collapses this set into an aliased product level set. This is used when
// transforming an order document from the database into a REST API payload.
exports.collapseBatchesToItemIds = collapseBatchesToItemIds
function collapseBatchesToItemIds (stock, alias, batches, products, key = 'adjusted') {
  const batchesById = keyBy(batches, '_id')
  const productsById = keyBy(products, '_id')
  const flattenedItems = Object.keys(stock).reduce((acc, productId) => {
    const item = stock[productId]
    if (item.batches) {
      for (const batchId in item.batches) {
        acc[batchId] = item.batches[batchId][key] || 0
      }
    } else {
      // in case the item is unbatched we fall back to the product id
      acc[productId] = item[key] || 0
    }
    return acc
  }, {})

  return Object.keys(flattenedItems).reduce((acc, batchOrProductId) => {
    const batch = batchesById[batchOrProductId]
    const product = productsById[batchOrProductId]
    if (!batch && !product) {
      return acc
    }

    const matchingAlias = batch
      ? batch.alias && batch.alias[alias]
      : product
        // **IMPORTANT**: using the first alias on a product without batch
        // level information is an approximation only. Ideally, all products
        // should have batch level information so they can be transformed into
        // the correct alias.
        ? (product.alias && Array.isArray(product.alias[alias]) && product.alias[alias][0])
        : null

    if (!matchingAlias) {
      return acc
    }

    const itemId = matchingAlias
      ? `product:${matchingAlias}`
      : batch
        ? batch.productId
        : product._id
    acc[itemId] = acc[itemId] || 0
    acc[itemId] += flattenedItems[batchOrProductId]
    return acc
  }, {})
}

// decorateOrderDoc takes an order in the shape at which it is stored in the
// database and transforms it into a REST API payload.
exports.decorateOrder = decorateOrder
function decorateOrder ({
  doc,
  warehouseCodeMap = {},
  alias,
  productMasterData = {},
  useGenericParent,
  routesById = {}
}) {
  const {
    additionalData = {}, closedStatus = '',
    createdAt, destinationId,
    orderId, products,
    programId, status, funderId,
    suborderId, supplierId, updatedAt,
    version,
    groupId,
    routeId
  } = doc

  const {allBatches = [], allProducts = []} = productMasterData
  let productQuantities
  if (alias === 'none') {
    productQuantities = collapseBatchedQuantities(products, 'adjusted')
  } else {
    const productsAtUnitOfIssue = convertProductUnits(products, 'issue', allProducts)
    productQuantities = collapseBatchesToItemIds(productsAtUnitOfIssue, alias, allBatches, allProducts, 'adjusted')
  }

  if (useGenericParent) {
    productQuantities = convertToGenericParent(productQuantities, allProducts)
  }

  const entity = {
    additionalData,
    closedStatus,
    createdAt,
    destinationId,
    groupId,
    destinationWarehouseCode: warehouseCodeMap[destinationId],
    funderId,
    orderId,
    productQuantities,
    programId,
    status,
    suborderId,
    supplierId,
    supplierWarehouseCode: warehouseCodeMap[supplierId],
    updatedAt,
    version,
    routeId
  }

  entity.route = get(routesById[routeId], 'name')

  return entity
}

// collapseBatchedQuantities takes a batched product set and aggregates the
// values stored at the given key on product level
exports.collapseBatchedQuantities = collapseBatchedQuantities
function collapseBatchedQuantities (quantities, key = 'adjusted') {
  return Object.keys(quantities).reduce((acc, productId) => {
    const {batches = null} = quantities[productId]
    if (batches) {
      acc[productId] = Object.keys(batches).reduce((sum, batchId) => {
        return sum + (batches[batchId][key] || 0)
      }, 0)
    } else {
      acc[productId] = quantities[productId][key] || 0
    }
    return acc
  }, {})
}

// this is to make order.funderId: funder:pepfar--some-sub-funder
// look like: order.funderId: funder:pepfar as PSM orders cannot support "sub funders"
exports.replaceSubFunders = replaceSubFunders
function replaceSubFunders (doc, fundersById = {}) {
  const parentFunderId = get(fundersById, `${doc.funderId}.ordersParentFunderId`)
  return parentFunderId
    ? Object.assign({}, doc, {funderId: parentFunderId})
    : doc
}

// NOT USED, just needed as a idea:
// "how do we turn order data into something we can post into the Update call in the Orders API"
// the idea is to do the same things as the translation steps in decorateOrder,
// but without collapsing the batches
exports.translateToAliasedBatches = translateToBatchesAlias
function translateToBatchesAlias (orderProducts, productsInfo, batchInfo, alias) {
  const productsAtUnitOfIssue = convertProductUnits(orderProducts, 'issue', productsInfo)

  if (Array.isArray(batchInfo)) {
    batchInfo = keyBy(batchInfo, '_id')
  }

  const translated = {}
  // Get a flat list of batches
  for (var productId of Object.keys(productsAtUnitOfIssue)) {
    const product = productsAtUnitOfIssue[productId]

    for (var batchId of Object.keys(product.batches)) {
      const batch = batchInfo[batchId]
      const {manufacturer, batchNo} = parse(batchId)
      const amount = get(productsAtUnitOfIssue, `${productId}.batches.${batchId}.adjusted`)
      // https://github.com/fielded/field-supply/blob/develop/backend/stacks/external_api/api_batch_get/src/index.js#L19
      const aliasedBatchId = `product:${batch.alias[alias]}:manufacturer:${manufacturer}:batchNo:${batchNo}`
      const aliasedProductId = `product:${batch.alias[alias]}`
      set(translated, `${aliasedProductId}.batches.${aliasedBatchId}.amount`, amount)
    }
  }

  return translated
}

function convertToGenericParent (productQuantities, allProducts) {
  const genericParentQuantities = {}
  for (const productId in productQuantities) {
    const product = allProducts.find(p => p._id === productId)
    if (!product) {
      genericParentQuantities[productId] = productQuantities[productId]
      continue
    }
    const genericParentId = product.genericParentId
      ? `product:${product.genericParentId.toLowerCase()}`
      : productId
    const divisor = product.genericFactor && product.genericFactor > 0
      ? product.genericFactor
      : 1
    genericParentQuantities[genericParentId] = productQuantities[productId] / divisor
  }
  return genericParentQuantities
}
