const groupBy = require('lodash/groupBy')

const decorateRouteWithDetails = async ({ routes, shipments, locations, funders }, api) => {
  if (!routes || routes.length === 0) {
    return []
  }
  const areDetailsNeeded = !!locations && !!funders

  const shipmentsWithVolumes = await Promise.all(
    shipments.map(async (shipment) => {
      const { totalVolume } = await api.shipment.calculateVolumeAndPrice(shipment)
      return {...shipment, loadVolume: totalVolume}
    })
  )

  const shipmentsByRoute = groupBy(shipmentsWithVolumes, 'routeId')

  return (
    routes
      .map((route) => {
        // Collect the shipments that belong to this route
        // and add the calculated load volume to each shipment
        const routeShipments = (shipmentsByRoute[route._id] || [])

        // Collect the unique LGAs, the total load volume and number of stops of the route
        const routeShipmentsData = routeShipments.reduce(
          (acc, shipment) => {
            const { lga, id: destinationId } = shipment.destination
            acc.lga.add(lga)
            acc.stops.add(destinationId)
            acc.totalLoadVolume += shipment.loadVolume
            return acc
          },
          { lga: new Set(), stops: new Set(), totalLoadVolume: 0 }
        )
        return {
          id: route._id,
          name: route.name,
          lga: Array.from(routeShipmentsData.lga).join(', '),
          stopsNo: routeShipmentsData.stops.size,
          load: routeShipmentsData.totalLoadVolume,
          startDate: route.startDate,
          endDate: route.endDate,
          ...(areDetailsNeeded
            ? {
              shipments: routeShipments,
              locations,
              funders
            }
            : {}),
          // TODO add these fields
          carrier: undefined,
          status: undefined
        }
      })
      // Filter out routes with no stops aka. empty routes because
      // that's not possible as we create routes from shipments
      .filter((route) => route.stopsNo > 0)
  )
}
module.exports = { decorateRouteWithDetails }
