const smartId = require('./../tools/smart-id')
const apiTools = require('../tools')
const get = require('lodash/get')
const CASHBACK_MULTIPLIER = 2

const locationsWithouthCashbacks = [
  'country:ke:state:north-rift:sdp:north-rift-cash-account',
  'country:ke:state:coastal:sdp:coastal-cash-account',
  'country:ke:state:south-rift:sdp:southrift-cash-acc',
  'country:ke:state:nyanza:sdp:nyanza-cash-account',
  'country:ke:state:nairobi:sdp:cash-account-nairobi',
  'country:ng:state:fct:sdp:no-cashback-pharmacy' // For the tests
]

class CashBackApi {
  constructor (
    state,
    logger,
    notification,
    payment,
    quickbooks,
    location,
    product,
    email
  ) {
    this.notification = notification
    this.payment = payment
    this.quickbooks = quickbooks
    this.location = location
    this.product = product
    this.logger = logger
    this.email = email
  }

  /**
   * Create a bonus entry in QuickBooks for a shipment.
   * @async
   * @param {Object} options - The options for creating a bonus.
   * @param {Object} options.quickbooksLocation - The QuickBooks location object.
   * @param {Object} options.location - The FS location object.
   * @param {number} options.cashbackAmount - The cashback amount to be used as bonus amount.
   * @param {string} options.shipmentNumber - The shipment number to relate the bonus to.
   * @returns {Object} - The posted QuickBooks journal entry.
   */
  async createBonus ({
    quickbooksLocation,
    location,
    cashbackAmount,
    shipmentNumber
  }) {
    const { quickbooksInterface } = this.quickbooks

    const bonusDescription = `Cashback for shipmentNo: ${shipmentNumber} (${CASHBACK_MULTIPLIER}x)`
    const bonusAccountId = quickbooksInterface.accountIds[quickbooksLocation.company_code].bonusAccountId
    const [accountReceivableId] = quickbooksInterface.accountIds[quickbooksLocation.company_code].accountReceivableIds
    return this.quickbooks.createJournalEntry({
      location,
      description: bonusDescription,
      loanReceivable: {
        Id: bonusAccountId
      },
      accountReceivable: {
        Id: accountReceivableId
      },
      amount: cashbackAmount,
      docNumber: `Cashback Id-${bonusAccountId}`
    })
  }

  /**
   * Get QuickBooks location information for a given location entity.
   * @async
   * @param {Object} location - The FS location entity.
   * @returns {Object} - The corresponding Quickbooks location info straight from RDS, see api.quickbooks.location.getOneWithMarketData
   */
  async quickbooksLocation (location) {
    let quickbooksLocation = null
    try {
      quickbooksLocation = await this.quickbooks.location.getOneWithMarketData(location.additionalData.uuid, {
        whereCondition: 'location_id'
      })
    } catch (error) {
      if (error.status !== 404) {
        throw error
      }
      // if quickbooks location not exists create one.
      const qboAccount = await this.quickbooks.createCustomer(location)
      if (!qboAccount) {
        throw new Error('Cant find qbo account')
      }
      quickbooksLocation = await this.quickbooks.location.getOneWithMarketData(location.additionalData.uuid, {
        whereCondition: 'location_id'
      })
    }
    return quickbooksLocation
  }

  /**
   * Create a QuickBooks bonus entry for a shipment.
   * @async
   * @param {Object} shipment - The shipment object.
   * @param {Object} location - The location object.
   * @param {number} cashbackAmount - The cashback amount.
   */
  async createQBOBonus (shipment, location, cashbackAmount) {
    const quickbooksLocation = await this.quickbooksLocation(location)
    const shipmentNumber = smartId.parse(shipment.id).shipmentNo
    const journalEntry = await this.createBonus({
      quickbooksLocation,
      location,
      cashbackAmount,
      shipmentNumber
    })
    await this.quickbooks.transform.transformAndSave(
      quickbooksLocation.company_code,
      {
        JournalEntry: [journalEntry]
      }
    )
  }

  /**
   * Send an email notification for a cashback.
   * @async
   * @param {Object} location - The location object.
   * @param {number} cashbackAmount - The cashback amount.
   * @param {number} totalShipmentValue - The total shipment value.
   */
  async sendEmailNotification (location, cashbackAmount, totalShipmentValue) {
    try {
      // Send the email notification
      const email = get(location, 'contacts.program:shelflife.email')
      const country = apiTools.locationIdToProperties(location._id).country
      const currency =
      country === 'ng' ? '₦' : 'KSh'
      if (!email) {
        throw new Error('Location has no email', location)
      }
      await this.email.sendNewBonusReceivedEmail({
        email,
        cashbackAmount: `${currency}${cashbackAmount}`,
        orderValue: `${currency}${totalShipmentValue}`,
        country: country
      })
    } catch (e) {
      this.logger.error("Couldn't send email notification for cashback", e)
    }
  }

  /**
   * Create a cashback from a shipment.
   * @async
   * @param {Object} shipment - The shipment object.
   * @param {Date} priceReferenceDate - The reference date for prices.
   * @returns {location: Object} - cashback-awarded location entity
   * @returns {totalShipmentValue: number} - The sum of the value of the products in the shipment including any additional costs
   * @returns {cashbackAmount: number} - The calculated cashback amount for this shipment
   * @returns null - As indicating everything's fine but we can't generate cashback for that shipment
   * @throws - if something goes sideway
   */
  async createFromShipment (shipment, priceReferenceDate) {
    const destinationId = shipment.destination.id

    // Exclude some special hardwired locations we do not want to provide with cashbacks
    if (locationsWithouthCashbacks.includes(destinationId)) {
      return null
    }

    const location = await this.location.get(destinationId)

    // Calculate cashback
    const { cashbackAmount, totalShipmentValue } =
      await this.calculateFromShipment({
        shipment,
        location,
        priceReferenceDate
      })

    if (cashbackAmount > 0) {
      await this.createQBOBonus(shipment, location, cashbackAmount)
      await this.sendEmailNotification(
        location,
        cashbackAmount,
        totalShipmentValue
      )
    }

    return { location, totalShipmentValue, cashbackAmount }
  }

  /**
   * Calculate cashback amount from a shipment.
   * @async
   * @param {Object} options - The options for calculating cashback.
   * @param {Object} options.shipment - The shipment object.
   * @param {Object} options.location - The location object.
   * @param {Date} options.priceReferenceDate - The reference date for prices.
   * @returns {totalShipmentValue: number} - The sum of the value of the products in the shipment including any additional costs
   * @returns {cashbackAmount: number} - The calculated cashback amount for this shipment
   */
  async calculateFromShipment ({ shipment, location, priceReferenceDate }) {
    // get order from shipment
    const { orderId: couchDBOrderId } = shipment

    const isPharmacy = location.level === 'sdp'
    // Old shipment docs are not linked to orders. They did not account for cashbacks either
    if (!couchDBOrderId || !isPharmacy) return 0

    const productQuantities = {}
    const shipmentProductsIds = Object.keys(shipment.counts).map((countId) => {
      const productId = countId.split(':manufacturer')[0]
      productQuantities[productId] = shipment.counts[countId].quantity
      return productId
    })

    const productData = await this.product.getProductsViaIds(shipmentProductsIds)

    const pricesDate = new Date(priceReferenceDate).toJSON()
    let totalFinancing = 0
    let totalShipmentValue = 0

    productData.forEach((product) => {
      const financingMarkup =
        apiTools.product.getPrice(product.prices, pricesDate, 'financingMarkup') || 0
      const sellPrice = apiTools.product.getPrice(product.prices, pricesDate)
      const perProductFinancing = productQuantities[product._id] * financingMarkup
      const perProductValue = productQuantities[product._id] * sellPrice
      totalFinancing += perProductFinancing
      totalShipmentValue += perProductValue
    })

    const cashback = totalFinancing
    const roundedCashback = (Math.round(cashback * 100) / 100) * CASHBACK_MULTIPLIER

    const didPaySomethingInCash = shipment.totalAmount > 0

    return {
      totalShipmentValue,
      cashbackAmount: didPaySomethingInCash ? roundedCashback : 0
    }
  }
}

module.exports = CashBackApi
