module.exports = FSApi
const get = require('lodash/get')
const OrderApi = require('./lib/order').OrderApi
const fsPouchDBAdapter = require('./lib/dal-adapters/pouchdb-adapter')
const fsPouchServerAdapter = require('./lib/dal-adapters/server-adapter')
const setupShipmentApi = require('./lib/shipment/setup')
const ReportApi = require('./lib/report').ReportApi
const StockApi = require('./lib/stock').StockApi
const alertApi = require('./lib/alert')
const programApi = require('./lib/program')
const ServiceApi = require('./lib/service').ServiceApi
const configurationApi = require('./lib/configuration')
const LocationApi = require('./lib/location').LocationApi
const ProductApi = require('./lib/product').ProductApi
const BatchApi = require('./lib/batch').BatchApi
const FundersApi = require('./lib/funders').FundersApi
const userApi = require('./lib/user')
const geoLocationsApi = require('./lib/geo-locations')
const AllocationApi = require('./lib/allocation')
const MarketApi = require('./lib/market')
const ForecastApi = require('./lib/forecast')
const documentApi = require('./lib/document')
const ProductSubscriptionApi = require('./lib/product-subscription')
const { ShipmentApi, SurveyApi } = require('./lib/shipment')
const InvoicesApi = require('./lib/invoices')
const PaymentPlansApi = require('./lib/payment-plans')
const SmsApi = require('./lib/sms')
const SignupApi = require('./lib/signup')
const CreditApplicationApi = require('./lib/credit-application')
const LicenseApi = require('./lib/license')
const FreshsalesApi = require('./lib/freshsales')
const FreshDeskApi = require('./lib/freshdesk')
const PaymentApi = require('./lib/payment')
const PaymentReconciliationApi = require('./lib/payment-reconciliation')
const DepositApi = require('./lib/deposits')
const DepositsApi = require('./lib/deposits/depositsApi')
const CurrentUserApi = require('./lib/current-user')
const AuditApi = require('./lib/audit')
const PriceApi = require('./lib/price')
const PriceBonusApi = require('./lib/price-bonus')
const SupplierApi = require('./lib/supplier')
const StatsApi = require('./lib/stats')
const SalesStatsApi = require('./lib/sales-stats')
const NotificationsFCMApi = require('./lib/notifications-fcm')
const NotificationsApi = require('./lib/notification')
const QuickbooksApi = require('./lib/quickbooks')
const NotificationsPlannerApi = require('./lib/notifications-planner')
const EmailApi = require('./lib/email')
const FinancesApi = require('./lib/finances')
const CashbackApi = require('./lib/cashback')
const { LedgerApi } = require('./lib/ledger')
const { RoutesApi } = require('./lib/routes')
const ExternalForecastApi = require('./lib/external-forecast')

const wrap = require('./lib/utils/wrap-api')
const { getDBPerUserDatabaseName } = require('./lib/user/tools')
const { hasOwnDatabase } = require('./lib/user/tools/user-db-sync')
const {
  RestAdapter: DefaultRestAdapter,
  Logger: DefaultLogger
} = require('./lib/common')

const STOCK_COUNT_DB_NAME = 'stock-count'
const STOCK_COUNT_RECONCILED_DB_NAME = 'stock-count-reconciled'
const ORDER_DB_NAME = 'order'
const LEDGER_DB_NAME = 'ledger-balances'
const ALERT_DB_NAME = 'alerts'
const SHIPMENTS_DB_NAME = 'van-shipments'
const INTEGRATED_DATA_DB_NAME = 'integrated-data'
const PROPOSALS_DB_NAME = 'integrated-data-proposals'
const MASTER_DATA_DB_NAME = 'master-data'
const DOCUMENTS_DB_NAME = 'documents'

function FSApi (params) {
  const {
    PouchDB,
    RemotePouchDB,
    user,
    // pre-configured fetch to point at the field endpoint (frontend)
    // or couchdb directly (lambdas)
    fetch,
    recordingBatchesSince,
    djangoAuthToken,
    restApiUrl,
    agaveApiUrl,
    // isomorphic fetch to be used in setting up the rest adapter that points to the
    // avocado django app. Lambdas pass in the node lib isomorphic-fetch,
    // frontend passes window.fetch. this is just to allow a more specific fetch setup
    // than our existing `fetch` param, that's already pointed at something with headers.
    isomorphicFetch,
    // while migrathing away from avocado we might run into situation where we
    // need a different fetch for agave and avocado at the same time. If this is
    // not configured, isomorphicFetch will be used instead
    agaveFetch,
    // allow passing a mocked RestAdapter inside the tests
    RestAdapter = DefaultRestAdapter,
    logger = new DefaultLogger(),
    // Optional africastalkingCredentials if using the API to send SMS
    africastalkingCredentials,
    // Optional africastalking node package if using the API to send SMS
    africastalking,
    // safaricom Sms sending credentials. if using africastalking need also safaricom for able to send sms kenya
    safaricomSmsCredentials,
    // Optional initialized pg database connection
    pgConnection,
    // Optional remitaApiConfig (the urls & secrets)
    remitaApiConfig,
    // Optional safaricomApiConfig (the urls & secrets)
    safaricomApiConfig,
    serverUserAdapter,
    quickbooksConfigs,
    quickbooksOAuthClient,
    freshsalesApiConfig,
    paystackSecrets,
    paystackWebConfigs,
    fcmConfigs,
    freshDeskConfigs,
    // Tells that the user has synced PouchDB with an id-dispenser or per-user-db
    // means we can take some shortcuts in queries sometimes
    prefilteredPouch = false
  } = params

  const {
    ledgerDbName,
    remoteLedgerDBName,
    orderDbName,
    stockCountDBName,
    stockCountReconciledDBName,
    alertDbName,
    shipmentsDbName,
    integratedDataDBName,
    proposalsDBName,
    masterDataDBName,
    documentsDBName,
    remoteDocumentsDBName
  } = getDatabaseNames(params)

  DefaultLogger.validate(logger)
  const integratedDataDB = new PouchDB(integratedDataDBName)

  const state = {
    PouchDB,
    user,
    recordingBatchesSince: recordingBatchesSince || '2018-01-01T00:00:00.000Z', // since when are batches recorded in stock counts
    db: new PouchDB(stockCountDBName),
    stockCountReconciledDB: new PouchDB(stockCountReconciledDBName),
    remoteDb: RemotePouchDB ? new RemotePouchDB(stockCountDBName) : null,
    orderDb: RemotePouchDB
      ? new RemotePouchDB(orderDbName)
      : new PouchDB(orderDbName),
    alertsDb: new PouchDB(alertDbName),
    shipmentsDb: new PouchDB(shipmentsDbName),
    shipmentsRemoteDb: RemotePouchDB
      ? new RemotePouchDB(shipmentsDbName)
      : null,
    programsDB: integratedDataDB,
    locationsDB: integratedDataDB,
    routesDB: RemotePouchDB
      ? new RemotePouchDB(integratedDataDBName)
      : integratedDataDB,
    productsDB: integratedDataDB,
    masterDataDB: new PouchDB(masterDataDBName),
    allocationsDB: integratedDataDB,
    forecastDB: integratedDataDB,
    proposalsDB: new PouchDB(proposalsDBName),
    ledgerDB: new PouchDB(ledgerDbName),
    remoteLedgerDB: RemotePouchDB ? new RemotePouchDB(remoteLedgerDBName) : null,
    documentsDB: (remoteDocumentsDBName && RemotePouchDB) ? new RemotePouchDB(remoteDocumentsDBName) : new PouchDB(remoteDocumentsDBName || documentsDBName),
    onlineOffline: user && user.roles && user.roles.includes('feature:online-offline'),
    device: { // this is used by .shipment and .survey
      id: null // loaded from separate device database in lib/shipment/setup.js
    },
    fetch,
    orderDbName,
    integratedDataDBName,
    shipmentsDBName: shipmentsDbName,
    documentsDBName: remoteDocumentsDBName || documentsDBName,
    stockCountDBName,
    dal: serverUserAdapter ? fsPouchServerAdapter : fsPouchDBAdapter,
    serverUserAdapter,
    logger,
    prefilteredPouch
  }

  if (!state.remoteLedgerDB) {
    logger.info('stock-count-api: no RemotePouchDB, only offline ledgers available')
  }

  const restAdapter = (isomorphicFetch && restApiUrl)
    ? new RestAdapter(isomorphicFetch, djangoAuthToken, restApiUrl)
    : null

  const agaveAdapter = ((agaveFetch || isomorphicFetch) && agaveApiUrl)
    ? new RestAdapter((agaveFetch || isomorphicFetch), '', agaveApiUrl)
    : null

  // Empty main api object that will later hold the complete api
  // This can be passed as ref for the main api to constructors,
  // but will only be valid after the constructor has run.
  const mainApi = { state }

  // Let's try keep this sorted alphabetically
  const apiDefinition = {
    alert: alertApi,
    allocation: new AllocationApi(state),
    audit: new AuditApi(state, pgConnection),
    batch: new BatchApi(state, mainApi),
    configuration: configurationApi,
    creditApplication: new CreditApplicationApi(pgConnection, agaveAdapter),
    currentUser: new CurrentUserApi(agaveAdapter),
    deposit: new DepositApi(state, pgConnection),
    document: documentApi,
    forecast: new ForecastApi(state, restAdapter),
    freshDesk: new FreshDeskApi(freshDeskConfigs, isomorphicFetch, pgConnection, logger),
    freshsales: new FreshsalesApi(freshsalesApiConfig, isomorphicFetch, pgConnection, agaveAdapter, logger, state.user),
    funders: new FundersApi(state, restAdapter),
    geoLocations: geoLocationsApi,
    ledger: new LedgerApi(state, pgConnection, agaveAdapter, logger),
    license: new LicenseApi(agaveAdapter),
    location: new LocationApi(state, restAdapter, logger, pgConnection, agaveAdapter),
    market: new MarketApi(state, logger, pgConnection),
    notificationsFcm: new NotificationsFCMApi(state, pgConnection, agaveAdapter, fcmConfigs),
    notificationsPlanner: new NotificationsPlannerApi(agaveAdapter),
    order: new OrderApi(state, restAdapter, agaveAdapter, logger, pgConnection),
    price: new PriceApi(state, pgConnection, agaveAdapter, logger),
    priceBonus: new PriceBonusApi(state, pgConnection, logger),
    product: new ProductApi(state, logger, agaveAdapter, pgConnection),
    productSubscription: new ProductSubscriptionApi(state, mainApi, restAdapter),
    program: programApi,
    report: new ReportApi(state, restAdapter),
    routes: new RoutesApi(state, agaveAdapter, logger, pgConnection, mainApi),
    salesStats: new SalesStatsApi(state, pgConnection, agaveAdapter),
    service: new ServiceApi(state, restAdapter),
    shipment: new ShipmentApi(state, agaveAdapter, pgConnection, logger, mainApi),
    signup: new SignupApi(pgConnection, agaveAdapter),
    sms: new SmsApi(state, restAdapter, logger, africastalkingCredentials, africastalking, isomorphicFetch, pgConnection, safaricomSmsCredentials),
    stats: new StatsApi(state, pgConnection, logger),
    stock: new StockApi(state, mainApi, restAdapter, logger, pgConnection),
    supplier: new SupplierApi(state, pgConnection, agaveAdapter),
    survey: new SurveyApi(state),
    user: userApi
  }

  apiDefinition.paymentPlans = new PaymentPlansApi(state, restAdapter, apiDefinition.location, pgConnection, agaveAdapter)
  apiDefinition.deposits = new DepositsApi(state, restAdapter, apiDefinition.location, logger)

  apiDefinition.notification = new NotificationsApi(
    state,
    pgConnection,
    agaveAdapter,
    apiDefinition.location,
    apiDefinition.notificationsFcm,
    logger
  )

  apiDefinition.email = new EmailApi(agaveAdapter, apiDefinition.notification)

  apiDefinition.quickbooks = new QuickbooksApi(
    state,
    pgConnection,
    quickbooksConfigs,
    isomorphicFetch,
    quickbooksOAuthClient,
    apiDefinition.notification,
    logger
  )

  apiDefinition.invoices = new InvoicesApi(state, {
    quickbooksApi: apiDefinition.quickbooks,
    pgConnection
  })

  apiDefinition.finances = new FinancesApi(
    state,
    pgConnection,
    agaveAdapter,
    logger,
    apiDefinition.quickbooks,
    apiDefinition.location
  )

  apiDefinition.payment = new PaymentApi(
    state,
    {
      isomorphicFetch,
      remitaApiConfig,
      safaricomApiConfig,
      logger,
      pgConnection,
      agaveAdapter,
      paystackSecrets,
      paystackWebConfigs,
      invoicesApi: apiDefinition.invoices,
      quickbooksApi: apiDefinition.quickbooks
    }
  )

  apiDefinition.paymentReconciliation = new PaymentReconciliationApi(
    state,
    logger,
    pgConnection,
    apiDefinition.payment,
    apiDefinition.paymentPlans,
    apiDefinition.quickbooks
  )

  apiDefinition.cashback = new CashbackApi(
    state,
    logger,
    apiDefinition.notification,
    apiDefinition.payment,
    apiDefinition.quickbooks,
    apiDefinition.location,
    apiDefinition.product,
    apiDefinition.email
  )

  apiDefinition.externalForecast = new ExternalForecastApi(
    state,
    logger,
    pgConnection,
    agaveAdapter,
    apiDefinition.product
  )

  apiDefinition.proxyAutoRoutes = []

  const entityApiList = [
    'market',
    'allocation',
    'forecast',
    'location',
    'order',
    'product',
    'productSubscription',
    'stock',
    'shipment',
    'survey',
    'report',
    'service',
    'funders',
    'invoices',
    'paymentPlans',
    'sms',
    'signup',
    'creditApplication',
    'freshsales',
    'freshDesk',
    'license',
    'payment',
    'paymentReconciliation',
    'deposit',
    'deposits',
    'currentUser',
    'audit',
    'price',
    'supplier',
    'stats',
    'salesStats',
    'quickbooks',
    'notificationsPlanner',
    'email',
    'priceBonus',
    'notificationsFcm',
    'notification',
    'finances',
    'batch',
    'ledger',
    'cashback',
    'externalForecast',
    'routes'
  ]
  const ignoreList = ['tools'].concat(entityApiList)

  // This makes `state` the first positional argument of every method on an API.
  // The newer API's that extend EntityApi don't need this positional argument.
  // We pass wrap the mainApi object to assign the api methods to. This way
  // we can hold a ref to the mainApi object before wrap is called.
  wrap(apiDefinition, state, setupShipmentApi, ignoreList, mainApi)

  entityApiList.forEach(entityName => {
    mainApi[entityName].mainApi = mainApi
    if (mainApi[entityName].adapter) {
      mainApi[entityName].adapter.mainApi = mainApi
    }
    if (mainApi[entityName].proxyAutoRoutes) {
      apiDefinition.proxyAutoRoutes = apiDefinition.proxyAutoRoutes.concat(mainApi[entityName].proxyAutoRoutes)
    }
  })

  return mainApi
}

function getDatabaseNames (params) {
  const {user = {}} = params
  const userRoles = get(user, 'roles', [])
  const usesDatabasePerUser = hasOwnDatabase(user)

  const userDatabaseName = usesDatabasePerUser
    ? getDBPerUserDatabaseName(params.user)
    : null

  const usesRemoteDocuments = !userRoles.includes('feature:settings:facilities:offline-edits') &&
    !usesDatabasePerUser

  let remoteDocumentsDBName = null
  let documentsDBName = DOCUMENTS_DB_NAME
  if (usesDatabasePerUser) {
    documentsDBName = userDatabaseName
  } else if (usesRemoteDocuments) {
    documentsDBName = null
    remoteDocumentsDBName = params.documentsDBName || DOCUMENTS_DB_NAME
  } else {
    documentsDBName = params.documentsDBName || DOCUMENTS_DB_NAME
  }

  return {
    ledgerDbName: userDatabaseName || params.ledgerDbName || LEDGER_DB_NAME,
    remoteLedgerDBName: userDatabaseName || params.remoteLedgerDBName || LEDGER_DB_NAME,
    orderDbName: userDatabaseName || params.orderDbName || ORDER_DB_NAME,
    stockCountDBName: userDatabaseName || params.stockCountDBName || STOCK_COUNT_DB_NAME,
    stockCountReconciledDBName: params.stockCountReconciledDBName || STOCK_COUNT_RECONCILED_DB_NAME,
    alertDbName: userDatabaseName || params.alertDbName || ALERT_DB_NAME,
    shipmentsDbName: userDatabaseName || params.shipmentsDbName || SHIPMENTS_DB_NAME,
    integratedDataDBName: userDatabaseName || params.integratedDataDBName || INTEGRATED_DATA_DB_NAME,
    proposalsDBName: userDatabaseName || params.proposalsDBName || PROPOSALS_DB_NAME,
    masterDataDBName: userDatabaseName || params.masterDataDBName || MASTER_DATA_DB_NAME,
    documentsDBName,
    remoteDocumentsDBName
  }
}
