import React from 'react'
import { isAfter, subDays, isValid } from 'date-fns'

import { Form, Button, Page } from '@fielded/shared-ui'

import { Arrow } from '@fielded/shared-ui/src/icons'
import { formatDate, dateFormats } from '../../../../van-shared/utils'
import DayPickerInput from '../../../../van-shared/components/DayPickerInput'

class NewShipmentMetadata extends React.Component {
  constructor (props) {
    super(props)
    const { shipment } = this.props
    this.state = {
      date: shipment.date || new Date().toJSON(),
      dateFieldError: false,
      origin: shipment.origin || undefined,
      destination: shipment.destination || undefined,
      program: shipment.program || undefined,
      funderId: shipment.funderId ? shipment.funderId : null
    }
  }

  setProgram = (program) => {
    const selectedProgram = {
      id: program.value,
      name: program.label
    }
    this.setState({
      ...this.state,
      program: selectedProgram
    })
  }

  setDestination = (target, program) => {
    const { locations } = this.props
    const destination = locations[program.id].destinations.find((location) => location._id === target.value)
    this.setState({
      ...this.state,
      destination
    })
  }

  setOrigin = (target, program) => {
    const { locations } = this.props
    const location = locations[program.id].origins.find((location) => location.id === target.value)
    this.setState({
      ...this.state,
      origin: location
    })
  }

  setFunder = (target) => {
    this.setState({
      ...this.state,
      funderId: target.value
    })
  }

  submit = () => {
    const { handleSubmit } = this.props
    if (!this.state.date) {
      return
    }

    handleSubmit(this.state)
  }

  validateDate = (date) => {
    const yesterday = subDays(new Date(), 1)
    return isAfter(date, yesterday) && isValid(new Date(date))
  }

  render () {
    const { date, funderId, program, dateFieldError } = this.state
    const { locations, cancel, funders, programs, shipment } = this.props
    let destinationOptions = []
    let originOptions = []
    let funderOptions = []
    const displayPrograms = programs.filter(program => locations[program.id].destinations.length > 0)
      .map(program => ({ value: program.id, label: program.name }))
    const isEdit = !!shipment.rev

    if (program) {
      const programId = program.id
      destinationOptions = locations[programId].destinations.map((location) => ({ label: location.name, value: location._id }))
      originOptions = locations[programId].origins.map((origin) => ({ label: origin.name, value: origin.id }))
      funderOptions = funders.filter(funder => Object.keys(funder.programs).includes(programId))
        .map((f) => ({ value: f._id, label: f.name }))
    }

    return (
      <Form onSubmit={this.submit} className='new-psm-shipment__container'>
        <Page.Intro title='Enter details'>
          <Form.Section>
            <Form.Row>
              <Form.Field
                fieldName='program'
                labelText='Program'
              >
                <Form.Field.Select
                  className='new-psm-shipment__select-input'
                  defaultValue={program ? { label: program.name, value: program.id } : undefined}
                  options={displayPrograms}
                  placeholder={'Select a program'}
                  onChange={this.setProgram}
                  required='Program is required'
                  creatable={false}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                fieldName='origin'
                labelText='Origin'
              >
                <Form.Field.Select
                  options={originOptions}
                  onChange={value => this.setOrigin(value, program)}
                  placeholder={'Select an origin...'}
                  defaultValue={shipment.origin ? { label: shipment.origin.name, value: shipment.origin.id } : undefined}
                  required='Origin is required'
                  clearable={false}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                fieldName='destination'
                labelText='Destination'
              >
                <Form.Field.Select
                  className='new-psm-shipment__select-input'
                  options={destinationOptions}
                  onChange={value => this.setDestination(value, program)}
                  placeholder={'Select a destination...'}
                  defaultValue={shipment.destination
                    ? destinationOptions.find(destinationOption => destinationOption.value === shipment.destination._id || shipment.destination.id) : undefined}
                  required='Destination is required'
                  clearable={false}
                  disabled={isEdit}
                />
                <Form.Field.ValidationErrors />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                fieldName='funder'
                labelText='Funder'
              >
                <Form.Field.Select
                  options={funderOptions}
                  onChange={this.setFunder}
                  defaultValue={funderId ? funderOptions.find(funder => funder.value === funderId) : undefined}
                  placeholder={'Select a funder...'}
                  creatable={false}
                />
              </Form.Field>
            </Form.Row>
            <Form.Row>
              <Form.Field
                fieldName='date'
                labelText='Date of Arrival:'
              >
                <DayPickerInput
                  initialValue={formatDate(date, 'long') || ''}
                  required
                  disabledInput={isEdit}
                  callbackFunction={date => {
                    if (date) {
                      date = date.toJSON()
                    }
                    const isValidDate = this.validateDate(date)
                    return this.setState({
                      date,
                      dateFieldError: !isValidDate
                    })
                  }}
                  dateFormat={dateFormats.long}
                  disabled={{ before: new Date() }}
                />
              </Form.Field>
            </Form.Row>
            {dateFieldError && (
              <div
                className='vs-form-field-errors__message vs-u-margin-top'
              >
                You can only select future dates.
              </div>
            )}
          </Form.Section>
        </Page.Intro>
        <Page.Footer fixed>
          <Form.Actions className='new-psm-shipment__button-wrapper'>
            <Form.Button
              icon={<Arrow direction='right' />}
              iconSide='right'
              fill='full'
              colorVariant='brand'
              type='submit'
              disabled={dateFieldError}
            >
              Next
            </Form.Button>
            <Button
              onClick={cancel}
              colorVariant='brand'
              alignOpposite
            >
              Cancel
            </Button>
          </Form.Actions>
        </Page.Footer>
      </Form>
    )
  }
}

export default NewShipmentMetadata
