/** Generate and verify passwords
 *
 * This module provides functions to generate and verify passwords.
 * It uses the PBKDF2 key derivation function, because that is available
 * as part of webcrypto, which comes standard with node.js and the browser
 * and we want the verification to work offline in the browser.
 *
 * Warning: The code here is not necessarily secure. It is written with
 *          the assumption, that the client already knows the password hash
 *          and that the client is allowed to bypass the password check
 *          manually. The password check should only be inconvenient to crack.
 *          Do not use this for anything that should be really secure.
 */

/**
 * @typedef {{ version: string, salt: string, bits: string }} Key
 * @typedef {{ saltSize: number, hash: string, iterations: number, chars: 'string', length: number }} Config
 */

/** Base64 encode string. Works in the browser and node.js.
 *
 * This function doesn't handle unicode in the browser, but
 * works for the use case here.
 *
 * @param {string} data
 * @returns {string}
 */
function base64Encode (data) {
  if (typeof window !== 'undefined') {
    return window.btoa(data)
  }
  return Buffer.from(data, 'utf-8').toString('base64')
}

/** Base64 decode string. Works in the browser and node.js.
 *
 * This function doesn't handle unicode in the browser, but
 * works for the use case here.
 *
 * @param {string} data
 * @returns {string}
 */
function base64Decode (encodedData) {
  if (typeof window !== 'undefined') {
    return window.atob(encodedData)
  }
  return Buffer.from(encodedData, 'base64').toString('utf-8')
}

/** Password version configs
 *
 * In the future we might want to update the way
 * we generate passwords and can add new version
 * configurations here.
 */
const Configs = {
  v1: {
    saltSize: 8,
    hash: 'SHA-256',
    iterations: 65536,
    chars: '0123456789',
    length: 4
  }
}

/** Byte lengths of hash function output
 *
 * The hash function output size is used for the
 * number of chars to be generated by the key
 * derivation function.
 */
const HashOutputLengths = {
  'SHA-1': 160,
  'SHA-256': 256,
  'SHA-384': 384,
  'SHA-512': 512
}

/** Get the byte length of the hash function output
 * @param {string} hash
 * @returns {number}
 */
function getHashOutputLength (hash) {
  const len = HashOutputLengths[hash]
  if (!len) {
    throw new Error('Unsupported hash')
  }
  return len
}

/** Get the version configuration
 * @param {string} version
 * @returns {Config}
 */
function getConfig (version = 'v1') {
  const params = Configs[version]
  if (!params) {
    throw new Error('Unsupported version')
  }
  return params
}

/** Derive the key bits using PBKDF2 from a string and salt
 *
 * @param {Crypto} crypto
 * @param {string} password
 * @param {Object} options
 * @param {ArrayBuffer} options.salt
 * @param {string} options.hash
 * @param {number} options.iterations
 * @returns {Promise<ArrayBuffer>}
 */
async function deriveKeyBits (crypto, password, { salt, hash, iterations }) {
  // Convert string to a TypedArray.
  const bytes = new TextEncoder().encode(password)

  // Create the base key to derive from
  const key = await crypto.subtle.importKey('raw', bytes, 'PBKDF2', false, ['deriveBits'])

  // Derive hash function output length bits using PBKDF2
  const hlen = getHashOutputLength(hash)
  const params = { name: 'PBKDF2', hash, salt, iterations }
  return crypto.subtle.deriveBits(params, key, hlen)
}

/** Compare two array buffers for equality
 *
 * @param {ArrayBuffer} a
 * @param {ArrayBuffer} b
 * @retuns {boolean}
 */
function compareArrayBuffers (a, b) {
  let equal = true
  const x = new Int8Array(a)
  const y = new Int8Array(b)
  for (let i = 0; i < x.byteLength; ++i) {
    equal = equal && i < y.byteLength && x[i] === y[i]
  }
  return equal
}

/** Verify a password against a key
 *
 * @param {Crypto} crypto
 * @param {string} password
 * @param {Object} key
 * @param {ArrayBuffer} key.bits
 * @param {ArrayBuffer} key.salt
 * @param {string} key.hash
 * @param {number} key.iterations
 * @returns {Promise<boolean>}
 */
async function verifyPassword (crypto, password, key) {
  const { bits: keyBits, salt, hash, iterations } = key
  const pwBits = await deriveKeyBits(crypto, password, { salt, hash, iterations })
  return compareArrayBuffers(pwBits, keyBits)
}

/** Generate a random password string
 *
 * @param {Crypto} crypto
 * @param {string} chars Allowed characters in the password
 * @param {number} length Length of the password
 * @returns {string}
 */
function generatePassword (crypto, chars, length) {
  let password = ''
  // max random value with a Uint32Array
  const maxUint32 = 2 ** 32
  // To avoid bias from the modulo, limit the end of the range
  // of random numbers to the max random number evenly divisable
  // by chars.length.
  const limit = maxUint32 - maxUint32 % chars.length
  for (let i = 0; i < length; ++i) {
    let r
    // Generate a random number until we find one inside the range
    do {
      r = crypto.getRandomValues(new Uint32Array(1))[0]
    } while (r >= limit)
    password += chars[r % chars.length]
  }
  return password
}

/** Generate a password and a key
 *
 * @param {Crypto} crypto
 * @param {string} version
 * @returns {Promise<{ password: string, key: Key }>}
 */
async function generate (crypto, version = 'v1') {
  const config = getConfig(version)
  const password = generatePassword(crypto, config.chars, config.length)
  const salt = crypto.getRandomValues(new Uint8Array(config.saltSize))
  const deriveParams = {
    salt,
    hash: config.hash,
    iterations: config.iterations
  }
  const bitsArr = await deriveKeyBits(crypto, password, deriveParams)
  return {
    password,
    key: {
      version,
      // encode strings from typed arrays
      salt: base64Encode(String.fromCharCode(...salt)),
      bits: base64Encode(String.fromCharCode(...new Uint8Array(bitsArr)))
    }
  }
}

/** Check a password against the key
 *
 * @param {Crypto} crypto
 * @param {string} password
 * @param {Key} key
 * @returns {Promise<boolean>}
 */
async function verify (crypto, password, key) {
  const config = getConfig(key.version)
  const bits = base64Decode(key.bits)
  const salt = base64Decode(key.salt)
  return verifyPassword(
    crypto,
    password,
    {
      // decode bit strings to typed array
      bits: Uint8Array.from([...bits].map(c => c.charCodeAt(0))),
      salt: Uint8Array.from([...salt].map(c => c.charCodeAt(0))),
      hash: config.hash,
      iterations: config.iterations
    }
  )
}

module.exports = {
  getConfig,
  generate,
  verify
}
