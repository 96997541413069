const { calculateStartDate, generatePaymentPlanData } = require('../../payment-plans/tools')
const { smartId } = require('../../tools')
const statementXlsExport = require('./statement-xls-export')
const sumBy = require('lodash/sumBy')
const { format } = require('date-fns')
const { MEMBERSHIPS } = require('../../location/tools/constants')
const { DIRECT_ORDER_TYPES } = require('../../allocation/config')

const calculateManagedCreditFee = (loanLimit) => {
  let managedCredit = 0
  if (loanLimit > 0 && loanLimit <= 50000) {
    managedCredit = 500
  } else if (loanLimit > 50000 && loanLimit <= 150000) {
    managedCredit = 1300
  } else if (loanLimit > 150000 && loanLimit <= 500000) {
    managedCredit = 3250
  } else if (loanLimit > 500000) {
    managedCredit = 5000
  }

  return managedCredit
}

const groupTransactions = (transactionList) => {
  return transactionList.reduce((acc, transaction) => {
    const dueDate = new Date(transaction.due_date)
    const startDate = calculateStartDate(dueDate, transaction.paymentOption.frequency)
    const { state } = smartId.parse(transaction.fsid)
    const { paymentPlan, pastDue } = generatePaymentPlanData({
      invoiceData: transaction,
      market: state,
      isPayPerUseClient: transaction.pay_per_use,
      feePercentage: transaction.fee_percentage,
      feePercentageLongTerm: transaction.fee_percentage_long_term,
      startDate,
      term: transaction.paymentOption.term,
      frequency: transaction.paymentOption.frequency,
      serviceFee: (transaction.paymentOption.service_fee_percentage / 100) * (transaction.amount - transaction.amount_paid)
    })
    transaction.paymentPlan = paymentPlan
    transaction.pastDue = pastDue
    acc[transaction.company_code].push(transaction)
    return acc
  }, {
    KE: [],
    NG: []
  })
}

const calculateBalance = ({
  transactions,
  pinnedTransactions = [],
  loanLimit = null,
  provisionalOrderAmount = null,
  pendingShipments = [],
  serviceFee = 0,
  vatPercentage = 0,
  futureInstallments = [],
  membershipType = null
}) => {
  const debits = transactions
    .filter(transaction => transaction.posting_type === 'debit')
    .map(transaction => ({ ...transaction, amount: -transaction.amount }))

  const credits = transactions
    .filter(transction => transction.posting_type === 'credit')
    .filter(transaction => (transaction.payment_plan && transaction.payment_plan.is_active) || !transaction.payment_plan)
  const pendingCredits = transactions
    .filter(transaction => transaction.posting_type === 'pending-credit')
  const instalmentBalance = futureInstallments.reduce((balance, installment) => {
    const overdue = installment.amount - installment.amount_paid
    balance += overdue
    return balance
  }, 0)

  const creditsBalance = sumBy(credits, 'amount')
  const debitsBalance = sumBy(debits, 'amount')
  const pendingCreditsBalance = sumBy(pendingCredits, 'amount')
  const totalBalance = (creditsBalance + debitsBalance)
  const pendingBalance = totalBalance + pendingCreditsBalance
  const overdueBalance = pinnedTransactions
    .filter(transaction => format(transaction.due_date, 'YYYY-MM-DD') < format(new Date(), 'YYYY-MM-DD'))
    .reduce((balance, transaction) => {
      const overdue = transaction.amount - transaction.amount_paid
      balance += overdue
      return balance
    }, 0)
  const pendingShipmentsAmount = pendingShipments.reduce((acc, prods) => {
    const vatAmount = prods.vat ? (prods.price * vatPercentage) / 100 : 0
    if (membershipType === MEMBERSHIPS.CLASSIC && (prods.subscription_type !== DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)) {
      return acc
    }
    acc = acc + prods.amount + vatAmount
    return acc
  }, 0)
  const addServiceFee = pendingShipmentsAmount || provisionalOrderAmount ? serviceFee : 0
  const loanLimitBalance = loanLimit + pendingBalance - (provisionalOrderAmount + pendingShipmentsAmount + addServiceFee + instalmentBalance)
  const pendingDeliveries = pendingShipmentsAmount + provisionalOrderAmount

  return {
    balance: totalBalance,
    overdue: overdueBalance,
    pending: pendingBalance,
    loanLimit,
    loanBalance: loanLimitBalance,
    pendingDeliveries,
    instalmentBalance
  }
}

const getCreditInfo = ({
  loanLimit,
  orderStatus,
  totalOutstandingAmount,
  pendingDeliveriesAmount,
  arBalance,
  lrBalance,
  creditApplicationStatus,
  balances,
  loanDeliveryAmountUsed
}) => {
  const cashBalance = (arBalance + lrBalance)
  return {
    creditLimit: loanLimit,
    creditCustomer: !!(loanLimit && loanLimit > 0),
    orderStatus,
    availableCreditBalance: (loanLimit || 0) - loanDeliveryAmountUsed - totalOutstandingAmount,
    pendingDeliveriesAmount,
    cashBalance,
    creditApplicationStatus,
    balances
  }
}

module.exports = {
  calculateManagedCreditFee,
  statementXlsExport,
  groupTransactions,
  calculateBalance,
  getCreditInfo
}
