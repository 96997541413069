const TRANSACTIONS_TABLE_NAME = 'avocado.data_quickbookstransactions'
const PAYMENT_PLAN_TABLE_NAME = '"avocado"."data_paymentplan"'
const INVOICE_PAYMENTPLAN_TABLE_NAME = 'avocado.data_invoicepaymentplan'
const PAYMENT_PLAN_OPTIONS_TABLE_NAME = 'avocado.payment_plan_options'

const TABLE_NAMES = {
  TRANSACTIONS_TABLE_NAME,
  PAYMENT_PLAN_TABLE_NAME,
  INVOICE_PAYMENTPLAN_TABLE_NAME
}

const PAYMENT_PLAN_FREQUENCIES = {
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
  MONTHLY: 'MONTHLY',
  BIMONTHLY: 'BIMONTHLY',
  TRIMONTHLY: 'TRIMONTHLY',
  FIFTEEN_DAYS: 'FIFTEEN_DAYS',
  FORTY_FIVE_DAYS: 'FORTY_FIVE_DAYS'
}

const PAYMENT_OPTION_BASE_ORDER_AMOUNT = {
  NG: {
    weekly: {
      12: { amount: 5000000 },
      24: { amount: 10000000 }
    },
    fixedTerms: {
      FIFTEEN_DAYS: { amount: 1000000 },
      MONTHLY: { amount: 1000000 }
    }
  },
  KE: {
    weekly: {
      12: { amount: 200000 },
      24: { amount: 1000000 }
    }
  }
}

const ACTIVE_MANDATE_DATE_DELAY = 8
const NG_DEFAULT_PAYMENT_TERM = 4
const KE_DEFAULT_PAYMENT_TERM = 6
const PENDA_DEFAULT_PAYMENT_TERM = 8
const CLINICS_AND_HOSPITALS_DEFAULT_PAYMENT_TERM = 1 // monthly term
const CLINICS_AND_HOSPITALS_DEFAULT_FREQUENCY = PAYMENT_PLAN_FREQUENCIES.MONTHLY
const CLINICS_AND_HOSPITALS_DEFAULT_FEE_PERCENTAGE = {
  KE: 0,
  NG: 0.25
}

module.exports = {
  TABLE_NAMES,
  PAYMENT_PLAN_FREQUENCIES,
  PAYMENT_OPTION_BASE_ORDER_AMOUNT,
  PAYMENT_PLAN_OPTIONS_TABLE_NAME,
  ACTIVE_MANDATE_DATE_DELAY,
  NG_DEFAULT_PAYMENT_TERM,
  KE_DEFAULT_PAYMENT_TERM,
  PENDA_DEFAULT_PAYMENT_TERM,
  CLINICS_AND_HOSPITALS_DEFAULT_PAYMENT_TERM,
  CLINICS_AND_HOSPITALS_DEFAULT_FREQUENCY,
  CLINICS_AND_HOSPITALS_DEFAULT_FEE_PERCENTAGE
}
