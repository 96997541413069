const isArray = require('lodash/isArray')
const PGAdapter = require('./../../common/pg-adapter')
const DATA_SHIPMENT_TABLE_NAME = 'avocado.data_shipment'
const DATA_SHIPMENT_COLUMNS = [
  'id',
  'shipment_id',
  'status',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by',
  'notes',
  'destination_id',
  'origin_id',
  'planning_type',
  'order_id',
  'route_id',
  'funder_id',
  'program_id',
  'vendor_id'
]

const DATA_SHIPMENT_VIRTUAL_COLUMNS = [
  'split_part(destination_id, \':\', 4)',
  'updated_at_gte',
  'updated_at_lte'
]

const DATA_SHIPMENTLINE_TABLE_NAME = 'avocado.data_shipmentline'
const { getPositionalArgs } = require('./../../tools/sql-tools')

class DataShipmentAdapter extends PGAdapter {
  static get lastMileDeliveryFilter () {
    return {
      origin_id: 'zone:%:name:%', // warehouse
      destination_id: 'zone:%:state:%:lga:%:sdp:%'// Health facility
    }
  }

  constructor (pgConnection, username, logger) {
    const opts = {
      getRelated: {
        'lines': {
          relatedTable: DATA_SHIPMENTLINE_TABLE_NAME,
          fk: 'shipment_id'
        }
      },
      'virtualColumns': DATA_SHIPMENT_VIRTUAL_COLUMNS
    }

    const arrayOrStringPreparer = (key, value) => {
      let arrayValue = isArray(value) ? value : [value]
      // We are now sure we have an array of values, if we have just one value, we use equal to
      // If more than one we use ANY
      if (arrayValue && arrayValue.length === 1) {
        const onlyValue = arrayValue[0]
        return [
          { statement: `"${key}"=%INDEX%`, value: onlyValue }
        ]
      }

      return [
        { statement: `"${key}" = ANY(%INDEX%::TEXT[])`, value: arrayValue }
      ]
    }

    const matchAllValuesILikePreparer = (key, value) => {
      let arrayValue = isArray(value) ? value : [value]
      // We are now sure we have an array of values, if we have just one value, we just use ILIKE
      // If more than one we use ILIKE ALL
      if (arrayValue && arrayValue.length === 1) {
        const onlyValue = arrayValue[0]
        return [
          { statement: `"${key}" ILIKE '%'||%INDEX%||'%'`, value: onlyValue }
        ]
      }

      return [
        { statement: `"${key}" ILIKE ALL(%INDEX%::TEXT[])`, value: arrayValue }
      ]
    }

    const columnPreparers = {
      origin_id: matchAllValuesILikePreparer,
      destination_id: matchAllValuesILikePreparer,
      funder_id: arrayOrStringPreparer,
      route_id: arrayOrStringPreparer,
      program_id: arrayOrStringPreparer,
      status: arrayOrStringPreparer,
      created_at: (key, value) => [{statement: `"${key}">=%INDEX%`, value}],
      updated_at_gte: (key, value) => [{statement: `"updated_at">=%INDEX%`, value: value}],
      updated_at_lte: (key, value) => [{statement: `"updated_at"<=%INDEX%`, value: value}],
      // Calculated column: default preparer without adding double quotes to the key
      'split_part(destination_id, \':\', 4)': (key, value) => [{statement: `${key}=%INDEX%`, value}]
    }

    super(
      pgConnection,
      DATA_SHIPMENT_TABLE_NAME,
      username,
      DATA_SHIPMENT_COLUMNS,
      undefined,
      columnPreparers,
      logger,
      opts
    )
  }

  async getPendingDeliveries ({locationId, weeks}) {
    let weekFilter = ''
    if (typeof (weeks) === 'number') {
      weekFilter = `AND s.created_at + '${weeks} week'::INTERVAL >= current_date`
    }
    const query = `
      select
        s.id,
        s.shipment_id,
        s.status,
        s.created_at,
        s.updated_at,
        s.created_by,
        s.updated_by,
        s.notes,
        s.destination_id,
        s.origin_id,
        s.planning_type,
        sl.sku,
        sl.amount as quantity,
        case when sl.order_invoicing_type = 'postpaid'
            then 'pay_on_delivery'
            else 'pay_as_you_sell'
        end as subscription_type,
        CAST(sp.sellprice as DOUBLE PRECISION) as price,
        (CAST(sp.sellprice  as DOUBLE PRECISION) * CAST(sl.amount as DOUBLE PRECISION)) as amount,
        coalesce(ps.vat, false) as vat,
        case 
          when coalesce(ql.invoice_txn_id, '') != '' or coalesce(ql.creditmemo_txn_id, '') != '' then true
          else false
        end as has_existing_transaction
      from avocado.data_shipment s
      left join avocado.data_shipmentline sl on s.id = sl.shipment_id
      left join avocado.data_location l on l.fsid = s.destination_id
      left join avocado.data_productsku ps on ps.old_sku_id = sl.sku
      left join real.sellprice_daily sp on sp.sku = sl.sku and sp.date = s.updated_at::date
      left join avocado.data_invoice_line il on il.shipment_id = s.shipment_id and il.sku = sl.sku
      left join avocado.data_invoice i on i.id = il.invoice_id
      left join avocado.data_invoice_quickbooks ql on ql.invoice_id = i.id
      where (s.status != 'received' or (ql.invoice_txn_id is null and ql.creditmemo_txn_id is null))
        and sl.order_invoicing_type = 'postpaid'
        and sl.amount > 0
        and destination_id = $1
        and (l.service_start_date is null or sl.created_at > l.service_start_date)
        ${weekFilter}
      order by s.created_at
    `
    const { rows } = await this.pgConnection.query(query, [locationId])
    return rows
  }

  async getShipments ({
    originId,
    destinationId,
    status = [],
    weeks = 0 // get shipments in the past n weeks
  }) {
    if (!originId && !destinationId) {
      throw new Error(`at least one of 'originId' or 'destinationId' is required`)
    }
    const params = []
    let query = `
      SELECT
        s.id,
        s.shipment_id,
        s.status,
        s.origin_id,
        s.destination_id,
        s.planning_type,
        s.order_id,
        concat('product:', sl.sku) as sku,
        sl.amount as quantity,
        s.created_at,
        s.updated_at
      FROM ${DATA_SHIPMENT_TABLE_NAME} s
      LEFT JOIN ${DATA_SHIPMENTLINE_TABLE_NAME} sl on s.id = sl.shipment_id
      WHERE
    `
    if (originId) {
      params.push(originId)
      query = query.concat(` origin_id = $${params.length}`)
    }
    if (destinationId && params.length >= 1) {
      params.push(destinationId)
      query = query.concat(` AND destination_id = $${params.length}`)
    } else if (destinationId && params.length === 0) {
      params.push(destinationId)
      query = query.concat(` destination_id = $${params.length}`)
    }
    if (status.length > 0) {
      const start = params.length + 1
      const args = getPositionalArgs(status, start)
      params.push(...status)
      query = query.concat(` AND status IN (${args})`)
    }
    if (typeof (weeks) === 'number') {
      query = query.concat(` AND s.created_at + '${weeks} week'::INTERVAL >= current_date order by s.created_at`)
    }
    const { rows } = await this.pgConnection.query(query, params)
    return rows
  }

  async getLastMileDeliveriesFilterValues (selectedFilter) {
    const tableName = 'data_shipment'
    const filteringTableColumns = [
      'route_id',
      'program_id',
      'funder_id',
      // TODO, we don't sync this prop yet to RDS
      // 'delivery_date',
      'vendor_id',
      'created_at',
      'origin_id',
      'destination_id',
      'status'
    ]

    const filteringCalculatedColumns = [
      { name: 'state', expression: 'split_part(destination_id, \':\', 4)' }
    ]

    const preFilter = {...DataShipmentAdapter.lastMileDeliveryFilter, ...selectedFilter}
    return this.getColumnsUniqueValues(
      filteringTableColumns,
      filteringCalculatedColumns,
      preFilter,
      tableName,
      this.columnPreparers
    )
  }

  async getShipmentProgramId (shipmentId) {
    if (!shipmentId) {
      throw new Error('shipmentId required to get program id')
    }

    const { rows } = await this.pgConnection.query(`
      select
        coalesce(o.program_id, s.program_id) as id,
        replace(coalesce(o.program_id, s.program_id), 'program:', '') as name
      from avocado.data_shipment s
      inner join avocado.data_order o on s.order_id = o.order_id and s.destination_id = o.destination_id
      where s.shipment_id = $1
    `, [shipmentId])
    return rows[0]
  }
}

module.exports = DataShipmentAdapter
