exports.getHeaders = function (inputPrograms, template) {
  const programs = filterPrograms(inputPrograms)
  const headers = [
    'additionalData.warehouseCode',
    'fullName',
    'level',
    'location.zone',
    'location.state',
    'location.lga',
    'location.sdp',
    'facilityId',
    ...getServiceHeaders(programs),
    ...getContactHeaders(programs),
    ...getFunderHeaders(programs),
    ...getImplementingPartnerHeaders(programs),
    'additionalData.hasPcr',
    'additionalData.physicalAddress',
    'additionalData.ward',
    ...getAdditionalDataHeaders(programs),
    ...getCoordinateHeaders('coordinates'),
    '_id'
  ]
  if (template) {
    headers.unshift('changeType')
  }
  return headers
}

function filterPrograms (programs) {
  return programs
    .reduce((acc, program) => {
      if (program.id.includes('immunization')) return acc
      // TODO: programs doc in PSM has immunization, check if we can
      // remove it from the doc entirely
      const services = program.services.filter(service =>
        !service.id.includes(':service:immunization') &&
        !service.id.includes(':service:warehouse') &&
        !service.notActive
      )
      return acc.concat([Object.assign({}, program, {services})])
    }, [])
}

// want: program:malaria:service:malaria
// =>   programs.malaria.services.malaria
function getServiceHeaders (programs) {
  return programs
    .reduce((acc, program) => acc.concat(program.services.map(service => service.id)), [])
    .map(id => id.replace('program:', 'programs.').replace(':service:', '.services.'))
}

// for each program, we want name & phone:
// 'contacts.program:mnch.name',
// 'contacts.program:mnch.phone',
function getContactHeaders (programs) {
  return programs
    .reduce((acc, program) =>
      acc.concat([`contacts.${program.id}.name`, `contacts.${program.id}.phone`]),
    []
    )
}

// for each program, we want:
// 'funders.family-planning'
function getFunderHeaders (programs) {
  return programs.map(({id}) => `funders.${id.replace('program:', '')}`)
}

function getImplementingPartnerHeaders (programs) {
  return programs.map(({id}) => `implementingPartners.${id.replace('program:', '')}`)
}

function getCoordinateHeaders (field = 'coordinates') {
  return ['lat', 'long'].map(point => `additionalData.${field}.${point}`)
}

// 'additionalData.programs.family-planning.designation',
function getAdditionalDataHeaders (programs) {
  return programs
    .reduce((acc, program) => {
      const baseId = program.id.replace('program:', 'programs.')
      return acc.concat([
        `additionalData.${baseId}.contactLab`,
        `additionalData.${baseId}.designation`,
        `additionalData.${baseId}.designationLab`,
        `additionalData.${baseId}.email`,
        `additionalData.${baseId}.labEmail`,
        `additionalData.${baseId}.pharmacyDesignation`,
        `additionalData.${baseId}.pharmacyEmail`,
        `additionalData.${baseId}.phoneNumberLab`,
        `additionalData.${baseId}.serviceType`,
        `additionalData.${baseId}.siteId`
      ])
    }, [])
}
