const get = require('lodash/get')
const sortBy = require('lodash/sortBy')
const keyBy = require('lodash/keyBy')
const XLSX = require('@sheet/core')
const { parse } = require('../tools/smart-id')
const format = require('date-fns/format')
const { PSM_WAREHOUSE_DEFINITIONS } = require('../order/tools/read/warehouse-code-to-virtual')

const getPlannedQuantities = (shipment) => {
  const snapshotStatus = ['new', 'packed', 'received']
  const acc = {}

  for (const delivery of Object.values(shipment.history)) {
    for (const status of snapshotStatus) {
      const snapshotDate = shipment.snapshotDates[status]
      if (snapshotDate && snapshotDate === delivery.createdAt) {
        for (const [productKey, { quantity }] of Object.entries(delivery.counts)) {
          const productId = productKey.split(':manufacturer')[0]
          acc[productId] = acc[productId] || {}
          acc[productId][status] = quantity
        }
      }
    }
  }

  return acc
}

const formatDate = (date) => {
  if (!date) return

  const newDate = new Date(date)
  return format(newDate, 'YYYY-MM-DD')
}

const exportLMDShipmentsData = async function (state, { api, shipments }) {
  // Get all products from shipments
  const productIds = [
    ...shipments.reduce((acc, shipment) => {
      Object.keys(shipment.counts).forEach(product => {
        acc.add(product.split(':manufacturer')[0])
      })
      return acc
    }, new Set())
  ]
  const allProductsDoc = await api.product.getByIds(productIds)
  const productsById = keyBy(allProductsDoc, '_id')

  // Get all destination locations
  const destinationIds = shipments.map(shipment => get(shipment, 'destination.id', ''))
  const destinationLocations = await api.location.getByIds(destinationIds)

  // Get all routes
  const routes = await api.routes.list()
  const routesById = keyBy(routes, '_id')

  const shipmentLineExport = []
  for (const [index, shipment] of shipments.entries()) {
    const destination = destinationLocations[index]
    const destinationId = get(shipment, 'destination.id', '')
    const { state: destinationState, lga } = parse(destinationId)

    const originId = get(shipment, 'origin.id', '')
    const warehouse = Object.values(PSM_WAREHOUSE_DEFINITIONS).find(wh => originId.includes(wh._id))

    const routeId = get(shipment, 'route.id', '')
    const route = get(routesById[routeId], 'name', '')

    const deliveryQuantitiesHistory = getPlannedQuantities(shipment)
    Object.entries(deliveryQuantitiesHistory).map(([productKey, statusQuantities]) => {
      const [productId] = productKey.split(':manufacturer')
      const productDetail = productsById[productId]
      shipmentLineExport.push({
        orderId: get(shipment, 'orderId', ''),
        shipmentNo: get(shipment, 'shipmentNo', ''),
        facilityCode: get(destination, 'alias.hrfc', ''),
        facilityName: get(destination, 'name', ''),
        facilityState: destinationState,
        facilityLGA: lga,
        warehouseCode: get(warehouse, 'warehouseCode', ''),
        warehouseName: get(warehouse, 'name', ''),
        productId: get(productDetail, '_id', ''),
        productName: get(productDetail, 'name', ''),
        productCode: get(productDetail, 'code', ''),
        quantityOrdered: get(statusQuantities, 'new', 0),
        quantityPacked: get(statusQuantities, 'packed', 0),
        quantityReceived: get(statusQuantities, 'received', 0),
        unitVolume: get(productDetail, 'unitVolume', 0),
        unitPrice: get(productDetail, 'unitPrice', 0),
        funderId: get(shipment, 'funder.id', ''),
        route,
        programId: get(shipment, 'programId', ''),
        vendorId: get(shipment, 'vendorId'),
        driverName: get(shipment, 'driverName', ''),
        status: get(shipment, 'status', ''),
        createdAt: formatDate(get(shipment, 'createdAt')),
        pickUpDate: formatDate(get(shipment, 'snapshotDates.sent', '')),
        deliveryDate: formatDate(get(shipment, 'snapshotDates.received', ''))
      })
    })
  }

  const sortedLMDs = sortBy(shipmentLineExport, ['destinationId', 'createdAt'])

  const columns = [
    {
      name: 'Order ID',
      key: 'orderId'
    }, {
      name: 'Route',
      key: 'route'
    }, {
      name: 'Shipment No',
      key: 'shipmentNo'
    }, {
      name: 'Warehouse Code',
      key: 'warehouseCode'
    }, {
      name: 'Warehouse Name',
      key: 'warehouseName'
    }, {
      name: 'Facility Code',
      key: 'facilityCode'
    }, {
      name: 'Facility Name',
      key: 'facilityName'
    }, {
      name: 'Facility State',
      key: 'facilityState'
    }, {
      name: 'Facility LGA',
      key: 'facilityLGA'
    }, {
      name: 'Product ID',
      key: 'productId'
    }, {
      name: 'Product Name',
      key: 'productName'
    }, {
      name: 'Product Code',
      key: 'productCode'
    }, {
      name: 'Quantity Ordered',
      key: 'quantityOrdered'
    }, {
      name: 'Quantity Packed',
      key: 'quantityPacked'
    }, {
      name: 'Quantity Received',
      key: 'quantityReceived'
    }, {
      name: 'Unit Volume',
      key: 'unitVolume'
    }, {
      name: 'Unit Price',
      key: 'unitPrice'
    }, {
      name: 'Funder',
      key: 'funderId'
    }, {
      name: 'Program',
      key: 'programId'
    }, {
      name: 'Carrier Name',
      key: 'vendorId'
    }, {
      name: 'Driver Name',
      key: 'driverName'
    }, {
      name: 'Status',
      key: 'status'
    }, {
      name: 'Creation Date',
      key: 'createdAt'
    },
    {
      name: 'Pickup Date',
      key: 'pickUpDate'
    },
    {
      name: 'Delivery Date',
      key: 'deliveryDate'
    }
  ]

  const headers = columns.map(column => column.name)
  const data = sortedLMDs.map(event => {
    return columns.map(column => {
      return get(event, column.key, '')
    })
  })
  return [headers].concat(data)
}

const generateExcelSheet = (aoa) => {
  return new Promise((resolve) => {
    const ws = XLSX.utils.aoa_to_sheet(aoa)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'last-mile-deliveries-export')
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array'
    })
    resolve(new global.Blob([wbout], {type: 'application/octet-stream'}))
  })
}

async function exportLMDShipments (state, params) {
  const data = await exportLMDShipmentsData(state, params)

  if (params.dryRun) return data

  return generateExcelSheet(data)
}
module.exports = { exportLMDShipments }
