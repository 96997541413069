import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../Button'

class ExpandableOptions extends Component {
  state = {
    isExpanded: this.props.isExpanded
  }

  toggleExpand = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    },
    () => {
      this.state.isExpanded ? this.registerBoundClose() : this.unregisterBoundClose()
    })

    if (this.props.onExpand) {
      this.props.onExpand(this.state.isExpanded)
    }
  }

  closeExpand = () => {
    this.setState({
      isExpanded: false
    },
    () => this.unregisterBoundClose())

    if (this.props.onExpand) {
      this.props.onExpand(this.state.isExpanded)
    }
  }

  boundClose = event => {
    const { closeOnTheClickInside } = this.props
    // Don't close if we click within the component
    if (!closeOnTheClickInside && (this.toggleButton.contains(event.target) || (this.expandWrapper && this.expandWrapper.contains(event.target)))) {
      return
    }

    this.closeExpand()
  }

  registerBoundClose = () => {
    this.expandWrapper.focus()
    document.body.addEventListener('click', this.boundClose, false)
  }

  unregisterBoundClose = () => {
    document.body.removeEventListener('click', this.boundClose)
  }

  componentDidMount () {
    if (this.props.isExpanded) {
      this.registerBoundClose()
    }
  }

  componentWillUnmount () {
    this.unregisterBoundClose()
  }

  componentDidUpdate (prevProps) {
    // if the expandable state is managed from outside we update it when prop is changed
    if (this.props.isExpanded !== null && (this.state.isExpanded !== this.props.isExpanded)) {
      this.setState({
        isExpanded: this.props.isExpanded
      })
    }
  }

  render () {
    const {
      label,
      labelClassName,
      expandedLabel,
      visibleChildren,
      expandableChildren,
      expandWrapperClassName,
      inlineLabel,
      expandSide,
      withoutArrow
    } = this.props

    const {
      isExpanded
    } = this.state

    return (
      <div
        className={classnames(
          'expandable-options',
          { 'expandable-options--is-expanded': isExpanded },
          { 'expandable-options--side': expandSide },
          { 'expandable-options--side-right': expandSide === 'right' }
        )}
      >
        <div
          className={classnames(
            'expandable-options__label',
            { 'expandable-options__label--inline': inlineLabel },
            labelClassName
          )}
          ref={(el) => { this.toggleButton = el }}
        >
          {expandableChildren ? (
            <Button
              colorVariant='brand'
              icon={!withoutArrow && <span className='expandable-options__toggle-icon' />}
              iconSide='right'
              selected={isExpanded}
              onClick={this.toggleExpand}
            >
              {(isExpanded && expandedLabel) ? expandedLabel : label}
            </Button>
          ) : (
            <span>
              {(isExpanded && expandedLabel) ? expandedLabel : label}
            </span>
          )}
        </div>
        {visibleChildren && (
          <span>
            {visibleChildren}
          </span>
        )}
        {(expandableChildren && isExpanded) && (
          <div
            className={classnames(
              'expandable-options__options-expand',
              expandWrapperClassName
            )}
            ref={(el) => { this.expandWrapper = el }}
          >
            {expandableChildren}
          </div>
        )}
      </div>
    )
  }
}

ExpandableOptions.propTypes = {
  /**
   * Trigger label
   */
  label: PropTypes.string,
  /**
   * Custom class for label
   */
  labelClassName: PropTypes.string,
  /**
   * Trigger label when expanded
   */
  expandedLabel: PropTypes.string,
  /**
   * Children that are always visible
   */
  visibleChildren: PropTypes.element,
  /**
   * Children that will appear when expanded
   */
  expandableChildren: PropTypes.element,
  /**
   * Custom class for expandable area
   */
  expandWrapperClassName: PropTypes.string,
  /**
   *
   */
  inlineLabel: PropTypes.bool,
  /**
   * From where the expandable area will appear, 'left' or 'right'
   */
  expandSide: PropTypes.oneOf(['left', 'right']),
  /**
   * Shows / hides the expandable part
   */
  isExpanded: PropTypes.bool,
  /**
   * Callback when expands / collaps
   */
  onExpand: PropTypes.func
}

ExpandableOptions.defaultProps = {
  isExpanded: null,
  expandSide: 'left'
}

export default ExpandableOptions
