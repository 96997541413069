import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { hasFeature } from '../../../van-shared/utils/features'
export const isLongHaulFeatureEnabled = (user, config) => {
  return (
    (userIsAuthorised(user, 'feature:planning') && hasFeature(config, 'features.longhaul')) ||
    // Temporarily introduced to manually enable feature on prod and test performance there
    // To be removed when not needed anymore
    userIsAuthorised(user, 'feature:test-prod-longhaul')
  )
}
