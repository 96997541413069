// See docs/Product-Aliases
const { getTerritoryForProduct } = require('../../service/tools/territory-lookup')
const UNKNOWN_PRODUCT_ALIAS_ERROR = exports.UNKNOWN_PRODUCT_ALIAS_ERROR = 'unknown-product-alias'

exports.translateTerritoryAlias = translateTerritoryAlias
function translateTerritoryAlias (productId, aliasesByService, serviceId, options = {throwErrorIfUnknown: false}) {
  const {throwErrorIfUnknown} = options

  if (!(productId && aliasesByService && serviceId &&
    typeof productId === 'string' &&
    typeof serviceId === 'string' &&
    typeof aliasesByService === 'object')) {
    throw new Error(`Usage: expected productId, aliasesByService, serviceId, found ${productId} ${aliasesByService} ${serviceId}`)
  }

  // We do not create aliases for basic services so use actual service alias
  if (serviceId.includes('basic-tier')) {
    serviceId = serviceId.replace('-basic-tier', '')
  }

  const alias = aliasesByService[serviceId] && aliasesByService[serviceId][productId]

  if (!alias) {
    const { service: productService } = getTerritoryForProduct(productId) || {}
    if (productService !== serviceId) {
      const failMessage = `We can't find an alias for ${productId} for service ${serviceId}`
      if (throwErrorIfUnknown) {
        const er = new Error(failMessage)
        er.details = {type: UNKNOWN_PRODUCT_ALIAS_ERROR}
        throw new Error(er)
      } else {
        console.warn(failMessage)
      }
    }
    return productId
  }
  return alias
}

exports.productAliasesByMarket = productAliasesByMarket
function productAliasesByMarket (allProducts, serviceId) {
  const productsToUse = Array.isArray(allProducts)
    ? allProducts
    : Object.values(allProducts)

  if (productsToUse.length === 0) return {}

  // We do not create aliases for basic services so use actual service alias
  if (serviceId && serviceId.includes('basic-tier')) {
    serviceId = serviceId.replace('-basic-tier', '')
  }

  const result = {}

  // Get the first product as a default service
  // For local calls we only have one market sku
  // For online calls we have every market
  // Unless serviceId is provided (can be needed for retailer translations)
  let defaultService = serviceId
  if (!serviceId) {
    const { service } = getTerritoryForProduct(productsToUse[0]._id) || {}
    defaultService = service
  }

  if (defaultService) {
    result[defaultService] = {}
  }

  for (const product of productsToUse) {
    const aliases = product.alias
    if (!aliases) continue

    const {service: productService} = getTerritoryForProduct(product._id) || {}
    if (!productService) continue

    for (const [service, [alias]] of Object.entries(aliases)) {
      // We've established a default service
      // We populate that directly from the product doc below
      if (service === defaultService) continue

      // Mapping for default service
      if (defaultService && productService === defaultService) {
        result[defaultService][`product:${alias}`] = product._id
      }

      // Mapping for product itself
      if (!result[service]) {
        result[service] = {}
      }
      result[service][product._id] = `product:${alias}`

      // If we only have one alias stop here
      if (Object.keys(aliases).length === 1) break

      // Map for other aliases on product
      for (const [otherService, [otherAlias]] of Object.entries(aliases)) {
        // We add the second condition for external api calls
        // Where we get all products on couch
        if (otherService !== service && otherService !== defaultService) {
          result[service][`product:${otherAlias}`] = `product:${alias}`
        }
      }
    }
  }

  return result
}
