const docsToShipmentRecords = require('./tools/docs-to-shipment-records')

const getExternalShipment = async function (state, params) {
  let { shipmentNumber } = params

  let shipment

  try {
    const docs = await state.dal.shipment.getExternalShipment(state, shipmentNumber)

    if (docs.length === 0) {
      return []
    }

    const [transformedDocs] = docsToShipmentRecords(null, docs, {})
    shipment = transformedDocs
  } catch (e) {
    throw createError(`Error getting external shipment`, 500)
  }

  return shipment
}

module.exports = getExternalShipment

function createError (message, status) {
  const err = new Error(message)
  err.status = status
  return err
}
