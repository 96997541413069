const { setISOYear, getISOYear, setISOWeek, getISOWeek, setISODay, startOfISOWeek, setYear, setMonth, startOfMonth, startOfQuarter } = require('date-fns')
const toStockCountId = require('../../tools/to-stock-count-id')
const getPeriodFromProgram = require('./get-period-from-program')
const getIdVersion = require('./get-id-version')
const getPreviousPeriodFromProgram = require('./get-previous-period')
const { addTZ, subTZ, formatTZ } = require('./tz')

const createId = ({locationId, service, period, withDate = null}) => {
  const idVersion = getIdVersion(service)
  if (idVersion === 1) {
    return createV1Id({locationId, service, period})
  } else if (idVersion === 2) {
    return createV2Id({locationId, service, period, withDate})
  } else {
    throw new Error('Unknown report id version: ' + idVersion)
  }
}
exports.createId = createId

const createV1Id = ({locationId, service, period}) => {
  return toStockCountId({
    location: locationId,
    service,
    reportingPeriod: period.id
  })
}
exports.createV1Id = createV1Id

const createV2Id = ({locationId, service, period, withDate}) => {
  let id = `${locationId}:${service.id}:period:${period.id}`
  if (withDate) {
    const idDate = new Date(withDate).toJSON()
    id += `:date:${idDate}`
  }
  return id
}
exports.createV2Id = createV2Id

const isV1Id = id => id.match(/(:week)|(:bimonth)/)
exports.isV1Id = isV1Id
const isV2Id = id => id.includes(':period:')
exports.isV2Id = isV2Id

const parsePeriodDateFromReportId = id => {
  const match = id.match(/(\d\d\d\d-[WMwm]\d\d?)/)
  if (!match) {
    throw new Error('Could not parse period date from report id: ' + id)
  }
  const periodId = match[1]
  return periodIdToDate(periodId)
}
exports.parsePeriodDateFromReportId = parsePeriodDateFromReportId

const docToReportingPeriodProps = (service, doc) => {
  // First we find the start date of the period the report was created for
  let date
  if (doc.date && doc.date.period) {
    // Ideally the document has a period start date
    date = new Date(doc.date.period.effectiveStart)
  } else {
    // We don't have a period property, lets parse the id then
    date = parsePeriodDateFromReportId(doc._id)
  }
  // Then we get the period and create the `report.date` property
  const period = getPeriodFromProgram(service.program, date, true)
  const props = {
    reportingPeriod: period.id,
    year: getISOYear(subTZ(period.effectiveStartDate)),
    period: {
      effectiveStart: formatShortDate(period.effectiveStartDate),
      effectiveEnd: formatShortDate(period.effectiveEndDate)
    }
  }
  if (period.definition.periodType.unit === 'week') {
    props.week = getISOWeek(subTZ(period.effectiveStartDate))
  } else if (period.definition.periodType.unit === 'month') {
    props.month = period.effectiveStartDate.getMonth() + 1
  }
  return props
}
exports.docToReportingPeriodProps = docToReportingPeriodProps

const isPeriodId = x => typeof x === 'string' && x.match(/\d\d\d\d-(W|M)\d?\d$/)
exports.isPeriodId = isPeriodId

// Create a date from the period id
// The period id has a format like '2019-M01'
const periodIdToDate = (periodId, {compatMode = false} = {}) => {
  let year, period, time, month, day
  if (periodId.startsWith('quarter:')) {
    [, year, month, day] = periodId.match(/quarter:([^-][^-][^-][^-])-([^-][^-])-([^-][^-])/)
    period = 'Q'
  } else {
    [, , year, period, time] = periodId.match(/(bimonth:|week:)?([^-]*)-(W|M)(.*)/)
  }
  switch (period) {
    case 'Q':
      return startOfQuarter(new Date(year, month - 1, day), 1)
    case 'W':
      let w = setISOYear(new Date(), parseInt(year, 10))
      w = setISOWeek(w, parseInt(time, 10))
      if (compatMode) {
        w = setISODay(w, 5)
      } else {
        w = startOfISOWeek(w)
      }
      w = addTZ(w)
      if (compatMode) {
        return formatShortDate(w)
      }
      return w
    case 'M':
      let m = setYear(new Date(), parseInt(year, 10))
      m = setMonth(m, parseInt(time, 10) - 1)
      m = startOfMonth(m)
      m = addTZ(m)
      if (compatMode) {
        return formatShortDate(m)
      }
      return m
    default:
      throw new Error('Unknown period id format: ' + periodId)
  }
}
exports.periodIdToDate = periodIdToDate

const createV1IdsBetweenDates = ({
  locationId,
  service,
  startDate = '2018-01-01T00:00:00.000Z',
  endDate = new Date()
}) => {
  let currentDate = isPeriodId(endDate) ? periodIdToDate(endDate) : new Date(endDate)
  let sinceDate = isPeriodId(startDate) ? periodIdToDate(startDate) : new Date(startDate)
  let period = getPeriodFromProgram(service.program, currentDate, true)
  const ids = []
  do {
    ids.push(toStockCountId({
      location: locationId,
      service,
      reportingPeriod: period.id
    }))
    period = getPreviousPeriodFromProgram(service.program, period)
  } while (period.effectiveEndDate > sinceDate)
  return ids
}
exports.createV1IdsBetweenDates = createV1IdsBetweenDates

const createV2IdsStartEndKey = ({locationId, service, startDate, endDate}) => {
  const startPeriod = getPeriodFromProgram(service.program, startDate, true)
  const endPeriod = getPeriodFromProgram(service.program, endDate, true)
  return {
    startkey: `${locationId}:${service.id}:period:${startPeriod.id}`,
    endkey: `${locationId}:${service.id}:period:${endPeriod.id}\ufff0`
  }
}
exports.createV2IdsStartEndKey = createV2IdsStartEndKey

const formatShortDate = date => formatTZ(date, 'YYYY-MM-DD')
exports.formatShortDate = formatShortDate
