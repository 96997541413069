const get = require('lodash/get')
const set = require('lodash/set')
const { generateUnknownBatchForProduct } = require('./tools/product-batch-util')
const { get: getService } = require('../service/api/read/get')
const { get: getLocation } = require('../location')
const saveChanges = require('./shipment-save-changes')
const saveCollectionStats = require('./shipment-save-collection-stats')
const PLANNING_TYPES = require('./tools/planning-types')
const COLLECTION_REASONS = require('./tools/collection-reasons')
const { REPORT_BALANCE_FIELD, SHELFLIFE_BALANCE_FIELD } = require('../tools/utils/constants')

async function updateCRQuantities (state, shipment, {
  dryRun = true
} = {}) {
  if (shipment && shipment.planningType !== PLANNING_TYPES.C_R) {
    throw new Error('Only collect-redistribute shipments (shelflife) can be used with this')
  }

  if (shipment && shipment.status !== 'new') {
    return shipment
  }

  const locationId = shipment.origin.id
  const location = await getLocation(state, locationId)
  location.id = locationId

  const serviceId = get(location, 'programs[0].services[0].id')
  const service = await getService(state, serviceId)

  const ledger = await this.mainApi.stock.getLedgerBalanceAsReport({
    locationId,
    service,
    excludeProducts: 'configuration',
    calculateFields: true
  })

  // TODO: would be really helpful to fix the call where getList is called
  // different server-side / client side!
  const salesStats = await this.mainApi.salesStats.getList({ locationId, applyLatestAndOOC: true })
  const productsWithSLBalance = Object.keys(ledger.stock)
    .filter(productId => {
      return get(ledger, `stock.${productId}.${SHELFLIFE_BALANCE_FIELD}`, 0) > 0
    })

  const collection = {}
  productsWithSLBalance.forEach(productId => {
    const collect = get(salesStats, `products.${productId}.collection`, false)
    const slBalance = get(ledger, `stock.${productId}.${SHELFLIFE_BALANCE_FIELD}`)
    const batchId = generateUnknownBatchForProduct(productId)
    const totalBalance = get(ledger, `stock.${productId}.${REPORT_BALANCE_FIELD}`)

    if (collect === COLLECTION_REASONS.NOT_SOLD && slBalance > 0) {
      collection[batchId] = {
        quantity: slBalance,
        reason: COLLECTION_REASONS.NOT_SOLD,
        slBalance,
        totalBalance,
        lastSold: get(salesStats, `products.${productId}.lastSold`)
      }

      return
    }

    const max = get(salesStats, `products.${productId}.max`)
    const overflow = totalBalance - max
    if (collect === COLLECTION_REASONS.ABOVE_MAX && overflow > 0 && slBalance > 0) {
      collection[batchId] = {
        quantity: Math.min(overflow, slBalance), // never collect more than sl balance
        reason: COLLECTION_REASONS.ABOVE_MAX,
        max,
        slBalance,
        totalBalance,
        min: get(salesStats, `products.${productId}.min`)
      }
    }
  })

  // Create change set for all batches currently on shipment and from forecast
  const batchesToUpdate = Array.from(new Set(Object.keys(collection).concat(Object.keys(shipment.counts))))

  const changes = {}
  let changed = false
  batchesToUpdate.forEach(batchId => {
    // If a driver has checked the product, let's not update it
    const alreadyChanged = get(shipment, `counts.${batchId}.checked`, false)
    // Don't change an amount that's already been updated before
    if (alreadyChanged) {
      return
    }

    const currentQuantity = get(shipment, `counts.${batchId}.quantity`)
    let newQuantity = get(collection, `${batchId}.quantity`, 0)

    if (newQuantity !== currentQuantity) {
      changed = true
      set(changes, `${batchId}.quantity`, newQuantity)

      // If a product is not set to be collected,
      // it should be removed from the shipment
      if (newQuantity === 0) {
        set(changes, `${batchId}.removed`, true)
      }
    }
  })

  if (dryRun) {
    const newShipment = await this.mainApi.shipment.createSnapshot({
      ...shipment,
      counts: collection,
      dryRun: true
    })

    newShipment.collection = collection
    return newShipment
  }

  if (!changed) {
    shipment.collection = collection
    return shipment
  }

  await saveCollectionStats(state, {
    snapshotId: shipment.snapshotId,
    collection,
    exclusions: salesStats.exclusions
  })

  const newShipment = await saveChanges(state, shipment.snapshotId, changes)
  return newShipment
}

module.exports = updateCRQuantities
