const isShipmentRelevant = require('./is-shipment-relevant')
const { bulkTranslateShipmentProducts } = require('../shipment/tools/bulk-translate-shipment-products')

const getRelevantShipments = (state, {shipments, date, lastReportDate, products, includeScheduledOutbound, location, service}) => {
  return shipments.filter(shipment => {
    const isReleveant = isShipmentRelevant({
      shipment,
      location,
      endDate: date,
      startDate: lastReportDate,
      includeScheduledOutbound
    })
    return isReleveant
  }).map(shipment => {
    const { translatedCounts } = bulkTranslateShipmentProducts(state, {
      shipment,
      location,
      products: Object.values(products),
      service
    })
    shipment.counts = translatedCounts
    return shipment
  })
}

module.exports = { getRelevantShipments }
